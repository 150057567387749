
.jgnt_block_article_bottom {
	background-color: #fff;
	position: relative;
	z-index: 1;
	padding: 2rem 0;

@media ( max-width: 980px ) {
	.jgnt_content {
		margin: 0 1rem;
	}
}
	
	.articles_see_also {
	}

}

.jgnt_article {
	
}

article.article_page {
	header {
		.frame {
			width: auto;
			left: auto;
			top: auto;
			margin: 0;
			position: relative;
			height: auto;
			z-index: 100;
			h1 {
				font-size: 3rem;
				line-height: 1 !important;
				margin-bottom: 0.8rem !important
			}
			.jgnt_btn_bordered {
				font-size: 14px;
				margin-bottom: 1.5rem;
				position: relative;
				z-index: 1;
			}
			.leed {
				font-size: 1.4rem !important;
				position: relative;
				z-index: 1;
			}
			.published_at {
				font-size: 14px !important;
				margin-top: 1rem !important;
			}
			@media ( max-width: 991px ) {
				h1 {
					font-size: 2.6rem !important;
				}
				.jgnt_btn_bordered {
					font-size: 13px !important;
				}
				.leed {
					font-size: 1.2rem !important;
				}
				.published_at {
					font-size: 13px !important;
				}
			}
			@media ( max-width: 500px ) {
				h1 {
					font-size: 1.8rem !important;
				}
				.jgnt_btn_bordered {
					font-size: 11px !important;
				}
				.leed {
					font-size: 1rem !important;
				}
				.published_at {
					font-size: 11px !important;
				}
			}
		}
	}
	.jgnt_cover {
		@include jgnt_adjust( "center" );
		&[data-text-position='align-center'] {
			.frame {
				margin-left: 8rem;
				margin-right: 8rem;
			}
		}
		&[data-text-position='align-top-left'] {
			align-items: flex-start;
			justify-content: flex-start;
			.frame {
				margin-top: 1rem;
				margin-left: 8rem;
				margin-right: 8rem;
			}
		}
		&[data-text-position='align-top-right'] {
			align-items: flex-start;
			justify-content: flex-end;
			.frame {
				margin-top: 1rem;
				margin-left: 8rem;
				margin-right: 8rem;
			}
		}
		&[data-text-position='align-bottom-left'] {
			align-items: flex-end;
			justify-content: flex-start;
			.frame {
				margin-left: 8rem;
				margin-right: 8rem;
			}
		}
		&[data-text-position='align-bottom-right'] {
			align-items: flex-end;
			justify-content: flex-end;
			.frame {
				margin-left: 8rem;
				margin-right: 8rem;
			}
		}
		@media ( max-width: 1199px ) {
			.frame {
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}
		}
	}
}

.landing_media_page {
	article {
		float: left;
	}
}


.profile_page {
	.articles.blocks {
		left: 10px !important;
		width: 960px !important;
	}
	.views_list {
		article {
			float: left;
		}
	}
@media ( max-width: 1020px ) {
		.modes {
			right: 1rem !important;
		}
}
	.articles.full_width {
		max-width: 940px !important;
		padding-top: 2rem;
@media ( max-width: 1020px ) {
		.modes {
			right: 1rem;
		}
		width: auto !important;
		padding: 2rem 1rem;
		margin: 0 !important;
}
		article {
			float: none !important;
			padding: 0 !important;
			margin-bottom: 4rem;
			.text_group  {
				height: auto !important;
				width: 100% !important;
			}
			.date_ago {
				position: absolute !important;
				text-align: right !important;
				right: auto !important;
				top: 3px !important;
				left: -170px !important;
				width: 150px !important;
				color: $jgntColorText !important;
//				font-weight: bold !important;
				
@media ( max-width: 1200px ) {
		position: static !important;
		margin-bottom: 1rem !important;
		left: 0 !important;
		width: auto !important;
		text-align: left !important;
		border-left: 3px solid $jgntColorText;
		padding-left: 1rem !important;
}
		}
		
			.social {
				position: static !important;
			}
		}
	}
}