.load_layer{
  position: relative;
  top:0;
  left:0;
  //z-index: 10000;
  //width:100%;
  //height:100%;
  //display: flex;
  //align-items: center;
  //background:rgba(255,255,255,0.5);
  .loading_icon{
    background: rgba(0, 0, 0, 0) url("/img/les/loading.png") no-repeat scroll 0 0 / 50px auto;
    height: 50px;
    margin: 0 auto;
    position: fixed;
    right:50px;
    top:50px;
    z-index: 10000;
    width: 50px;
    animation: infinite-spinning 0.5s linear infinite;
  }
}
.notice_layer{
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  display:flex;
  align-items: center;
  .notice{
    width:374px;
    position: relative;
    background:#fff;
    padding:54px 40px;
    box-sizing: border-box;
    margin:0 auto;
    color:$red;
    font-weight: bold;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    text-align: center;
    &.article_notice{
      width:$cell6;
      padding: 0;
      text-align: left;
      header{
        background:$black;
        color:#fff;
        padding: 22px 50px;
        box-sizing: border-box;
        h2{
          font-size:24px;
          margin:0 0 -1px 0;
        }
        p{
          font-weight: normal;
          font-size:14px;
        }
      }
      .main{
        padding: 22px 55px;
        box-sizing: border-box;
        label{
          font-weight: bold;
          color:$black;
          left: -15px;
          position: relative;
          a{
            font-weight: normal;
            text-decoration: underline;
            color:$black;
          }
        }
        ul{
          list-style: none;
          > li{
            margin:0 0 20px 0;
            display: none;
            &.selected{
              display: block;
            }
          }
        }
        ol{
          list-style: disc;
          li{
            font-weight: normal;
            margin:0 0 5px 0;
            color:#000;
          }
        }
      }
    }
  }
}
.veil{
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height:100%;
  display:flex;
  align-items: center;
  z-index:2000;
  background:rgba(255,255,255,0.5);
  aside.side_float_nav{
  	position: fixed;
  	left:-240px;
  	height: 100%;
  	width:230px;
  	z-index: 200;
  	top:0;
  	box-sizing: border-box;
  	padding: 160px 35px 160px 35px;
  	background:#fff;
  	box-shadow: 1px 1px 2px rgba(0,0,0,0.4);
  	transition: ease-in 0.3s all;
  	&.selected{
  		left:0;
  	}
  	h2{
  		font-size: 24px;
  		margin:0 0 20px 0;
  		font-weight: bold;
  	}
  	ul{
  		li{
  			text-transform: uppercase;
  			margin:0 0 20px 0;
  			a{
  				color:#000;
  			}
  		}
  	}
  }
  .close{
    background: #ff4e50 none repeat scroll 0 0;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-size: 14px;
    height: 55px;
    line-height: 55px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 55px;
    left:50%;
    z-index: 20;
    margin:0 0 0 415px;
  }

  &.white{
    form{
      background:#fff;
      color:#343434;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      h2{

      }
      >span{
        color:#343434;
      }

    }
  }
  form{
    position: relative;
    background:#fff;
    width:413px;
    //height:430px;
    padding:38px 55px 38px;
    box-sizing: border-box;
    margin:0 auto;
    color:$black;
    text-align: left;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    &.center{
      text-align: center;
      h2{
        margin:0 0 25px;
      }
      .left{
        float:left;
        width:$cell2;
        box-sizing: border-box;
        padding: 0;
      }
      .right{
        float:right;
        width:$cell2;
        box-sizing: border-box;
        padding: 0;
      }
    }
    .close_button{

      background: #fff none repeat scroll 0 0;
      border-radius: 20px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      display: block;
      font-size: 7px;
      height: 22px;
      line-height: 22px;
      position: absolute;
      right: -11px;
      text-align: center;
      top: -11px;
      width: 22px;
      color:$gray2;
      cursor:pointer;
    }
    &.broadcast_form{
      width:308px;
    }
    .small{
      font-size: 10px;
    }
    &#video_form{
      width:340px;
      padding: 20px;
    }
    &.tab{
      display: none;
      &.selected{
        display: block;
      }
    }
    h2{
      font-size:24px;
      font-weight: bold;
      clear:both;
      margin:0 0 10px 0;
      &.or{
        margin:0 0 14px;
      }
    }
    p.under_h{
      position: relative;
      margin:-10px 0 0 0;
    }

    >span{
      font-size:8px;
      color:#fff;
      margin:0 0 14px;
      display:block;
    }
    ul.types{
      display: block;
      list-style: none;
      li{
        a{
          font-weight: bold;
          color:$black;
        }
      }
    }
    ul.social{
      display: block;
      list-style: none;
      clear: both;
      li{
        display: block;
        float:left;
        width:140px;
        height:34px;
        margin:0 0 10px 0;
        a{
          width:100%;
          height:100%;
          color:#fff;
          font-size:12px;
          font-weight: bold;
          display: block;
          line-height: 34px;
          text-align: center;
          position: relative;
          i{
            height: 16px;
            position: relative;
            width: 16px;
            display: inline-block;
          }
          &.facebook{
            background:#3b5998;
            i{
              background:url($fb-icon) no-repeat;
              margin: 0 5px 0 0;
              top: 4px;
            }
          }
          &.vk{
            background:#45668e;
            i{
              background:url($vk-icon) no-repeat;
              margin: 0 10px 0 0;
              top: 6px;
            }
          }
          &.twitter{
            background:#00aced;
            i{
              background:url($tw-icon) no-repeat;
              margin: 0 13px 0 0;
              top: 5px;
            }
          }
        }
        &:nth-child(even){
          float:right;
        }
      }
    }
    .forgot_password{
      color: $black;
      display: block;
      float: left;
      font-size: 14px;
      font-weight: bold;
      line-height: 34px;
      margin: 0;
      text-align: center;
    }
    .error{
      width:100%;
      box-sizing: border-box;
      font-size: 10px;
      color:$black;
      margin:0px 0 10px 0;
    }
    select,
    input[type='text'],
    input[type='email'],
    input[type='password'],
    textarea{
      width:100%;
      background:$mcolor;
      height:34px;
      border: none;
      color:#000;
      margin:0 0 10px 0;
      box-sizing: border-box;
      padding: 0 10px;
      font-family: $font1;
      font-size: 14px;
      @include placeholder {
        color:#343434;
      }
      &:focus{
      	@include placeholder {
      		transition: opacity 0.2s 0.2s ease;
      		opacity:0;
      	}
      }
    }
    textarea{
      height:112px;
      padding: 13px 12px;
      resize:none;
    }
    /*
    .selectboxit-container{
      * {
        font-family: $font1;
      }
      .selectboxit-options{
        width:100% !important;
        border-radius: 0;
        border: 1px solid #000;
        box-shadow: none;
      }
      .selectboxit{
        width:100% !important;
        border-radius:0;
      }
      span,
      .selectboxit-options a{
        color:#000;
        font-size: 14px;
      }
    }
    .selectboxit-container span, .selectboxit-container .selectboxit-options a{

    }*/
    >p{
      margin:0 0 10px 0;
      a{
        color:$black;
      }
    }
    >span{
      a{
        color:$green;
      }
    }
    button{
      border:none;
      background:$red;
      width:140px;
      border-radius: 15px;
      float: right;
      margin:0 0 10px 0;
      font-weight: bold;
      text-align: center;
      color:#fff;
      height:34px;
      font-size: 14px;
    }
  }
}
