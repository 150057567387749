.search_header_wrap{
  width:$cell12;
  padding: 0;
}
.h1_search{
  font-size:48px;
  margin:80px 0 0 0;
}
nav.search_nav_tabs.sub_header_menu{
  margin:6px 0 0;
  border-bottom:none;
  li{
    font-weight: normal;

    &.selected{
      font-weight:bold;
      a{
        color:$black !important;
      }
    }
  }
}
.search_tabs{
  height:100%;
  .tab{
    display: none;
    &.selected{
      display: block;
    }
  }
  .broadcast_card.opened {
    display: inline;
  }
}
.search_users{
  width:100%;
  list-style: none;
  .user_block{
    width:100%;
  }
  .subscription_button{
    float:right;
  }
  li{
    width:$cell6;
    float:left;
    height:80px;
    &:nth-child(even){
      float:right;
    }
  }
}
