.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1010;
    display: none;
    max-width: 276px;
    padding: 1px;
    text-align: left;
    white-space: normal;
    background-color: #fff;
    border: 1px solid #ccc;
    border: 1px solid rgba(0,0,0,0.2);
    border-radius: 6px;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    box-shadow: 0 5px 10px rgba(0,0,0,0.2);
    background-clip: padding-box;
    &.right{
      margin-left: 10px;
    }
    .arrow,.arrow:after{
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }
    .arrow{
      border-width: 11px;
      &:after{
        border-width: 10px;
        content: "";
      }
    }
    &-title{
      padding: 8px 14px;
      margin: 0;
      font-size: 14px;
      font-weight: normal;
      line-height: 18px;
      background-color: #f7f7f7;
      border-bottom: 1px solid #ebebeb;
      border-radius: 5px 5px 0 0;
    }
    &-content {
        padding: 9px 14px;
    }
}
