@import 'default';

html{
  position: relative;
}
@mixin cell-rules() {
  margin:10px 0;
}
@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &:-moz-placeholder           {@content}
  &::-moz-placeholder          {@content}
  &:-ms-input-placeholder      {@content}
}

@import 'colorpicker_slider';
@import 'magnific_popup';
@import 'fields';
@import 'custom_radio_checkbox';
@import 'defaults';
@import 'font4';
@import 'nouislider';
@import 'selectboxit';
@import 'autocomplete';
@import 'autocomplete_users';
@import 'scrollbar';
@import 'responsiveslides';
@import 'black_tooltip';
@import 'editor_bubble';
@import 'team';

$icons: "/img/les/icons/LES_Icons_";

//sign in
$fb-icon: $icons+"2-01.svg";
$vk-icon: $icons+"2-02.svg";
$tw-icon: $icons+"2-03.svg";

$header-height:55px;

@keyframes animatedBackground {
	from { background-position: 0 0; }
	to { background-position: 1129px 0; }
}
@keyframes infinite-spinning {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body.construction	{
	width:100%;
	height:100%;
	display: flex;
	justify-content: center;
	background-image:url('/img/les/underconstruction.png');
	background-position: 0px 0px;
	background-repeat: repeat;
	animation: animatedBackground 20s linear infinite;
	&.admin{
		background:$black;
		form{
			width:$cell4;
			.logo{
				color:#fff;
			}
			.field{
				margin:0 0 10px 0;
				input{
					width:$cell4;
				}
				button{
					margin:10px 0 0 0;
					width:$cell2;
					background:$red;
				}
			}
		}
	}
	form{
		width:360px;
		text-align: center;
		align-self: center;
		text-align: cecenter;
		.logo{
			display: block;
	    font-size: 145px;
	    height: 35px;
	    line-height: 28px;
	    margin: 0 auto 	48px auto;
			left:24px;
	    position: relative;
	    text-align: center;
		}
		p{
			margin:0 0 42px 0;
		}
		.field{
			input{
				background:#fff;
				border:none;
				width:237px;
				height:35px;
				box-sizing: border-box;
				padding: 0px 10px;
				font-size: 14px;
				font-family: $font1;
				color:$black;
				float:left;
			}
			button{
				float:right;
				width:84px;
				background:$black;
				color:#fff;
				text-align: center;
				height:35px;
				border:none;
				cursor: pointer;
				border-radius: 35px;
			}
		}
	}
}
ul.tags_list{
	list-style:none;
//	margin: 0 -470px 0 0;
	position: absolute;
//	right: 50%;
	bottom:20px;
	li{
		margin:0 7px 0 0;
		display: inline-block;
		&:last-child{
			margin:0;
		}
		a{
			display: inline-block;
			background:$mcolor;
			color:$black !important;
			border-radius: 5px;
			font-weight: normal;
			font-size: 10px;
			padding: 5px 10px;
		}
	}
}
.live-feed{
	position: relative;
	width:940px;
	max-height: 221px;
	overflow: hidden;
	margin:0 auto;
}
.contour_button{
  font-weight: bold;
	color:$black;
	font-size: 14px;
	height:34px;
	line-height: 34px;
	padding: 0 20px;
	border-radius: 30px;
  border: 1px solid $black;
	background:none;
  display: inline-block;
  &:hover{
    color:$black;
  }
}
.red_button{
	font-weight: bold;
	color:#fff;
	font-size: 14px;
	height:34px;
	line-height: 34px;
	padding: 0 20px;
	border-radius: 30px;
	background:$red;
  display: inline-block;
  &:hover{
    color:#fff;
  }
}
.slider_space{
	height:56px;
}
.special_stuff{
	background:url('/img/les/progressors-banner.jpg') fixed center center / cover;
  background:url('/img/media-pol-tymen-back.jpg') fixed center center / cover;
	width:100%;
	height:377px;
	position: relative;
	display: block;
	.filter{
		background:rgba(0,0,0,0.2);
		position: absolute;
		top:0;
		left:0;
		width:100%;
		height:100%;
		z-index: 10;
	}
	a{
		color:#fff;
		&:hover{
			color:#fff;
		}
	}
  .fond{
    display:block;
    position: absolute;
    width:100px;
    right:0;
    bottom:55px;
    width: 234px;
    z-index: 88;
    font-size: 10px;
    img{
      display: block;
      width:100%;
    }
  }
	.broadcast{
		margin:48px 0 0 0;
		border:1px solid #fff;
		width:213px;
		line-height: 34px;
		height:34px;
		display: block;
		text-align: center;
		border-radius: 30px;
    font-weight: bold;
    float:left;
    z-index: 20;
    position: relative;
		.icon{
			display: inline-block;
      margin: 0 8px 0 0;
      position: relative;
      top: 2px;
		}
	}
	.wrapper{
		color:#fff;
		padding:39px 0 0 0;
    position: relative;
    height:100%;
		.left{
			float:left;
			width:$cell4;
			position: relative;
			z-index: 20;
			h2{
				font-size: 48px;
				text-transform: uppercase;
				line-height: 59px;
		    margin: 10px 0 0;
			}
			h3{
				font-size:24px;
				color:#fff;
        text-transform: none;
        font-size: 24px;
        line-height: 7px;
        margin: 26px 0 0;
        position: relative;
			}
		}
		.right{
			float:right;
			width:$cell6;
			position: relative;
			z-index: 20;
      margin:37px 0 0;
			h3{
				font-size:18px;
				color:#fff;
				line-height:26px;
				margin:-18px 0 14px 0;
        text-transform: none;
			}
			p{
				line-height: 20px;
			}
			font-size:14px;
		}

    >p{
      float:left;
      color:#fff;
      z-index: 20;
      clear:both;
      margin:20px 0 0 0;
      line-height: 22px;
      //letter-spacing:-0.5px;
      position: relative;
    }
	}
}
#recomend_section,#experts_recomend_section{
	display: none;
	&.selected{
		display: block;
	}
}
#watching_section,#reading_section{
	display: none;
	&.selected{
		display: block;
	}
}
h2.section_header{
	text-transform: uppercase;
	color:$black;
	font-size:24px;
	margin:0 0 14px 0;
	position: relative;
	.location_name{
		float:right;
		color:$red;
	}
	a{
		color:$black;
		&.selected{
			border-bottom:1px solid $black;
		}
		&.left_margin{
			margin:0 8px 0 0;
		}
		&.right_margin{
			margin:0 0 0 8px;
		}
	}
	span{
		font-size: 14px;
		a{
			font-size: 14px;
			color:$black;
			display: inline-block;
			&:first-child{
				margin:0 4px 0 0;
			}
			&:last-child{
				margin:0 0 0 4px;
			}
		}
	}
}
section.full_height{
	height:100%;
}

nav.aside_articles{
	float:right;
	width:$cell3;
	height: 463px;
	margin: 0 20px 0 0;
	width: 220px;
  &.short{
    height:443px;
    margin: 0 20px 20px 0;
  }
	.news{
		background: #fff;
		height: 523px;
		margin: -1px 0 20px;
		overflow: auto;
		padding: 0 20px;
		h2 {
      font-size: 18px;
      line-height: 35px;
      margin: 0 0 12px;
      padding: 0;
      text-transform: uppercase;
		}
		.date{
			font-size: 10px;
			color:$gray;
			margin:0 0 4px 0;
		}
		h3{
			margin:0 0 5px 0;
      text-transform: none;
      color:$black;
      font-size:14px;
      line-height: 22px;
		}
		ul{
			li{
				border-bottom:1px solid #f4f4f4;
        margin:0 0 10px 0px;
			}
		}
	}
	.notices{
		background: $mcolor none repeat scroll 0 0;
		box-sizing: border-box;
		height: 250px;
		margin: -1px 0 20px;
		overflow: auto;
		padding: 0 20px;
		h2 {
		  padding: 20px 0 0;
		}
	}
}
.user_block{
	.profile_pic{
		float:left;
		width:34px;
		height:34px;
		margin:0 10px 0 0;
		position: relative;
		.icon-close{
      background: $black none repeat scroll 0 0;
      border: 2px solid #fff;
      border-radius: 12px;
      box-sizing: border-box;
      color: #fff;
      font-size: 4px;
      height: 14px;
      line-height: 11px;
      position: absolute;
      right: -4px;
      text-align: center;
      top: -2px;
      width: 14px;
		}
		img{
			display: block;
			width:100%;
			border-radius: 90px;
		}
		.round{
      background: $black;
      border-radius: 90px;
      color: #fff;
      font-weight: bold;
      height: 100%;
      font-size: 14px;
      line-height: 100%;
      text-align: center;
      width: 100%;
	    line-height: 34px;
      text-transform: uppercase;
      box-shadow: 0px 0px 1px rgba(0,0,0,0.3);
    }
	}
	.profile_info{
    float:left;
    //width: 190px;
		margin:-5px 0 0;
    h2{
      font-size: 14px;
      font-weight: bold;
      margin: 0 0 2px;
    }
    p{
      //color:#d9d9d9;
      color:#000;
      line-height: 20px;
    }
  }

}
nav.sub_header_menu{
  background:#fff;
  width:100%;
  margin: $header-height 0 0 0;
  height:55px;
  line-height: 55px;
  text-align: left;
  font-size: 14px;
	border-top:1px solid $mcolor;
	border-bottom:1px solid $mcolor;
  text-transform: uppercase;

  &.volume{
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    z-index: 10;
    text-transform: none;
    > .wrapper > ul > li{
      font-weight: bold;
    }
    ul{
      ul.subfolders{
        width:262px;
        box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.19);
        position: absolute;
        text-transform: none;
        top: 55px;
        &.fixed_width{
          width: 262px;
        }
      }
    }
  }
  .search {
	  position: relative;
	  .search_text {
		  border: 1px solid #888;
	  }
  }
  .tags_list{
    right:0;
    margin: 0;
    bottom:0;
    li{
      margin:0 7px 0 0;
      a{
        height: auto;
        display: inline;
        text-transform: none;
      }
    }
  }
  &.advert{
    margin:352px 0 0 0;
    //transition:ease-out all 0.3s;
    &.shrink{
      margin: $header-height 0 0 0;
    }
  }
	&.after_top{
		margin:0;
	}
	&.center{
		text-align: center;
	}
	.control{
		font-size: 14px;
		padding-left:18px;
	}
	.control__indicator{
		top:20px;
	}
	.wrapper{
		position: relative;
	}
	.search_form{
		position: absolute;
    right: -15px;
    top: 10px;
		&:hover,&:focus{
			input{
				border:1px solid $mcolor;
			}
			.icon-search{
				color:$black;
			}
		}
		input{
			border:rgba(255,255,255,0) 1px solid;
			transition: all ease-in 0.2s;
			position: absolute;
			right:0;
			width:220px;
			background:none;
			border-radius: 30px;
			height:33px;
			line-height: 33px;
			padding:0 20px;
			box-sizing: border-box;
			font-size:14px;
			font-family: $font1;
		}
		.icon-search{
			transition: all ease-in 0.2s;
			position: absolute;
			right: 25px;
			top: 10px;
			color:$mcolor;
		}
	}
  ul{
    display: block;
    .icon-arrow-down-thin{
      position: relative;
      top:2px;
    }
		&.modes{
			position: absolute;
			top:0;
			right:0;
			font-size:12px;
			li{
				margin:0 10px 0 0px;
        a{
          &.selected{
            font-weight: normal;
            color:$gray;
          }
        }
				&.last-child{
					margin:0;
				}
			}
		}

    li{
      display: inline-block;
      margin:0 25px 0 0;
      vertical-align: top;
      &:last-child{
        margin:0;
      }
      &.selected{
        a{
          color:#fff;
        }
      }
			&.dash{
				border-top: 1px solid #000;
				height: 10px;
				position: relative;
				top: 15px;
				vertical-align: top;
				width: 19px;
			}
      a{
        display: block;
        height:100%;
        color:#000;
        &.icon-bars,
        &.icon-magazines{
          margin:20px 0 0 0;
        }
				.icon-arrow-down{
					font-size: 11px;
					margin: 0 0 0 1px;
					position: relative;
					top: 0;
				}
				&.selected{
					font-weight: bold;
				}
      }
			&:hover{
				&.got_subfolders{

				}
				>a{
					color:$gray;
				}
				ul.subfolders{
					display: block;
				}
			}
    }
		ul.subfolders{
			display: none;
			position: absolute;
			background:#fff;
			z-index: 100;
			text-transform: none;
			margin:0 0 0 -20px;
			padding: 0 20px 10px 20px;
			box-sizing: border-box;
			&.fixed_width{
				width:180px;
			}
			li{
				display: block;
				margin: 0;
				line-height: 34px;
				font-weight: normal;
				text-transform: uppercase;
			}
		}
  }
}
@media (max-width: 820px) {
	nav.sub_header_menu{
		height: auto;
	}
}

textarea:focus, input:focus,button{
    outline: none;
}

.next_page{
  background: $red none repeat scroll 0 0;
  border-radius: 30px;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: 33px;
  line-height: 33px;
  padding: 0 19px;
  text-align: center;
  z-index: 200;
}
.hide_placeholder_on_hover:focus{
	@include placeholder {
		transition: opacity 0.2s 0.2s ease;
		opacity:0;
	}
}
.lower_header{
	font-size:24px;
	text-transform: uppercase;
	margin:46px 0 20px 0;
}
.full_banner{
	background:$black;
	height:616px;
	clear: both;
	position: relative;
	//margin:0 0 87px 0;
	.wrapper{
		position: relative;
		height:100%;
    z-index: 10;
	}
  >.tint{
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
	h2.section_header{
		padding:50px 0 130px 0;
		color:#fff;
		span{
			a{
				color:#fff;
			}
		}
	}
	.articles{
		> article{
			width:$cell6;
			vertical-align: top;
			margin:0 0 30px 0;
			padding:0 0 0 0 !important;
			position: absolute;
			bottom:0;
			h2{
				font-size: 48px;
		    height: auto;
		    line-height: 60px;
		    padding: 0;
			}
			.leed{
				position: relative;
				color:#fff;
				height: auto;
				line-height: 21px;
				bottom: auto;
				margin:0 0 7px 0;
			}
			.author{
				.user_pic{
					display: none;
				}
				.user_name{
					strong{
						text-transform:uppercase;
					}
					.date{
						display: none;
					}
				}
			}
		}
		ul.list{
			clear: both;
			position: absolute;
			right: 0px;
			bottom:20px;
			width:$cell6;
			>li{
				list-style: none;
				display: block;
				float:left;
				margin:17px 0 0 0;
				width:$cell3;

				&:nth-child(2n){
					float:right;
				}
				&:nth-child(3n){
					border-bottom:none;
				}
				&:nth-child(4n){
					float:right;
					border-bottom:none;
				}
				.social{
					margin:0 0 20px 0;
				}
        .up,.down{
          bottom:10px;
        }
			}
			article{
				&:hover{
					h2{
						text-decoration: underline;
					}
				}
				width:220px;
				vertical-align: top;
				padding: 0;
				margin:0;
//				height:124px;
        .edit{
          right: -4px;
          top: -25px;
          padding: 0 0 20px 20px;
        }
				.date_ago{
					display: none;
				}
				.text_group{
//					min-height:94px;
//					overflow: hidden;
					margin:0 0 0px 0;
				}
				h2{
					font-size: 18px;
					//height: 60px;
					height:auto;
					line-height: 21px;
					margin:0 0 4px;
				}
				p{
					font-size:14px;
					margin:0 0 5px 0;
					line-height: 21px;
				}
			}
		}
		article{
			background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
			border: medium none;
			padding:0 20px 0 0;
			.author{
				display: none;
			}
			a{
				color:#fff;
			}
			h2{
				color:#fff;
			}
			.social{
				ul{
					li{
						a{
							color:#fff;
						}
					}
				}
			}
			.date_ago{
				position: relative;
				bottom:auto;
				right:auto;
			}
			.author{
				.user_date{
					.date{
						background:#fff;
					}
				}
			}
		}
	}
}//full_banner end
section.create_and_stuff{
	margin:70px 0 80px 0;
	padding:40px 0 22px;
	background:$mcolor;
	clear:both;
}
section.wrapper{
	&.margin_top_pad{
		margin:41px auto 0 auto;
	}
	&.home_wrap.strict_top_pad{
		margin:55px auto;
		padding: 0 40px;
	}
	article{
		margin:0 20px 20px 0;
		&:nth-child(4n+4){
			margin:0 0 20px 0;
		}
	}
}
.become_author{
	.left{
		float:left;
		width:$cell2;
	}
	.right{
		float:right;
		width:$cell9;
	}
	.authors{
    .black_tooltip{
      .tooltip_holder{
        top:-22px;
      }
    }
		.author{
			width:56px;
			height:56px;
			float:left;
			clear:none;
			margin:0 24px 20px 0;
			&:nth-child(9n+9){
				margin:0 0 20px 0;
			}
			.user_pic{
				width:100%;
				height:100%;
				img{
					width:100%;
					height:100%;
				}
				.round{
					width:100%;
					height:100%;
					line-height: 53px;
					font-size: 20px;
				}
			}
		}
	}
}

.gallery{
	.wrapper{
		padding: 0;
	}
	.rslides_frame{
		position: relative;
		display: none;
		&.selected{
			display: block;
		}
    .inscription{
      float:left;
    }
	}
	.gallery_frame{
		width:820px;
		margin:0 auto;
		box-shadow:0 0 4px rgba(0, 0, 0, 0.2);
		padding:20px;
		position: relative;
		box-sizing: border-box;
		display: none;
		&.selected{
			display: block;
			left:-20px;
		}
	}
  .cancel{
    color:#000;
  }
	.save{
		background: #57996b none repeat scroll 0 0;
		border: 1px solid #57996b;
		border-radius: 30px;
		color: #fff;
		display: inline-block;
		font-weight: bold;
		height: 32px;
		margin: 0 0 0 20px;
		text-align: center;
		width: 140px;
	}
	.previews{
		position:relative;
		min-height:55px;
		display:block;
		margin:0 0 1px 0;
		list-style: none;
		li{
			display: inline-block;
			margin:0 1px 0 0;
			vertical-align: top;
			&.selected{
				border:3px solid $red;
        box-sizing: border-box;
				a{
					display: block;
					height: 51px;
					overflow: hidden;
				}
			}

			a{
				display: block;
			}
			img{
				height:55px;
				width:auto;
			}
		}
	}
	.list{
		display: block;
		list-style: none;
		li{
			display: none;
			img{
				background:$mcolor;
//				width:780px;
				//height:520px;
				display: block;
				margin:0 0 10px 0;
			}
			textarea{
				background:$mcolor;
				height:55px;
				width:100%;
				display: block;
				border: none;
				resize: none;
				font-family: $font1;
				box-sizing: border-box;
				padding: 10px;
				font-size: 10px;
        color:$gray;
        line-height: 20px;
        margin:11px 0 0 0;
			}
			&.selected{
				display: block;
			}
		}
	}
	.edit{
		position: absolute;
		right:20px;
		top:20px;
		line-height: 55px;
		a{
			display:inline-block;
			margin:0 3px 0 0;
			background:$black;
			border-radius: 20px;
			width:23px;
			height:23px;
			color:#fff;
			vertical-align: middle;
			&:last-child{
				margin:0 10px 0 0;
			}
			span{
				line-height: 23px;
				display: block;
				text-align: center;
				font-size: 12px;
			}
		}
	}
	.buttons{
		position: absolute;
		right:20px;
		bottom:23px;
		line-height: 30px;
	}
	.rslides_nav{
		position: absolute;
		bottom:50%;
		z-index: 10;
		font-size:44px;
		color:#fff;
		background:rgba(0,0,0,0.1);
		border-radius: 0 12px 12px 0;
		margin: 0 0 0 0px;
		padding: 2px 5px 2px 0;
		.icon-arrow-up-thin{
			-moz-transform: rotate(-90deg);
			-webkit-transform: rotate(-90deg);
			-o-transform: rotate(-90deg);
			transform: rotate(-90deg);
			position: relative;
			display: block;
			padding: 5px 0;
		}
		&.prev{
			left: 0;
			z-index: 20;
		}
		&.next{
			right:0;
			z-index: 20;
			-moz-transform: scaleX(-1);
			-webkit-transform: scaleX(-1);
			-o-transform: scaleX(-1);
			transform: scaleX(-1);
		}
	}
}
.gallery.stripped{
	.edit{
		z-index: 20;
		line-height: unset;
		&.hidden{
			display: none;
		}
		a{
			margin:0 10px 0 0;
			&:last-child{
				margin:0;
			}
		}
	}
	.previews{
		&.hidden_always,
		&.hidden{
			display: none;
		}
	}
	.load-gallery,
	.load-gallery-form,
	.replace-image-form{
		display: none;
	}
	.buttons{
		display: none;
	}
/*
	.tint{
		height:625px;
		width:940px;
	}
*/
	.list{
		li{
			textarea{
				background:none;
				height:20px;
				padding: 0;
			}
		}
	}
	.rslides_nav{
		bottom:auto;
		top:50%;
	}
	.upload_images_banner{
		.image_frame{
			background: $mcolor url("/img/les/upload.svg") no-repeat !important;
			background-position: center center !important;
			background-size: contain !important;
		}
	}
	.gallery_frame{
		box-shadow: none;
		padding:0;
		width:100%;
		left:0;
//		height:auto;
		.picture_frame{
			width:100%;
//			height:100%;
			.image_frame{
				min-height: 80vh;
				max-height: 100vh;
/*
				width:940px;
*/
//        display: flex;
				background:$mcolor;
//				display: flex;
//				align-items:center;
//				flex-direction: column;
				margin:0 0 10px;
//				justify-content:center;
			}
/*
			img{
				margin: 0;
				max-height: 100%;
				max-width: 100%;
				width: auto !important;
				height: auto !important;
			}
*/
		}
	}
}

.black_banner{
  height:56px;
  background:#000;
  color:#fff;
  font-size: 14px;
  line-height: 56px;
  a{
    font-weight: bold;
    color:#fff;
  }
  .wrapper{
    position: relative;
    text-align: center;
    ul{
      display: inline-block;
      list-style: none;
      li{
        display: inline-block;
        &:first-child{
          margin:0 20px 0 0;
        }
      }
    }
  }
}

.subscribe_icon{
  display: block;
  width:21px;
  height:21px;
  line-height: 21px;
  text-align: center;
  .sub{
    background: #000 none repeat scroll 0 0;
    border-radius: 30px;
    color: #fff;
    font-size: 11px;
    height: 100%;
    line-height: 22px;
    text-align: center;
    width: 100%;
  }
  .subed{
    width:100%;
    height:100%;
    background:$red;
    color:#fff;
    border-radius: 30px;
    display: none;
    position: relative;
    &:after{
      width: 3px;
      height: 7px;
      left: 7px;
      top: 5px;
      transform: rotate(45deg);
      border: solid #fff;
      content:"";
      position: absolute;
      border-radius: 0 0 1px 1px;
      border-width: 0 3px 3px 0;
    }
  }
  &.subscribed{
		.subed{
			display: block;
		}
		.sub{
			display:none;
		}
	}
}
.small_map_location{
  position: absolute;
  width:460px;
  height:245px;
  border-radius: 0 0 5px 5px;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background:$gray2;
  .map_view{
    height:210px;
    width:100%;
    display: block;
  }
  .map_input{
    display: block;
    height:36px;
    width:100%;
    border: none;
    box-shadow: none;
    line-height: 36px;
    padding: 0 10px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 0 0 5px 5px;
  }
  .icon-search{
    bottom: 11px;
    position: absolute;
    right: 10px;
    color:$red;
    cursor:pointer;
  }
  .close{
    position: absolute;
    right:-11px;
    top:-11px;
    background:#fff;
    color:$gray2;
    width:22px;
    height:22px;
    cursor: pointer;
    line-height: 20px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.18);
    border-radius: 30px;
    text-align: center;
    .icon-close{
      font-size: 8px;
      display: inline-block;
    }
  }
}
.subscription_button{
	display: block;
//	bottom:2px;
	width:156px;
	text-align: center;
	height:34px;
	line-height: 34px;
	font-size: 14px !important;
	font-weight: bold;
	position: relative;
	margin: 0px 0 0 9px;
	.sub{
//		border:1px solid $black;
		display: block;
		border-radius: 30px;
		background:$black;
		color:#fff;
	}
	.subed{
		display: none;
		background:$red;
//		border:1px solid $red;
		color:#fff;
		width:100%;
		height:100%;
		border-radius: 30px;
	}
	&.subscribed{
		.subed{
			display: block;
		}
		.sub{
			display:none;
		}
	}
}
aside.side_float_nav{
	position: fixed;
	left:-240px;
	height: 100%;
	width:230px;
	z-index: 200;
	top:0;
	box-sizing: border-box;
	padding: 160px 35px 160px 35px;
	background:#fff;
	box-shadow: 1px 1px 2px rgba(0,0,0,0.4);
	transition: ease-in 0.3s all;
	&.selected{
		left:0;
	}
	h2{
		font-size: 24px;
		margin:0 0 20px 0;
		font-weight: bold;
	}
	ul{
		li{
			text-transform: uppercase;
			margin:0 0 20px 0;
			a{
				color:#000;
			}
		}
	}
}
@import 'settings';
@import 'articles';
@import 'landings';
@import 'chat';
@import 'profile';
@import 'map';
@import 'about';
@import 'search';

body.article_page,body.new_article_page{
  background:#fff;
}
body.rubric_page{
  section.wrapper{
    margin:0 auto;
  }
}
.rubric_name{
  text-align: center;
  font-size:24px;
  height:110px;
  line-height: 110px;
  text-transform: uppercase;
}

button{
  display: inline-block;
  border:1px solid $red;
  background:$red none repeat scroll 0 0;
  color:#fff;
  width:140px;
  height:32px;
	border-radius: 30px;
  font-weight: bold;
  font-size:14px;
	font-family: $font1;
	cursor: pointer !important;
  &:hover{
    background:#fff;
    color:$red;
  }
}
.editor_buttons{
  //width:100%;
  box-sizing: border-box;
  padding: 12px 25px 12px 0;
  text-align: right;
  float:right;
	a{
		display: inline-block;
		margin:0 20px 0 0;
		width:auto;
		height:32px;
		vertical-align: top;
		background: $red none repeat scroll 0 0;
		border-radius: 30px;
		color: #fff;
		display: block;
		font-size: 14px;
		font-weight: bold;
		height: 33px;
		line-height: 33px;
		padding: 0 19px;
		text-align: center;
		top: 12px;
		&:hover{
			color:#fff;
		}
	}
  button{
		display: inline-block;
		margin:0 20px 0 0;
		color:$black;
		width:auto;
		background: none;
		height:32px;
		font-weight: normal;
		text-align: center;
		line-height: 13px;
		border: none;
		padding: 0;
		text-transform: uppercase;
		vertical-align: top;
    &.clean{
      font-weight: bold;
      cursor: pointer;
      font-size:14px;
      text-transform: none;
      &:hover{
        color:$black;
      }
      &.selected{
        text-decoration: underline;
      }
    }
    &.icon_black{
      font-size: 16px;
      height: 34px;
      line-height: 32px;
      background:$black;
      color:#fff;
      width:34px;
      margin:0 5px 0 0;
      font-size: normal !important;
      &.selected{
        font-weight: normal;
        display: none;
      }
      &.black_tooltip{
        .tooltip_holder{
          top:40px;
          .tooltip_text{
            &:after{
              margin-top: -10px;
              position: absolute;
              top: 0;
              transform: rotate(180deg);
            }
          }
        }
      }

    }
    &.black{
      height:34px;
      line-height: 34px;
      background:$black;
      text-transform: none;
      font-weight: bold;
      color:#fff;
      padding:0 20px;
      margin:0 0 0 5px;
      &:last-child{
        margin:0 0 0 20px;
      }
    }
		&.selected{
			font-weight: bold;
		}
    &:hover{
      color:#fff;
    }
    &:last-child{
      margin:0;
    }
  }
}
@media (max-width: 960px) {
	.editor_buttons {
		float: left;
		margin-left: 20px;
	}
}
@media (max-width: 780px) {
	.editor_buttons {
		float: none;
		margin: 0;
		position: absolute;
		left: 20px;
		bottom: 0;
		a {
			height: 20px;
			font-size: 12px;
			line-height: 20px;
			padding: 0 10px;
		}
	}
}
nav.aside_nav{
  .promo,
  .news_list,
  >header.user_profile{
    background: $mcolor;
    width:100%;
    padding:23px;
    box-sizing: border-box;
    margin:0 0 1px 0;
  }
  &.profile{
    ul{
      li{
        &.selected{
          a{
            .icon,h3,.count{
              color:$red;
            }
          }
        }
        a{
          .icon,h3,.count{
            color:#000;
          }
        }
      }
    }
  }
  .notices{
    background: #mcolor none repeat scroll 0 0;
    box-sizing: border-box;
    height: 275px;
    margin: -1px 0 20px;
    overflow: auto;
    padding: 0px 20px 0px 20px;
    h2{
      padding: 20px 0 0 0;
    }
  }
  .news_list{
    height:275px;
    background: #fff;
    overflow: auto;
    border: 1px solid $gray2;
    padding: 0px 20px 0px 20px;
    h2{
      padding: 20px 0 0 0;
    }
  }
  .notices,
  .news_list{
    h2{
      font-size: 15px;
      font-weight: bold;
      color:$black;
    }
    ul{
      li{
        border-bottom:1px solid #d9d9d9;
        display: block;
        margin:15px 0 15px 0;
        a{
          .date{
            font-size:10px;
            color:$gray;
            margin:0 0 5px 0;
          }
          h3{
            font-size: 12px;
            font-weight: bold;
            color:#333;
            margin:0 0 15px 0;
            text-transform: none;
          }
        }
        &:last-child{
          border-bottom: 0;
        }
      }
    }
  }
  .menu{
    margin:14px 0 0 0;
    ul{
      font-size: 8px;
      li{
        display: inline-block;
        margin:0 8px 0 0;
        &:last-child{
          margin:0 0 0 0;
        }
        a{
          text-decoration:underline;
          color:$gray;
          display: block;
          &:hover{
            text-decoration: none;
          }
        }
      }
    }
  }
  .promo{
    margin:0 0 23px 0;
    .tag{
      font-size: 10px;
      font-weight: bold;
      color:$red;
      margin:0 0 10px 0;
    }
    .date{
      color:$gray;
      font-size: 10px;
      margin:0 0 35px 0;
    }
    h2{
      font-size: 15px;
      font-weight: bold;
    }
  }

  >ul{
    &.sublist{
      li{
        h3{
          text-transform: none;
        }
      }
    }
    li{
      background:$mcolor;
      margin:0 0 1px 0;
      width:100%;
      display: block;
      height:54px;
      position: relative;
      &.large_space{
        margin:0 0 12px 0;
      }

      &.selected{
        .name,.icon,.count{
          color:$red;
        }
        /*
        &:after{
          background: $red none repeat scroll 0 0;
          content: " ";
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          width: 5px;
        }*/
      }

      a{
        display: block;
        padding: 20px;
        box-sizing: border-box;
        height:100%;
				&.highlight{
					color:$red !important;
					transition: ease-out 0.1s all;
					.name{
						color:$red !important;
						transition: ease-out 0.1s all;
					}
				}
        &:hover{
          .name,.icon,.count{
            color:$red;
          }
        }
        .icon{
          float:left;
          width:15px;
          margin:0 28px 0 0;
          display: block;
          height:20px;
          font-size: 16.5px;
          color:$gray;
        }
        .name{
          float:left;
        }
        .count{
          font-weight: bold;
          float:right;
          color:$gray;
        }
      }
    }
  }
}
nav.sub_menu{
  display: block;
  position: absolute;
  right:0;
  margin:0px -10px 0 0;
	font-size:12px;
	a{
		color:$gray;
	}
  .arrow_up{
    border-bottom: 8px solid $red;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    height: 0;
    left: 50%;
    margin: 3px 0 0 -8px;
    position: absolute;
    width: 0;
  }
  ul{
    background:$red;
    display: block;
    position: relative;
    margin:10px 0 0 0;
    width:140px;
    box-sizing: border-box;
    padding: 15px 20px;
    line-height: normal;
    clear: both;
    li{
      height:35px;
      clear: both;
      line-height: 35px;
      margin: 0 0 0px 0;
      width:100%;
      float: none;
    }
  }
}
.search_text{
	height:34px;
	line-height: 34px;
	border:1px solid $mcolor;
	border-radius: 30px;
	color:$black;
	width:$cell3;
	padding: 0 30px;
	box-sizing: border-box;
}
.search_icon_position{
	position: absolute;
	left:10px;
	top:19px;
  font-size: 17px;
	color:$gray2;
	z-index: 20;
}
@import 'veil';
@import 'subscribers_group';
body, .jgnt_page {
  background:#fff;
  font-size: 14px;
  .wrapper{
    width:960px;
    padding:0 10px;
    width:940px;
    padding:0;
    margin:0 auto;
    box-sizing: border-box;
  }
  .fixed_holder{
    //position: fixed;
  }
  a{
    color:$link;
    &:hover{
      color:$red;
      text-decoration: none;

      h3{
        color:$red;
      }
    }
  }
  h3{
    font-size:12px;
    text-transform: uppercase;
    color:$gray;
    font-weight: bold;
  }
  .header_article_type{
		height:34px;
		text-align: center;
		position: fixed;
		top:$header-height - 30px;
		width:100%;
		background:#485f99;
		color:#fff;
		line-height: 34px;
		left:0;
		transition: ease-out 0.2s all;
		z-index: 109;
    &.display_as_view{
      display: none;
    }
		&.selected{
			top:$header-height;
		}
		&.current_draft{
			background:$true_red;
		}
		&.personal{
			background:$red;
		}
		&.draft{
			background:$purple;
		}
		&.public{
			background:$gray;
		}
		&.archive{
			background:$black;
		}
		&.export{
			background:$pink;
		}
	}
  >header{
    width:100%;
    position: fixed;
    top:0;
    background:#fff;
    height:$header-height;
    z-index: 100;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding:0 0px;
		z-index: 110;
    &.advert{
      height:352px;
      //transition:ease-out all 0.3s;
      &.shrink{
        top:-297px;
      }
    }
    .special_stuff{
      height:297px;
      .wrapper{
        padding:18px 0 0 0;
        height:297px;
        position: relative;
      }
      .broadcast{
        margin:11px 0 0 0;
      }
      .fond{
        bottom:33px;
      }
    }
		&.broadcast_header,
		&.broadcast_short_header,
		&.media_header{
			position: relative;
			height: auto;
			box-shadow: none;
			z-index: 1;
			.wrapper{
				position: relative;
			}
			.edit{
				position: absolute;
		    right: 10px;
		    top: 64px;
	      .button{
	        height:34px;
	        line-height: 34px;
	        border-radius: 30px;
	        margin:0 10px 0 0;
	        display: inline-block;
	        padding:0 17px;
	        background:$black;
	        color:#fff;
	        font-weight: bold;
	        &:last-child{
	          margin:0;
	        }
	      }
	    }
			ul.links{
	      position: absolute;
	      top: 90px;
	      right:0;
	      list-style: none;
	      li{
	        display:inline-block;
	        margin:0 10px 0 0;
          &:last-child{
            margin:0;
          }
	        a{
	          display: block;
		          color:$black;
	          font-size:18px;
	        }
	      }
	    }
		}
		&.broadcast_short_header{
			background:$black;
			min-height:176px;

			>.wrapper{
				min-height:176px;
			}
			h1{
				font-size: 48px;
				font-weight: normal;
				color:#fff;
				position: absolute;
				bottom:29px;
				left:0;
		    white-space: nowrap;
				overflow: hidden;
				width:100%;
				strong{
					text-transform: none;
				}
			}
			ul.links{
				bottom: 20px;
//				height: auto;
				list-style: outside none none;
				position: absolute;
				right: auto;
				top: auto;
				li{
					a{
						color:#fff;
						margin:0 20px 0 0;
					}
					a.button{
						color:#fff;
						font-size: 14px;
						border:1px solid #fff;
					}
				}
				.subscription_button{
					.sub{
						border:1px solid #fff;
					}
				}
			}

			.tint{
				position: absolute;
				width:100%;
				height:100vh;
				left:0;
				top:0;
			}
			nav.main_menu{
        margin:20px 0 0 0;
				ul{
					li{
            &.hidden{
              display: none;
            }
						a{
							color:#fff;
						}
						.button{
							border:1px solid #fff;
/*
              &#back_to_broadcast{
                border:1px solid №ааа;
                font-weight: normal;

              }
*/
						}
						a.media_logo{
							margin:23px 0 55px 0;
						}
					}
				}
			}
			.wrapper{
				position: relative;
				height:100%;
			}
			.edit{
				top:auto;
				bottom:20px;
			}
		}
		&.broadcast_header{
			height:450px;
			.edit{
				top:120px;
			}
			a{
				color:#fff;
			}
			.wrapper{
				position: relative;
				height:100%;
			}
			.tint{
				position: absolute;
				width:100%;
				height:100%;
				left:0;
				top:0;
			}
			ul.links{
				li{
					a{
						color:#fff;
					}
				}
				.subscription_button{
					.sub{
						border:1px solid #fff;
					}
				}
			}
			.broadcast_info{
				position: absolute;
				bottom:0px;
				left:0px;
				color:#fff;
				h1{
					font-size:48px;
					font-weight: bold;
					margin:0 0 4px 0;
				}
				p{
					font-size: 14px;
					font-weight: bold;
					margin:0 0 16px 0;
				}
				.sponsored_tags{
					height:23px;
					margin:0 0 20px 0;
				}
			}
			nav.main_menu{
				ul{
					li{
						.button{
							color:#fff;
							border:1px solid #fff;
						}
						a{

						}
					}
				}
			}
		}
    @import 'header_broadcast_mediapolygon';
    // end broadcast_header
		.article_status{
			float:left;
			line-height: 55px;
			margin: 0 0 0 20px;
		}
    .logo_spacer{
      height:37px;
      top:10px;
      position: relative;
      float:left;
      width:1px;
      background:#000;
      margin:0 13px 0 13px;
      &.white{
        background:#fff;
      }
    }
    .logo{
      margin:11px 0 0 0;
      float:left;
      &.logo_h{
        margin:0;
        img{
          height:55px;
          top:0;
        }
      }
      .landing_name{
        font-size: 24px;
        font-weight: bold;
        color:$true_black;
        text-transform: uppercase;
        position: absolute;

        max-width:245px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
			&.landing_logo{
				img{
					height: 32px;
					width: auto;
				}
			}
			.icon-logo{
				font-size: 83px;
				margin: -27px 0 0;
				height:70px;
				overflow: hidden;
			}
      img{
				display: block;
		    height: 27px;
		    position: relative;
		    top: 4px;
      }
    }
		.live-feed-banner{
			background: $red none repeat scroll 0 0;
	    box-sizing: border-box;
	    color: #fff;
	    float: left;
	    height: 30px;
	    left: 10px;
	    line-height: 30px;
	    overflow: hidden;
	    padding: 0;
	    position: relative;
	    text-align: center;
	    top: 10px;
	    white-space: nowrap;
	    width: 0px;
			transition: 0.2s ease-in width;
			&.selected{
				width:100px;
			}
		}
    .search{

    }
    a{
      color:$black;
    }
    nav.main_menu{
		float:right;
		font-weight: bold;
		&.main_folders{
			margin:0 0 0 24px;
			position: absolute;
			margin:0 0 0 108px;
			a{
				font-weight: bold;
			}
		}
		&.to_left{
			float:left;
			font-weight: bold;
			ul{
				li{
					a{
						&.selected{
							color:$black;
							font-weight: bold;
							&.media_logo{
								&:after{
									display: none;
								}
							}
							&:after{
								border-top: 1px solid $black;
							content: " ";
							display: block;
							position: relative;
							top: -20px;
							width: 100%;
							}
						}
					}
				}
			}
		}
      ul{
        height:100%;
        line-height: 55px;
        li{
          display: block;
          float: left;
          margin:0 20px 0 0;
          position: relative;
          color:$gray;
					.media_logo{
						margin:23px 0 20px 0;
            min-height: 75px;
            &.mediapoly{
              margin:0;
              img{
                width:188px;
              }
            }
						&:hover{
							.resize{
								display: block;
							}
						}
						img{
							width:100px;
							display: block;
							position: relative;
							z-index: 10;
						}
						.resize{
						 background: rgba(0, 0, 0, 0) url("/img/les/resize.svg") repeat scroll 0 0;
				     bottom: 13px;
				     display: none;
				     height: 16px;
				     position: absolute;
				     right: -8px;
				     width: 16px;
						 z-index: 5;
						}
					}
					.button{
						background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
				    border: 1px solid $red;
				    color: $red;
						border-radius: 30px;
				    font-weight: bold;
				    height: 32px;
				    line-height: 32px;
				    margin: 10px 0 0;
				    padding: 0 20px;
				    text-align: center;
				    vertical-align: top;
						&.black{
							background:$black;
							border:1px solid $black;
							color:#fff;
						}
					}
          &.search{
            .name{
              margin:-10px 0 0 -11px;
            }
						&.selected{
							.search_text{
								width:$cell3;
								border:1px solid $mcolor;
								color:$black;
								padding: 0 30px;
                background:#fff;
							}
							.search_icon_position{
								left:10px;
								color:$gray2;
							}
						}
						.search_text{
							width:17px;
							padding: 0;
							transition: 0.2s ease-in all;
							border: 1px rgba(0,0,0,0) solid;
              background:none;
						}
						.search_icon_position{
							color:$black;
							left:0;
							transition: 0.2s ease-in all;
						}
          }
          &.nav{
            .name{
              margin:-10px 0 0 -12px;
            }
          }
          &.home{
            .name{
              margin:-10px 0 0 -21px;
            }
          }
          &.notice{
            .name{
              margin:-10px 0 0 -26px;
            }
          }
          &.post{
            .name{
              margin:-10px 0 0 -29px;
            }
          }
          &.profile{
						>a{
							font-weight: bold;
							&:hover {
								color:$black;
								text-decoration: underline;
							}
						}
				
/*

						&:hover{
							>a{
								color:$black;
								text-decoration: underline;
							}
						}
*/

            &.unsigned{
              a{
                display: inline-block;
              }
            }
          }
          &:last-child{
            margin:0;
          }
          &:hover{
            a{
              .name{
                display: block;
              }
            }
          }
          a{
            display: block;
            height:100%;
            .icon{
              height:14px;
              top: 3px;
              width: 18px;
              position: relative;
              display:inline-block;
              font-size: 19px;
            }
						.icon-close{
							color:$red;
							font-size: 14px;
						}
            .name{
              display: none;
              position: absolute;
              line-height: normal;
              white-space: nowrap;
              font-size:8px;
              background:$red;
              padding: 3px 8px;
              margin:-10px 0 0 0;
              color:#fff;
              &::before{
                content: " ";
                border-bottom: 5px solid $red;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                height: 0;
                left: 50%;
                margin: -7px 0 0 -3px;
                position: absolute;
                width: 0;
              }
            }
						&.selected,
            &:hover{
              color:#fff;
              color:$red;
            }
          }
          &:hover{
            nav.sub_menu{
              display: block;
            }
          }
          nav.sub_menu{
            display: none;
						left: 50%;
						margin: -14px -10px 0 -70px;
						position: absolute;
						width: 141px;
            .arrow_up{
              background: #fff none repeat scroll 0 0;
              border: medium none;
              box-shadow:-2px -2px 3px -2px rgba(0, 0, 0, 0.32);
              content: "";
              display: block;
              left: 50%;
              height: 8px;
              margin: -2px 0 0 -5px;
              width: 8px;
              position: absolute;
              transform: rotate(45deg) translate(6px, 6px);
              z-index: 4;

            }
            ul{
              background:#fff;
              display: block;
              position: relative;
              margin:10px 0 0 0;
              width:140px;
              box-sizing: border-box;
              border-radius: 10px;
              padding: 15px 18px;
              line-height: normal;
              clear: both;
							border:1px solid #f7f7f7;
							box-shadow:0 0 4px rgba(0, 0, 0, 0.32);
              li{
                height:35px;
                font-size: 14px;
								font-weight: normal;
                clear: both;
                line-height: 35px;
                margin: 0 0 0px 0;
                width:100%;
                float: none;
								&:hover{
									a{
                    span.folder{
                      text-decoration: underline;
                    }
									}
								}
                a{
                  color:#333;
									.ico{
                    font-size: 14px;
                    margin: 0 9px 0 0;
									}
                }
              }
            }
          }
        }
      }
    }
  }
	@media (max-width: 760px) {
		header {
			nav.main_menu {
				&.to_left {
					position: relative;
					float: left;
					margin-left: 1rem;
					margin-right: 1rem;

				}
				.profile, .search {
					display: none;
				}

			}

		}
		&.post_page {
			header {
				nav.main_menu {
					.profile, .search {
						display: block;
					}
					ul {
						line-height: 30px;
						margin: 12px 0;
					}
					.search_icon_position {
						top: 9px;
					}
				}
			}
		}

	}

	@media (max-width: 480px) {
		&.post_page {
			header {
				nav.main_menu {
					ul {
						li {
							margin-right: 5px;
						}
					}
				}
			}
		}
	}

  section.wrapper{
    margin:($header-height+0px) auto 0 auto;
    box-sizing: border-box;
    padding:0 40px 44px 40px;
    width: 100%;
    max-width:1020px;
    background:#fff;
    &.map_wrap{
      margin:49px auto 0 auto;
      padding:55px 40px 0;
      position: relative;
    }
    &.flat_wrap{
      margin-top: 0;
      padding: 0px 40px 44px 40px;
    }
    &.home_wrap{
     padding: 0px 40px 44px 40px;
    }
		&.profile_wrap{
			padding: 0;
			width: 940px;
		}
		&.landing_wrap{
			margin:20px auto 67px auto;
			padding: 0 40px 0;
		}
    .rubrics{
      display: block;
      width:100%;
      font-size: 12px;
      font-weight: bold;
      display: block;
      margin: -17px 0 40px;
      ul{
        list-style: none;
        display: flex;
        width:100%;
        flex-direction: row;
        justify-content: space-between;
        li{
          display:block;
          &:hover,
          &.selected{
            a{
              color:$red;
            }
          }
          a{
            display: block;
            height:100%;
            color:#333;
          }
        }
      }
    }
    .cell3,.cell6,.cell9{
      float:left;
    }

    .articles{
      &.cell6{
        margin:0px 0px 0 20px;
        article{
          &:nth-child(even){
            float:right;
          }
        }
      }
      &.cell9{
        width:$cell9+20px;
        nav.aside_nav.cell3{
          float:right;
          margin:0 20px 0 0;
          height:588px;
        }
        article{
          vertical-align: top;
          margin:0 20px 20px 0;
        }
        .load_more{
          margin:0 20px 45px 0;
        }
      }


    }
    > aside{
      .fixed_holder{
        width:$cell3;
      }
      section{
        width:$cell3;
        background:#fff;
        margin:0 0 1px 0;
        box-sizing: border-box;
        padding:24px 20px 16px 20px;
        font-size: 12px;
        h3{
          margin:0 0 10px;
        }
        p{
          line-height: 20px;
        }
      }
    }
  }
 > footer {
    width:100%;
    background: $mcolor;
    .wrapper{
      padding: 40px 0;
    }
    nav.lowest{
      width:100%;
      background:$black;
			.wrapper{
				position: relative;
			}
			.icon-logo{
				color: $gray;
		    font-size: 83px;
		    position: absolute;
		    top: 8px;
			}
      ul{
        text-align: left;
				float:right;
        li{
          display: inline-block;
					margin:0 20px 0 0;
					&:last-child{
						margin:0;
					}
          a{
            color:$gray;
						&:hover{
							color:#fff;
						}
          }
        }
      }
    }


  }
}
.city_search{
	line-height:60px;
	height:60px;
	span{
		text-transform: uppercase;
		margin:0 20px 0 0;
	}
	button{
		border-radius: 30px;
		height:35px;
		line-height: 35px;
		position: relative;
		top:3px;
	}
	input[type='text']{
		border:none;
		background:#fff;
		box-shadow: none;
		height:35px;
		line-height: 35px;
		width:220px;
		box-sizing: border-box;
		padding: 0 20px;
		margin:0 20px 0 0;
	}
}
.ratings{
  background:$mcolor;
  width:100%;
  padding: 40px 0;
  h2{
    font-size:15px;
    font-weight: bold;
    margin:0 0 30px 0;
    text-transform: uppercase;
    padding: 0 20px;
    box-sizing: border-box;
    nav.time{
      text-transform: none;
      display: inline-block;
      margin:0 0 0 10px;
      font-size:10px;
      a{
        color: $black;
        &.selected,
        &:hover{
          color: $red;
        }
      }
    }
  }
  nav.categories{
    font-size: 12px;
    font-weight: bold;
    margin:0 0 30px 0;

    padding: 0 20px;
    box-sizing: border-box;
    ul{
      li{
        display: inline-block;
        margin:0 15px 0 0;
        &:hover,
        &.selected{
          a{
            border-bottom:3px solid $red;
            transition: 0.2s ease-out border;
            color:$red;
          }
        }
        a{
          display: block;
          padding:0 0 8px 0;
          border-bottom:3px solid rgba(255,78,80,0);
          transition: 0.2s ease-out border;
          color:$black;
        }
      }
    }
  }
  .articles_list{
    ul{
      list-style: none;
      display: none;

      &.selected{
        display: block;
        li{
          width:$cell3;
          margin:0 20px 16px 0;
          padding: 0 20px;
          box-sizing: border-box;
          display: inline-block;
          vertical-align: top;
          &:nth-child(3n+3){
            margin:0 0 10px 0;
          }
          a{
            color:$black;
            padding: 0 0 13px 0;
            border-bottom: 1px solid #d9d9d9;
            display: block;
            h3{
              font-size: 12px;
              color:$black;
              text-transform: none;
							height: 20px;
							overflow: hidden;
            }
            span{

            }
          }
        }
      }
    }
  }
}
.pagination {
	display: flex;
	float: left;
	width: 100%;
	text-align: center;
	justify-content: center;
	flex-wrap: wrap;
	list-style: none;
	margin-bottom: 40px;
	li {
		margin: 0 5px;
		a {
			&:hover {
				color: #000;
			}
		}
	}
}
@import 'article_page';

a[href^="http://maps.google.com/maps"],a[href^="https://maps.google.com/maps"]{
  display:none !important
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
    display:none;
}
.gmnoprint div {
    //background:none !important;
}

@import "jgnt_main";