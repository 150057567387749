$background:#fbf6f2;
$color1:#333333;
$width:963px;
$phone-width: 320px;
$tablet-width: 768px;
$desktop-width: 1600px;
$font1:"Open Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
$font2:TimesNewRoman,"Times New Roman",Times,Baskerville,Georgia,serif;

$wrapper-width:1600px;
@mixin phone{
	@media (min-width: #{$phone-width}) and (max-width: #{$tablet-width - 1px}){
		@content;
	}
}
@mixin tablet{
	@media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}){
		@content;
	}
}
@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

body,html{
  width:100%;
  height:100%;
  font-family: $font1;
  color:#000;
}
a{
	 outline: 0;
}
a::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
}
$h1_size:24px;
$cell3:220px;
$cell6:620px;
$cell9:940px;

$cell2:220px;

$cell1:60px;
$cell2:140px;
$cell3:220px;
$cell4:300px;
$cell5:380px;
$cell6:460px;
$cell7:540px;
$cell8:620px;
$cell9:700px;
$cell10:780px;
$cell12:940px;

$color2:#989898;
//$red:#ea5054;
//$red:#ff4e50;
$red:#57996b;
$purple:#485f99;
$gray:#989898;
$gray2:#d9d9d9;
$black:#333333;
$true_black:#000;
$green:#019198;
$gold:#ffd75e;
$link:#57996b;
$purple:#485f99;
$pink:#9185be;
$true_red:#ec5c57;
$mcolor:#f4f4f4;
