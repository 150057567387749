/*! http://responsiveslides.com v1.55 by @viljamis */

.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0;
  li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
    max-height: 80vh;
    &:first-child {
      position: relative;
      display: block;
      float: left;
    }
  }
  img {
    display: block;
	max-width: 100%;
    max-height: 100%;
    border: 0;
    margin: auto;
  }
}
