#about_me_tab{
  .selectboxit-container span, .selectboxit-container .selectboxit-options a{
    display: inline-block;
    white-space: nowrap;
  }
  #birthdate_daySelectBoxIt{
    width:68px;
    text-overflow: ellipsis;
    margin-right:5px;
  }
  #birthdate_monthSelectBoxIt{
    width:80px;
    text-overflow: ellipsis;
    margin-right:5px;
  }
  #birthdate_daySelectBoxItText{
    max-width:40px;
  }
  #birthdate_monthSelectBoxItText{
    max-width: 50px !important;
  }
  #birthdate_yearSelectBoxItText{
    display: inline;
    float: none;
    margin: 0 0 0 5px;
    max-width: 35px;
  }
  #birthdate_yearSelectBoxIt{
    width:60px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #rubric_idSelectBoxItContainer .selectboxit-options, #rubric_idSelectBoxItContainer .selectboxit-btn{
    background:$mcolor;
  }
  .selectboxit .selectboxit-option-icon-container{
    margin-left: 0px;
  }
}
.veil.profile_subscribers{
  form{
    //width:660px;
    width:500px;
    height:660px;
    padding: 0;
    .left{
      width:500px;
      height:100%;
      float: left;
      >nav{
      border-bottom:1px solid $mcolor;
      padding: 0 20px;
      box-sizing: border-box;
        ul{
          height:55px;
          li{
            display: inline-block;
            height:55px;
            line-height: 55px;
            margin:0 24px 0 0;
            position: relative;
            a{
              font-weight: bold;
              font-size:14px;
              color:$black;
              &.selected{
                &:after{
                  content:" ";
                  border-bottom:1px solid $black;
                  position: absolute;
                  bottom:19px;
                  width:100%;
                  left:0;
                }
              }
            }
          }
        }
      }
    }
    .right{
      width:160px;
      height:100%;
      background:$mcolor;
      float:right;
    }

    .subscription_button{
      float:right;
    }
    h2{
      color:$black;
    }
    .users_holder{
      height:560px;
      padding: 10px 20px;
      box-sizing: border-box;
      overflow: auto;
      display: none;
      &.selected{
        display: block;
      }
      >ul{
        list-style: none;
        li.user_block{
          width:100%;
          clear: both;
          height:35px;
          margin:0 0 48px 0;

        }
      }
    }

  }
}
#load-usercover,
#load-userpic{
  display: none;
}
body.profile_page{
  .social_icons{
    a{
      color:#000;
      font-size: 21px;
      display: inline-block;
      margin:0 10px 0 0;
      &:last-child{
        margin:0;
      }
    }

  }
  .broadcast_card.opened {
    display: inline;
  }
  .views_list{
    height:100%;
    display: none;
    &.selected{
      display: block;
    }
    .view{
      height:100%;
      display: none;
      &.selected{
        &.articles.blocks{
          margin:44px auto 0 auto;
        }
        display: block;
      }
    }
  }
  .broadcasts_list{
    display: none;
    height: 100%;
    &.selected{
      display: block;
    }
  }
  .authors,
  .landings,
  .about_me_tab,
  .drafts{
    display: none;
    height: 100%;
    &.selected{
      margin:44px 0 0 0;
      display: block;
    }
  }
}
.about_me_tab{
  .moto_text{
    margin:-6px 0 44px 0;
    font-size:24px  ;
    font-family:$font1;
    color:#000;
    border:none;
    width:100%;
    text-align: center;
    background:none;
    font-weight: bold;
    @include placeholder {
      color:#d9d9d9;
      opacity: 1;
  	}
  }
  ul.fields{
    display:block;
    width:$cell9+20px;
    float:left;
    list-style: none;
    margin:10px 0 0 0;
    >li{
      display: inline-block;
      vertical-align: top;
      width:$cell3;
      margin:0 20px 20px 0;
      &:nth-child(3n+3){
        margin:0 0 20px 0;
      }
      &.double{
        width:$cell6;
        margin:0 0 20px 0;
      }
    }
    label{
      font-weight: bold;
      display: block;
      width:100%;
    }
    .selectboxit-container{
    }
    textarea,
    input[type='text']{
      width:100%;
      display:block;
      border:none;
      background:none;
      font-size: 14px;
      padding: 0;
      margin:0 20px 0 0;
      resize:none;
      @include placeholder {
        color:#989898;
        opacity: 1;
    	}
    }
    textarea{
      height: 85px;
      min-height: 85px;
    }
  }
  aside.rating{
    float:right;
    width:$cell3;
    box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.07);
    box-sizing: border-box;
    padding: 20px;
    margin:-10px 0 0 0;
    ul{
      list-style: none;
      label{
        display: block;
        font-weight: bold;
      }
      li{
        margin:0 0 26px 0;
      }
    }
  }
}
.drafts_list{
  margin:0 auto;
  width:$cell12;
  list-style: none;
  position: relative;
  &.rounded{
    > li{
      box-sizing: border-box;
      padding: 0 0 0 80px;
      border-bottom:none;
      .actions{
        position: absolute;
        right:auto;
        left:0px;
        top:2px;
        ul.modify{
          right:auto;
          display: block;
          position: relative;
          li{
            display:inline-block;
            margin:0 5px 0 0;
            a{
              background:$gray2;
              box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.22);
            }
          }
        }
      }
    }
    .article_type{
      height:23px;
      line-height: 23px;
      font-size:10px;
      border-radius: 30px;
    }
    p{
      color:$gray;
    }
    h2{
      font-size:18px;
      margin:19px 0 9px;
    }
  }
  .article_type{
    background:$red;
    line-height: 34px;
    height:34px;
    color:#fff;
    font-size: 14px;
    display: inline-block;
    padding: 0 10px;
    margin:0 10px 0 0;
    &.current_draft{
			background:$true_red;
		}
		&.personal{
			background:$red;
		}
		&.draft{
			background:$purple;
		}
		&.public{
			background:$gray;
		}
		&.archive{
			background:$black;
		}
		&.export{
			background:$pink;
		}
  }
  >li{
    border-bottom:1px solid $mcolor;
    position: relative;
  }
  .actions{
    position: absolute;
    right:0;
    top:12px;
    ul{
      list-style: none;
      li{
        display: block;
        margin:0 0 2px 0;
      }
    }
  }
  a{
    color:$black;
  }
  h2{
    font-size: 24px;
    margin:19px 0  12px 0;
    color:$black;
    width:$cell10;
    .status{
      display: inline-block;
      background:$purple;
      color:#fff;
      font-weight: bold;
      font-size: 10px;
      height:21px;
      padding: 0 10px;
      line-height: 21px;
      position: relative;
      bottom:3px;
      border-radius: 10px;
      text-transform: lowercase;
    }
  }
  p{
    font-size:10px;
    margin:0 0 22px 0;
  }
}
.profile_cover{
  width:100%;
  height:395px;
  display: block;
  background:url("/img/les/forest.jpg") center center / cover;
  position: relative;
  margin:55px 0 0 0;
  .tan{
    width:100%;
    height:100%;
    position: absolute;
    left:0;
    top:0;
    background:rgba(0,0,0,0.15);
  }
  .upload_cover_image{
    background:rgba(0,0,0,0.66);
    position: absolute;
    width:100%;
    height:100%;
    left:0;
    top:0;
    opacity: 0;
    transition: all 0.2s ease-in;
    .text{
      cursor:pointer;
      color:#fff;
      font-weight: bold;
      font-size:14px;
      position: absolute;
      width:190px;
      height:140px;
      text-align: center;
      left:50%;
      top:50%;
      margin:-70px 0 0 -95px;
      .icon-upload{
        font-weight: normal;
        font-size: 65px;
        margin:0 0 15px 0;
        display: block;
      }
    }
    &:hover{
      opacity: 1;
    }
  }
  .pattern{
    width:100%;
    height:100%;
    position: absolute;
    left:0;
    top:0;
    background:url("/img/les/patterns/black-criss-cross.png") repeat;
  }
}
header.user_profile{
  padding: 44px 0;
  font-size:14px;
  position:relative;
  &.overflow{
    padding: 0;
    .center{
      position: relative;
    }
    .profile_pic{
      position: absolute;
      left:50%;
      width:128px;
      height:128px;
      top: -122px;
      margin:0px 0 0 -64px;
      float:none;
      border:4px #fff solid;
      overflow: hidden;
      border-radius: 90px;
      .round{
        border-radius: 0px;
        background:$black;
        color:#fff;
        width:100%;
        height:100%;
        box-shadow:0;
        font-size: 50px;
        line-height: 126px;
      }
      img{
        width:100%;
        display: block;
        border-radius: 0px;
      }
      &.got_no_picture{
        .replace_picture{
          background:$red;
        }
      }
      .replace_picture{
        position: absolute;
        cursor: pointer;
        width:100%;
        height:100%;
        left:0;
        top:0;
        color:#fff;
        background:rgba(0,0,0,0.65);
        color: #fff;
        font-size: 50px;
        line-height: 125px;
        text-align: center;
        top: 0;
        width: 100%;
        opacity: 0;
        transition: all 0.2s ease-in;
        &:hover{
          opacity: 1;
        }
      }
    }
    .profile_info{
        float:none;
        width:$cell6;
        padding:34px 0 0 0;
        margin:0px auto 0 auto;
        text-align: center;
        .owned_landings{
          .expert_style{
            color:$red;
            white-space: nowrap;
          }
        }
    }
    .buttons{
      text-align: center;
      margin:20px 0 0 0;
      a{
        color:#000;
      }
    }
  }
  .profile_pic{
    float:left;
    margin: 0px 20px 0 0;
    img{
      width:90px;
      border-radius: 90px;
      display: block;
    }
    .round{
      background: #fff none repeat scroll 0 0;
      border-radius: 90px;
      color: #000;
      font-weight: bold;
      height: 90px;
      font-size: 25px;
      line-height: 90px;
      text-align: center;
      width: 90px;
      text-transform: uppercase;
      box-shadow: 0px 0px 1px rgba(0,0,0,0.3);
    }
  }
  .buttons{
    width:100%;
    text-align:left;
    clear:both;
    margin:20px 0 0 14px;
    float:left;
    a{
      color: $gray;
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      height: 17px;
      line-height: 17px;
      margin: 0 37px 0 0;
      &:last-child{
        margin:0;
      }
      &.selected{
        font-weight:bold;
        border-bottom:1px solid $black;
      }
    }
  }
  .profile_info{
    float:left;
    max-width:650px;
    h1{
      font-size: 24px;
      font-weight: bold;
      height: 24px;
      line-height: 27px;
      margin: 0 0 18px;
      text-transform: capitalize;
      .expert_style{
        color: $gold;
        font-weight: normal;
        font-size: 14px;
        text-transform: none;
        display: inline-block;
        margin:0 0 0 8px;
        .icon-star{

        }
      }
    }
    h2{
      font-size: 12px;
      font-weight: bold;
      margin: 0 0 6px;
    }
    p{
      //color:#d9d9d9;
      color:#000;
      line-height: 20px;
      &.owned_landings{
        margin:16px 0 -10px;
        a{
          color:$black;
          font-weight: bold;
        }
      }
    }
  }
  .user_type{
    margin:24px 0 0 0;
    float:left;
    width:100%;
    font-weight: bold;
    font-size: 12px;
    .position{
      text-transform: uppercase;
    }
    .landing{

    }
  }
}
.veil.about_me_panel{
  display: none;
  height: auto;
  left: auto;
  position: absolute;
  top: 145px;
  width: 365px;
  z-index:100;
  &.selected{
    display:block;
  }
  .close_panel{
    color: #fff;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 9px;
  }
  h2{
    background:$black;
    font-weight:bold;
    font-size:14px;
    color:#fff;
    height:34px;
    line-height:34px;
    width:100%;
    display:block;
    box-sizing:border-box;
    padding:0 20px;
  }
  form{
    width:auto;
    padding:10px 50px 10px 20px;
    
    max-height: 500px;
    overflow: auto;
  }
  p{
    margin:0 0 17px 0;
  }
  .social{
    a{
      display: inline-block;
      font-size: 18px;
      margin:0 10px 0 0;
      &:last-child{
        margin:0;
      }
    }
  }
}
nav.sub_header_menu.profile_nav{
  border-bottom: 1px solid $mcolor;
  border-top: 1px solid $mcolor;
  margin: 0 0 0px;
  &.sp{
    border-bottom: 1px solid #f4f4f4;
    border-top: medium none;
    box-shadow: 0 8px 11px 0 rgba(0, 0, 0, 0.04);
    position: relative;
    z-index: 26;
    ul#main_nav{
      position: absolute;
      text-align: center;
      width: 100%;
    }
  }
  .wrapper{
    width:940px;
    padding:0;
    position: relative;
  }
  li{
    a{
      color:$black;
      &.selected{
        font-weight:bold;
      }
    }
  }
}
.profile_page{
  section.profile_block{
    display:none;
    &.selected{
      display: block;
    }
  }
  .profile_wrap{
    position: relative;
    ul.links{
      list-style: outside none none;
      position: absolute;
      right: 0;
      top: 12px;
      z-index: 20;
      li{
        display: inline-block;
        margin: 0 10px 0 0;
        &:last-child{
          margin:0;
        }

      }
    }
  }
}
.articles.my_posts{
  float:right !important;
}
.live-feed.profile_block{
  float:right;
  width:620px;
  max-height:inherit;
  overflow: auto;
  .add_comment{
    textarea{
      width:100%;
    }
  }
}

.users_list{
  float:left;
  margin:0 0 0 20px;
  width:$cell6;
  article{
    width:$cell3;
    box-sizing: border-box;
    padding: 20px;
    float:left;
    border:1px solid #d9d9d9;
    display: block;
    margin:0 0 12px 0;
    .user_pic{
      .round{
        background:$black;
        color:#fff;
        border-radius: 55px;
        height: 55px;
        line-height: 55px;
        width: 55px;
        font-size: 22px;
      }
    }
    .user_name{
      width:193px;
      strong{
        a{
          color:#000;
          font-size: 15px;
        }
      }
    }
    .button_holder{
      text-align: right;
      width:100%;
      margin:20px 0 0;
      button{
        background:#fff;
        border:1px solid $green;
        position: relative;
        color:$green;
        &.subscribe{
          font-size: 13px;
          left:auto;
          top:auto;
          color:$green;
        }
        &:hover{
          background:$green;
          color:#fff;
          &.subscribed{
            &::after{
              background:#fff;
              color:$green;
            }
          }
        }
        &.subscribed{
          &::after{
            background: $green none repeat scroll 0 0;
            border: 1px solid $green;
            color:#fff;
            content: "Слежу";
            display: block;
            height: 100%;
            left: -1px;
            line-height: 27px;
            position: absolute;
            top: -1px;
            width: 100%;
          }
        }
      }
    }
    &:nth-child(even){
      float:right;
    }
  }
}
section.profile_settings{
  form{
    border:1px solid $gray;
    display: block;
    width:$cell6;
    float:right;
    margin:0 0 25px 0;
    padding: 21px 20px;
    box-sizing: border-box;
    h2{
      font-size: 12px;
      font-weight: bold;
      color:#000;
    }
    input[type='text'],
    input[type='password']{
      border: 1px solid #989898;
      box-sizing: border-box;
      line-height: 35px;
      padding: 0 10px;
      width: 280px;
      margin:0 0 10px 0;
    }
  }
  .left{
    float:left;
    width:280px;
  }
  .right{
    float:right;
    width:280px;
    text-align: right;
  }
}
.resume{
  margin: 0 0 0 20px;
  .left{
    float:left;
    width:$cell3;
  }
  .right{
    float:right;
    width:$cell3;
  }
  article{
    display:block;
    float:left;
    width:$cell3;
    margin:0 0px 1px 0;
    background:#f4f4f4;
    box-sizing: border-box;
    padding: 22px 20px;

    h3{
      color:#000;
      text-transform: none;
    }
    p{
      width:100%;
      &[contenteditable="true"]:focus{
        background:rgba(0,0,0,0.1);
      }
    }
  }
}
