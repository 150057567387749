section.about_page{

  h1{
    font-weight: normal;
    font-size:36px;
    margin:18px 0 40px 0;
  }
  h2{
    font-size:24px;
    font-weight: normal;
    margin:0 0 65px 0;
  }
  a{
    color:#000;
    font-weight:bold;
  }
  ul.interview{
    list-style: none;
    li{
      margin:0 0 50px 0;
      float:left;
      width:100%;
      &.deformed{
        .left{
          width:100%;
        }
        .right{
          width:100%;
        }
      }
    }
    .left{
      width:230px;
      font-weight: bold;
      float:left;
      font-size: 18px;
      line-height: 22px;
      margin:-3px 0 22px;
    }
    .right{
      float:right;
      width:$cell12 - 230px;
      &.large{
        font-size: 24px;
      }
    }
  }
}
