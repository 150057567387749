
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: -6px;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
	z-index: 10;
}
.noUi-state-tap .noUi-origin {
-webkit-transition: left 0.3s, top 0.3s;
	transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
	height: 30px;
}
.noUi-horizontal .noUi-handle {
	width: 34px;
	height: 28px;
	left: -17px;
	top: -6px;
}
.noUi-vertical {
	width: 23px;
}
.noUi-vertical .noUi-handle {
	width:8px;
	height:8px;
	border-radius: 3px;
	left:-4px;
}

/* Styling;
 */
.noUi-background {
	background: $black;
	padding: 10px 0 20px 0;
	box-sizing: content-box;
	&:after{
		content:" ";
		border-left:1px solid $gray;
		position: absolute;
		height:131px;
		left:12px;
		top:10px;
	}
}
.noUi-connect {
	background:#fff;
}
.noUi-origin {
	border-radius: 2px;
	left: 12px;
	width: 1px;
}
.noUi-target {
	border-radius: 30px;
}
.noUi-target.noUi-connect {
	box-shadow: inset 0 0 3px rgba(51,51,51,0.45), 0 3px 6px -5px #BBB;
}

/* Handles and cursors;
 */
.noUi-draggable {
	cursor: w-resize;
}
.noUi-vertical .noUi-draggable {
	cursor: n-resize;
}
.noUi-handle {
	border: 1px solid #D9D9D9;
	border-radius: 3px;
	background: #FFF;
	cursor: default;
	box-shadow: inset 0 0 1px #FFF,
				inset 0 1px 7px #EBEBEB,
				0 3px 6px -3px #BBB;
}
.noUi-active {
	box-shadow: inset 0 0 1px #FFF,
				inset 0 1px 7px #DDD,
				0 3px 6px -3px #BBB;
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed;
}
