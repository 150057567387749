.autocomplete_users{
  position: absolute;
  width:$cell4;
  font-size: 12px;
  z-index: 110;
  left:100px;
  top:100px;
  .user_picture{
    width:35px;
    height:35px;
    float:left;
    margin:0 10px 0 0;
    .round{
      width:35px;
      height:35px;
      display: block;
      line-height: 35px;
      font-size:18px;
      text-align: center;
      text-transform: uppercase;
      background:$black;
      color:#fff;
      border-radius: 35px;
    }
    img{
      display: block;
      border-radius: 30px;
      width:100%;
    }
  }
  .login{
    font-weight: bold;
  }
  .full_name{
    font-weight: normal;
  }
  ul{
    list-style: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.09);
    padding: 20px 0 20px 0;
    display: block;
    background:#fff;
    li{
      display: block;
      width:100%;
      padding: 5px 20px;
      box-sizing: border-box;
      height:45px;
      background:#fff;
      color:$black;
      cursor: pointer;
      &:hover{
        text-decoration: none;
        background:$black;
        color:#fff;
        .user_picture{
          .round{
            color:$black;
            background:#fff;
          }
        }
      }
    }
  }
}
