.gm-style-iw {
   box-shadow: none;
   border: none;
   border-radius: 3px;
}
.map{
	width:100%;
	height:570px;
}
.mylabel{
  font-weight: bold !important;
  font-size: 14px;
  font-family: $font1;
}
.got_subfolders{
  .all_states{
    max-height: 300px;
    overflow: auto;
  }
}
.rating_area{
  .rating{
    margin:40px 0 0 0;
    height:825px;
    list-style:none;
    article{
      position: relative;
      margin:0 0 15px 0;
      .number{
        color:$gray2;
        font-size: 18px;
        font-weight: bold;
        left: -67px;
        position: absolute;
        text-align: right;
        width: 50px;
        top: 17px;
      }
      h2{
        font-size: 18px;
        font-weight: bold;
        margin:0 0 2px 0;
        color:#000;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        br{
          display: none;
        }
        div{
          display: inline;
        }
      }
      p{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color:#000;
        min-height: 20px;
        br{
          display: none;
        }
        div{
          display: inline;
        }
      }
      .criteria{
        color:$gray;
        font-size:10px;
        margin:0 0 1px 0;
        >span{
          font-size:13px;
          margin: 0 2px 0 0;
          position: relative;
          top: 2px;
        }
      }
    }
    li{
      .left,.right{
        width:400px;
      }
      .left{
        float:left;
      }
      .right{
        float:right;
      }
    }
  }
}
.map_location{
  text-transform: none;
  color:$gray;
  float:left;
  > a{
    display: inline-block;
    margin: 0 20px 0 0px;
    &:first-child{
      margin:0 20px 0 20px;
    }
    &.selected{
      background:$red;
      border-radius: 5px;
      color: #fff;
      padding: 0 5px;
      line-height: 22px;
    }
  }
  > ul{
    display: inline-block !important;
  }
}
ul.most_visited{
  float:right;
  width:195px;
  text-align: right;
  .subfolders{
    text-align: left;
    width:214px;
  }
}
.time_period{
  font-size: 14px;
  position: absolute;
  right:40px;
  bottom:3px;
  .control{
    font-size:14px;
    padding-left:34px;
    display: inline-block;
  }
  .control__indicator{
    left: 13px;
    top: 3px;
  }
}
.map_filter{
  font-size: 18px;
  position: relative;
  ul{
    li{
      display: inline-block;
      margin:0 27px 0 0;
      text-transform: uppercase;
      &:last-child{
        margin:0;
      }
      a{
        display:block;
        color:#000;
        margin:0 0 16px 0;
      }
    }
  }
  .pointer{
    transition: ease-in 0.2s all;
    &[data-pos='pos1']{
      width:67px;
      left:0;
    }
    &[data-pos='pos2']{
      left:100px;
      width:72px;
    }
    &[data-pos='pos3']{
      left: 204px;
      width: 89px;
    }
    height:7px;
    position: absolute;
    bottom:0;
    background:$red;
  }
}
.map_holder{
	background:$mcolor;
  .low_background{
    background:#fff;
    border-bottom:1px solid $mcolor;
    position: relative;
    .left-arrow,
    .right-arrow{
      font-size: 62px;
      top:43px;
      color:$gray2;
    }
    .left-arrow{
      display: block;
      left: 50%;
      margin: 0 0 0 -531px;
      padding: 5px 0;
      position: absolute;
      transform: rotate(-90deg);
    }
    .right-arrow{
      transform: scaleX(-1);
      position: absolute;
      right:50%;
      margin: 0 -531px 0 0;
      display: block;
      padding: 5px 0;
      transform: rotate(-90deg);
    }
  }
}
.info_content{
	h3,a{
		color:$black;
	}
	h3{
		font-size:14px;
		color:$black;
		text-transform: none;
    margin:0 0 15px;
	}
	.number{
    color:$gray;
		font-size: 10px;
		margin:2px 0 3px 0px;
		span{
			font-size: 14px;
			position: relative;
			top: 2px;
		}
	}
}
.wrapper.map_articles_holder{
  &.non_brakable{
    overflow: hidden;
    position: relative;
    ul{
      white-space: nowrap;
      height:200px;
      li{
        display: inline-block;
        float: none;
        white-space: normal;
        vertical-align: top;
        &:nth-child(4n+4),
        &:last-child{
          margin:0 20px 20px 0;
        }
      }
    }
  }
  ul{
    list-style: none;
    li{
      float:left;
      width:$cell3;
      padding:34px 0 25px 0;
      box-sizing: border-box;
      position: relative;
      margin:0 20px 20px 0;
      a{
        color:$black;
      }
      .count{
        color:$gray2;
      }
      &:nth-child(4n+4),
      &:last-child{
        margin:0 0 20px 0;

      }
      h3{
        font-size:14px;
        color:$black;
        text-transform: none;
      }
      .number{

        font-size: 10px;
        margin:2px 0 3px 10px;
				span{
					font-size: 14px;
					position: relative;
					top: 2px;
				}
      }
      .count{
        font-size: 42.57px;
        font-weight: bold;
        position: absolute;
        top: 4px;

        left: -45px;
        text-align: right;
        width: 50px;
      }
    }
  }
}
