.date_ago{
  position: absolute;
  right:21px;
  bottom:9px;
  font-size: 10px;
  color:#fff;
}
.load_more{
  color:$black;
  text-align: center;
  //width:$cell7;
  height:34px;
  display: block;
  line-height: 34px;
  font-weight: bold;
  font-size:14px;
  clear: both;
  //margin:0 0 45px 0;
  position: relative;
  &:before{
    width:100%;
    position:absolute;
    border-top:1px solid #d9d9d9;
    content:" ";
    top:18px;
    left:0;
  }
  span{
    position: relative;
    background: #fff none repeat scroll 0 0;
    display: inline-block;
    padding: 0 10px;
    position: relative;
  }
  i{
    display: inline-block;
    width:17px;
    height:17px;
    margin: 0 10px 0 0;
    position: relative;
    top: 4px;
  }
}
.on_moderation{
  right:50px;
  top:20px;
  font-size: 10px;
  color:#fff;
  padding: 2px 4px;
  background:$red;
}
.social{
  ul{
    font-size:12px;
    list-style: none;
    li{
      display: inline-block;
      margin:0 10px 0 0;
      color:$gray;
      &.selected{
        a{
          color:$red;
        }
      }
      &.ago{
        float:right;
        font-size: 10px;
        position: relative;
        top:2px;
      }
      a{
        color:$gray;
        i{
          display: inline-block;
          height: 14px;
          position: relative;
          top: 2px;
          width: 16px;
        }
        &:hover{
          color:$red;
        }
      }
    }
  }
}
.author{
  width:100%;
  clear: both;
  margin:0 0 6px 0;
  .user_pic{
    float:left;
    margin:0 10px 0 0;
    img{
      border-radius: 35px;
      display: block;
      width: 35px;
    }
    .round{
      background: #fff none repeat scroll 0 0;
      border-radius: 35px;
      color: #000;
      font-weight: bold;
      height: 35px;
      line-height: 35px;
      text-align: center;
      width: 35px;
      text-transform: uppercase;
      box-shadow: 0px 0px 1px rgba(0,0,0,0.3);
    }
  }
  .user_name{
    float:left;
    a{
      color:$black;
    }
    .location{
      display: inline-block;
      font-size: 14px;
      text-transform: none;
      color:$gray;
      margin:0 0 0 20px;
    }
    strong{
      font-size: 14px;
      margin:0 0 10px 0;
    }
    .date{
      color:$gray;
      font-size: 10px;
    }
    .subscribe{
      font-size: 10px;
      color: #fff;
      font-size: 12px;
      left: 5px;
      position: relative;
      top: 2px;
      &.subscribed,&.subscribed:hover{
        .name{
          display: none;
        }
        .icon-plus{
          display: none;
        }
        .icon-check{
          display: inline-block;
        }
      }
      &:hover{
        .name{
          display:inherit;
        }
        .icon-plus{
          background:red;
          border-radius: 10px;
        }
      }
      .icon-check{
        display: none;
      }
      .icon-plus{
        display: inline-block;
      }
      .name{
        display: none;
        background: $red none repeat scroll 0 0;
        bottom: 2px;
        color: #fff;
        font-size: 8px;
        left: 5px;
        padding: 0 5px;
        position: relative;
        &::before{
          border-bottom: 5px solid transparent;
          border-right: 5px solid #ff4e50;
          border-top: 5px solid transparent;
          content: " ";
          height: 0;
          left: 0;
          margin: 3px 0 0 -3px;
          position: absolute;
          width: 0;
        }
      }
    }
  }
}//author end
.articles.blocks{
  left: 10px;
  position: relative;
  width: 980px;
  article{
    margin:0 20px 20px 0;
  }
}
.articles{
  &.fit_all{
    padding:0;
    position: relative;
    width:$cell12+20;
    >article{
		float: left;
      &:nth-child(4n+4){
        margin:0 20px 20px 0;
      }
    }
  }
  &.landing_wrap {
	  > article {
		float: left;
	  }
  }
  article[data-format='box']{
    width:$cell3;
    height:250px;
    .text_group{
      bottom:55px;
      max-height: 156px !important;
    }
    &.none{
      border: 1px solid $mcolor;
      .date_ago{
        bottom: 40px;
        color: $gray;
        left: 20px;
        right: auto;
      }
    }
  }
  article[data-format='wide'],
  &.top_articles{
    width:$cell6;
    height:250px;
    .leed{
      font-size: 14px;
      line-height: 22px;
    }
    a{
      color:$black;
    }
    .text_group{
      bottom:41px !important;
      width:92% !important;
    }
    h2{
      top:auto !important;
    }
    .leed{
      position: relative;
      bottom:auto;
    }
    .date{
      //bottom:178px !important;
    }
    &.none,&.small,&.full{
      h2{
        bottom: auto;
        font-size: 24px;
        max-height: none;
        line-height: 31px;
        top: 80px;
        position: relative;
      }
      .date_ago{
        bottom: 18px;
        right: 21px;
        left:auto;
        position: absolute;
      }
    }

    &.full{
      h2{
        top:auto;
      }
    }
    &.none{
      border: 1px solid $mcolor;
    }
    &:nth-child(odd){
      margin: 0 20px 25px 0;
    }
    &:nth-child(even){
      margin:0 20px 25px 0;
    }
  }
  article[data-format='box'] {
	a{
      color:$black;
    }
  }
  .info_block{
    width:220px;
    display: block;
    float:left;
    margin:0 20px 0 0;
    height:220px;
    position: relative;
    font-size: 14px;
    .actions{
      position: absolute;
      bottom:20px;
      height:130px;
      a{
        color:#000;
        display: block;
        margin:13px 0 0 0;
        &:first-child{
          margin:40px 0 0 0;
        }
      }
    }
  }
  &.full_width{
    width:100%;
    height:100%;
    margin:0 auto;
    &.center_view{
      article{
        margin:0;
        &.full_background{
          .description_holder{
            position: absolute;
            width:100%;
            bottom:60px;
            z-index:10;
            h2{
              color:#fff;
            }
            .leed{
              color:#fff;
              margin-bottom:30px !important;
            }
            .text_group{
              margin-bottom:20px;
            }
            .social{
              position: relative;
              width: 940px;
              margin: 0 auto;
              float: none;
              left: 0px;
              ul li a{
                color:#fff;
                &.rubric{
                  background:rgba(0,0,0,.3);
                }
              }
            }
          }
        }
        &.full_background,
        &.half_gallery,
        &.full_text,
        &.half_video,
        &.half_image,
        &.half_quote{
          .text_group{
            width:$cell12;
            margin:0 auto;
            float: none;
          }
          .author{
            display: none;
          }
          .author .user_name .date{
            margin:0 0 0 -600px;
            left:50%;
            display: none;
          }
          .date_ago{
//            left:50%;
//            margin:0 0 0 -600px;
            text-align: right;
//            width: 111px;
          }
          .social{
//            left:50%;
//          margin:0 0 0 -470px;
          }
          .gallery{
            width:$cell12;
            .rslides li img{
              //height:626px;
            }
            &:hover{
              .rslides_nav{
                display:block;
              }
            }
            .rslides_nav{
              display:none;
            }
          }
          .image{
            .filter{
              position: absolute;
              top:0;
              left:0;
              width:100%;
              height:100%;
            }
            p{
              position: relative;
              z-index: 20;
              background:#fff;
            }
          }
        }
      }
    }
    article{
      width:100%;
      border: none;
      &.half_gallery{
        .gallery{
          display: block;
          float: none;
          height: 100%;
          margin: 0 auto;
          overflow: hidden;
          position: relative;
          width: 54.1666%;
          p{
            position: relative;
            top: auto;
            font-size: 14px;
          }
        }
      }
      &.full_background,
      &.half_gallery,
      &.full_text,
      &.half_video,
      &.half_image,
      &.half_quote{
        box-sizing: border-box;
        padding:40px 90px 50px;
        .edit{
          margin: 0 -470px 0 0;
          position: absolute;
          right: 50%;
          top: 20px;
          z-index: 100;
        }
        .text_group{
//          width:89.682%;
//          float: right;
          padding: 0;
        }
        .leed{
          height: auto;
          position: relative;
          a{
            color:#000;
          }
        }
        .social{
//          left:90px;
//          margin:0 0 0 9.317%;
        }
        .date_ago{
          position: absolute;
          left:90px;
          color:#000;
          font-size: 14px;
          right:auto;
          bottom:auto;
          top:43px;
        }
        .author{
          margin:0 0 3px;
          >a{
            display: none;
          }
          .user_name{
            margin:0;
            strong{
              a{
                font-size: 14px;
                font-weight: bold;
                color:#000;
              }
            }
            .date{
              background: #000 none repeat scroll 0 0;
              border-radius: 30px;
              color: #fff;
              font-size: 14px;
              font-weight: bold;
              height: 35px;
              left: 90px;
              line-height: 35px;
              padding: 0 20px;
              position: absolute;
              top: 0;
              a{
                color:#fff;
              }
            }
          }
        }
      }

      &.full_background{
        min-height:100vh;
//        background:$gray2;
        margin:36px 0 0;
        .edit{
          top: auto;
          bottom:212px;
          .icon-pencil{
            background:rgba(255,255,255,0.4);
          }
          &:hover{
            .icon-pencil{
              background:$red;
            }
          }
        }
        .text_group{
  //        height:100%;
          color:#fff;
        }
        h2{
//          bottom: 138px;
//          color: #fff;
//          height: 35px;
			margin-bottom: 1rem;
//          position: absolute;
        }
        .leed{
//          bottom: 0;
//          height: 125px;
          margin: 0 0 1rem;
//          position: absolute;
        }

        .date_ago{
          right:auto;
//          bottom:200px;
          top:auto;
        }
        .author{
          .user_name{
            top:auto;
            bottom:176px;
            position: absolute;
            strong{
              a{
//                color:#fff;
              }
            }
            .location{
//              color:#fff;
            }
            .date{
              background: #fff none repeat scroll 0 0;
              bottom: 259px;
              color: #000;
              left: -12%;
              position: absolute;
              top: auto;
              a{
                color:#000;
              }
            }
          }
        }
      }
    }
  }
  &.feed{
    width:780px;
    margin:0 auto;
    article{
      width:100%;
      height:400px;
    }
  }
  article{
    background:#fff;
    //border:1px solid #d9d9d9;
    border:1px solid rgba(0,0,0,0);
    box-sizing: border-box;
    padding: 20px;
    margin:0 0 20px 0;
//    float:left;
    //width:$cell3;
    width:220px;
    position: relative;
    //overflow: hidden;
    &.advert{
      background:$mcolor !important;
      height: 406px;
      padding: 0;
      margin-bottom: 57px;
      img{
        width:100%;
        display: block;
      }
    }
    &.extrude.none{
      border:none;
      background:$mcolor;
      min-height:406px;
      margin-bottom: 57px;
      header{

      }
      .text_group{
        bottom: auto !important;
        left: auto;
        max-height:335px !important;
        overflow:hidden;
        position: relative;
        width: auto !important;

        h2{
          color:#000;
          font-size:18px;
          line-height: 22px;
          margin:0 0 12px;
        }
        .author{
          margin:1px 0 15px 0;
          .user_name{
            .date{
              &.landing_link{
                a{
                  max-width: 80px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              a{
                background:$gray;
                border:1px solid $gray;
                color:#fff;
                height:21px;
                line-height: 18px;
              }
            }
          }
        }
      }
      .social{
        bottom:19px;
        ul{
          li{
            a{
              span:first-child{
                font-size: 11px;
              }
            }
          }
        }
      }
      .date_ago{
        bottom:auto;
        top:25px;
        color:$gray;
        left:auto;
        right:20px;
        text-transform: uppercase;
      }
    }
    &.extrude.full{
      border:none;
      padding: 0;
      height: 406px;
      background:none;
      margin-bottom: 57px;
      header{
        left: 0;
        overflow: hidden;
        padding: 0;
        top:0;
        height:252px;
        margin:0 0 21px 0;
      }
      .text_group{
        position: relative;
        left:auto;
        bottom:auto !important;
        width: auto !important;
        color:#000;
        > a{
          max-height: 70px;
          overflow: hidden;
          display: block;
          br{
            display: none;
          }
        }
        h2{
          color:#000;
          font-size:18px;
          line-height: 22px;
          margin:0 0 2px;
        }
        .leed{
          color:#000;
        }
        .author{
          margin:1px 0 13px 0;
          .user_name{
            .date{
              &.landing_link{
                a{
                  max-width: 130px;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              a{
                background:$gray;
                border:1px solid $gray;
                color:#fff;
                height:21px;
                line-height: 18px;
              }
            }
          }
        }
      }
      .social{
        bottom:auto;
        position:relative;
        margin:14px 0 43px 0;
        ul{
          li{
            color:$gray;
            a{
              span:first-child{
                font-size: 11px;
              }
              color:$gray;
            }
            span {
              color:$gray;
            }
            &.disabled {
              a {
                cursor: auto;
              }
            }
          }
        }
      }
      .date_ago{
        bottom:auto;
        top:278px;
        color:$gray;
        left:auto;
        right:0;
        text-transform: uppercase;
      }
    }
    .author{
      margin:0 0 20px;
    }
    &.landing{
      height:220px;
    }
    .date_ago{
      color:$black;
    }
    .leed{
      color:$black;
      box-sizing: border-box;
      overflow: hidden;
      line-height: 22px;
      font-size:14px;
      position: relative;
    }
    &.half_gallery,
    &.full_text,
    &.half_video,
    &.full_background,
    &.half_image,
    &.half_quote{
      width:100%;
      padding: 0;
      margin:0 0 45px 0;
      h2{
        font-size: 18px;
        height: auto;
        margin: 0 0 11px;
      }

      .social{
        position: absolute;
        bottom:18px;
        left:20px;
      }
      .leed{
        font-size: 14px;
        line-height: 21px;
        bottom: auto;
        margin:0 0 15px;
      }

      .date_ago{
        left:20px;
        bottom:40px;
      }
    }
    &.half_video{
      .video_frame{
        background: #f4f4f4;
//       height: 0;
		height: 70vh !important;
		min-height: 400px;
		padding: 0 !important;
       position: relative;
       width:$cell12;
       margin:0 auto;
       iframe{
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
       }
      }
    }
    &.half_gallery{
      .text_group{
        width:30.769%;
        padding: 20px;
        height:90%;
        box-sizing: border-box;
        float:left;
      }
      h2{
        height:auto;
        margin:0 0 8px;
      }
      .leed{
        position: relative;
        font-size: 14px;
        margin:0 0 15px 0;
        padding:0;
      }
      .gallery{
        width:69.23%;
        display: block;
        height:100%;
        position: relative;
        float:right;
        overflow: hidden;
        .rslides{
          height:100%;
        }
        p{
          top:12px;
          position: relative;
          font-size:10px;
        }
        .rslides_tabs{
          bottom: 43px;
          position: absolute;
          text-align: center;
          width: 100%;
          z-index: 20;
          list-style: none;
          li{
            display: inline-block;
            margin:0 5px 0 0;
            &:last-child{
              margin:0;
            }
            &.rslides_here{
              a{
                background:#fff;
              }
            }
            a{
              display: block;
              border:1px solid #fff;
              border-radius: 20px;
              width:10px;
              height:10px;
              color:rgba(0,0,0,0);

            }
          }
        }
        .rslides_nav{
          /*
          bottom:50%;
          font-size:24px;
          color:#fff;
          background:rgba(0,0,0,0.1);
          border-radius: 0 12px 12px 0;
          margin: 0 0 0 -3px;
          padding: 2px 5px 2px 0;*/
          z-index: 10;
          position: absolute;
          .icon-arrow-up-thin{
            display: block;
            padding: 5px 0;
            position: relative;
            transform: rotate(-90deg);
          }
          &.prev{
            left: 0;
          }
          &.next{
            right:0;
            -moz-transform: scaleX(-1);
            -webkit-transform: scaleX(-1);
            -o-transform: scaleX(-1);
            transform: scaleX(-1);
          }
        }
      }
    }
    &.full_text{
      .text_group{
        width:100%;
        padding:20px;
        box-sizing: border-box;
      }
      .leed{
        height:165px;
      }
    }
    &.full_background{
      color:#fff;
      header{
        background: rgba(0, 0, 0, 0) url("/img/les/full_background.jpg") no-repeat scroll center center / cover ;
//        height: 100%;
//        left: 0;
        min-height: 85vh;
        min-height: 661px;
        max-height: 100vh;
        position: relative;
        width: 100%;
        margin-bottom: 1rem;
  //      top: 0;
//        width: 100%;
        .filter{
          width:100%;
          height:100%;
          background:rgba(0,0,0,0.1);
        }
      }

      h2{
//        color:#fff;
//        margin:100px 0 0 0;
      }
      .leed{
//        color:#fff;
//        height:102px;
      }
/*
      .date_ago{
        color:#fff;
      }
*/
/*
      .social ul li{
        a{
//          color:#fff;
          &.rubric{
            background:rgba(255,255,255,0.4);
          }
        }
      }
*/
      .text_group{
        width:100%;
        padding:20px;
        box-sizing: border-box;
        z-index: 20;
        position: relative;
      }
/*
      a{
        color:#fff;
      }
*/
    }
    &.half_image{
      .text_group{
        width:30.769%;
        padding: 20px;
        height:90%;
        box-sizing: border-box;
        float:left;
      }

      .image{
        position: relative;
        img{
          display: block;
        }
      }
      h2{
        height:auto;
        margin:0 0 8px;
      }
      .leed{
        position: relative;
        height:163px;
        font-size: 14px;
      }
    }
    &.half_quote{
      .text_group{
        width:69.230%;
        padding: 20px;
        height:90%;
        box-sizing: border-box;
      }
    }
    &.none,&.small,&.full{
      height:250px;
      .text_group{
        position: absolute;
        bottom:70px;
        //max-height: 156px;
        max-height:174px;
        overflow: hidden;
        left:20px;
        width:83%;
      }
      .author{
        .user_pic{
          display: none;
          img{
            width:35px;
            border-radius: 35px;
            display: block;
          }
          .round{
            width:35px;
            height:35px;
            background:#fff;
            color:#000;
            border-radius: 35px;
            text-align: center;
            line-height: 35px;
            font-weight: bold;
          }
        }
        .user_name{
          strong{
            font-size: 10px;
            font-weight: bold;
            color:#fff;
            position: relative;
            text-transform: uppercase;
          }
          .date{
            color:#fff;
            font-size: 10px;
            position: relative;
            left:0;
            a{
              border-radius: 23px;
              color:#fff;
              display: block;
              height: 23px;
              padding: 0 10px;
              line-height: 23px;
              //background:rgba(255,255,255,0.4);
              border:1px solid #fff;
              font-weight: bold;
              &:hover{
                color:$red;
              }
            }
          }
        }
      }
      h2{
        font-size: 18px;
        line-height: 22px;
        height: auto;
        margin: 0;
        max-height: 70px;
        overflow: hidden;
        padding: 0;
        position: relative;
      }
      .social{
        position: absolute;
        bottom:16px;

      }
    }
    &.none{
      h2{
      }
      a{
        color:$black;
      }
      .left,.right{
        color:#fff;
      }
      header{
        display: none;
      }
      .author{
        .user_pic{
          .round{
            background:#000;
            color:#fff;
          }
        }
        .user_name{
          strong{
            color:#000;
          }
          .date{
            color:#000;
            a{
              color:#000;
              border:1px solid #000;
            }
          }
        }
      }
    }
    &.full{
      a{
        /*color:#fff;*/
      }
      h2{
        color:#fff;
      }
      .date_ago{
        bottom: 40px;
        color: #fff;
        left: 20px;
        right: auto;
      }
      .leed{
        color:#fff;
        max-height: 84px;
      }
      .social{
        ul{
          li{
            color:#fff;
            a{
              color:#fff;
            }
          }
        }
      }

      header{
        overflow: hidden;
        height: 250px;
        left: -20px;
        overflow: hidden;
        padding: 0 40px 0 0;
        width: 100%;
        img{
          margin: 0 0 0 -18%;
          width: 138%;
        }
        .filter{
          width:100%;
          height:100%;
          position: absolute;
        }
      }
    }
    &.small{
      header{
        overflow:hidden;
        height:144px;
      }
      .date_ago{
        color:$gray;
      }
    }
    header{
      position: relative;
/*
      left:-21px;
      top:-21px;
*/
      width:$cell3;
      background:#d2d2d2;
      min-height:111px;
      img{
        width:100%;
        display: block;
      }
    }
    &:hover{
      .edit{
        display: block;
      }
      .left,.right{
        display: block;
      }
    }
    .up,.down{
      border-radius: 30px;
      box-sizing: border-box;
      color: #fff;
      font-size: 10px;
      height: 24px;
      width:24px;
      line-height: 22px;
      text-align: center;
      padding:0;
      background:rgba(0,0,0,0.2);
      position: absolute;
      bottom:0;
    }
    .down{
      right:0;
      -moz-transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg);
    }
    .up{
      right:26px;
    }
    .left,.right{
      border-radius: 30px;
      box-sizing: border-box;
      color: #fff;
      font-size: 10px;
      height: 24px;
      width:24px;
      line-height: 22px;
      text-align: center;
      padding:0;
      background:rgba(0,0,0,0.2);
      position: absolute;
      top:50%;
      display: none;
    }
    .left{
      margin: -10px 0 0 -10px;
      left:0;
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
    }
    .right{
      margin: -10px -10px 0 0px;
      right:0;
      -moz-transform: rotate(90deg);
      -webkit-transform: rotate(90deg);
      -o-transform: rotate(90deg);
      transform: rotate(90deg);
    }
    .edit{
      display: none;
      position: absolute;
      right: 20px;
      top: 20px;
      z-index: 100;
      .icon-pencil{
        background: $red none repeat scroll 0 0;
        border-radius: 20px;
        color: #fff;
        display: block;
        font-size: 12px;
        padding: 6px;
      }
      &:hover{
        nav.sub_menu{
          display: block;
        }
      }
      nav.sub_menu{
        right: -52px;
        top: 12px;
        margin:0;
        display: none;
        border-radius: 3px;
        font-size:10px;
        .arrow_up{
          left: auto;
          margin: 13px 0 0;
          position: absolute;
          right: 56px;
        }
        ul{
          width:125px;
          padding: 10px 10px 2px 10px;
          margin:20px 0 0 0;
          border-radius: 3px;
          li{
            height: auto;
            line-height: inherit;
            margin: 0 0 8px;
            color:#fff;
            font-weight: bold;
            &.category_name{
              font-size: 10px;
              color:#fff;
              text-transform: uppercase;
            }
            &.dotted_spacer{
              border-bottom:1px dotted #fff;
            }
            &.spacer{
              border-bottom:1px solid #fff;
              &:last-child{
                display: none;
              }
            }
            a{
              display: block;
              color:#fff;
            }
          }
        }
      }
    }

    h2{
      font-size:15px;
      font-weight: bold;
      color:#343434;
      line-height: 24px;
      margin:0 0 20px;
      height:75px;
      overflow: hidden;
    }
    .social{
      ul{
        font-size:10px;
        list-style: none;
        li{
          display: inline-block;
          margin:0 15px 0 0;
          color:$gray;
          &.selected{
            a{
              color:$red;
            }
          }
          &.ago{
            float:right;
            font-size: 10px;
            position: relative;
            top:2px;
          }
          a{
            color:$gray;
            &.rubric{
              background: #c1c1c1 none repeat scroll 0 0;
              border-radius: 20px;
              color: #fff;
              font-weight: bold;
              padding: 4px 10px;
            }
            span:first-child{
              font-size: 13px;
              position: relative;
              top:1px;
              margin:0 3px 0 0;
            }
            i{
              display: inline-block;
              height: 14px;
              position: relative;
              top: 2px;
              width: 16px;
            }
            &:hover{
              color:$red;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .articles {
    .info_block {
      margin: 0 auto;
      width: 220px;
      float: none;
    }
    article {
      &.landing[data-block_type="box"].extrude {
        margin: 0 auto;
        width: 220px;
        float: none;
      }

    }
  }
}
