.autocomplete-suggestions{
	background: none;
	background-color: rgba( 51, 51, 51, 0.95 );
	border-bottom: medium none;
	border-image: none;
	border-top: medium none;
	color: #d9d9d9;
	font-family: Open Sans;
	font-size: 0.8vw;
	font-weight: bold !important;
	letter-spacing: 0;
	line-height: 25px;
	padding: 10px 0;
	text-transform: none;

	.autocomplete-suggestion{

		box-sizing: border-box;
		font-weight: bold;
		height: 25px;
		color:#d9d9d9;
		line-height: 25px;
		padding: 0 10px;
		cursor: pointer;

		&:hover {
			color:#fff;
		}
		strong {
			display: inline;
		}
	}
}
