@media (max-width: 960px) {
	.article_page {
		overflow-x: hidden !important;
	}
	.add_comment textarea {
		width: 100% !important;
	}
	
	.post_page > header,
	.post_page .wrapper,
	.post_page #true_header .wrapper,
	.post_page article.article_page.selected,
	.post_page article.article_page section.block .wrapper,
	.post_page article.article_page .saved_article,
	.post_page article.article_page section.block.quote .wrapper, article.article_page section.block .wrapper .quote,
	.post_page article.article_page section.block.leed .wrapper,
	.post_page footer.comments .wrapper,
	.post_page .article_specs,
	.post_page .article_specs .location_and_tags,
	.post_page article.article_page section.block.quote .wrapper,
	.post_page article.article_page section.block.quote .quote,
	.post_page article.article_page section.block.quote .quote_text
		{width:100% !important;}
	
	.post_page #true_header .wrapper,
	.post_page article.article_page section.block .wrapper,
	.post_page .article_specs,
	.post_page footer.comments .wrapper
		{padding-left:20px !important; padding-right:20px !important;}
		
//	.post_page article.article_page > header .frame.modified[data-position="align-bottom-right"] {left:auto; right:20px !important;}
	
	.post_page article.article_page section.block .inscription {max-width:none !important; width:100% !important;}
	
	.post_page  > header {height:80px !important;}
	.post_page  > header nav.main_menu {float:right !important; right:20px !important; top:27px !important; position:absolute;}
	.post_page  > header nav.main_menu.to_left {top:0 !important;}


	.post_page article.article_page section.block.leed .wrapper {font-size:25px !important; line-height:125% !important;}
	.post_page article.article_page section.block[data-section="keyword"] .wrapper {font-size:29px !important; line-height:125% !important; padding-top:40px !important; padding-bottom:30px !important;}
	.post_page article.article_page section.block.quote .wrapper, article.article_page section.block .wrapper .quote {font-size:28px !important; line-height:125% !important;}
	.post_page article.article_page section.block .wrapper {font-size:20px !important; line-height:144% !important;}
	.post_page article.article_page section.block {padding:18px 0 !important;}
	
//	.post_page article.article_page > header .frame.modified h2.rubric.bordered {font-size:3.92vw !important; padding-left:2vw !important; padding-right:2vw !important}
//	.post_page article.article_page > header .frame h1 {font-size:6.35vw !important;}
//	.post_page article.article_page > header .frame.modified h1 {line-height:6.95vw !important; margin:0 0 1.6vw !important;}
//	.post_page article.article_page > header .frame.modified p {font-size:3.68vw !important;}
//	.post_page article.article_page > header .frame.modified .published_at {font-size:2.98vw !important;}
	.mfp-arrow-right {transform-origin:50% 50% 0 !important;}
	
	.post_page article.article_page section.block.footnote .wrapper div {font-size:14px !important; line-height:23px !important;}
	
	.post_page article.article_page section.block.picture_with_text2 .picture_frame {float:none !important; max-width:none !important; width:100% !important; margin-left:0 !important;}
	.post_page article.article_page section.block.picture_with_text2 .inscription {margin-left:0 !important; margin-bottom:10px !important;}
	
	.post_page article.article_page section.block.quote .wrapper .author, .post_page article.article_page section.block .wrapper .quote .author {line-height:150% !important;}
	.post_page article.article_page section.block .wrapper .quote .author {margin-top:6px !important; margin-bottom:16px !important;}
	
	.post_page article.article_page .right {float:none !important;}
	.post_page article.article_page section.block.quote_and_text .right {width:100% !important;}
	
	.post_page article.article_page section.block.picture_with_text .picture_frame  {float:none !important; margin-right:0 !important;}
	.post_page article.article_page section.block.picture_with_text .inscription {float:none !important; margin-right:0 !important; margin-bottom:16px !important;}
	
	.post_page article.article_page section.block.quote .quote_text {float:none !important;}
	
}

@media (max-width: 480px) {
	.post_page > header nav.main_menu.to_left {
		right: 0 !important;
		margin-right: 0;
		margin-left: 0;
	}
}


@import 'jgnt/jgnt_colors';
@import 'jgnt/jgnt_mixin';
@import 'jgnt/jgnt_blocks';
@import 'jgnt/jgnt_pages';
@import 'jgnt/jgnt_articles';
@import 'jgnt/jgnt_broadcast';
@import 'jgnt/jgnt_editor';

// forms  validation
// futher change it to Bootstrap native!

.jgnt_form_validation {
	fieldset {
		position: relative;
	}
	.form-control {
	}
	.nod-error-message {
		font-size: 10px;
		position: relative;
		top: -10px;
	}
	.has-danger {
		.form-control {
			border: 1px solid $jgntColorError !important;
		}
	}	
	.has-success {
		.form-control {
			border: 1px solid $jgntColorSuccess !important;
			 
		}
	}
// adjusts
	#press_find_location {
		top: 10px !important;
		bottom: auto;
	}
}

#press_auto_location {
	position: absolute;
	left: 0;
	top: 40px;
}

#auto_location_small {
	position: absolute;
	right: 10px;
	bottom: 150px;
	font-size: 26px;
	
}

.jgnt_upload_progress {
	background-color: #ddd;
	height: 10px;
	.progress-bar {
		height: inherit;
		background-color: $jgntColorError;
		&.done {
			background-color: $jgntColorSuccess;
		}
	}
}

.upload_images_banner {
	cursor: pointer;
}


.jgnt_infoblock {
	padding: 6rem 0 !important;
	font-size: 2rem;
	@include clearfix();
}

.user_block {
	.profile_pic {
		.delete {
			cursor: pointer !important;
			&:hover {
				background-color: $jgntColorError !important;
			}
		}
	}
}

.tags_list {
	a:hover {
		background-color: $jgntColorSuccess !important;
		color: #fff !important;
	}
}

.landing_page {
	@include clearfix();	
}

.jgnt_inputclear {
	cursor: pointer;
	position: absolute;
	right: 10px;
	top: 20px;
	color: #888;
	font-size: 17px;
	&:hover {
		color: $jgntColorSuccess;
	}
}

.jgnt_search_tag {
	a {
		display: inline-block !important;
	}
	.btn {
		position: relative;
		line-height: 10px;
		display: inline-block !important;
		background-color: $jgntColorSuccess;
		color: #fff;
		border-radius: 5px;
		font-weight: normal;
		font-size: 10px;
		padding: 6px 22px 6px 10px;
	}
}

.jgnt_fieldclear {
	cursor: pointer;
	position: absolute;
	right: 6px;
	top: 5px;
	color: #fff;
	font-size: 12px;
	background-color: transparent;
	border-radius: 50%;
/*
	&:hover {
		background-color: #fff;
		color: $jgntColorSuccess;
	}
*/
}


.full_background {
//	position: relative;
	.jgnt_upload_progress {
		position: absolute;
		left: 0;
		right: 0;
		top: -10px;
	}	
}


.jgnt_order_by {
	font-weight: normal !important;
	span {
		color: #000 !important;
		font-weight: bold;
		white-space: nowrap !important;
	}
	a {
		display: inline-block !important;
		color: #989898 !important;
		text-decoration: none !important;
		&.selected{
			color: #000 !important;
		}
		&:hover {
			color: $jgntColorSuccess !important;
			text-decoration: underline !important;
		}
	}
}


.dummy_holder {
	  margin: 0 auto;
	  position: relative;
  .dummy_image {
    display: block;
    position: relative;
	  width: 220px;
	  height: 120px;
//    height:auto;
    background: #f4f4f4;
    margin: 5px 0 0 0;
//    overflow: hidden;
    border-radius: 5px;
    img{
	    max-width:100%;
		max-height: 100%;
      display: block;
      width:100%;
    }
  }
}


.accent_color.ready {
      background-color: rgb(255, 185, 0) !important;
      color:#fff !important;
      border-radius: 30px;
      &:hover {
	     background-color: darken( rgb(255, 185, 0), 5%) !important;
      }
}

h2 {
	span {
		font-size: 0.6em;
		font-weight: normal;
		&.float_right {
			float: right;
		}
	}	
}

article.article_page section.block img {
	
}

.rslides {
//	overflow: visible !important;
	li {
//		background-color: #f4f4f4;
		max-height: none !important;
//		@include jgnt_adjust("center");
		img {
			max-width:100% !important;
//			max-height: 100% !important;
			max-height: 80vh !important;
			display: block !important;
			width: auto !important;
			height: auto !important;
			margin: auto !important;
		}
		.inscription {
			background-color: #fff !important;
			float: none !important;
			clear: both !important;
			margin: 0 !important;
			padding-top: 10px !important;
		}
		.inscription:empty {
			display: none;
		}
	}		
}

.gallery .rslides_nav {
	margin-bottom: -31px !important;
}

.gallery {
	.rslides {
		li {
			img {
				width: auto !important;
			}
		}
	}
	.save, .cancel {
		background-color: $jgntColorSuccess !important;
		color: #fff;
		display: inline-block !important;
		font-size: 14px !important;
		font-weight: bold !important;
		line-height: 34px !important;
		border: none !important;
		border-radius: 30px !important;
		height: 34px !important;
		padding: 0 20px !important;
		width: 140px !important;
		text-align: center !important;
	}
	.cancel {
		background-color: #989898 !important;
	}
	.gallery_frame {
		.picture_frame {
			.tint:not(.no-picture) {
				cursor: pointer;
				&:hover {
					background-color: rgba( 255, 255, 255, 0.8 );
					background-image: url(/img/les/upload.svg);
					background-position: center center;
					background-size: auto;
					background-repeat: no-repeat;
				}
			}
			.list {
				li {
					.image_wrap {
						@include jgnt_adjust("center");
						height: 520px !important;
					}
/*
					.image {
						max-width:100% !important;
						max-height: 100% !important;
						display: block !important;
						width: auto !important;
						height: auto !important;
					}
*/
				}
			}
		}
	}
}


.jgnt_home_promo {
	display: flex !important;
	align-items: flex-end;
	flex-wrap: wrap;
	margin-bottom: -20px !important;
	& > li {
		margin: 0 0 30px 0 !important;
		flex-grow: 1;
		flex-shrink: 0;
		flex-basis: 50%;
		.social {
			margin-bottom: 0 !important;
		}
		.edit {
			top: 5px;
		}
		.up, .down {
			bottom: 0 !important;
			display: none;
		}
		&:hover {
			.up, .down {
				display: block;
			}
		}
  	}
}

.block {
	background-color: #fff;
//	z-index: 1;
/*
	&.date_block {
		z-index: 2;
	}
	&.rubric_block {
		z-index: 2;
	}
*/
}

.article_specs {
	position: relative;
	z-index: 1;
	background-color: #fff;
}

.article_page {
	position: relative;
	z-index: 1;
}
.map_holder {
	position: relative;
	z-index: 1;
}
footer {
	position: relative;
	z-index: 1;
}
.saved_article {
	background-color: #fff;
	position: relative;
	z-index: 1;
}

.article_footer {
	background-color: #fff;
	position: relative;
	z-index: 1;
}

.jgnt_cover {
	position: relative;
	overflow: hidden;
	z-index: 1;
	.jgnt_bg {
		position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    background-repeat: no-repeat;
	    background-size: cover;
	    background-position: center center;
	    z-index: 0;
	}
	&.no_cover .jgnt_bg {
		display: none;
	}
	&.fixed .jgnt_bg {
		position: fixed;
		top: 55px;
		height: 80%;
	}
    &[data-bg-position="align-top-left"] .jgnt_bg {
	    background-position: left top;
    }
    &[data-bg-position="align-top-right"] .jgnt_bg {
	    background-position: right top;
    }
    &[data-bg-position="align-bottom-left"] .jgnt_bg {
	    background-position: left bottom;
    }
    &[data-bg-position="align-bottom-right"] .jgnt_bg {
	    background-position: right bottom;
    }
}

/*


.jgnt_cover_fixed {
	position: relative;
	overflow: hidden;
	z-index: 1;
	.jgnt_bg {
		display: block;
//	    background-color: red;
	    background: url('/img/les/cityscape.jpg') no-repeat center center;
	    background-size: contain;
	    background-position: center center;
	    will-change: transform; // creates a new paint layer
	    z-index: -1;
	    &[data-bg-position="align-top-left"] {
		    background-position: left top;
	    }
	    &[data-bg-position="align-top-right"] {
		    background-position: right top;
	    }
	    &[data-bg-position="align-bottom-left"] {
		    background-position: left bottom;
	    }
	    &[data-bg-position="align-bottom-right"] {
		    background-position: right bottom;
	    }
	}
}
*/


.add_comment {
	position: relative;
	.nod-error-message {
		position: absolute;
		left: 5px;
		top: auto;
		bottom: -15px;
	}
	button {
		cursor: pointer !important;
	}
	&.has-danger .form-control, &.has-success .form-control {
		border: none !important;
	}
}

.jgnt_social_top {
	position: absolute;
	right: -2px;
	top: 14px;
	height: 25px;
	line-height: 25px !important;
	a {
		display: inline-block;
		height: 25px;
		margin-left: 14px;
		font-size: 24px;
		color: #333333;
		&:hover {
			color: $jgntColorSuccess;
			#telegram { 
				fill: $jgntColorSuccess;
			}
		}
		&.telegram {
			position: relative;
			top: 2px;
		}
	}
}

.jgnt_article_small {
	.extrude.full {
		height: auto !important;
	}
	.article_holder {
		height: 430px !important;
	}
	
}


// date dropdown fix

/*
.selectboxit-container {
	position: relative;
	z-index: 1000;
}

.selectboxit-list {
	z-index: 1000;
}

.selectboxit-options {
	z-index: 1000;
}

.selectboxit {
	z-index: 1000;
}
*/

.selectboxit-container .selectboxit-options {
	z-index: 1000;
}

#first_section_ever {
	z-index: 2;
}

.jgnt_partner {
	margin: 0 auto 2rem auto;
	text-align: center;
	max-width: 220px;
	color: #fff;
	img {
		width: 100%;
		height: auto;
	}
	a {
		color: #fff;
	}
}

.jgnt_select_post_types {
	a {
		display: block;
		padding: 0 20px;
	    height: 34px;
		line-height: 34px;
	    border: 1px solid #000;
		border-radius: 30px;
		text-align: center;
		margin-bottom: 10px;
		&:hover {
			background-color: $jgntColorSuccess;
			border: 1px solid $jgntColorSuccess;
			color: #fff !important;
		}
	}
}

article.article_page section.block ul.modify {
	top: -23px !important;
}

article.article_page.in_editor .inscription {
	background: rgba(0, 0, 0, 0.08);
}

.jgnt_textarea {
	border: 1px solid #f4f4f4;
	height: 56px;
	padding: 7px 10px;
	a {
		color: $jgntColorSuccess;
	}
	&.tags {
		height: 100px;
	}
}

/*
.jgnt_placeholder {
	position: relative;
	.jgnt_placeholder_text {
		&.off {
			display: none;
		}
	}
}
*/

// JGNT RESPONSIVE
.special_stuff {
	@include jgnt_adjust("center");
	img {
		display: block;
		width: 100% !important;
		max-width: 940px !important;
		margin: auto !important;
	}
}

.team.padded {
	width: 100% !important;
	max-width: 960px;
}

/*
.landing_wrap {
	padding: 0 !important;
}
*/

.jgnt_page {
	width: 100% !important;
	// some lame hack? 
	//overflow: hidden !important;
	min-height: 88vh;
}

section.wrapper {
	padding: 55px 0 44px 0 !important;
	max-width: 940px !important;
	&.become_author {
		max-width: 960px !important;
	}
}

.next_page {
	color: #fff !important;
}

.broadcast_editor_page {
	#true_header {
		.tint {
			display: none !important;
		}
	}
}

.home_page {
	#authors_list {
		h2 {
			color: $jgntColorText !important;
		}
	}
}

.jgnt_content {
	max-width: 940px;
	margin: auto;
}

.jgnt_big_banner {
	background-position: center center;
	background-size: cover;
//	min-height: 380px;
	height: auto !important;
	padding: 3rem 0 2rem 0;
	.logo {
		float: left;
		max-width: 320px;
		margin-top: 0.2rem;
		margin-bottom: 4rem;
		img {
			width: 100%;
			height: auto;
		}
	}
	.button {
		float: left;
		clear: left;
		display: inline-block;
		a {
			display: block;
			background-color: #0EB1F3;
			color: #fff;
			text-align: center;
			padding: 0.5rem 2rem;
			border-radius: 18px;
			text-transform: uppercase;
			&:hover {
				background-color: #fff;
				color: #000;
			}
		}
	}
	.text {
		float: right;
		max-width: 540px;
		color: #fff;
		h2 {
			font-size: 1.4rem;
			margin-bottom: 2rem;
		}
		p {
			font-size: 1rem;
			margin-bottom: 1rem;
		}
	}
	@media (max-width: 1000px) {
		padding: 3rem 2rem 2rem 2rem;

		.logo {
			float: none;
			margin-top: 0;
			margin-bottom: 2rem;
		}
		.text {
			float: none;
			max-width: none;
			margin-bottom: 2rem;
		}
		.button {
			float: none;
		}
	}
}


@media (max-width: 1020px) {
	section.wrapper {
		padding: 0 1rem 44px 1rem !important;
	}

	.wrapper {
		width: 100% !important;
		padding-right: 1rem !important;
		padding-left: 1rem !important;
	}
	.home_wrap {
		overflow-y: hidden;
	}
	.jgnt_social_top {
		right: 1rem;
	}
	.articles.fit_all {
		width: 100% !important;
		article {
			width: 48% !important;
			&:nth-child(2n) {
				margin-right: 0 !important;
			}
		}
	}
	nav.sub_header_menu .search_form {
		right: 0;
	}

	section.wrapper{
		article{
			&:nth-child(4n+4){
				margin:0 20px 20px 0;
			}
		}
	}

	.full_banner {
		.articles {
			max-width: 940px;
		}
	}
}

@media (max-width: 600px) {
	nav.sub_header_menu.after_top {
		height: auto;
	}
	nav.sub_header_menu .search_form {
		position: static;
	}
	nav.sub_header_menu .search_form input {
		position: static;
	}
	nav.sub_header_menu .search_form .icon-search {
		position: static;
		margin-left: -25px;
	}
}
@media (max-width: 540px) {
	.articles.fit_all {
		article {
			width: 100% !important;
			margin-right: 0 !important;
		}
	}

	section.wrapper{
		article{
			width: 100%;
		}
	}

}

.map_articles_holder {
	width: 960px !important;
	li {
		h3 {
			padding-right: 20px !important;
		}
		p {
			padding-right: 20px !important;
		}
	}
}

@media (max-width: 1070px) {
	.map_holder {
		background-color: #fff;
		width: 100%;
		overflow: hidden !important;

	    .left-arrow{
	      left: 0 !important;
	      margin: 0 !important;
	    }
	    .right-arrow{
	      right: 0 !important;
	      margin: 0 !important;
	    }
	}
	.map_articles_holder {
		width: 720px !important;
		padding: 0 !important;
	}
}

@media (max-width: 820px) {
	.map_articles_holder {
		width: 480px !important;
		padding: 0 !important;
	}
}

@media (max-width: 600px) {
	.map_articles_holder {
		width: 240px !important;
		padding: 0 !important;
	}
}


// header

@media (max-width: 820px) {
	.home_page {	
		#top_main_menu_nav {
			height: auto !important;
			ul {
				margin-top: 12px;
				margin-right: 140px;
				line-height: 30px;
				min-height: 55px;
				.icon-bars {
					margin-top: 7px;
				}
			}
		}
	}
}

@media (max-width: 760px) {
	.home_page {
		#true_header {
			.main_folders {
				position: relative !important;
				float: left !important;
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}
		}
	}
}
@media (max-width: 600px) {

	body > header nav.main_menu {
		float: none;
	}
}
.jgnt_burger {
	position: absolute;
	left: 0;
	top: 0;
	a {
		color: $jgntColorText;
	}
}

@media ( max-width: 1020px ) {
	.jgnt_burger {
		left: 1rem;
	}
}

.jgnt_top_menu {
	margin-left: 2rem;
}

@media (max-width: 820px) {
	.jgnt_top_menu {
		margin-top: 12px;
		margin-right: 140px;
		line-height: 30px;
		min-height: 55px;
	}
}

// big banner
@media ( min-width: 721px ) and (max-width: 960px) {	
	.first_article {
		bottom: auto !important;
		left: 2rem !important;
		top: 2rem !important;
		width: 60% !important;
	}
	.jgnt_home_promo {
//		width: 65% !important;
		right: 2rem !important;
	}
}

@media (max-width: 720px) {	
	.full_banner {
		.articles {
			padding-top: 2rem;
			@include jgnt_adjust( "htable" );
			article {
				position: relative !important;
				margin: 0  0 4rem 0 !important;
			}
			.jgnt_home_promo {
				position: relative !important;
			}
		}
	}
}

@media (max-width: 520px) {	
	.full_banner {
		.articles {
			width: 100% !important;
			display: block !important;
			.first_article {
				position: absolute !important;
				top: auto !important;
				bottom: 0 !important;
				margin-bottom: 2rem !important;
				width: auto !important;
			}
			.jgnt_home_promo {
				display: none !important;
			}
		}
	}
}

@media (max-width: 420px) {	
	.jgnt_hide_small {
		display: none;
	}
	.jgnt_hide_big {
		display: inline;
	}
}

@media (min-width: 421px) {	
	.jgnt_hide_small {
		display: inline;
	}
	.jgnt_hide_big {
		display: none;
	}
}


body > header nav.main_menu ul li.profile .icon-user {
	margin-right: 0.5rem;
}