aside.chat{
  position: fixed;
  right:0;
  bottom:0;
  width:$cell4;
  z-index: 120;
  &.closed{
    .messages{
      background: #fff none repeat scroll 0 0;
      box-sizing: border-box;
      height: 340px;
      margin: 0 0 -408px;
      padding: 0 10px;
      position: relative;
    }
    .message_field{
      opacity: 0;
    }
    header{
      opacity: 0;
      position: relative;
      z-index: 10;
    }
    footer{
      border-top:1px solid $gray;
      border-left:1px solid $gray;
      border-right:1px solid $gray;
      border-radius: 10px 10px 0px 0px;
      right:1px;
      position: relative;
    }
  }
  .toggle{

  }
  header{
    width:100%;
    color:#fff;
    font-weight: bold;
    text-align: left;
    box-sizing: border-box;
    height:34px;
    line-height: 34px;
    padding: 0 10px;
    background: $black;
    transition: 0.4s ease-out all;
    cursor:pointer;
    .icon-arrow-up{
      font-size: 12px;
    }
  }
  .toggle{
    border-left:1px solid $gray;
    border-right:1px solid $gray;
  }
  .editors_list{
    height:240px;
    background:$mcolor;
  }
  .messages{
    height:340px;
    background:#fff;
    box-sizing: border-box;
    padding: 0 10px;
    overflow: auto;
    transition: 0.4s ease-out all;
  }
  .author{
    display:table;
    margin:0 0 10px 0;
    &.me{
      .user_pic{
        display: none;
      }
      text-align: right;
      .user_name{
        float: right;
        strong{
          display: none;
        }
        .date{
          float:right;
        }
        .message{
          float:right;
          clear:right;
        }
      }
    }
    .user_pic{
      display: table-cell;
      float:none;
      width:45px;
      vertical-align: top;
      img{

      }
    }
    .user_name{
      display: table-cell;
      strong{
        font-size:10px;
        vertical-align: top;
        margin:0 7px 0 0;
        float:left;
      }
      .date{
        vertical-align: top;
        display: inline-block;
        font-size:10px;
        clear: right;
        float:left;
      }
    }
    .message{
      background:$mcolor;
      border-radius: 10px;
      font-size:12px;
      padding: 2px 6px;
      position: relative;
      right: 4px;
      clear:left;
      float:left;
    }
  }
  .message_field{
    margin:0;
    border:none;
    border-top:1px solid $mcolor;
    font-family: $font1;
    box-sizing: border-box;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    resize: none;
    width: 100%;
    transition: 0.4s ease-out all;
  }
  footer{
    line-height: 34px;
    height: 34px;
    width:100%;
    transition: 0.4s ease-out all;
    background:$mcolor;
    text-align: center;
    font-weight: bold;
  }
}
