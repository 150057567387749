@keyframes shadow_play {
    0%   {box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.18);}
    50%  {box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);}
    100% {box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.18);}
}
.new_posts_notice{
  background: #fff none repeat scroll 0 0;
  border-radius: 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  color: #000;
  display: none;
  font-size: 10px;
  height: 24px;
  left: 50%;
  line-height: 24px;
  margin: 10px 0 0 -55px;
  padding: 0 10px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 110px;
  z-index: 300;
  animation-name: shadow_play;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite;
  &.selected{
    display: block;
  }
}
.hidden_posts{
  display: none;
}
.broadcast_stats{
	min-height:100px;
  width:100%;
  background:$black;
  color:$gray;
  ul{
    position: relative;
    bottom:-5px;
    list-style: none;
    font-weight: bold;
    font-size:14px;
    li{
      display: inline-block;
      width:$cell3;
      margin:0 20px 0 0;
      &:last-child{
        margin:0;
      }
      strong{
        font-size:36px;
        display: block;
        margin:0 0 -4px 0;
      }
    }
  }
}
.selected_broadcasts{
  background:$mcolor;
  width:100%;
  height:350px;
  .left-arrow{
    display: block;
    left: 50%;
    margin: 0 0 0 -531px;
    padding: 5px 0;
    position: absolute;
    transform: rotate(-90deg);
    font-size:47px;
    top:115px;
    color:$gray2;
  }
  .right-arrow{
    display: block;
    margin: 0 -531px 0 0;
    padding: 5px 0;
    position: absolute;
    right: 50%;
    transform: rotate(-90deg);
    font-size:47px;
    top:115px;
    color:$gray2;
  }
  .edit{
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 100;
    display: none;
    &:hover{
      nav.sub_menu{
        display: block;
      }
    }
    .icon-pencil{
      border-radius: 20px;
      color: #fff;
      display: block;
      font-size: 12px;
      padding: 6px;
      background:$red;
    }
    nav.sub_menu{
      border-radius: 3px;
      display: none;
      font-size: 10px;
      margin: 0;
      right: -52px;
      top: 12px;
      .arrow_up{
        left: auto;
        margin: 13px 0 0;
        position: absolute;
        right: 56px;
      }
      ul{
        border-radius: 3px;
        margin: 20px 0 0;
        padding: 10px 10px 2px;
        width: 125px;
        li{
          color: #fff;
          font-weight: bold;
          height: auto;
          line-height: inherit;
          margin: 0 0 8px;
          a{
            color: #fff;
            display: block;
          }
        }
      }
    }
  }

  .wrapper{
    position: relative;
    >h2{
      font-size: 24px;
      margin: 0 0 16px;
      padding:37px 0 0 0;
      overflow: visible;
    }
  }
  ul{
    white-space: nowrap;
      overflow: hidden;
    li{
      white-space: normal;
      position: relative;
      display: inline-block;
      width:$cell3;
      vertical-align: top;
      margin-right:20px;
      &:hover{
        .edit{
          display: block;
        }
      }
      &:last-child{
        margin-right:0;
      }
      a{
        img{
          display: block;
          margin:0 0 19px 0;
          width:$cell3;
        }
        .event_date{
          font-size: 10px;
          color:$gray;
          margin:0 0 1px 0;
          strong{
            font-size:14px;
          }
        }
        h2{
          font-size: 14px;
          font-weight: bold;
          line-height: 22px;
          color:#000;
        }
      }
    }
  }
}
.broadcast_editor_page{
  .autocomplete-suggestions{
    font-size:12px;
    .autocomplete-suggestion{
      strong{
        color:#fff;
      }
    }
  }
}
.broadcast_editor_filter{
  height:100%;
  .line{
    display: block;
    margin:0 0 25px 0;
    .search_holder{
      display: inline-block;
      position: relative;
      right: auto;
      top: auto;
    }
    .search{
      width:350px;
    }
    >strong{
      display: inline-block;
      width:120px;
    }
    ul{
      list-style: none;
      display: inline-block;
      li{
        display: inline-block;
        margin:0 20px 0 0;
        &:last-child{
          margin:0;
        }
      }
    }
    .control__indicator{
      top:3px;
    }
    .control{
      margin:0;
      font-size: 14px;
      padding-left: 18px;
    }
    .rating label:first-child{
      margin:0;
    }
    label{
      text-transform: uppercase;
    }
  }
  .search_holder{
    position: absolute;
    top:9px;
    right:0;

  }
  .search{
    line-height: 34px;
    height:34px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: $font1;
    border:1px solid $gray2;
    color:#000;
    border-radius: 30px;
    padding:0 10px;
    background:none;
    width:$cell3;
  }
  .icon-search{
    color:$gray2;
    position: absolute;
    bottom:20px;
    right:10px;
    top:9px;
  }
  .rating{
    text-transform: uppercase;
    text-align: right;
    .lower{
      position: relative;
      top:10px;
    }
    label{
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      margin: 0 0 0 7px;
      padding-left: 17px;
      padding-top: 1px;
/*
      &:first-child{
        margin:0 0 0 14px;
      }
      &:last-child{
        margin: 0 0 0 7px;
      }
*/
    }
  }

  h2{
    font-size: 24px;
    text-transform: none;
    margin:17px 0 9px 0;
  }
  nav.sub_header_menu{
    margin:0;
    height:253px;
    line-height: normal;
    .control__indicator{
      top:3px;
    }
    ul li{
      margin:0 21px 0 0;
      &:last-child{
        margin:0;
      }
    }

    .wrapper{
      position: relative;
      ul{
        li{
          margin:0 25px 0 0;
          &:last-child{
            margin:0;
          }
        }
      }
      &.second{
        ul{
          li{
            margin:0 27px 0 0;
            &:last-child{
              margin:0;
            }
          }
        }
      }
      ul{
        height:33px;
      }
    }
    .more_options{
      position: relative;
      margin:15px auto 0 auto;
      width:$cell12;
      text-transform: none;
      .control{
        top:6px;
        text-transform: uppercase;
      }
      ul{
        list-style: none;
        li{
          display: inline-block;
          margin:0 41px 0 0;
          &:last-child{
            margin:0;
          }
        }
      }
      .search_holder{
        position: relative;
        top:auto;
        right:auto;
        display: inline-block;
      }
    }
    .control{
      margin:0;
      top:10px;
    }

  }
}

.broadcast_holder{
//   display: inline;
}
.broadcast_page{
/*
  .broadcast_card.opened{
    display: inline;
  }
*/
  .wrapper{
    &.load_more{
      width:940px;
    }
  }
}
.broadcast_card{
  position: relative;
  clear:both;
  margin:0 0 1px 0;
/*
  .holder h2{
    max-width: 795px;
  }
*/
  .gallery{
    &.stripped{
      .rslides_nav{
        margin:-44px 0 0 0;
      }
      .gallery_frame .picture_frame .image_frame{
//        height:auto;
//        min-height: 534px;
      }
    }
    .gallery_frame{
      .edit{
        margin:0 -150px 0 0 !important;
        width:300px;
        text-align: center;
        a{
          margin:0 3px 0 0;
          &:last-child{
            margin:0;
          }
        }
      }
    }
  }

  .selectboxit-container .selectboxit-btn{
    background:none;
    &:hover,
    &:focus{
      background:none;
    }
  }
  .selectboxit-container .selectboxit{
    width:180px;
    margin:-3px 0 0 5px;
  }
  .selectboxit-container .selectboxit-options{
    background:#fff;
    border-radius: 7px;
    padding: 21px 0 13px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2);
    .selectboxit-option-anchor{
      font-size:12px !important;
      margin:0 0 0px;
      height:34px;
      line-height: 34px;
      &:hover{
        background:$black;
        color:#fff;
      }
    }
  }
  .delete_button.selected{
    background:$red;
  }
  ul.modify{
     margin: 0 0 0 0;
     right: 0;
     left: 0;
     top: 0.5rem;
     text-align: center;
     li{
       margin:0 3px 0 0;
     }
  }
  .control input:checked ~ .control__indicator{
    &.photo,
    &.video,
    &.text,
    &.corrector{
      border:1px solid #000;
    }
  }
  .control__indicator{
    &.photo{
      background:#488ccb;
      border:1px solid #488ccb;
    }
    &.video{
      background:#ff4e50;
      border:1px solid #ff4e50;
    }
    &.text{
      background:#9185be;
      border:1px solid #9185be;
    }
    &.corrector{
      background:#00bac0;
      border:1px solid #00bac0;
    }
  }
  .icon-location{
    margin:0 5px 0 1px;
    cursor: pointer;
  }
  .load-gallery,
  .load-image-form,
  .load-gallery-form{
    display: none;
  }
  &.in_edit{
    &.someone_else{
      ul.modify > li > a.edit_button{
        background:#ff4e50;
      }
    }
    ul.modify > li > a.edit_button{
//      background:$red;
      background: #ff4e50;
      .icon-pencil:before {
	      content: "\e966" !important;
      }
    }
    &.opened{
      .text_group{
        //margin:40px auto 0 auto !important;
      }
      .external_wrapper{
        display: block;
      }
    }
  }
  ul.modify > li > a.open_button{
    .icon-views{
      display: none;
    }
    .icon-hidden{
      display: block;
    }
  }
  &.opened{
    margin:0;
    height:100%;
    ul.modify > li > a.open_button{
      background:$red;
      .icon-views{
        display: block;

      }
      .icon-hidden{
        display: none;
      }
    }
    article{
      display: block;
    }
  }
  article{
  	margin: 0 !important;
    padding: 0 !important;
    display: none;

    &.full_background:not(.jgnt_move_down){
      .social{
//        bottom:36px;
        ul li a.rubric{
          background:rgba(0,0,0,0.4);
        }
      }
      ul.tags_list{
//        bottom:31px;
        li{
          a{
            background:rgba(0,0,0,0.4);
            color: #fff !important;
          }
        }
      }
     }
    &.full_background{
      .text_group{
//        bottom: 77px;
//        left: 50%;
//        margin: 0 0 0 -470px !important;
//        position: absolute;
        height: auto !important;
        h2 {
          position: relative;
          bottom:auto;
          height: auto;
          margin: 0;
        }
        .author{
          .user_name{
            position: relative;
            bottom: auto;
          }
        }
        .leed{
          position: relative;
          height: auto;

        }
      }


    }
    ul.tags_list{
      bottom:13px;
      height: 24px;
      overflow: hidden;
      text-align: right;
      width: 690px;
      li{
        vertical-align: top;
        margin:0 0 20px 7px;
      }
    }
    .date_ago{
//      top:65px !important;
//      width:150px !important;
//      margin-left: -640px !important;
      font-size:10px;
      strong{
        font-size: 14px;
      }
      .local{
        margin:-3px 0 0;
        font-size:10px;
      }
    }
    .gallery li{
      p{
        color: $gray;
        font-size: 10px !important;
      }
    }
    .image{
//      margin:12px auto 0 !important;
      p {
        margin: 0.5rem 0 0 0;
        color: $gray;
        font-size: 10px;
      }
    }
    .text_group{
      h2{
        font-size:24px;
        margin:0 0 27px;
        overflow: visible;
      }

      .leed{
        a{
          color:$red !important;
        }
      }
      .author{
        display: block !important;
        margin:0 0 1rem !important;
        .user_name{
          .location{
            color:#000;
            margin:0 24px 0 20px;
            .icon-location{
              margin:0 0 0 0;
            }
          }
          a{
            &:hover{
              color:$red !important;
            }
          }
        }
        .event_date{
          display: inline-block;
          .local{
            font-size: 10px;
            display: inline-block;
            margin:0 0 0 4px;
          }
        }
      }
    }
  }
  .full_background{
    header{
      .modify{
        top:20px;
      }
      .filter{
        position: absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
      }
    }
  }
  .image{
    .modify{
      top:20px;
      right:20px;
    }
  }
  .author_comments,
  .editor_comments{
    margin:0 0 13px 0;
    vertical-align: top;
  }
  .holder{
    position: relative;
    box-sizing: border-box;
    padding: 20px 0;
    background:$mcolor;
    .left{
      display: inline-block;
      width:$cell7;
      margin:0 0 13px 0;
      .status{
        strong{
          text-transform: uppercase;
        }
      }
    }
    .right{
      display: inline-block;
      width:$cell3+20px;
      margin:0 0 13px 0;
    }
  }
  .status_float{
    position: absolute;
    width:165px;
    height:35px;
    top:0;
    font-weight: bold;
    right:0;
    line-height: 35px;
    color:#fff;
    background-color:$gray;
    text-align: center;
      &:hover {
	     color: #fff;
	     background-color: darken( $gray, 10%);
      }
    &.red{
      background-color:#ff4e50;
      &:hover {
	     background-color: darken( #ff4e50, 10%);
      }
    }
    &.yellow{
      background-color:#ffd75e;
      &:hover {
	     background-color: darken( #ffd75e, 10%);
      }
    }
    &.accent_color,
    &.save_button{
      background-color:$red;
      color:#fff !important;
      border-radius: 30px;
      &:hover {
	     background-color: darken( $red, 10%);
      }
    }
  }
  .number{
    text-align: center;
    background: $black none repeat scroll 0 0;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-weight: bold;
    left: -98px;
    padding:0 10px;
    position: absolute;
    width: 79px;
    height:34px;
    line-height: 34px;
  }
  .modify{
    display: block;
  }
  .modify.main{
    display: block;
    left: -112px;
    right: auto;
    top: 48px;
    width:93px;
    text-align: right;
    li{
      margin:0 0 5px 12px;
      a{
        width:34px;
        height:34px;
        div{
          font-size: 14px;
          line-height: 34px;
        }
      }
      &.delete_button{
        position: absolute;
        right: -958px;
        top: 41px;
        a{
          background:$gray2;
          &.selected{
            background:$red;
          }
        }
      }
    }
  }
  h2{
    margin:0px 0 13px 0;
  }
  a{
    color:$black;
  }
  .rating,
  .checks{
    label{
      display: inline-block;
      font-size: 14px;
      padding-left: 18px;
      margin:0 9px 0 0;
      font-weight: bold;
      &:last-child{
        margin:0;
      }
    }
  }
  .rating{
    text-transform: uppercase;
    text-align: right;
    label{
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      margin: 0 0 0 7px;
      padding-left: 17px;
      padding-top: 1px;
      &:first-child{
        margin:0 0 0 14px;
      }
      &:last-child{
        margin: 0 0 0 7px;
      }
    }
  }
  .row{
    width: $cell3 + 20px;
    margin:0 20px 0 0;
    display: inline-block;
    vertical-align: top;
    //overflow: hidden;
    >div{
      white-space: nowrap;
      margin:0 0 13px 0;
    }
    &.long{
      width:$cell4;
      margin:0 0 0 0;
      &.to_right{
        text-align: right;
      }
    }
  }
  .external_wrapper{
    display: none;
  }
  .input_field{
    display: inline-block;
    min-width:200px;
    min-height:10px;
    vertical-align: top;
  }
  .move_left{
    margin:30px 20px 0 0;
    float:left;
    width:$cell6;
  }
  .move_right{
    margin:30px 0 0 0;
    float:left;
    width:$cell3;
    &.space{
      margin:30px 20px 0 0;
    }
  }
  .previews{
    li{
//      width:89px;
      float:left;
    }
  }
  textarea.tags{
    background: transparent none repeat scroll 0 0;
    border: 1px solid #f4f4f4;
    height: 114px;
    margin: 0 0 20px;
    resize: none;
  }
  .wrapper{
    position: relative;

  }
}
article.landing{
  position: relative;
  &[data-block_type='wide']{
    display: block;
    width:$cell9;
    height:220px;
    background:$red;
    header{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
    .filter{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
    }
  }
  &[data-block_type='box']{
    display: block;
    width:220px;
    height:220px;
    padding:0;
    align-items:center;
    text-align: center;
    position: relative;
    background:$red;
    .social{
      display: none;
    }
    &.extrude{
      height:387px;
      background:none;
      .social{
        display: block;
        list-style: none;
        color:$gray;
        font-size: 10px;
        margin:0 0 12px 0;
        .icon-list{
          font-size: 12px;
          top:2px;
          position: relative;
        }
        .icon-views{
          font-size:13px;
          position: relative;
          top:2px;
        }
        li{
          display: inline-block;
          margin:0 14px 0 0;
        }
      }
      header{
        position: relative;
        width:220px;
        height: 220px;
        background:none;
        left:0;
        padding: 0;
        margin:0 0 17px 0;
        background:$red;
      }
      a.frame{
        color: #000;
        display: block;
        padding: 0;
        position: relative;
        text-align: left;
        height: auto;
        overflow: hidden;
        max-height: 120px;
        h2{
          color:#000;
          margin:0 0 3px 0;
        }
        p{
          color:#000;
          font-size: 14px;
          font-weight: normal;
          line-height: 23px;
        }
      }
    }
    &:hover{
      .screen{
        opacity: 1;
      }
    }
    .filter{
      position: absolute;
      width:100%;
      height:100%;
    }
    header{
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      left: -1px;
      padding: 0 2px 2px 0;
      position: absolute;
      top: -1px;
    }
    a.frame{
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin: 0;
      position: absolute;
      text-align: center;
      width: 100%;
      z-index: 10;
      box-sizing: border-box;
      padding: 20px;
      h2{
        height:auto;
        color:#fff;
        margin: 0;
        font-size: 18px;
        font-weight: bold;
      }
      p{
        color:#fff;
        font-size: 10px;
        font-weight: bold;
      }
    }
  }
}


nav.landing_navigation{
  margin:$header-height 0 0 0;
}
ul.articles_setup{
  list-style: none;
  width:$cell12;
  margin:23px auto 0 auto;
  > li{
    width:$cell6;
    float:left;
    min-height:250px;
    background:$mcolor;
    margin:0 0 23px 0;
    position: relative;
    .number{
      position: absolute;
      height:32px;
      line-height: 32px;
      padding: 0 10px;
      background:$black;
      color:#fff;
      font-weight: bold;
      top:0;
      left:0;
    }
    p{
      margin:0 0 13px 0;
    }
    .status_list,
    .category_list{
      width:130px;
      display: inline-block;
    }
    .info_block{
      float:left;
      width:$cell3+20px;
      box-sizing: border-box;
      padding: 50px 20px;
      position: relative;
    }
    .publish{
      bottom: 0;
      position: absolute;
      right: 20px;
      &:hover{
        color:#fff;
      }
    }
    .article_holder{
      float:right;
      width:$cell3;
    }
    &:nth-child(even){
      float:right;
    }
    .selectboxit-container{
      margin:0 0 0px 0;
      height:22px;
      .selectboxit{
        border-radius: 0;
      }
      .selectboxit-option-icon-container{
        display: none;
      }
      .selectboxit-btn{
        border:none;
        background:#fff;
      }
      .selectboxit-options{
        border:none;
        background: #fff;
        border: 0 none;
        border-radius: 0;
        width:100%;
        margin:-4px 0 0 0;
         span,a{
          height:20px;
          line-height: 20px;
          color:$black;
          clear: both;
        }
      }
      .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor{
        background:none;
        color:#000;
      }
      span.selectboxit-btn{
        height:22px;
        line-height: 22px;
      }
      span.selectboxit-text{
        max-width: 110px !important;
        height:22px;
        line-height: 22px;
      }
    }
    .selectboxit{

      .selectboxit-arrow-container{
        .selectboxit-arrow{
          bottom: auto;
          top: 5px;
          color:$black;
          font-size: 12px;
          left: 10px;
          margin: 0 auto;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
header.short_landing_header{
    margin:$header-height 0 0 0;
    height:164px;
    width:100%;
    padding:0;
    background:$gray;
    position: relative;
    z-index: 10;
    .filter{
      position: absolute;
      width:100%;
      height:100%;
    }
    .wrapper{
      position: relative;
      display:flex;
      flex-direction: column;
      justify-content: center;
      height:100%;
    }

    h1{
      font-size: 48px;
      text-align: center;
      width:100%;
      color:#fff;
    }
}
section.landing_about_page{
  display: none;
  &.selected{
    display: block;
  }

  .manifest{
    margin:90px 0 0 0;
    font-size:24px;
    font-weight: bold;
    text-align: left;
    &.media_manifest{
      margin:0;
    }
  }
  .info{
    margin:75px 0 0 0;
    font-size:14px;
  }
  h2{
    font-size: 18px;
    font-weight: bold;
  }
  .contact_us{
    margin:100px 0 0 0;
    #small_map{
      width:100%;
      height:220px;
      margin:20px 0 65px 0;
      float:left;
      background:$mcolor;
    }
    h2{
      margin:0 0 10px 0;
    }
    .left{
      float: left;
    }
    .right{
      float:right;
    }
    label{
      font-weight: bold;
      display: block;
      margin:0 0 3px 0;
    }
    p{
      margin:0 0 13px 0;
    }
    .links{
      list-style: none;
      li{
        display: inline-block;
        margin:0 10px 0 0;
        &:last-child{
          margin:0;
        }
        a{
          display: block;
          color:$black;
          font-size: 18px;
        }
      }
    }
  }
}
section.landing_page{
  display: none;
  &.selected{
    display: block;
    height:100%;
  }
  nav.sub_header_menu{
    margin:0;
  }
  .top_part{
    left: 10px;
    margin: 40px auto 0;
    padding: 0;
    width:$cell12+20px;
    position: relative;
    article{
      &.extrude{
        margin:0 20px 57px 0;
      }
      margin:0 20px 20px 0;
      &[data-format='wide']{
        &.extrude{
          margin:0 20px 57px 0;
        }
        margin:0 20px 20px 0;
      }
    }
  }
  >header{
    margin:$header-height 0 0 0;
    height:507px;
    width:100%;
    background:$black;
    position: relative;
    &.media_sub_header{
      margin: 0;
      height: auto;
      background:none;
      position: absolute;
      top:66px;
      z-index: 10;
      .edit{
        top:0;
      }
      ul.links{
        top:0;
        a{
          color:$black;
        }
        .landing_subscription{
          .subed{

          }
          .sub{
            border:1px solid $black;
          }
        }
      }
    }
    .filter{
      top:0;
      left:0;
      width:100%;
      height:100%;
      position: absolute;
    }
    .wrapper{
      position: relative;
      display:flex;
      flex-direction: column;
      justify-content: center;
      height:100%;
    }
    .edit{
      position: absolute;
      right:0;
      bottom:20px;
      .button{
        height:34px;
        line-height: 34px;
        border-radius: 30px;
        margin:0 10px 0 0;
        display: inline-block;
        padding:0 17px;
        background:$black;
        color:#fff;
        font-weight: bold;
        &:last-child{
          margin:0;
        }
      }
    }
    ul.links{
      position: absolute;
      top:12px;
      right:0;
      list-style: none;
      li{
        display:inline-block;
        margin:0 10px 0 0;
        a{
          display: block;
          color:#fff;
          font-size:18px;
          &.subscription_button{
            font-size:14px;
          }
        }
      }
    }
    h1{
      font-size: 48px;
      text-align: center;
      width:100%;
      color:#fff;
    }
    p{
      width:100%;
      font-size:14px;
      font-weight: bold;
      text-align: center;
      color:#fff;
    }
  }
}
.landing_setup{
  .video_frame{
    height: 0;
    padding-bottom: 56.25%;
    padding-top: 25px;
    position: relative;
    background: $mcolor url("/img/les/upload_video.png") no-repeat scroll center center;
    iframe{
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
  .load-image{
    display: none;
  }
  textarea.manifest{
    height:100px;
    resize:none;
  }
  textarea.info{
    height:445px;
    resize:none;
  }
  ul.modify{
    display: block;
    right:0;
  }

  &.broadcast_post{
    .sub_header_menu{
      margin:55px 0 43px 0;
    }
    h1{
      margin:0;
    }
    .date_block{
      margin:0 0 32px 0;
    }
    .fields{
      position: relative;
      .error{
        margin:0;
      }
      textarea.short{
        height:55px;
        resize:none;
      }
      textarea.tall{
        height:168px;
        resize:none;
      }
      textarea.white{
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: 1px solid #f4f4f4;
        height: 56px;
//        margin: 0 0 10px;
        resize: none;
      }
    }
    .choose_rubric{
      width:$cell3;
    }
    div.block{
      width:100%;
    }
    .wrapper{

      >.left{
        width:$cell6;
      }
      >.right{
        width:$cell6;
        margin:0;
      }
    }
  }
  #landing_profile{
    .wrapper.hipster_block,
    .wrapper.media_block{
      display: none;
    }
    &[data-template='hipster']{
      .wrapper.hipster_block{
        display:block;
      }
      .wrapper.media_block{
        display: none;
      }
    }
    &[data-template='media']{
      .wrapper.hipster_block{
        display: none;
      }
      .wrapper.media_block{
        display: block;
        margin:-13px auto 0 auto;
        h1{
          margin:30px 0 13px 0;
        }
      }
    }
  }
  #landing_about{
    .wrapper{
      >.left{
        margin:-23px 0 0 0;
      }
    }
    .editors_list{
      width:100%;
      .user_block{
        width:$cell3;
        margin:0 20px 20px 0;
        float:left;
        &:nth-child(3n+3){
    			margin:0 0 20px 0;
    		}
      }
    }
    .editors{
      h1{
        margin:0 0 20px 0;
      }
      .left{
        width:$cell9;
        clear: none;
      }
      .right{
        width:$cell3;
        clear:none;
      }
      .search_group{
        position: relative;
        .icon-search{
          bottom: 20px;
          position: absolute;
          right: 10px;
        }
      }
    }
  }
  .wrapper{
    >.right{
      margin:-35px 0 0 0;
    }
  }
  .inline{
    width:$cell6+20px;
    input{
      display:inline-block;
      margin:0 20px 10px 0;
      &.half{
        width:$cell3;
      }
      &.third{
        width:$cell2;
      }
      &.full{
        width:$cell6;
      }
    }
  }
  .left_right{
    .additional_rubrics{
      display: none;
      &.selected{
        display: block;
      }
    }
    input{
      width:$cell3;
      float:left;
      &:nth-child(even) {
        margin:0 0 10px 20px;
      }
    }
    .fixed_field{
      box-sizing: border-box;
      float: left;
      padding: 0 10px;
      width: $cell3;
      line-height: 35px;
      &:nth-child(even) {
        margin:0 0 10px 20px;
      }
    }
    textarea{
      width:$cell3;
      float:left;
      resize:none;
      height:100px;
    }
    select{
      float:right;
      width:$cell3;
    }
  }
  .add,.hide{
    font-size:10px;
    color:$black;
  }
  .add{
    float:left;
    display: block;
    margin:5px 0 0 0;
  }
  .hide{
    display: block;
    font-weight: bold;
    float:right;
    clear:both;
    .off{
      display: block;
    }
    .on{
      display: none;
    }
    &.selected{
      .off{
        display: none;
      }
      .on{
        display: block;
      }
    }
  }
  .left{
    float:left;
    position:relative;
  }
  .right{
    float:right;
    position:relative;
  }
  h1{
    font-size:$h1_size;
    margin:30px 0 40px 0;
  }
  div.block{
    margin:0 0 30px 0;
    width:$cell6;
    display:block;
    clear:both;
    &.main_info{
      margin:0 0 30px 0;
    }
    &.color_info{
      margin:0 0 28px 0;
    }
    &.visible_block{
      margin:0 0 54px 0;
    }
    >.small{
      margin:0 0 20px 0;
    }
    &.folder_block{
      margin:0 0 0px 0;
      &.news{
        margin:0 0 11px 0;
      }
    }
    &.logo_place{

      .left{
        width:$cell3;
        .logo_holder{
          margin: 0 auto;
          width:113px;
          position: relative;
          .logo_image{
            display: block;
            position: relative;
            width:113px;
            height:113px;
            background:$mcolor;
            margin:15px 0 0 0;
            overflow: hidden;
            border-radius: 5px;
            img{
              display: block;
              width:100%;
            }
          }
        }
      }
      .right{
        width:$cell3;
        margin:2px 0 0px 0;
        .holder{
          margin:14px 0 0 0;
        }
      }
    }
    &.full_width{
      width:100%;
      margin:30px 0 20px 0;
      float:left;
      input[type='text']{
        width:$cell3;
        float:left;
        margin:0 20px 10px 0;
        &:nth-child(4n+5){
    			margin:0 0 10px 0;
    		}
      }
      .left{
        width:$cell9;
      }
      .right{
        width:$cell3;
      }
    }
  }

  .holder{
    margin:10px 0 0 0;
    clear:both;
    background:#e5e5e5;
    .wrapper div:last-child{
      margin:0 0 0;
    }
  }
  .rename_block{
    >span{
      float:left;
      width:$cell3;
    }
    input{
      float:right;
      width:$cell3;
      clear:right;
    }

  }
  .sub_header_menu{
    border-bottom:1px solid $mcolor;
    .dash{
     border-top: 1px solid #000;
     height: 10px;
     margin: 0 8px 0 -18px;
     position: relative;
     top: 27px;
     vertical-align: top;
     width: 14px;
    }
    .selected{
      font-weight: bold;
    }
    a{
      color:$black;
    }
  }
  section.tab{
    display: none;
    &.selected{
      display: block;
    }
  }
  ul.templates{
    list-style: none;
    margin:0 0 55px 0;
    li{
      display: inline-block;
      width:$cell6;
      vertical-align: top;
      a{
        &.selected{
          .frame{
            opacity: 1;
          }
        }
      }
      .image{
        float:left;
        position: relative;
        .frame{
          width:100%;
          height:100%;
          border:3px solid $red;
          position: absolute;
          top:0;
          left:0;
          box-sizing: border-box;
          opacity: 0;
          transition: 0.2s ease-in opacity;
        }
        img{
          display: block;
        }
      }
      .text{
        float:right;
        width:$cell3;
        font-size:14px;
        color:$black;
        strong{
          margin:0 0 25px 0;
          display: block;
        }
        p{
          margin:0 0 20px 0;
        }
      }
    }
  }
  .next_page{
    clear:both;
    float:right;
    display: block;
    margin:0 0 30px 0;
    &:hover{
      color:#fff;
    }
  }
}
