p.small {
	font-size: 0.8;
	margin-bottom: 0.4rem !important;	
}

.jgnt_linkbox {
	cursor: pointer;
	& > a.jgnt_linkbox {
		display: none;
	}
	&:hover {
		.jgnt_link_hover {
			text-decoration: underline;
		}
	}
}

.journal_select {
	margin-top: 0.6rem;
	margin-bottom: 0.6rem;	
}

.jgnt_load {
}

.jgnt_loader {
	min-height: 50px;
	min-width: 50px;
	.icon {
		display: block;
		height: 50px;
		width: 50px;
		background: rgba(0, 0, 0, 0) url("/img/les/loading.png") no-repeat scroll 0 0 / 50px auto;
		margin: 0 auto;
		animation: infinite-spinning 0.5s linear infinite;
	}
}

.jgnt_processing {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	
	z-index: 9999;

	background-color: rgba( 255, 255, 255, 0.8 );
	
	&.off {
		display: none;
	}
	
	.icon {
		background: rgba(0, 0, 0, 0) url("/img/les/loading.png") no-repeat scroll 0 0 / 50px auto;
		height: 50px;
		width: 50px;
		margin: 0 auto;
		position: fixed;
		right:50px;
		top:50px;
		z-index: 10000;
		animation: infinite-spinning 0.5s linear infinite;
	}
}

.jgnt_icon {
	height: .8em;
	width: auto;
	margin-right: 0.4em;
	&.big {
		height: 1.8em;
		margin-right: 0;
	}
}

.jgnt_badge {
	display: inline-block;
	background-color: $jgntColorBadge;
	border: 1px solid $jgntColorBadge;
	border-radius: 10em;
	text-decoration: none;
	font-size: 10px;
	font-weight: bold;
	line-height: 18px;
	color: #fff !important;
	padding: 0.2em 1em;
	&:hover {
		color: $jgntColorBadge !important;
		background-color: #fff;
		text-decoration: none;
	}
}

.jgnt_page_block {
	.filter {
		margin-bottom: 1rem;
		.head {
			font-size: 24px;
			color: #000;
			font-weight: bold;
			margin-bottom: 0rem;
			text-transform: uppercase;
			margin-right: 0.5rem;
		}
		.list {
			a {
				display: inline-block;
				text-transform: uppercase;
				color: $jgntColorText;
				margin: 0 0.2rem;
				&.active {
					border-bottom: 1px solid $jgntColorText;
				}
				&:hover {
					text-decoration: none;
				}
			}
		}
	}	
}

.jgnt_items {
	@include jgnt_adjust( "htable" );
	flex-flow: row wrap;
	margin: 0 -0.5rem;
	.item {
		flex-basis: 25%;
		margin-bottom: 1rem;
		.item_wrap {
			margin: 0 0.5rem;			
		}
		.pic {
			background-position: center center;
			background-size: cover;
			height: 252px;
			margin-bottom: 1rem;
		}
		.info {
			@include jgnt_adjust( "hline" );
			margin-bottom: 1rem;
			.rubric {
				
			}
			.date {
				font-size: 10px;
				color: $jgntColorBadge;
			}		
		}
		.name {
			font-size: 18px;
			font-weight: bold;
			line-height: 22px;
			margin-bottom: 2px;
		}
		.text {
			font-size: 14px;
			line-height: 22px;
			margin-bottom: 0.6rem;
		}
		.social {
			font-size: 10px;
			span {				
				color: $jgntColorBadge;
				margin-right: 1em;
				i {
					margin-right: 0.4em;
				}
			}
		}
	}
@media ( max-width: 980px ) {
	.item {
		flex-basis: 50%;
	}
}
@media ( max-width: 540px ) {
	.item {
		flex-basis: 100%;
	}
}
}


/*
.jgnt_tab_switch {
	list-style: none;
	padding: 0;
	border: none !important;
	.nav-link {
		display: none;
		padding: 0;
		&.active {
			display: block;
		}
	}
}
*/

.jgnt_tabs {
	.tab-pane {
		display: none;
	}
	.tab-pane.active {
		display: flex;
	}
	&.tab-pane-block {
		.tab-pane.active {
			display: block;
		}
	}
}

.jgnt_sortable {
	ul {
		li {
			display: inline-block !important;
			float: none !important;
		}
	}
}

.sub_menu {
	z-index: 9999;
}

footer {
@media ( max-width: 460px ) {
	ul {
/*
		position: relative;
		top: -1rem;
*/
		li {
			display: block !important;
			margin: 0 0 0.5rem 0 !important;
			text-align: right;
			
		}
	}
}
}


.small_map_location {
@media ( max-width: 500px ) {
	width: auto !important;
	right: 0.7rem !important;
	left: 0.5rem !important;
}
}

.jgnt_image_container {
	position: relative;
	margin-bottom: 1rem;
	max-height: 85vh !important;
	@include jgnt_adjust( "center" );
	img {
		margin: auto !important;
		object-fit: contain;
		width: 100%;
		max-height: 85vh;
    }
}
