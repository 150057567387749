.feed_page {
	h1 {
		font-weight: normal;
		font-size: 36px;
		margin: 1rem 0 2rem 0;
	}
	p {
		margin-bottom: 2rem;
	}
	a {
		&:hover {
			text-decoration: underline;
		}
	}
}

.profile_page {
	.load_more {
		margin-bottom: 2rem;
	}
}