@mixin jgnt_adjust( $type ) {
	@if $type {
		@if $type == "center" {
			display: flex;
			align-items: center;
			justify-content: center;
		}
		@else if $type == "centertop" {
			display: flex;
			align-items: flex-start;
			justify-content: center;
		}
		@else if $type == "vcenter" {
			display: flex;
			align-items: center;
		}
		@else if $type == "hcenter" {
			display: flex;
			justify-content: center;
		}
		@else if $type == "hline" {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-flow: row nowrap;
		}
		@else if $type == "htable" {
			display: flex;
			align-items: stretch;
			justify-content: center;
			flex-flow: row nowrap;
		}
		@else if $type == "vtable" {
			display: flex;
			align-items: center;
			justify-content: center;
			flex-flow: column nowrap;
		}
	}
}

@mixin clearfix() {
  &::after {
    content: "" !important;
    display: table !important;
    clear: both !important;
  }
}

@mixin jgnt_placeholder_color( $active: #fff, $inactive: transparent ){
	
	&::-webkit-input-placeholder { color:$active; }
	&:-moz-placeholder { color:$active; } /* FF 4-18 */
	&::-moz-placeholder { color:$active; } /* FF 19+ */
	&:-ms-input-placeholder { color:$active; } /* IE 10+ */

	&:focus::-webkit-input-placeholder { color:$inactive; }
	&:focus:-moz-placeholder { color:$inactive; } /* FF 4-18 */
	&:focus::-moz-placeholder { color:$inactive; } /* FF 19+ */
	&:focus:-ms-input-placeholder { color:$inactive; } /* IE 10+ */
}