.broadcast_editor_filter {
@media ( max-width: 1000px ) {
	.search_holder {
		display: inline-block;
		position: relative;
		top: 0;
		margin-bottom: 1rem;
	}
	
}
}

.jgnt_broadcast_newpost {
	&:hover {
		background-color: $jgntColorSuccess !important;
		color: #fff !important;
	}
}


.broadcast_page {
	.jgnt_broadcast_promo_topic {
		margin-top: 3rem;
		margin-bottom: 1rem;
		font-size: 0.8rem;
@media ( max-width: 400px ) {
		font-size: 0.75rem;
}
	}
	.bottom_block {
		clear: both !important;
	}
	.main_menu {
		.post {
			display: none;
		}
		a:hover {
			text-decoration: none !important;
		}
	}
	.button, .send-post {
		cursor: pointer;
		&:hover {
			color: #fff;
			background-color: $jgntColorSuccess !important;
			border-color: $jgntColorSuccess !important;
		}
	}
	article.full_background {
		margin-top: 68px !important;
	}
	.main_folders li {
		float: right !important;
	}
}


.broadcast_info { // .notitle
//	text-align: right !important;
//	background-color: red;
	left: 0 !important;
	right: 0 !important;
	top: auto !important;
	bottom: 2rem !important;
	width: 100% !important;
}


.jgnt_broadcast_logo {
	width: 100px;
	height: 77px;
//	background-color: red;
	margin-top: 25px;
	@include jgnt_adjust( "center" );
	img {
//		background-color: #fff;
		width: auto !important;
		height : auto !important;
		max-width: 100px;
		max-height: 80px;
	}
}

.broadcast_header {
	.main_menu.common {
		clear: right;
		float: right !important;
		position: static !important;
		margin-top: 30px;
	}
	.edit {
		clear: right;
		float: right !important;
		position: static !important;
	}
	.links {
		clear: right;
		float: right !important;
		position: static !important;
		margin-bottom: 2rem;
	}
@media ( max-width: 767px ) {
	.main_menu.common {
		clear: left;
		float: left !important;
		margin-top: 0;
	}
	.edit {
		clear: left;
		float: left !important;
	}
	.links {
		clear: left;
		float: left !important;
	}
}
}

.broadcast_setup_page {
	.logo_holder {
		width: 120px;
		margin: 0 auto 0 0 !important;
	}

@media ( max-width: 960px ) {
	.left_right {
		input, select {
			float: none !important;
			width: 100% !important;
		}
		.fixed_field:nth-child(even) {
			    margin-left: 0 !important;
		}
		
	}
	.holder {
		margin-bottom: 1rem !important;
		article.landing[data-block_type='wide'] {
			width: 100% !important;
		}
	}
	.block {
		width: 100% !important;
	}
	.left {
		@include clearfix();
		float: none;
		width: 100% !important;
		max-width: 680px;
//			margin-bottom: 1rem !important;
		textarea.info {
			height: auto !important;
			max-height: 300px;
		}
	}
	.right {
		float: none;
		width: 100% !important;
		max-width: 680px;
		margin: 0 !important;
	}
	.next_page {
		float: none;
		display: inline-block !important;
	}
}	
}

 // !*** broadcast_post/editor/setup_page

.broadcast_page, .broadcast_post_page, .broadcast_editor_page, .broadcast_setup_page {
/*
	.image {
		max-height: 90vh !important;
		img {
			width: 100% !important;
			height: auto !important;
			max-height: 80vh !important;
		}
	}
*/
	.links {
		a:hover {
			color: $jgntColorSuccess !important;
		}
	}
	.bottom_block {
		clear: both !important;
	}
	.profile {
		&:hover, *:hover {
			text-decoration: none !important;
		}
		.button {
			cursor: pointer;
/*
			&:hover {
				color: $jgntColorSuccess !important;
				border-color: $jgntColorSuccess !important;
			}
*/
		}
		.icon {
//			margin-right: 0.2rem;
			display: none;
		}
@media ( max-width: 420px ) {
		.icon {
			display: inline;
		}
		.username {
			display: none;
		}
}
	}
	.go_back {
		a:hover {
			background-color: $jgntColorSuccess !important;
			color: #fff !important;
			border-color: $jgntColorSuccess !important;
		}
	}
	.back_to_main {
		a:hover {
			background-color: $jgntColorSuccess !important;
			color: #fff !important;
		}
	}
	.sub_header_menu {
		height: auto !important;
		padding: 0.5rem 0 !important;
		h1 {
			line-height: 1.2 !important;
		}
	}
	.image_frame {
		width: 100% !important;
		max-height: 80vh !important;
		min-height: 300px !important;
		background-size: contain !important;
	}
@media ( max-width: 600px ) {
	.back_to_main {
		display: none;
	}
}
	.broadcast_post {
		.map_block {
			margin-bottom: 2rem !important;
		}
@media ( max-width: 960px ) {
		.left {
			float: none;
			width: 100% !important;
			max-width: 680px;
		}
		.right {
			float: none;
			width: 100% !important;
			max-width: 680px;
		}
		.next_page {
			float: none;
			display: inline-block !important;
		}
}	
	}
}
 
 // !*** broadcast_editor_page
 
.broadcast_editor_page {
	.wrapper {
		max-width: 960px !important;
	}
	.back_to_main {
		display: none;
	}
	.broadcast_card {
		.rating {
			text-align: left;
		}
		.checks {
			text-align: left;
		}
		.location {
			text-align: left;
			& > div {
				white-space: normal !important;
			}
		}
/*
		.row {
			min-width: 260px;
		}
*/
		.left {
			width: 560px;
		}
		&.in_edit {
			.input_field {
				background-color: #e8e8e8;
			}
		}
@media ( max-width: 1180px ) {
		.number {
			position: static;
			display: inline-block;
		}
		.main_switch {
			width: auto !important;
			position: relative;
			top: -6px;
			left: 0;
			display: inline-block;
			margin: auto;
			.delete_button {
				position: static !important;
			}
		}
		.status_float {
			right: 1rem !important;
		}
}
@media ( max-width: 920px ) {
	.right {
		display: block;
		width: auto;
		float: none;
		clear: both;		
	}
	.left {
		display: block;
		width: auto;
		float: none;
		clear: both;		
	}
}
@media ( max-width: 590px ) {
	.row {
		width: 100% !important;
		& > div {
			white-space: normal !important;
		}
	}
}
@media ( max-width: 560px ) {
		.status_float {
			display: block;
			clear: both;
			position: static;
			margin-bottom: 0.5rem;
		}
}
@media ( max-width: 420px ) {
		.input_field {
			width: 100%;
			margin-top: 3px;
		}
}
	}
/*
	.cmd_buttons {
@media ( max-width: 420px ) {
		max-width: 220px;
		margin-bottom: 1rem !important;
		
}
	}
*/
	.profile {
		margin-right: 0 !important;
		a:hover {
			color: #fff !important;
		}
	}
	.broadcast_short_header {
		height: auto !important;
		min-height: 1rem !important;
		.wrapper {
			height: auto !important;
			min-height: 1rem !important;
			.main_menu {
				margin-top: 0 !important;
			}
		}
		.button {
			&:hover {
				background-color: $jgntColorSuccess !important;
				color: #fff !important;
				border-color: $jgntColorSuccess !important;
			}
		}
	}
	.top_info {
		background: #333333;
	}
	.broadcast_editor_h1 {
		background: #333333;
		position: static !important;
		color: #fff;
		font-size: 2rem;
		font-weight: normal;
@media ( max-width: 660px ) {
		font-size: 1.6rem;
}
	}
	.broadcast_stats {
		min-height: 0 !important;
		padding-bottom: 1rem !important;
		ul {
			font-size: 1rem;
			li {
				font-weight: normal;
				strong {
					color: #fff;
					font-size:2rem;
				}
			}
		}
@media ( max-width: 960px ) {
		ul {
			font-size: 0.7rem;
			li {
				width: auto !important;
				margin-right: 1rem;
				strong {
					font-size:1.2rem;
				}
			}
		}
}
	}
}

.broadcast_page {

	.image, .image_frame {
		margin: 1rem 0 !important;
	}

//@media ( max-width: 1200px ) {
	nav.sub_header_menu {
		height: auto !important;
		min-height: 55px !important;
		.wrapper {
			@include jgnt_adjust( "hline" );
			flex-flow: row wrap;
		}
		.filters {
			position: static !important;
			margin-right: 1rem !important;
//			line-height: 45px;
//			margin-left: 1rem !important;
		}
		.tags_list {
			position: static !important;
//			line-height: 45px;
//			margin-right: 1rem !important;
		}
	}	
//}
}

.broadcast_card {
	margin-bottom: 2rem !important;
	
	article {
		float: none !important;
	}
	.jgnt_content {
		@include clearfix();
		position: relative;
		padding-top: 2rem;
//		padding-bottom: 2rem;
		
	}
	.text_group {
		width: auto !important;
		position: static !important;
		h2 {
			margin-bottom: 0.2rem !important;
			color: inherit !important;
		}
		.leed {
			margin-bottom: 1rem !important;
			line-height: 1.4 !important;
		}
		.event_date {
			span {
				font-weight: bold;
			}
		}
		.author {
			margin-bottom: 1rem !important;
			.textuser {
				display: inline-block !important;
				margin: 0 1rem 0 0 !important;
			}
			.location {
				display: inline-block !important;
				margin: 0 1rem 0 0 !important;
			}
			.event_date {
				display: inline-block !important;
				margin: 0 1rem 0 0 !important;
			}
		}
	}
	.image, .image_frame {
		margin: 1rem 0 !important;
		p {
			color: $jgntColorText !important;
		}
	}
	.gallery {
		margin-bottom: 1rem !important;
		width: auto !important;
	}
	.video_frame {
		margin: 1rem 0 !important;
		width: 100% !important;
//		height: 60vh !important;
	}
	.bottom_block {
		@include jgnt_adjust( "hline" );
		align-items: flex-start;
	}
	.social {
		min-height: 24px;
		line-height: 24px;
		left: 0 !important;
		margin: 0 !important;
		bottom: 0 !important;
		position: static !important;
		display: block !important;
		min-width: 400px !important;
		.rubric:hover {
			background-color: $jgntColorSuccess !important;
			color: #fff !important;
		}
	}
	ul.tags_list {
		width: auto !important;
		position: static !important;
		display: block !important;
		height: auto !important;
		li {
			margin: 0 0 0.5rem 0.5rem !important;
		}
	}
	.date_ago {
		position: absolute !important;
		text-align: right !important;
		right: auto !important;
		top: 2rem !important;
		left: -170px !important;
		width: 150px !important;
	}
	.holder {
		.wrapper {
			overflow: visible !important;
		}
		.selectboxit-container {
			margin-bottom: 0 !important;
		}
		.selectboxit-text {
			float: right !important;
			margin-right: 2rem !important;
		}
		.setup_type {
			margin-bottom: 5px !important;
		}
		.text_move_down {
			font-size: 14px;
			padding-left: 1.2rem !important;
//			font-weight: bold;
		}
	}
	.full_background {
		.jgnt_content {
			position: absolute;
			bottom: 1rem;
			left: 0;
			right: 0;
		}
		&:not(.jgnt_move_down) {
			.location {
				color: #fff !important;
			}
			.leed {
				color: #fff !important;
			}
			.author {
				a {
					color: #fff !important;
				}
			}
			.date_ago {
				color: #fff !important;
			}
			.social {
				ul li {
					color: #fff !important;
					a {
						color: #fff !important;
					}
				}
			}
		}
		&.jgnt_move_down {
			height: auto !important;
			background-color: #fff !important;
			.bg_picture {
				height: 100vh;
				position: relative !important;
				background-color: $jgntColorBadge !important;
			}
			.jgnt_content {
				position: relative;
				.text_group {
					color: $jgntColorText !important;
				}
			}
		}
	}
@media ( max-width: 1200px ) {
	.text_group {
		h2 {
			margin-top: 3rem !important;
		}
	}
	.date_ago {
		left: 0 !important;
		width: auto !important;
		text-align: left !important;
		border-left: 3px solid $jgntColorText;
		padding-left: 1rem !important;
	}
	.full_background:not(.jgnt_move_down) {
		.date_ago {
			border-left: 3px solid #fff;
		}
	}
}
@media ( max-width: 990px ) {
	.jgnt_content {
		margin-left: 1rem;
		margin-right: 1rem;
	}
	.gallery {
		.rslides_tabs {
			bottom: 1rem !important;
			
		}
	}
}
@media ( max-width: 800px ) {
	.bottom_block {
		display: block;
	}
	.social {
		margin-bottom: 1rem !important;
		min-width: 280px !important;
	}
	ul.tags_list {
		text-align: left !important;
		li {
			margin: 0 0.5rem 0.5rem 0 !important;
		}
	}
}
@media ( max-width: 375px ) {
	margin-bottom: 1rem !important;

	.social {
		.permalink {
			padding-top: 0.5rem !important;
		}
	}
}
}


.selected_broadcasts {
@media ( max-width: 1080px ) {
	position: relative;
	
	.left-arrow {
		margin: 0 !important;
		left: 1rem !important;
	}
	.right-arrow {
		margin: 0 !important;
		right: 1rem !important;
	}
	.items {
		width: 700px !important;
		margin: auto !important;
	}
}	
@media ( max-width: 840px ) {
	.items {
		width: 460px !important;
	}
	
}
@media ( max-width: 620px ) {
	.items {
		width: 200px !important;
	}
}
}

.landing_hipster_page {
	.profile {
		.icon {
			display: none;
		}
	}
	.articles {
		article {
			float: left;
		}
	}
}
