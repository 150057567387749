#add_options.add_options{
  display: none;
}
#autosave_template{
  display:none;
}
#template.template{
  display: none;
}
#veil_date_toggle,
#veil_about_me_toggle,
#veil_cover_toggle{
  display: none;
  &.selected{
    display: block;
  }
}
#load-other-preview{
  display: none;
}
.range-slider{
  height:125px;
  left:0px;
  z-index: 20;

}
ul.modify{
  display: none;
  position: absolute;
  //top:20px;
  top:0;
  right:35px;
  list-style: none;
  z-index: 105;
  .icon-english,
  .icon-russian{
    font-size:13px;
  }
  .circle{
    border: 2px solid #fff;
    border-radius: 10px;
    height: 13px;
    width: 13px;
    .inner{
      background: #fff none repeat scroll 0 0;
      border-radius: 20px;
      height: 9px;
      margin: 2px 0 0 2px;
      width: 9px;
    }
  }
  &:hover{
    >li{
      >a{
        background:$gray2;
      }
    }
  }
  >li{
    vertical-align: top;
    display:inline-block;
    margin:0 5px 0px 0;
    &:last-child{
      margin:0;
    }
    &:hover{
      a.switch_icon{
        background: $black;
        div.default{
          display: none;
        }
        div.toggle{
          display: block;
        }
      }
      div.holder,div.style_holder,div.position_holder,div.bg_position_holder,.range-slider{
        display: block;
      }
    }
    .range-slider{
      display: none;
    }
    div.holder{
      display: none;
      z-index: 21;
      position: absolute;
      white-space: nowrap;
      ul.sub_menu{
        display: block;
        color: #fff;
        list-style: none;
        font-size: 8px;
        li{
          &:first-child{
            margin:0;
          }
          margin:3px 0 0 0;
          a{
            position: relative;
            display: block;
            background:$black;
            border-radius: 40px;
            width:23px;
            height:23px;
            z-index: 20;
            &:hover{
              background:$red;
            }
            div{
              text-align: center;
              color:#fff;
              line-height: 23px;
              font-size: 12px;
            }

            .circle{
              border-radius: 10px;
              height: 13px;
              left: 3px;
              position: relative;
              top: 3px;
              width: 13px;
              .inner{
                height: 12px;
                margin: 2px 0 0 2px;
                width: 12px;
              }
            }
          }
        }
      }
    }
    div.style_holder{
      position: absolute;
      background:$black;
      border-radius: 5px;
      display: none;
      margin:0 0 0 -60px;
      z-index: 10;
      text-align: left;
      ul.sub_menu{
        position: relative;
        width:100%;
        padding:1px 12px 5px 7px;
        list-style: none;
        li{
          width:100%;
          a{
            color:#fff;
            &.deck{
              font-size:24px;
              line-height: 33px;
              font-weight: bold;
            }
            &.leed{
              font-size:24px;
              line-height: 33px;
            }
            &.text{
              font-size:14px;
            }
            &.footnote{
              font-size:10px;
            }
          }
        }
      }
    }
    div.position_holder, div.bg_position_holder{
      width:66px;
      height:73px;
      position: absolute;
      background:$black;
      border-radius: 5px;
      margin:0 0 0 -20px;
      box-sizing: border-box;
      padding: 11px;
      z-index: 10;
      display: none;
      ul.sub_menu{
        display: block;
        list-style: none;
        position: relative;
        width:100%;
        height:100%;
        .circle{
          .inner{
            background:none;
          }
          &:hover,&.selected{
            .inner{
              background: #fff none repeat scroll 0 0;
            }
          }
        }
        li{
          position: absolute;
          &.align-top-left{
            top:0;
            left:0;
          }
          &.align-top-right{
            top:0;
            right:0;
          }
          &.align-top{
            top:0;
          }
          &.align-center{
            top:50%;
            left:50%;
            margin: -7px 0 0 -7px;
          }
          &.align-bottom-left{
            bottom:0;
            left:0;
          }
          &.align-bottom{
            bottom:0;
          }
          &.align-bottom-right{
            bottom:0;
            right:0;
          }
        }
      }

    }

    >a{
      position: relative;
      display: block;
      background:$black;
      border-radius: 40px;
      width:23px;
      height:23px;
      z-index: 20;
      margin:0 0 3px 0;
      transition: background ease-out 0.1s;
      &:hover{
        //background:$red;
        background:$black;
        span.name{
          display: block;
        }
      }
      .circle{
        border-radius: 10px;
        height: 13px;
        left: 3px;
        position: relative;
        top: 3px;
        width: 13px;
        border-radius: 10px;
        .inner{
          height: 12px;
          margin: 2px 0 0 2px;
          width: 12px;
        }
      }
      &.switch_icon{
        div.default{
          display: block;
        }
        div.toggle{
          display: none;
        }

      }

      .tooltip_holder{
        margin: 0 0 0 -2px;
        top: -23px;
      }
      span.name{
        display: none;
        background: $black none repeat scroll 0 0;
        color: #fff;
        font-size: 10px;
        margin: 0 0 0 -5px;
        padding: 1px 7px 3px;
        position: absolute;
        top: -23px;
        white-space: nowrap;
        &::before{
          border-top: 5px solid $black;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          height: 0;
          content:" ";
          margin: 1px 0px 0 10px;
          position: absolute;
          top: 17px;
          width: 0;
        }
      }
      div{
        text-align: center;
        color:#fff;
        line-height: 23px;
        font-size: 12px;
        &.icon-upload{
          font-size: 15px;
        }
      }
    }
  }
}
.picture_frame{
  display: block;
  position: relative;
  .tint{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    &.no-picture{
        background:url('/img/les/upload.svg') no-repeat center center;
    }
    .progress{
      display: block;
      width:295px;
      margin:-10px auto 0;
      text-align: center;
      top:20%;
      position: relative;

      .bar{
        width:100%;
        border-radius: 3px;
        background:$gray2;
        height:5px;
        .percents{
          width:0;
          background:$red;
          height:100%;
          border-radius: 20px;
        }
      }
      p{
        margin:10px 0 0 0;
        font-weight: bold;
      }
    }
  }
}

  .article_footer{
    height:300px;
    clear:both;
  }

#rubric_idSelectBoxItContainer{
  .selectboxit-options,
  .selectboxit-btn{
    width:$cell3;
    background:#fff;
  }
}
article.article_page{
  background:#fff;
  margin: $header-height 0 0 0;
  height: 100%;
  #block_types_id{
    &.no_cover{
      .full_background,
      .half_image,
      .half_gallery,
      .half_quote{
        display: none;
      }
    }
  }
  .social.top{
    &.right{
      a{
        .val{
          position: relative;
          bottom:5px;
        }
      }
    }
    ul{
      font-size:21px;
      li{
        margin:0 8px 0 0;
        a{
          color:#000;
          .val{
            font-size: 10px;
          }
        }
      }
    }
  }
  &.display_as_view{
    .modify{
      display: none !important;
    }
    header.editor{
      display: none !important;
    }
  }
  .creators{
    position: relative;
    margin: -3px 0 0 0;
    float:left;
    ul{
      list-style:none;
      li{
        a{
          font-weight: bold;
          color:#000;
        }
      }
    }
  }
  .next_page{
    background: $red none repeat scroll 0 0;
    border-radius: 30px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 33px;
    left: 50%;
    line-height: 33px;
    margin: 0 0 0 470px;
    padding: 0 19px;
    position: fixed;
    text-align: center;
    top: 12px;
    z-index: 200;
  }
  #load-cover{
    display: none;
  }
  .inscription{
    min-height: 25px;
    font-size: 10px;
    line-height: normal;
    //larger font
    font-size:14px;
    line-height: 26px;
    margin:10px auto 0 auto;
    clear: both;
    display: block;
    color: $black;
  }
  .rslides_tabs{
    bottom: 53px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 20;
    list-style: none;
    li{
      display: inline-block;
      margin:0 5px 0 0;
      &:last-child{
        margin:0;
      }
      &.rslides_here{
        a{
          background:#fff;
        }
      }
      a{
        display: block;
        border:1px solid #fff;
        border-radius: 20px;
        width:10px;
        height:10px;
        color:rgba(0,0,0,0);

      }
    }
  }
  .rslides_frame{
    &:hover{
      .rslides_nav{
        display: block;
      }
    }
    .rslides_nav{
      display: none;
    }
  }
  .rslides_nav{
    position: absolute;
    bottom:50%;
    z-index: 10;
    font-size:24px;
    color:#fff;
    background:rgba(0,0,0,0.1);
    border-radius: 0 12px 12px 0;
    margin:0 0px -13px 0;
    padding: 2px 5px 2px 0;
    .icon-arrow-up-thin{
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      position: relative;
      display: block;
      padding: 5px 0;
      margin:0 0 0 -7px;
      font-size: 48px;
    }
    &.prev{
      left: 0;
      z-index: 20;
    }
    &.next{
      right:0;
      z-index: 20;
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1);
    }
  }
  #bottom_marker{
    position: relative;
    clear: both;
  }
  [contenteditable="true"]:focus{
    background:rgba(0,0,0,0.1);
  }

  .saved_article{
    display: inline;
  }
  .user_info{
    .date.right{
      font-size: 14px;
      margin:0px 0 0 0;
      text-transform: lowercase;
      strong{
        margin:-6px 0 0 0;
        font-weight: bold;
        display: block;
        line-height: 33px;
        height: 33px;
      }

    }
    .button{
      height:33px;
      line-height: 33px;
      color:#fff;
      display: block;
      font-size: 14px;
      padding: 0 15px;
      border-radius: 20px;
      background:$gray2;
      text-transform: none;
    }
    .authors{
      display: inline-block;
      margin: 0 0 0 10px;
      max-width: 580px;
      min-height: 20px;
      min-width: 100px;
      vertical-align: top;
    }
  }
  .actions{
    width:100%;
    box-sizing: border-box;
    padding: 32px 35px 22px;
    text-align: right;
    button{
      display: inline-block;
      margin:0 20px 0 0;
      border:1px solid $red;
      color:$red;
//	      width:140px;
      background: none;
      height:32px;
      font-weight: bold;
      &:hover{
        background:$red;
        color:#fff;
      }
      &:last-child{
        margin:0;
      }
    }
  }
  &.guest{
    section.block{
      header.editor{
        display: none;
      }
      .modify{
        //display: none;
      }
      &:hover{
        .modify{
          display: none;
        }
      }
    }
  }
  .aside_nav{
    position: fixed;
    left:0;
    z-index: 2000;
    top:$header-height+20px;
    
    ul{
      li{
        transition: 0.2s ease-out all;
        white-space: nowrap;
        a{
          .icon{
            font-size: 15px;
            position: relative;
            right:2px;
          }
        }
        .upload-percents{
          position: absolute;
          bottom: 0;
          left:0;
          width:0%;
          height:4px;
          background:$red;
          transition: 0.2s ease-out width;
        }
        #load-image-button,
        #load-cover-button{
          display: none;
        }
        &.main{
          background:$red;
          z-index: 100;
          .icon{
            color:#fff;
          }
          .name{
            color:#fff;
          }
          .count{
            color:#fff;

          }
        }
        &.child{
          background:#d9d9d9;
          z-index: 50;
        }
        &.option{
          color:$gray;
          display: block;
          a.button{
            display: inline-block;
            color:$gray;
            opacity: 1;
            transition: 2s ease-out all;
            flex-grow: 1;
            text-align: center;
            &.half{
              width:50%;
            }
            &.third{
              width:33.333%;
            }
            &.quarter{
              width:25%;
            }
            &.fifth{
              width:20%;
            }
            &.sixth{
              width:16.6666%;
            }

          }
          &.closed.lv1{
            opacity: 0;
            margin:-55px 0 1px 0;
            display: block;
            a.button{
              display: none;
              transition: 0.5s ease-in all;
            }
          }
          .name{
            color:$gray;
          }
          .icon{
            color:$gray;
          }
        }
        .name{
          opacity: 1;
          transition: 0.5s ease-in opacity;
          position: absolute;
          left:63px;
        }
        .count{
          transition: 0.5s ease-in opacity;
        }
        &.closed{
          width:18%;
          overflow: hidden;
          .icon{

          }
          .name{
            opacity: 0;
            transition: 0.05s ease-out opacity;
          }
          .count{
            opacity: 0;
            transition: 0.05s ease-in opacity;
          }
        }
        .icon,.name,.count{
          color:#000;
        }
      }

    }
  }

  header{
    width:100%;
    height: 80vh;
    &.editor {
	    height: auto;
    }
    display: block;
    position: relative;
    &.fixed{
      background-attachment: fixed !important;
    }
    &.no_cover{
      color:#000;
      height:auto;
      background-image:none !important;
      .tan{
        display: none;
      }
      .pattern{
        display: none;
      }
      ul.modify > li div.position_holder, ul.modify > li div.bg_position_holder{
        border-radius: 17px;
        height: 32px;
        margin: 0 0 0 -22px;
        padding: 9px 7px 9px 8px;
        width: 65px;
        .align-center{
          top: 8px;
          margin:-8px 0 0 -7px;
        }
        .align-bottom-left,
        .align-bottom-right{
          display: none;
        }

      }
      .frame{
        width:$cell10;
        height: auto;
        left: 50%;
        margin: -80px 0 0 -390px;
        &.modified{
          top:auto;
          width:$cell10;
          margin:80px auto -21px auto;
          left:auto;
          position: relative;
          h1{
//            color:#000;
            font-size:48px;
            margin:0 0 10px 0;
            line-height: 55px;
            float:none !important;
          }
          h2.rubric{
            font-size: 14px !important;
            margin:0 0 32px 0!important;
            float:none;
            display: inline-block !important;
            padding:0 !important;
            top:none !important;
//            color:#000;
            &.bordered{
              border:1px solid #000 !important;
              line-height: 32px;
              height: 32px;
              padding:0 15px !important;
              margin:23px 0 3px !important;
            }
            input{
              font-size: 14px;
  //            color:#000;
              float:none;
            }
          }
          .published_at{
//            color:#000;
            font-size:14px;
            margin: 30px 0 94px;
          }
          >p{
            font-size:24px;
//            color:#000;
          }
        }
        h1{
//          color:#000;
          font-size:48px;
          margin:0 0 37px 0;
        }
        h2.rubric{
          font-size: 14px;
          margin:0 0 32px 0;
/*
          input{
            font-size: 14px;
            color:#000;
          }
*/
        }
        >p{
          font-size:24px;
//          color:#000;
        }
      .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,.published_at{
        color:#fff !important;
      }
        &[data-color="white"]{
          .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,.published_at{
            color:#fff !important;
          }
        }
        &[data-color="dark"]{
          .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,.published_at{
            color:#000 !important;
          }
        }
        &[data-color="gray2"]{
          .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,.published_at{
            color:$gray !important;
          }
        }
        &[data-color="gray"]{
          .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,.published_at{
            color:$gray2 !important;
          }
        }
        &[data-color="theme"]{
          .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,.published_at{
            color:$red !important;
          }
        }
      }
    }
    img{
      width:100%;
      display: block;
    }

    &:hover{
        .modify{
          display: block;
        }
    }
    .modify{
      top:0;
      margin: 0;
      padding: 25px 20px 0 70px;
      right: 0px;
      text-align: center;
      .icon-download{
        font-size: 14px;
      }
      .position_holder, .bg_position_holder{
        width:65px;
        height:65px;
        border-radius: 15px;
        padding:9px;

        .circle{
          width:14px;
          height:14px;
          box-sizing: border-box;
          border:1px solid #fff;
          .inner{
            box-sizing: border-box;
            height: 8px;
            margin: 2px 0 0 2px;
            width: 8px;
          }
        }
      }
    }
    .tan{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background: rgba(0,0,0,0.3);
    }
    .pattern{
      position: absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background:url('/img/les/patterns/black-criss-cross.png') repeat;
      z-index: 10;
    }
    .frame{
      $height:12.81vw;
      //width:87.362%;
      width:79.362%;
      //left:6.319%;
      left:10.319%;
      margin:-($height/2)  0 0 0px;
      position: absolute;
      top:50%;
      height:$height;
      z-index: 100;
      .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,&.frame.modified .published_at, a.jgnt_btn_bordered{
         color:#fff;
         border-color:#fff !important;
      }
      a.jgnt_btn_bordered {
        &:hover {
          opacity: 0.8;
        }
      }
      &[data-color="white"]{
        .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,&.frame.modified .published_at{
          color:#fff;
          border-color:#fff !important;
        }
      }
      &[data-color="dark"]{
        .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,&.frame.modified .published_at{
          color:#000;
          border-color:#000 !important;
        }
      }
      &[data-color="gray2"]{
        .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,&.frame.modified .published_at{
          color:$gray;
          border-color:$gray !important;
        }
      }
      &[data-color="gray"]{
        .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,&.frame.modified .published_at{
          color:$gray;
          border-color:$gray !important;
        }
      }
      &[data-color="theme"]{
        .jgnt_btn_bordered,h2.rubric,h2.rubric input,h1,h2,p,.h1,.h2,.leed,&.frame.modified .published_at{
          color:$red;
          border-color:$red !important;
        }
      }
      &[data-position='align-top-left']{
        text-align: left;
        //top:8.48%;
        top:10.48%;
        margin:0;
        bottom:auto;
        height: auto;
        h2.rubric,h1,p{
          text-align: left;
        }
        h2.rubric{
          input{
            text-align: left;
            margin:0;
          }
        }
      }
      &[data-position='align-top-right']{
        text-align: right;
        //top:8.48%;
        top:10.48%;
        margin:0;
        bottom:auto;
        height: auto;
        h2.rubric,h1,p{
          text-align: right;
        }
        h2.rubric{
          input{
            text-align: right;
            margin:0;
            float:right;
          }
        }
      }
      &[data-position='align-bottom-right']{
        text-align: right;
        top:auto;
        //bottom:8.48%;
        bottom:10.48%;
        height: auto;
        margin:0;
        h2.rubric,h1,p{
          text-align: right;
        }
        h2.rubric{
          input{
            text-align: right;
            margin:0;
            float:right;
          }
        }
      }
      &[data-position='align-bottom']{
        top:auto;
        //bottom:8.48%;
        bottom:10.48%;
        height: auto;
      }
      &[data-position='align-bottom-left']{
        text-align: left;
        top:auto;
        //bottom:8.48%;
        bottom:10.48%;
        height: auto;
        margin:0;
        h2.rubric,h1,p{
          text-align: left;
        }
        h2.rubric{
          input{
            text-align: left;
            margin:0;
          }
        }
      }
      &.modified{
        text-align: center;
        h2.rubric{
          &.bordered{
            border:0.11vw solid #fff;
            text-transform: none;
            display: inline-block;
            border-radius: 30px;
            padding: 0.35vw 1vw;
            margin: 0 0 0.96vw;
            position: relative;
            top:-1vw;
            font-size:0.92vw;
          }
        }
        h1{
          line-height:3.95vw;
          margin:0 0 0.6vw;
          position: relative;
          z-index: 1;
        }
        p{
          font-weight: normal;
          font-size: 1.68vw;
        }
        .published_at{
          font-weight: bold;
          color:#fff;
          font-size: 0.98vw;
          margin:2.15vw 0 0;
        }
          &[data-position='align-top-left']{
            text-align: left;
          }
          &[data-position='align-top-right']{
            text-align: right;
          }
          &[data-position='align-bottom-left']{
            text-align: left;
          }
          &[data-position='align-bottom-right']{
            text-align: right;
          }

      }
      h2.rubric{
        font-size:0.84vw;
        text-transform: uppercase;
        font-weight: bold;
        color:#fff;
        text-align: center;
        margin:0 0 2.5vw 0;
        input{
          background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
           border: medium none;
           color: #fff;
           display: block;
           font-size: 0.82vw;
           font-weight: bold;
           margin: 0 auto;
           padding: 0;
           text-align: center;
           text-transform: none;
           width: 15vw;
          &:focus{
            background:rgba(0,0,0,0.1);
          }
        }
      }
      h1{
        font-size:3.35vw;
        color:#fff;

        font-weight: bold;
        position: relative;
        width:100%;
        text-align: center;
        margin:0 0 3.1vw 0;
      }
      >p{
        text-align: center;
        font-size: 1.25vw;
        font-weight: bold;
        color:#fff;
      }
    }
  }
  &.in_editor{
    section.block{

    }
  }
  section.block{
    padding:30px 0;
    float:left;
    width:100%;
    position: relative;
    //new size
    font-size:14px;
    .wrapper{
      //width:780px;
      //new width
      width:940px;
      margin:0 auto;
      //larger font
      line-height: 35px;
      font-size: 21px;
      &.cell3{
        width:$cell3;
      }

    }
    &.only_section,
    &:last-child{
      header.editor{
        opacity: 1;
        &.selected{
          width:518px;
        }
        width:58px;
        .plus-button,
        ul li a{
          background:$red;
          width:56px;
          height:56px;
          font-size:18px;
          div{
            line-height: 56px;
          }
        }
        .tooltip_holder{
          margin: 0 0 0 -2px;
          top: -23px;
        }
        ul{
          width:518px;
          li{
            a{
              &:hover{
                div{
                  color:#fff;
                }
              }


            }
          }
        }
      }
    }
    ul.modify{
      /*left: 50%;
      margin: 0 0 0 -125px;
      right: auto;
      text-align: center;
      top: -5px;
      width: 250px;*/


      //height: 200px;
      margin: 0;
      //overflow: hidden;
      padding: 23px 20px 0 70px;
      right: 0;
      top: -28px;
    }

    &.closed{
      background: red none repeat scroll 0 0;
      color: rgba(0, 0, 0, 0) !important;
      float: none;
      height: 50px !important;
      margin: 0 auto 20px;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 50px;
      transition: 1s ease all;
    }
    &.focus{
      background:#d0d0d0;
      ul.modify{
        display: block;
        li.edit_button{
          a{background:$red;
            span.name{
              display: block;
            }
          }
        }
      }
      header.editor{
        ul{
          li{
            a{
              div{
                color:#000;
              }
            }
          }
        }
      }
    }
    .wrapper{
      [contenteditable="true"]{
        -moz-hyphens: auto;
        -webkit-hyphens: auto;
        -ms-hyphens: auto;
        hyphens: auto;
      }
    }
    &.deck{
      .wrapper{
        //font-size:18px;
        //new size
        font-size:24px;
        font-weight: bold;
        line-height: 33px;
        //larger font
        font-size: 36px;
        line-height: 45px;
      }
    }
    &:hover{
      .modify{
        display: block;
      }
      header.editor{
        opacity: 1;
        //transition: 0.2s ease-in all;
      }
    }
    .load-image,.load-gallery{
      display: none;
    }
    header.editor{
      bottom: 0;
      clear: both;
      display: block;
      //opacity: 1;
      opacity: 0;
      margin: 30px auto 0 auto;
      position: relative;
      text-align: center;
      width:35px;
      top:16px;
      //overflow: hidden;
      background:#fff;
      z-index: 10;
      transition: 0.3s ease-out all;
      &.selected{
        width:350px;
        .plus-button{
          opacity: 0;
          z-index: 5;
        }
        ul{
          opacity: 1;
          z-index: 10;
          li{
            visibility: visible;
          }
        }
      }
      .plus-button{
        position: absolute;
        z-index: 10;
      }
      .plus-button,
      ul li a{
        transition: 0.3s ease-out all;
        background:$gray2;
        color:#fff;
        display: block;
        font-size: 14px;
        padding: 0;
        width:35px;
        height:35px;
        border-radius: 35px;
        .tooltip_holder{
          margin: 0 0 0 -2px;
          top: -23px;
        }
        div{
          color:#fff;
          line-height: 35px;
        }
      }
      ul{
        z-index: 5;
        position: relative;
        transition: 0.3s ease-out all;
        width:350px;
        list-style: none;
        opacity: 0;
        li{
          display: inline-block;
          position: relative;
          margin:0 10px 0 0;
          vertical-align: top;
          visibility: hidden;
          &:last-child{
            margin:0;
          }
          a{

            &[data-section='picture']{
              .name{
                margin:8px 0 0 -48px;
              }
            }
            &[data-section='plain']{
              .name{
                margin:8px 0 0 -11px;
              }
            }
            &[data-section='quote']{
              .name{
                margin:8px 0 0 -15px;
              }
            }
            &[data-section='interview']{
              .name{
                margin:8px 0 0 -21px;
              }
            }

            .name{
              display: none;
              position: absolute;
              line-height: normal;
              white-space: nowrap;
              font-size:8px;
              background:$red;
              padding: 3px 8px;
              margin:8px 0 0 -50px;
              color:#fff;
              &::before{
                content: " ";
                border-bottom: 5px solid $red;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                height: 0;
                left: 50%;
                margin: -7px 0 0 -5px;
                position: absolute;
                width: 0;
              }
            }
            &:hover{
              div{
                color:$red;
              }
              .name{
                display: block;
              }
            }
          }
        }
      }
    }
    &.quote,&.quote_and_text{
      &[data-language='russian']{
        .english_wrapper{
          display:none;
        }
      }
      &[data-language='english']{
        .russian_wrapper{
          display:none;
        }
      }
    }
    &.quote_and_text{
      .right{

      }
      .left{

      }
      &[data-language='english']{
        .quote_text{
          float:left;
        }
      }
    }
    &.quote{
      .right{
        display:none;
      }
      .author{
        display:none;
      }
      &[data-language='russian']{
      }
      &[data-language='english']{
        .wrapper{
          width:$cell10;
        }
        .quote{
          width:$cell10;
        }
        .quote_text{
          text-align: left;
          width:$cell9+20px;
          float:left;
        }
      }
    }
    &.quote .wrapper,.wrapper .quote{
        font-size:24px;
        text-align: center;
        color:$red;
        font-weight: bold;
        width:$cell10;
        line-height: 33px;
        //larger font
        font-size: 36px;
        line-height: 45px;
        .icon-qoutes{
          font-size: 70px;
          height: 55px;
          margin: -23px 0 10px;
          text-align: center;
        }
        .icon-qoute-fancy{
          bottom: 5px;
          float: left;
          font-size: 37px;
          width:60px;
          text-align: left;
          position: relative;
        }
        .right{
          float:right;
          width:688px;
          text-align: left;
        }
        .author{
          font-size: 12px;
          font-weight: normal;
        }
    }
    &.quote_and_text{
      .left.quote{
        text-align: left;
        width:380px;
        .icon-qoutes{
          text-align: left;
        }
        .quote_text{
          width:300px;
        }
      }
      .right{
        width:380px;
      }
    }
    &.leed{
      .wrapper{
        width:780px;
        //new width
        width:940px;
        font-size:24px;
        line-height: 33px;
        font-size:36px;
        line-height: 45px;
      }
    }
    &.footnote{
      .wrapper{
        width:780px;
        //new width
        width:940px;
        font-size:10px;
        line-height: 14px;
      }
    }
    &.link{
      .wrapper{
        font-size:14px;
      }
    }
    &.solid_footnote{
      color:#000;
      .wrapper{
        background:$mcolor;
        box-sizing: border-box;
        width:$cell10;
        padding: 20px;
      }
      .main{
        font-weight: bold;
        //font-size: 14px;
        //line-height: 22px;
        //larger_font
        margin:0 0 10px 0;
      }
      .left{
        font-size: 10px;
        line-height: 16px;
        //larger_font
        font-size:14px;
        line-height: 26px;
        width:360px;
        float:left;
      }
      .right{
        font-size: 10px;
        line-height: 16px;
        //larger_font
        font-size:14px;
        line-height: 26px;
        width:360px;
        float:right;
      }
    }
    &.solid_footnote_mono{
      .wrapper{
        background:$mcolor;
        box-sizing: border-box;
        width:$cell10;
        padding: 20px;
      }
      .main{
        font-weight: bold;
        font-size: 14px;
        line-height: 22px;
        margin:0 0 10px 0;
      }
      .left{
        font-size: 10px;
        line-height: 16px;
      }
      .right{
        display: none;
      }
    }
    &.picture{
      .overlap_text{
        display: none;
      }
      .fixed_background{
        display: none;
      }
    }
    &.fullscreen_picture_fixed,
    &.fullscreen_picture{
      .inscription{
        margin:10px auto 0 auto !important;
      }
    }
    &.fullscreen_picture_fixed{
      .wrapper{
        margin: 0 auto;
        padding: 0;
        width: 100%;
        div.editable{
          width:780px;
          //new width
          width:940px;
          margin:0 auto;
        }
      }
      .fixed_background{
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
      .picture_frame{
        img{
          max-width: none;
          width:100%;
          max-height: none;
        }
      }

      .overlap_text{
        display: none;
      }
    }
    &.fullscreen_picture{
      .fixed_background{
        display: none;
      }
      .wrapper{
        margin: 0 auto;
        padding: 0;
        width: 100%;
        div.editable{
          width:780px;
          //new width
          width:940px;
          margin:0 auto;
        }
      }
      .picture_frame{
        img{
          max-width: none;
          width:100%;
          max-height: none;
        }
      }

      .overlap_text{
        display: none;
      }
    }
    &.picture_with_text2{
      .picture_frame{
        float:right;
        //width:380px;
        width:auto;
        max-width:380px;
        margin:7px 0px 0 20px;
        .tint.no-picture{
          background: rgba(0, 0, 0, 0) url("/img/les/upload.svg") no-repeat scroll center center / 310px auto;
        }
      }
      .inscription{
        float:right;
        width:380px;
        margin:10px 0px 0 20px;
      }
      .overlap_text{

      }
    }
    &.picture_with_text{
      .picture_frame{
        float:left;
        //width:380px;
        width:auto;
        max-width:380px;
        margin:7px 20px 0 0;
        .tint.no-picture{
          background: rgba(0, 0, 0, 0) url("/img/les/upload.svg") no-repeat scroll center center / 360px auto;
        }
      }
      .inscription{
        float:left;
        width:380px;
        margin:10px 20px 0 0;
      }
      .overlap_text{

      }
    }
    &.fullscreen_video{
      position: relative;
      //padding-bottom: 56.25%; /* 16:9 */
      //padding-top: 25px;
      height: 100%;
      .wrapper{
        display: inline;
      }
      .video_frame{
        display: block;
        width:100%;
        height:100%;
        iframe {
        	position: relative;
        	top: 0;
        	left: 0;
        	width: 100%;
        	height: 100%;
        }
      }
    }
    .video_frame{
      background:$mcolor ;
      &.blank{
        background:$mcolor url("/img/les/upload_video.png") no-repeat scroll center center;
      }
    }
    &.video{
      .video_frame{
      	position: relative;
      	padding-bottom: 56.25%; /* 16:9 */
      	padding-top: 25px;
        iframe {
        	position: absolute;
        	top: 0;
        	left: 0;
        	width: 100%;
        	height: 100%;
        }
      }
    }
    &[data-section='keyword']{
      font-size: 42px;
      font-weight: 300;
      font-family: $font1;
      .wrapper{
        text-align: center;
        font-size: 42px;
        line-height: 43px;
        //larger font
        font-size: 36px;
        line-height: 45px;
        position: relative;
        padding: 53px 0 63px 0;
      }
    }
    &.keyword_center_left{
      .wrapper{
        text-align: left;
        .aside_text{
          display: none;
        }
        &:before{
          content:" ";
          width:180px;
          height:3px;
          position: absolute;
          top:0;
          background:#000;
          margin:0 0 0 0;
          left:0;
        }
        &:after{
          content:" ";
          width:180px;
          height:3px;
          position: absolute;
          bottom:0;
          background:#000;
          margin:0 0 0 0;
          left:0;
        }
      }
    }
    &.keyword_center_right{
      .wrapper{
        text-align: right;
        .aside_text{
          display: none;
        }
        &:before{
          content:" ";
          width:180px;
          height:3px;
          position: absolute;
          top:0;
          background:#000;
          margin:0 0 0 0;
          right:0;
        }
        &:after{
          content:" ";
          width:180px;
          height:3px;
          position: absolute;
          bottom:0;
          background:#000;
          margin:0 0 0 0;
          right:0;
        }
      }
    }
    &.keyword_left{
      .wrapper{
        text-align: left;
        .aside_text{
          display: none;
        }
        &:before{
          content:" ";
          width:140px;
          height:5px;
          position: absolute;
          top:0;
          background:$red;
          margin:0 0 0 0;
          left:0;
        }
      }
    }
    &.keyword_top{
      .wrapper{
        .aside_text{
          display: none;
        }
        &:before{
          content:" ";
          width:140px;
          height:5px;
          position: absolute;
          top:0;
          background:$red;
          margin:0 0 0 -70px;
          left:50%;
        }
      }
    }
    &.keyword_right{
      .wrapper{
        text-align: right;
        .aside_text{
          display: none;
        }
        &:before{
          content:" ";
          width:140px;
          height:5px;
          position: absolute;
          top:0;
          background:$red;
          margin:0 0 0 0;
          right:0;
        }
      }
    }
    &.keyword_text{
      .wrapper{
        text-align: left;
        padding: 0;
        .aside_text{
          float:left;
          width:$cell5;
          line-height: 35px;
          font-size:21px;
          font-weight: normal;
          //font-family: $font2;
        }
        .main_text{
          float:right;
          width:$cell5;

        }
      }
    }
    &.keyword_text_right{
      .wrapper{
        text-align: left;
        padding: 0;
        .aside_text{
          float:right;
          width:$cell5;
          line-height: 35px;
          font-size:21px;
          font-weight: normal;
          //font-family: $font2;
        }
        .main_text{
          float:left;
          width:$cell5;

        }
      }
    }
    &.keyword{
      .wrapper{
        .aside_text{
          display: none;
        }
        &:before{
          content:" ";
          width:180px;
          height:3px;
          position: absolute;
          top:0;
          background:#000;
          margin:0 0 0 -90px;
          left:50%;
        }
        &:after{
          content:" ";
          width:180px;
          height:3px;
          position: absolute;
          bottom:0;
          background:#000;
          margin:0 0 0 -90px;
          left:50%;
        }
      }
    }
    &.keyword_full{
      .wrapper{
        .aside_text{
          display: none;
        }
        &:before{
          content:" ";
          width:100%;
          height:3px;
          position: absolute;
          top:0;
          background:#000;
          left:0%;
        }
        &:after{
          content:" ";
          width:100%;
          height:3px;
          position: absolute;
          bottom:0;
          background:#000;
          left:0%;
        }
      }

    }
    &.interview_with_faces{
      .add_string{
        font-size:10px;
        //larger font
        font-size: 14px;
        color:$color2;
        display: block;
        float:right;
        width:$cell8+20px;
        text-align: left;
        position: relative;
        top:-31px;
      }
      .wrapper{
        ul{
          list-style: none;
            display: block;
          li{
            display: block;
            float:left;
            padding:0 0px 32px 0;
            width:100%;
            .portrait{
              display: block;
              width:$cell2;
              float:left;
              img{
                width:90px;
                height:90px;
                border-radius: 50px;
                display: block;
                float:left;
              }
            }
            .person{
              display: block;
              float: left;
              font-weight: bold;
              font-size:14px;
            }
            .question{
            }
            .question,
            .answer{
              .name{
                display: block;
              }
              width:$cell8+20px;
              float:left;
              display: table-cell;
            }
          }
        }
      }
    }
    &.interview_with_borders,
    &.interview{
      .add_string{
        font-size:10px;
        color:$color2;
        display: block;
        float:right;
        width:89.6%;
        text-align: left;
        position: relative;
        top:-6px;
      }
      .wrapper{
        ul{
          list-style: none;
          display: table;
          li{
            //display: block;
            display:table-row;
            //float:left;
            float:none;
            margin:0 0 20px 0;
            width:100%;
            .portrait{
              display: none;
            }
            .person{
              //float:left;
              //float:none;
              //width:7.692%;
              //margin:0 2.564% 0 0;
              padding:0 10px 20px 0;
              min-width:7.692%;
              display: table-cell;
              hyphens: none;
            }
            .question{
              font-weight: bold;
            }
            .question,
            .answer{
              .name{
                display: none;
              }
              width:89.743%;
              //float:right;
              float:none;
              display: table-cell;
              padding:0 0px 20px 0;
            }
          }
        }
      }
    }
    &.interview_with_borders{
      .wrapper{
        border:1px dashed #000;
        box-sizing: border-box;
        padding: 21px 23px 31px 23px;
        border-radius: 3px;
        li{
          &:first-child{

          }
          &:last-child{

          }
        }
      }
    }
    &.large_link_with_preview,
    &.link_with_preview{
      .wrapper{
        .left{
          width:380px;
          .card{
            img{
              display: block;
              float:left;
              width:140px;
              margin:0 20px 0 0;
            }
          }
        }
        .right{
          width:220px;
        }
        .card{
          display: block;
          width:100%;
          height:100%;
          background:#f4f4f4;
          position: relative;
        }
        .info{
          padding:20px;
          box-sizing: border-box;
          height:100%;
          span{
            position: absolute;
            bottom:20px;
            color:$gray;
            text-transform: uppercase;
          }
          h2{
            font-weight: bold;
            font-size:18px;
            color:#000;
          }
          p{
            color:#000;
          }
        }
      }
    }
    &.large_link_with_preview{
      .wrapper{
        .card{
          width:100%;
          img{
            width:50%;
            float:left;
            margin:0 20px 0 0;
          }
        }
      }
    }
    .gallery_frame .previews li img{
      width:auto;
    }
    .picture_frame{
      img{
        max-height: 900px;
        max-width: 100%;
        width: auto;
      }
    }
    img{
      //max-width:100%;
      width:100%;
      display: block;
      margin: 0 auto;
    }
  }
  .left{
    float:left;
  }
  .right{
    float:right;
  }
}
.add_comment{
  background:#fff;
  width:100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  margin:0 0 10px 0;
  .author{
    display: block;
    float: left;
    margin: 0;
    vertical-align: top;
    width: auto;
  }
  textarea{
    float:right;
    width:690px;
    padding: 0;
    margin: 0;
    border: none;
    font-family: $font1;
    font-size: 14px;
    display: inline-block;
    resize: unset;
    height:35px;
  }
}
ul.comments_list{
  list-style: none;
  li{
    background:#fff;
    display: block;
    margin:0 0 12px 0;
    width:100%;
    box-sizing: border-box;
    padding:20px;
    iframe{
      width:100%;
      display: block;
    }
  }
}
.article_specs {
//  width:$cell10;
  box-sizing: border-box;
  clear: both;
  .cnt {
//	  width: 780px;
	  //new width
	  width:940px;
	  margin: auto;
  }
  padding: 70px 0;
  ul.tags_list{
    position: relative;
    bottom:0;
    right:auto;
    margin:0;
    li{
      a{
        margin:0 0 10px 0;
      }
    }
    &.rubric{
      li{
        a{
          background:#000;
          color:#fff !important;
        }
      }
    }
  }
  .location_and_tags{
    float:left;
    width:50%;
    .location{
      margin:0 0 10px 0;
    }
  }
  .rating{
    float:right;
    text-align: right;
  }
  .spacer{
    width:100%;
    float:left;
    height:11px;
  }
  .social{
    &.left{
      float:left;
      ul{
        li{
          margin:0 20px 0 0;
          a{
            span:last-child{
              bottom: 2px;
              font-size: 10px;
              position: relative;
            }
          }
          &.disabled {
            a {
              cursor: auto;
            }
          }
        }
      }
    }
    &.right{
      float:right;
      position: relative;
      top:-4px;
      a{
        span:first-child{
          font-size:21px;
        }
        span:last-child{
          position: relative;
          bottom:5px;
          font-size: 10px;
        }
      }
    }
    ul{
      li{
        a{
          color:#000;
        }
        &:last-child{
          margin:0;
        }
        span {
          color:#000;
        }
      }
    }
  }
  a{
    color:$black;
  }
}
.made_on.wrapper{
  border:1px solid $red;
  box-sizing: border-box;
  padding: 40px 80px;
  margin-bottom: 87px;
  h2{
    font-size: 36px;
    color:#000;
    margin-bottom:32px;
    .smaller{
      font-size: 21px;
      margin-left:12px;
    }
  }
  p{
    margin-bottom:19px;
  }
  h3{
    color:#000;
    font-size: 21px;
    text-transform: none;
    margin-bottom:13px;
  }
  .column{
    width:356px;
    display: inline-block;
    &.space{
      margin-right: 46px;
    }
  }

}
div.comments{
  width:100%;
  background:#f4f4f4;
  clear:both;
  position: relative;
  z-index: 1;
  .wrapper{
    padding:0px 0 40px 0;
    width:$cell10;
  }
  h2{
    font-size:24px;
    color:#000;
    font-weight: bold;
    padding: 21px 0 26px 0;
  }
  form{
    text-align: right;
    margin:0 0 20px 0;
  }

  .button{
    float:right;
  }
}
