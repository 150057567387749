@charset "UTF-8";
body, html {
  width: 100%;
  height: 100%;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000; }

a {
  outline: 0; }

a::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0; }

html {
  position: relative; }

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1010;
  display: none;
  max-width: 276px;
  padding: 1px;
  text-align: left;
  white-space: normal;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background-clip: padding-box; }
  .popover.right {
    margin-left: 10px; }
  .popover .arrow, .popover .arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid; }
  .popover .arrow {
    border-width: 11px; }
    .popover .arrow:after {
      border-width: 10px;
      content: ""; }
  .popover-title {
    padding: 8px 14px;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
    line-height: 18px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-radius: 5px 5px 0 0; }
  .popover-content {
    padding: 9px 14px; }

/* Magnific Popup CSS */
.mfp-iframe-holder .mfp-close-button.mfp-close,
.mfp-image-holder .mfp-close-button.mfp-close {
  border-radius: 20px;
  box-sizing: border-box;
  color: #d9d9d9;
  cursor: pointer !important;
  display: block;
  font-size: 24px;
  font-weight: normal;
  height: 22px;
  line-height: 22px;
  padding: 0;
  position: fixed;
  right: 26px;
  text-align: center;
  top: 15px;
  width: 22px; }

.mfp-my-arrow-left,
.mfp-my-arrow-right {
  background: rgba(0, 0, 0, 0.15) none repeat scroll 0 0;
  border: medium none;
  border-radius: 30px;
  color: #fff;
  cursor: pointer !important;
  display: inline-block;
  font-size: 24px;
  height: 56px;
  left: 0;
  margin: -28px 10px 0;
  padding: 0px 0 0 2px;
  position: absolute;
  top: 50%;
  width: 56px;
  z-index: 1055; }
  .mfp-my-arrow-left:hover,
  .mfp-my-arrow-right:hover {
    background: #333333 none repeat scroll 0 0;
    color: #fff; }
  .mfp-my-arrow-left .icon-arrow-up-thin,
  .mfp-my-arrow-right .icon-arrow-up-thin {
    display: block;
    font-size: 24px;
    margin: 0 0 0 -7px;
    position: relative;
    transform: rotate(-90deg); }

.mfp-my-arrow-right {
  right: 0;
  left: auto;
  transform: scaleX(-1); }

.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: rgba(255, 255, 255, 0.9);
  opacity: 1; }

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden; }

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box; }

.mfp-container:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle; }

.mfp-align-top .mfp-container:before {
  display: none; }

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045; }

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto; }

.mfp-ajax-cur {
  cursor: progress; }

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out; }

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in; }

.mfp-auto-cursor .mfp-content {
  cursor: auto; }

.mfp-close,
.mfp-arrow,
.mfp-my-arrow-left,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none; }

.mfp-loading.mfp-figure {
  display: none; }

.mfp-hide {
  display: none !important; }

.mfp-preloader {
  color: #000;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044; }
  .mfp-preloader a {
    color: #000; }
    .mfp-preloader a:hover {
      color: #FFF; }

.mfp-s-ready .mfp-preloader {
  display: none; }

.mfp-s-error .mfp-content {
  display: none; }

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation; }

button::-moz-focus-inner {
  padding: 0;
  border: 0; }

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 1;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace; }
  .mfp-close:hover, .mfp-close:focus {
    opacity: 1; }
  .mfp-close:active {
    top: 1px; }

.mfp-close-btn-in .mfp-close {
  color: #333; }

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%; }

.mfp-counter {
  position: absolute;
  top: 12px;
  right: 0;
  color: #000;
  white-space: nowrap;
  font-size: 14px;
  font-weight: bold;
  line-height: 15px; }

.mfp-arrow {
  position: absolute;
  opacity: 1;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: transparent; }
  .mfp-arrow:active {
    margin-top: -54px; }
  .mfp-arrow:hover, .mfp-arrow:focus {
    opacity: 1; }
  .mfp-arrow:before, .mfp-arrow:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    margin-top: 35px;
    margin-left: 35px;
    border: medium inset transparent; }
  .mfp-arrow:after {
    border-top-width: 13px;
    border-bottom-width: 13px;
    top: 8px; }
  .mfp-arrow:before {
    border-top-width: 21px;
    border-bottom-width: 21px;
    opacity: 0.7; }

.mfp-arrow-left {
  left: 0; }
  .mfp-arrow-left:after {
    border-right: 17px solid #FFF;
    margin-left: 31px; }
  .mfp-arrow-left:before {
    margin-left: 25px;
    border-right: 27px solid #3F3F3F; }

.mfp-arrow-right {
  right: 0; }
  .mfp-arrow-right:after {
    border-left: 17px solid #FFF;
    margin-left: 39px; }
  .mfp-arrow-right:before {
    border-left: 27px solid #3F3F3F; }

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px; }
  .mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px; }
  .mfp-iframe-holder .mfp-close {
    top: -40px; }

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%; }
  .mfp-iframe-scaler iframe {
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #000; }

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 100px 0 100px;
  margin: 0 auto; }

/* The shadow behind the image */
.mfp-figure {
  line-height: 0; }
  .mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 100px;
    bottom: 100px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
    background: #444; }
  .mfp-figure small {
    color: #000;
    display: block;
    font-size: 12px;
    line-height: 14px; }
  .mfp-figure figure {
    margin: 0; }

.mfp-bottom-bar {
  margin-top: -96px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto; }

.mfp-title {
  margin: 10px 0 0 0;
  text-align: left;
  line-height: 22px;
  color: #000;
  word-wrap: break-word;
  padding-right: 60px; }

.mfp-image-holder .mfp-content {
  max-width: 100%; }

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer; }

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
       * Remove all paddings around the image on small screen
       */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0; }
  .mfp-img-mobile img.mfp-img {
    padding: 0; }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0; }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px; }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box; }
    .mfp-img-mobile .mfp-bottom-bar:empty {
      padding: 0; }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px; }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0; } }

@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75); }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0; }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%; }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px; } }

.fields select,
.fields input[type='text'],
.fields input[type='email'],
.fields input[type='password'],
.fields textarea {
  width: 100%;
  background: #f4f4f4;
  height: 34px;
  border: none;
  color: #000;
  margin: 0 0 10px 0;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px; }
  .fields select:focus::-webkit-input-placeholder,
  .fields input[type='text']:focus::-webkit-input-placeholder,
  .fields input[type='email']:focus::-webkit-input-placeholder,
  .fields input[type='password']:focus::-webkit-input-placeholder,
  .fields textarea:focus::-webkit-input-placeholder {
    transition: opacity 0.2s 0.2s ease;
    opacity: 0; }
  .fields select:focus:-moz-placeholder,
  .fields input[type='text']:focus:-moz-placeholder,
  .fields input[type='email']:focus:-moz-placeholder,
  .fields input[type='password']:focus:-moz-placeholder,
  .fields textarea:focus:-moz-placeholder {
    transition: opacity 0.2s 0.2s ease;
    opacity: 0; }
  .fields select:focus::-moz-placeholder,
  .fields input[type='text']:focus::-moz-placeholder,
  .fields input[type='email']:focus::-moz-placeholder,
  .fields input[type='password']:focus::-moz-placeholder,
  .fields textarea:focus::-moz-placeholder {
    transition: opacity 0.2s 0.2s ease;
    opacity: 0; }
  .fields select:focus:-ms-input-placeholder,
  .fields input[type='text']:focus:-ms-input-placeholder,
  .fields input[type='email']:focus:-ms-input-placeholder,
  .fields input[type='password']:focus:-ms-input-placeholder,
  .fields textarea:focus:-ms-input-placeholder {
    transition: opacity 0.2s 0.2s ease;
    opacity: 0; }

.fields textarea {
  padding: 7px 10px; }

.fields label {
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 4px 0;
  display: block; }
  .fields label.control {
    font-weight: normal; }
  .fields label.where {
    margin: 40px 0 0 0; }

.fields .error {
  width: 100%;
  box-sizing: border-box;
  font-size: 10px;
  color: #333333;
  margin: 0px 0 10px 0; }

.fields .small {
  font-size: 10px;
  font-weight: normal; }

.control {
  font-size: 18px;
  position: relative;
  display: block;
  margin-bottom: 15px;
  padding-left: 30px;
  cursor: pointer; }

.control input {
  position: absolute;
  z-index: -1;
  opacity: 0; }

.control__indicator {
  border: 1px solid;
  border-radius: 3px;
  height: 12px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 12px;
  background: #fff; }

.control--radio .control__indicator {
  border-radius: 50%; }

/* Hover and focus states */
.control:hover input ~ .control__indicator,
.control input:focus ~ .control__indicator {
  background: #ccc; }

/* Checked state */
.control input:checked ~ .control__indicator {
  background: #fff; }

/* Hover state whilst checked */
.control:hover input:not([disabled]):checked ~ .control__indicator,
.control input:checked:focus ~ .control__indicator {
  background: #fff; }

/* Disabled state */
.control input:disabled ~ .control__indicator {
  pointer-events: none;
  opacity: .6;
  background: #e6e6e6; }

/* Check mark */
.control__indicator:after {
  position: absolute;
  display: none;
  content: ''; }

/* Show check mark */
.control input:checked ~ .control__indicator:after {
  display: block; }

/* Checkbox tick */
.control--checkbox .control__indicator:after {
  width: 3px;
  height: 7px;
  height: 6px;
  left: 3.5px;
  top: 1px;
  transform: rotate(45deg);
  border: solid #333333;
  border-width: 0 2px 2px 0; }

/* Disabled tick colour */
.control--checkbox input:disabled ~ .control__indicator:after {
  border-color: #fff; }

/* Radio button inner circle */
.control--radio .control__indicator:after {
  top: 7px;
  left: 7px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #fff;
  background: #000 none repeat scroll 0 0;
  border-radius: 50%;
  height: 8.4px;
  left: 2px;
  top: 2px;
  width: 8px; }

/* Disabled circle colour */
.control--radio input:disabled ~ .control__indicator:after {
  background: #7b7b7b; }

html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent; }

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

nav ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  text-decoration: none; }

a:hover {
  text-decoration: underline; }

ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none; }

mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold; }

del {
  text-decoration: line-through; }

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help; }

/* tables still need cellspacing="0" in the markup */
table {
  border-collapse: collapse;
  border-spacing: 0; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

input, select {
  vertical-align: middle;
  border-radius: 0; }

input[type="radio"] {
  border: none !important;
  width: auto !important;
  height: auto !important; }

/* @end reset */
.clearfix:before,
.clearfix:after {
  content: " ";
  display: table; }

.clearfix:after {
  clear: both; }

.clearfix {
  *zoom: 1; }

@font-face {
  font-family: 'les-icons4';
  src: url("/css/fonts/les-icons4.eot?y3q0zo");
  src: url("/css/fonts/les-icons4.eot?y3q0zo#iefix") format("embedded-opentype"), url("/css/fonts/les-icons4.ttf?y3q0zo") format("truetype"), url("/css/fonts/les-icons4.woff?y3q0zo") format("woff"), url("/css/fonts/les-icons4.svg?y3q0zo#les-icons4") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'les-icons4' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-logo:before {
  content: "\e900"; }

.icon-views:before {
  content: "\e901"; }

.icon-settings:before {
  content: "\e902"; }

.icon-draft:before {
  content: "\e903"; }

.icon-rotate:before {
  content: "\e904"; }

.icon-trash:before {
  content: "\e905"; }

.icon-switch:before {
  content: "\e906"; }

.icon-copy:before {
  content: "\e907"; }

.icon-arrow-down-thin:before {
  content: "\e908"; }

.icon-plus:before {
  content: "\e909"; }

.icon-text-case:before {
  content: "\e90a"; }

.icon-star:before {
  content: "\e90b"; }

.icon-bold-italic:before {
  content: "\e90c"; }

.icon-bold:before {
  content: "\e90d"; }

.icon-italic:before {
  content: "\e90e"; }

.icon-underline:before {
  content: "\e90f"; }

.icon-linethrough:before {
  content: "\e910"; }

.icon-align-left:before {
  content: "\e911"; }

.icon-align-center:before {
  content: "\e912"; }

.icon-align-right:before {
  content: "\e913"; }

.icon-quote:before {
  content: "\e914"; }

.icon-russian:before {
  content: "\e915"; }

.icon-english:before {
  content: "\e916"; }

.icon-quote-cover-right:before {
  content: "\e917"; }

.icon-quote-cover:before {
  content: "\e918"; }

.icon-flag:before {
  content: "\e919"; }

.icon-keyword-center:before {
  content: "\e91a"; }

.icon-keyword-center-left:before {
  content: "\e91b"; }

.icon-keyword-center-right:before {
  content: "\e91c"; }

.icon-keyword-left:before {
  content: "\e91d"; }

.icon-keyword-top:before {
  content: "\e91e"; }

.icon-keyword-right:before {
  content: "\e91f"; }

.icon-keyword-text:before {
  content: "\e920"; }

.icon-keyword-text-right:before {
  content: "\e921"; }

.icon-keyword-full:before {
  content: "\e922"; }

.icon-interview:before {
  content: "\e923"; }

.icon-interview-border:before {
  content: "\e924"; }

.icon-interview-portraits:before {
  content: "\e925"; }

.icon-chat:before {
  content: "\e926"; }

.icon-footnote-half:before {
  content: "\e927"; }

.icon-footnote-full:before {
  content: "\e928"; }

.icon-cover:before {
  content: "\e929"; }

.icon-content-center:before {
  content: "\e92a"; }

.icon-content-top:before {
  content: "\e92b"; }

.icon-spacer:before {
  content: "\e92c"; }

.icon-content-cover:before {
  content: "\e92d"; }

.icon-content-cover-right:before {
  content: "\e92e"; }

.icon-brightness:before {
  content: "\e92f"; }

.icon-plus-minus:before {
  content: "\e930"; }

.icon-move:before {
  content: "\e931"; }

.icon-gallery:before {
  content: "\e932"; }

.icon-pencil:before {
  content: "\e933"; }

.icon-upload:before {
  content: "\e934"; }

.icon-video:before {
  content: "\e935";
  left: 0.06vw;
  position: relative; }

.icon-link:before {
  content: "\e936"; }

.icon-content-left-full:before {
  content: "\e937"; }

.icon-content-right-full:before {
  content: "\e938"; }

.icon-square:before {
  content: "\e939"; }

.icon-location:before {
  content: "\e93a"; }

.icon-position:before {
  content: "\e93b"; }

.icon-download:before {
  content: "\e93c"; }

.icon-arrow-up-thin:before {
  content: "\e93d"; }

.icon-qoutes:before {
  content: "\e93e"; }

.icon-qoute-fancy:before {
  content: "\e93f"; }

.icon-facebook-round:before {
  content: "\e940"; }

.icon-vk-round:before {
  content: "\e941"; }

.icon-twitter-round:before {
  content: "\e942"; }

.icon-google-plus-round:before {
  content: "\e943"; }

.icon-pinterest:before {
  content: "\e944"; }

.icon-facebook:before {
  content: "\e945"; }

.icon-vk:before {
  content: "\e946"; }

.icon-twitter:before {
  content: "\e947"; }

.icon-likes:before {
  content: "\e948"; }

.icon-expert_likes:before,
.icon-expert-likes:before {
  content: "\e949"; }

.icon-shares:before {
  content: "\e94a"; }

.icon-comments:before {
  content: "\e94b"; }

.icon-search:before {
  content: "\e94c"; }

.icon-notice:before {
  content: "\e94d"; }

.icon-write:before {
  content: "\e94e"; }

.icon-user:before {
  content: "\e94f"; }

.icon-logout:before {
  content: "\e951"; }

.icon-bars:before {
  content: "\e952"; }

.icon-bars2:before {
  content: "\e953"; }

.icon-smile:before {
  content: "\e954"; }

.icon-conversation:before {
  content: "\e955"; }

.icon-broadcast:before {
  content: "\e956"; }

.icon-print:before {
  content: "\e957"; }

.icon-magazines:before {
  content: "\e958"; }

.icon-hidden:before {
  content: "\e959"; }

.icon-round-check:before {
  content: "\e95a"; }

.icon-round-plus:before {
  content: "\e95b"; }

.icon-round-close:before {
  content: "\e95c"; }

.icon-list:before {
  content: "\e95d"; }

.icon-important:before {
  content: "\e95e"; }

.icon-LES_Icons_part_4-96 .path1:before {
  content: "\e95f";
  color: #57996b;
  opacity: 0.5; }

.icon-LES_Icons_part_4-96 .path2:before {
  content: "\e960";
  margin-left: -1em;
  color: #57996b; }

.icon-arrow-left .path1:before {
  content: "\e961";
  color: black;
  opacity: 0.25; }

.icon-arrow-left .path2:before {
  content: "\e962";
  margin-left: -1em;
  color: white; }

.icon-arrow-both .path1:before {
  content: "\e963";
  color: white; }

.icon-arrow-both .path2:before {
  content: "\e964";
  margin-left: -1em;
  color: black;
  opacity: 0.25; }

.icon-arrow-both .path3:before {
  content: "\e965";
  margin-left: -1em;
  color: white; }

.icon-close:before {
  content: "\e966"; }

.icon-fat-plus:before {
  content: "\e967"; }

/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.noUi-target {
  position: relative;
  direction: ltr; }

.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  /* Fix 401 */ }

.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: -6px; }

.noUi-handle {
  position: relative;
  z-index: 1; }

.noUi-stacking .noUi-handle {
  /* This class is applied to the lower origin when
   its values is > 50%. */
  z-index: 10; }

.noUi-state-tap .noUi-origin {
  -webkit-transition: left 0.3s, top 0.3s;
  transition: left 0.3s, top 0.3s; }

.noUi-state-drag * {
  cursor: inherit !important; }

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 30px; }

.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px; }

.noUi-vertical {
  width: 23px; }

.noUi-vertical .noUi-handle {
  width: 8px;
  height: 8px;
  border-radius: 3px;
  left: -4px; }

/* Styling;
 */
.noUi-background {
  background: #333333;
  padding: 10px 0 20px 0;
  box-sizing: content-box; }
  .noUi-background:after {
    content: " ";
    border-left: 1px solid #989898;
    position: absolute;
    height: 131px;
    left: 12px;
    top: 10px; }

.noUi-connect {
  background: #fff; }

.noUi-origin {
  border-radius: 2px;
  left: 12px;
  width: 1px; }

.noUi-target {
  border-radius: 30px; }

.noUi-target.noUi-connect {
  box-shadow: inset 0 0 3px rgba(51, 51, 51, 0.45), 0 3px 6px -5px #BBB; }

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: w-resize; }

.noUi-vertical .noUi-draggable {
  cursor: n-resize; }

.noUi-handle {
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #FFF;
  cursor: default;
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #EBEBEB, 0 3px 6px -3px #BBB; }

.noUi-active {
  box-shadow: inset 0 0 1px #FFF, inset 0 1px 7px #DDD, 0 3px 6px -3px #BBB; }

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
  background: #B8B8B8; }

[disabled].noUi-origin,
[disabled] .noUi-handle {
  cursor: not-allowed; }

/*
 * jquery.selectBoxIt.css 3.8.1
 * Author: @gregfranko
 */
/*
  Common CSS Properties
  ---------------------
  These properties will be applied to any themes that you use
*/
/* SelectBoxIt container */
.selectboxit-container {
  position: relative;
  display: inline-block;
  vertical-align: top; }

/* Styles that apply to all SelectBoxIt elements */
.selectboxit-container * {
  font: 14px Helvetica, Arial;
  /* Prevents text selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  outline: none;
  white-space: nowrap; }

/* Button */
.selectboxit-container .selectboxit {
  width: 220px;
  /* Width of the dropdown button */
  cursor: pointer;
  margin: 0;
  padding: 0;
  border-radius: 6px;
  overflow: hidden;
  display: block;
  position: relative; }

/* Height and Vertical Alignment of Text */
.selectboxit-container span, .selectboxit-container .selectboxit-options a {
  height: 30px;
  /* Height of the drop down */
  line-height: 30px;
  /* Vertically positions the drop down text */
  display: block; }

/* Focus pseudo selector */
.selectboxit-container .selectboxit:focus {
  outline: 0; }

/* Disabled Mouse Interaction */
.selectboxit.selectboxit-disabled, .selectboxit-options .selectboxit-disabled {
  opacity: 0.65;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  cursor: default; }

/* Button Text */
.selectboxit-text {
  text-indent: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: left; }

.selectboxit .selectboxit-option-icon-container {
  margin-left: 5px; }

/* Options List */
.selectboxit-container .selectboxit-options {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  min-width: 100%;
  /* Minimum Width of the dropdown list box options */
  *width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: absolute;
  overflow-x: hidden;
  overflow-y: auto;
  cursor: pointer;
  display: none;
  z-index: 9999999999999;
  border-radius: 6px;
  text-align: left;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none; }

/* Individual options */
.selectboxit-option .selectboxit-option-anchor {
  padding: 0 2px; }

/* Individual Option Hover Action */
.selectboxit-option .selectboxit-option-anchor:hover {
  text-decoration: none; }

/* Individual Option Optgroup Header */
.selectboxit-option, .selectboxit-optgroup-header {
  text-indent: 5px;
  /* Horizontal Positioning of the select box option text */
  margin: 0;
  list-style-type: none; }

/* The first Drop Down option */
.selectboxit-option-first {
  border-top-right-radius: 6px;
  border-top-left-radius: 6px; }

/* The first Drop Down option optgroup */
.selectboxit-optgroup-header + .selectboxit-option-first {
  border-top-right-radius: 0px;
  border-top-left-radius: 0px; }

/* The last Drop Down option */
.selectboxit-option-last {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px; }

/* Drop Down optgroup headers */
.selectboxit-optgroup-header {
  font-weight: bold; }

/* Drop Down optgroup header hover psuedo class */
.selectboxit-optgroup-header:hover {
  cursor: default; }

/* Drop Down down arrow container */
.selectboxit-arrow-container {
  /* Positions the down arrow */
  width: 30px;
  position: absolute;
  right: 0; }

/* Drop Down down arrow */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  /* Horizontally centers the down arrow */
  margin: 0 auto;
  position: absolute;
  top: 50%;
  right: 0;
  left: 0; }

/* Drop Down down arrow for jQueryUI and jQuery Mobile */
.selectboxit .selectboxit-arrow-container .selectboxit-arrow.ui-icon {
  top: 30%; }

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-container {
  float: left; }

.selectboxit-container .selectboxit-option-icon {
  margin: 0;
  padding: 0;
  vertical-align: middle; }

/* Drop Down individual option icon positioning */
.selectboxit-option-icon-url {
  width: 18px;
  background-size: 18px 18px;
  background-repeat: no-repeat;
  height: 100%;
  background-position: center;
  float: left; }

.selectboxit-rendering {
  display: inline-block !important;
  *display: inline !important;
  zoom: 1 !important;
  visibility: visible !important;
  position: absolute !important;
  top: -9999px !important;
  left: -9999px !important; }

/* jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon {
  background-color: inherit; }

/* Another jQueryUI and jQuery Mobile compatability fix - Feel free to remove this style if you are not using jQuery Mobile */
.jqueryui .ui-icon-triangle-1-s {
  background-position: -64px -16px; }

/*
  Default Theme
  -------------
  Note: Feel free to remove all of the CSS underneath this line if you are not using the default theme
*/
.selectboxit-btn {
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border: 1px solid #cccccc;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  border-bottom-color: #b3b3b3; }

.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus,
.selectboxit-btn.selectboxit-enabled:active {
  color: #333333;
  background-color: #e6e6e6; }

.selectboxit-btn.selectboxit-enabled:hover,
.selectboxit-btn.selectboxit-enabled:focus {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px; }

.selectboxit-default-arrow {
  width: 0;
  height: 0;
  border-top: 4px solid #000000;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent; }

.selectboxit-list {
  background-color: #ffffff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2); }

.selectboxit-list .selectboxit-option-anchor {
  color: #333333; }

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: #ffffff;
  background-color: #0081c2;
  background-image: -moz-linear-gradient(top, #0088cc, #0077b3);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#0088cc), to(#0077b3));
  background-image: -webkit-linear-gradient(top, #0088cc, #0077b3);
  background-image: -o-linear-gradient(top, #0088cc, #0077b3);
  background-image: linear-gradient(to bottom, #0088cc, #0077b3);
  background-repeat: repeat-x; }

.selectboxit-list > .selectboxit-disabled > .selectboxit-option-anchor {
  color: #999999; }

.selectboxit-container {
  margin: 0 0 20px 0; }
  .selectboxit-container .selectboxit {
    border-radius: 5px; }
  .selectboxit-container .selectboxit-btn {
    border: none;
    background: #f4f4f4; }
  .selectboxit-container .selectboxit-options {
    border: none;
    background: #f4f4f4;
    border: 0 none;
    border-radius: 3px;
    width: 100%; }
    .selectboxit-container .selectboxit-options span, .selectboxit-container .selectboxit-options a {
      height: 20px;
      line-height: 20px;
      color: #333333;
      clear: both; }
  .selectboxit-container .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
    background: none;
    color: #000; }

.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  bottom: auto;
  top: 9px;
  color: #989898;
  font-size: 12px;
  left: 11px;
  margin: 0 auto;
  position: absolute;
  right: 0; }

.date_group .selectboxit-container {
  margin: 0 0 10px 0; }

.date_group .selectboxit-container span, .date_group .selectboxit-container .selectboxit-options a {
  height: 24px;
  line-height: 24px;
  color: #333333; }

.date_group .selectboxit-options span {
  display: none; }

.date_group .selectboxit-option-icon-container {
  float: right;
  padding-left: 30px; }

.date_group .selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  bottom: auto;
  top: 6px;
  color: #989898;
  font-size: 12px;
  left: 12px;
  margin: 0 auto;
  position: absolute;
  right: 0; }

.autocomplete-suggestions {
  background: none;
  background-color: rgba(51, 51, 51, 0.95);
  border-bottom: medium none;
  border-image: none;
  border-top: medium none;
  color: #d9d9d9;
  font-family: Open Sans;
  font-size: 0.8vw;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 25px;
  padding: 10px 0;
  text-transform: none; }
  .autocomplete-suggestions .autocomplete-suggestion {
    box-sizing: border-box;
    font-weight: bold;
    height: 25px;
    color: #d9d9d9;
    line-height: 25px;
    padding: 0 10px;
    cursor: pointer; }
    .autocomplete-suggestions .autocomplete-suggestion:hover {
      color: #fff; }
    .autocomplete-suggestions .autocomplete-suggestion strong {
      display: inline; }

.autocomplete_users {
  position: absolute;
  width: 300px;
  font-size: 12px;
  z-index: 110;
  left: 100px;
  top: 100px; }
  .autocomplete_users .user_picture {
    width: 35px;
    height: 35px;
    float: left;
    margin: 0 10px 0 0; }
    .autocomplete_users .user_picture .round {
      width: 35px;
      height: 35px;
      display: block;
      line-height: 35px;
      font-size: 18px;
      text-align: center;
      text-transform: uppercase;
      background: #333333;
      color: #fff;
      border-radius: 35px; }
    .autocomplete_users .user_picture img {
      display: block;
      border-radius: 30px;
      width: 100%; }
  .autocomplete_users .login {
    font-weight: bold; }
  .autocomplete_users .full_name {
    font-weight: normal; }
  .autocomplete_users ul {
    list-style: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.09);
    padding: 20px 0 20px 0;
    display: block;
    background: #fff; }
    .autocomplete_users ul li {
      display: block;
      width: 100%;
      padding: 5px 20px;
      box-sizing: border-box;
      height: 45px;
      background: #fff;
      color: #333333;
      cursor: pointer; }
      .autocomplete_users ul li:hover {
        text-decoration: none;
        background: #333333;
        color: #fff; }
        .autocomplete_users ul li:hover .user_picture .round {
          color: #333333;
          background: #fff; }

.scroll-wrapper {
  overflow: hidden !important;
  padding: 0 !important;
  position: relative; }

.scroll-wrapper > .scroll-content {
  border: none !important;
  box-sizing: content-box !important;
  height: auto;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none !important;
  overflow: scroll !important;
  padding: 0;
  position: relative !important;
  top: 0;
  width: auto !important; }

.scroll-wrapper > .scroll-content::-webkit-scrollbar {
  height: 0;
  width: 0; }

.scroll-element {
  display: none; }

.scroll-element, .scroll-element div {
  box-sizing: content-box; }

.scroll-element.scroll-x.scroll-scrollx_visible,
.scroll-element.scroll-y.scroll-scrolly_visible {
  display: block; }

.scroll-element .scroll-bar,
.scroll-element .scroll-arrow {
  cursor: default; }

.scroll-textarea {
  border: 1px solid #cccccc;
  border-top-color: #999999; }

.scroll-textarea > .scroll-content {
  overflow: hidden !important; }

.scroll-textarea > .scroll-content > textarea {
  border: none !important;
  box-sizing: border-box;
  height: 100% !important;
  margin: 0;
  max-height: none !important;
  max-width: none !important;
  overflow: scroll !important;
  outline: none;
  padding: 2px;
  position: relative !important;
  top: 0;
  width: 100% !important; }

.scroll-textarea > .scroll-content > textarea::-webkit-scrollbar {
  height: 0;
  width: 0; }

/*************** SIMPLE INNER SCROLLBAR ***************/
.scrollbar-inner > .scroll-element,
.scrollbar-inner > .scroll-element div {
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-inner > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-inner > .scroll-element.scroll-x {
  bottom: 2px;
  height: 8px;
  left: 0;
  width: 100%; }

.scrollbar-inner > .scroll-element.scroll-y {
  height: 100%;
  right: 2px;
  top: 0;
  width: 8px; }

.scrollbar-inner > .scroll-element .scroll-element_outer {
  overflow: hidden; }

.scrollbar-inner > .scroll-element .scroll-element_outer,
.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px; }

.scrollbar-inner > .scroll-element .scroll-element_track,
.scrollbar-inner > .scroll-element .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  filter: alpha(opacity=40);
  opacity: 0.4; }

.scrollbar-inner > .scroll-element .scroll-element_track {
  background-color: #e0e0e0; }

.scrollbar-inner > .scroll-element .scroll-bar {
  background-color: #c2c2c2; }

.scrollbar-inner > .scroll-element:hover .scroll-bar {
  background-color: #919191; }

.scrollbar-inner > .scroll-element.scroll-draggable .scroll-bar {
  background-color: #919191; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_track {
  left: -12px; }

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_track {
  top: -12px; }

.scrollbar-inner > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -12px; }

.scrollbar-inner > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -12px; }

/*************** SCROLLBAR MAC OS X ***************/
.scrollbar-macosx > .scroll-element,
.scrollbar-macosx > .scroll-element div {
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10; }

.scrollbar-macosx > .scroll-element div {
  display: block;
  height: 100%;
  left: 0;
  top: 0;
  width: 100%; }

.scrollbar-macosx > .scroll-element .scroll-element_track {
  display: none; }

.scrollbar-macosx > .scroll-element .scroll-bar {
  background-color: #d9d9d9;
  display: block;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear; }

.scrollbar-macosx:hover > .scroll-element .scroll-bar,
.scrollbar-macosx > .scroll-element.scroll-draggable .scroll-bar {
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  opacity: 0.7; }

.scrollbar-macosx > .scroll-element.scroll-x {
  bottom: 0px;
  height: 0px;
  left: 0;
  min-width: 100%;
  overflow: visible;
  width: 100%; }

.scrollbar-macosx > .scroll-element.scroll-y {
  height: 100%;
  min-height: 100%;
  right: 0px;
  top: 0;
  width: 0px; }

/* scrollbar height/width & offset from container borders */
.scrollbar-macosx > .scroll-element.scroll-x .scroll-bar {
  height: 7px;
  min-width: 10px;
  top: -9px; }

.scrollbar-macosx > .scroll-element.scroll-y .scroll-bar {
  left: -6px;
  min-height: 10px;
  width: 5px; }

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_outer {
  left: 2px; }

.scrollbar-macosx > .scroll-element.scroll-x .scroll-element_size {
  left: -4px; }

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_outer {
  top: 2px; }

.scrollbar-macosx > .scroll-element.scroll-y .scroll-element_size {
  top: -4px; }

/* update scrollbar offset if both scrolls are visible */
.scrollbar-macosx > .scroll-element.scroll-x.scroll-scrolly_visible .scroll-element_size {
  left: -11px; }

.scrollbar-macosx > .scroll-element.scroll-y.scroll-scrollx_visible .scroll-element_size {
  top: -11px; }

/*! http://responsiveslides.com v1.55 by @viljamis */
.rslides {
  position: relative;
  list-style: none;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: 0; }
  .rslides li {
    -webkit-backface-visibility: hidden;
    position: absolute;
    display: none;
    width: 100%;
    left: 0;
    top: 0;
    max-height: 80vh; }
    .rslides li:first-child {
      position: relative;
      display: block;
      float: left; }
  .rslides img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    border: 0;
    margin: auto; }

.black_tooltip {
  position: relative;
  display: inline-block; }
  .black_tooltip:hover .tooltip_holder {
    display: block;
    opacity: 1;
    transition: opacity 0.3s ease-in; }
  .black_tooltip.special .tooltip_holder {
    right: 70px !important; }
    .black_tooltip.special .tooltip_holder .tooltip_text:after {
      right: 15% !important; }
  .black_tooltip .tooltip_holder {
    position: absolute;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    right: 50%;
    top: -15px;
    display: block;
    opacity: 0;
    transition: opacity 0.1s ease-out;
    line-height: normal !important; }
    .black_tooltip .tooltip_holder .tooltip_text {
      position: relative;
      right: -50%;
      background: #333333;
      border-radius: 3px;
      padding: 2px 10px;
      color: #fff !important;
      font-size: 10px;
      font-weight: normal;
      text-transform: none;
      white-space: nowrap;
      line-height: normal !important; }
      .black_tooltip .tooltip_holder .tooltip_text::after {
        content: " ";
        line-height: normal;
        position: absolute;
        top: 100%;
        /* At the bottom of the tooltip */
        right: 50%;
        margin-right: -5px;
        margin-top: -1px;
        border-width: 5px;
        border-style: solid;
        border-color: #333333 transparent transparent transparent;
        line-height: normal !important; }

.editor_bubble {
  position: absolute;
  background: #333333;
  color: #d9d9d9;
  left: 0;
  top: 0;
  z-index: 200;
  font-size: 10px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  margin: -35px 0 0 -125.5px;
  text-align: center;
  border-radius: 20px;
  display: none;
  visibility: hidden;
  padding: 15px;
  height: 43px;
  box-sizing: border-box;
  width: 251px; }
  .editor_bubble--active {
    display: inline-block;
    visibility: visible; }
  .editor_bubble::after {
    content: " ";
    line-height: normal;
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -3px;
    margin-top: -1px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    line-height: normal !important; }
  .editor_bubble ul {
    list-style: none; }
    .editor_bubble ul li {
      display: inline-block;
      vertical-align: top; }
      .editor_bubble ul li.spacer {
        width: 20px; }
        .editor_bubble ul li.spacer:after {
          content: " ";
          position: absolute;
          height: 18px;
          width: 1px;
          top: 12px;
          background: #d9d9d9; }
      .editor_bubble ul li:first-child button {
        padding-left: 0; }
      .editor_bubble ul li:last-child button {
        padding-right: 0; }
  .editor_bubble button {
    width: auto;
    border: none;
    height: auto;
    background: none;
    cursor: pointer;
    font-weight: normal;
    font-size: 11px;
    line-height: normal;
    position: relative;
    top: -3px;
    color: #d9d9d9; }
    .editor_bubble button:hover {
      background: none;
      color: #fff; }
    .editor_bubble button.color {
      width: 19px;
      height: 19px;
      border: 2px solid #fff;
      top: -5px;
      position: relative;
      margin: 0 3px; }

@keyframes pop-upwards {
  0% {
    opacity: 0;
    transform: matrix(0.97, 0, 0, 1, 0, 12); }
  20% {
    opacity: 0.7;
    transform: matrix(0.99, 0, 0, 1, 0, 2); }
  40% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, -1); }
  70% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0); }
  100% {
    opacity: 1;
    transform: matrix(1, 0, 0, 1, 0, 0); } }

.team {
  margin: 90px 0 0 0;
  clear: both;
  width: 100%; }
  .team.padded {
    width: 960px;
    left: 0px;
    margin: 0;
    position: relative; }
    .team.padded .info_block {
      display: block;
      float: left;
      font-size: 14px;
      height: 220px;
      margin: 0 20px 0 0;
      position: relative;
      width: 220px; }
    .team.padded ul li {
      width: 220px;
      margin: 0 20px 0 0; }
  .team ul {
    list-style: none; }
    .team ul li {
      width: 25%;
      float: left;
      height: 220px; }
  .team .profile_pic {
    text-align: center;
    height: 97px; }
    .team .profile_pic img {
      width: 90px;
      border-radius: 90px;
      display: inline-block; }
    .team .profile_pic .round {
      background: #fff none repeat scroll 0 0;
      border-radius: 90px;
      color: #000;
      font-weight: bold;
      height: 90px;
      font-size: 25px;
      line-height: 90px;
      text-align: center;
      width: 90px;
      text-transform: uppercase;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3);
      display: inline-block; }
  .team a {
    color: #333333; }
    .team a:hover {
      color: #333333; }
  .team .views {
    font-size: 10px;
    color: #989898;
    vertical-align: top; }
    .team .views .icon-views {
      font-size: 13px;
      margin: 0 2px 0 0;
      position: relative;
      top: 2px; }
  .team h2 {
    font-size: 18px;
    font-weight: bold; }
  .team .full_name {
    font-size: 14px;
    font-weight: bold; }
  .team .profile_info {
    text-align: center; }
  .team .position {
    text-align: center;
    color: #d9d9d9;
    margin: 10px 0 0 0; }
  .team .small_position {
    font-size: 10px;
    color: #989898;
    text-align: center;
    margin: 6px 0 0 0; }
    .team .small_position a {
      color: #989898; }

@media (max-width: 530px) {
  .team.padded .info_block {
    margin: 0 auto 50px;
    float: none;
    height: auto; }
  .team.padded ul li {
    margin: 0 auto;
    float: none; } }

@keyframes animatedBackground {
  from {
    background-position: 0 0; }
  to {
    background-position: 1129px 0; } }

@keyframes infinite-spinning {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

body.construction {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-image: url("/img/les/underconstruction.png");
  background-position: 0px 0px;
  background-repeat: repeat;
  animation: animatedBackground 20s linear infinite; }
  body.construction.admin {
    background: #333333; }
    body.construction.admin form {
      width: 300px; }
      body.construction.admin form .logo {
        color: #fff; }
      body.construction.admin form .field {
        margin: 0 0 10px 0; }
        body.construction.admin form .field input {
          width: 300px; }
        body.construction.admin form .field button {
          margin: 10px 0 0 0;
          width: 140px;
          background: #57996b; }
  body.construction form {
    width: 360px;
    text-align: center;
    align-self: center;
    text-align: cecenter; }
    body.construction form .logo {
      display: block;
      font-size: 145px;
      height: 35px;
      line-height: 28px;
      margin: 0 auto 	48px auto;
      left: 24px;
      position: relative;
      text-align: center; }
    body.construction form p {
      margin: 0 0 42px 0; }
    body.construction form .field input {
      background: #fff;
      border: none;
      width: 237px;
      height: 35px;
      box-sizing: border-box;
      padding: 0px 10px;
      font-size: 14px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      color: #333333;
      float: left; }
    body.construction form .field button {
      float: right;
      width: 84px;
      background: #333333;
      color: #fff;
      text-align: center;
      height: 35px;
      border: none;
      cursor: pointer;
      border-radius: 35px; }

ul.tags_list {
  list-style: none;
  position: absolute;
  bottom: 20px; }
  ul.tags_list li {
    margin: 0 7px 0 0;
    display: inline-block; }
    ul.tags_list li:last-child {
      margin: 0; }
    ul.tags_list li a {
      display: inline-block;
      background: #f4f4f4;
      color: #333333 !important;
      border-radius: 5px;
      font-weight: normal;
      font-size: 10px;
      padding: 5px 10px; }

.live-feed {
  position: relative;
  width: 940px;
  max-height: 221px;
  overflow: hidden;
  margin: 0 auto; }

.contour_button {
  font-weight: bold;
  color: #333333;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  padding: 0 20px;
  border-radius: 30px;
  border: 1px solid #333333;
  background: none;
  display: inline-block; }
  .contour_button:hover {
    color: #333333; }

.red_button {
  font-weight: bold;
  color: #fff;
  font-size: 14px;
  height: 34px;
  line-height: 34px;
  padding: 0 20px;
  border-radius: 30px;
  background: #57996b;
  display: inline-block; }
  .red_button:hover {
    color: #fff; }

.slider_space {
  height: 56px; }

.special_stuff {
  background: url("/img/les/progressors-banner.jpg") fixed center center/cover;
  background: url("/img/media-pol-tymen-back.jpg") fixed center center/cover;
  width: 100%;
  height: 377px;
  position: relative;
  display: block; }
  .special_stuff .filter {
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10; }
  .special_stuff a {
    color: #fff; }
    .special_stuff a:hover {
      color: #fff; }
  .special_stuff .fond {
    display: block;
    position: absolute;
    width: 100px;
    right: 0;
    bottom: 55px;
    width: 234px;
    z-index: 88;
    font-size: 10px; }
    .special_stuff .fond img {
      display: block;
      width: 100%; }
  .special_stuff .broadcast {
    margin: 48px 0 0 0;
    border: 1px solid #fff;
    width: 213px;
    line-height: 34px;
    height: 34px;
    display: block;
    text-align: center;
    border-radius: 30px;
    font-weight: bold;
    float: left;
    z-index: 20;
    position: relative; }
    .special_stuff .broadcast .icon {
      display: inline-block;
      margin: 0 8px 0 0;
      position: relative;
      top: 2px; }
  .special_stuff .wrapper {
    color: #fff;
    padding: 39px 0 0 0;
    position: relative;
    height: 100%; }
    .special_stuff .wrapper .left {
      float: left;
      width: 300px;
      position: relative;
      z-index: 20; }
      .special_stuff .wrapper .left h2 {
        font-size: 48px;
        text-transform: uppercase;
        line-height: 59px;
        margin: 10px 0 0; }
      .special_stuff .wrapper .left h3 {
        font-size: 24px;
        color: #fff;
        text-transform: none;
        font-size: 24px;
        line-height: 7px;
        margin: 26px 0 0;
        position: relative; }
    .special_stuff .wrapper .right {
      float: right;
      width: 460px;
      position: relative;
      z-index: 20;
      margin: 37px 0 0;
      font-size: 14px; }
      .special_stuff .wrapper .right h3 {
        font-size: 18px;
        color: #fff;
        line-height: 26px;
        margin: -18px 0 14px 0;
        text-transform: none; }
      .special_stuff .wrapper .right p {
        line-height: 20px; }
    .special_stuff .wrapper > p {
      float: left;
      color: #fff;
      z-index: 20;
      clear: both;
      margin: 20px 0 0 0;
      line-height: 22px;
      position: relative; }

#recomend_section, #experts_recomend_section {
  display: none; }
  #recomend_section.selected, #experts_recomend_section.selected {
    display: block; }

#watching_section, #reading_section {
  display: none; }
  #watching_section.selected, #reading_section.selected {
    display: block; }

h2.section_header {
  text-transform: uppercase;
  color: #333333;
  font-size: 24px;
  margin: 0 0 14px 0;
  position: relative; }
  h2.section_header .location_name {
    float: right;
    color: #57996b; }
  h2.section_header a {
    color: #333333; }
    h2.section_header a.selected {
      border-bottom: 1px solid #333333; }
    h2.section_header a.left_margin {
      margin: 0 8px 0 0; }
    h2.section_header a.right_margin {
      margin: 0 0 0 8px; }
  h2.section_header span {
    font-size: 14px; }
    h2.section_header span a {
      font-size: 14px;
      color: #333333;
      display: inline-block; }
      h2.section_header span a:first-child {
        margin: 0 4px 0 0; }
      h2.section_header span a:last-child {
        margin: 0 0 0 4px; }

section.full_height {
  height: 100%; }

nav.aside_articles {
  float: right;
  width: 220px;
  height: 463px;
  margin: 0 20px 0 0;
  width: 220px; }
  nav.aside_articles.short {
    height: 443px;
    margin: 0 20px 20px 0; }
  nav.aside_articles .news {
    background: #fff;
    height: 523px;
    margin: -1px 0 20px;
    overflow: auto;
    padding: 0 20px; }
    nav.aside_articles .news h2 {
      font-size: 18px;
      line-height: 35px;
      margin: 0 0 12px;
      padding: 0;
      text-transform: uppercase; }
    nav.aside_articles .news .date {
      font-size: 10px;
      color: #989898;
      margin: 0 0 4px 0; }
    nav.aside_articles .news h3 {
      margin: 0 0 5px 0;
      text-transform: none;
      color: #333333;
      font-size: 14px;
      line-height: 22px; }
    nav.aside_articles .news ul li {
      border-bottom: 1px solid #f4f4f4;
      margin: 0 0 10px 0px; }
  nav.aside_articles .notices {
    background: #f4f4f4 none repeat scroll 0 0;
    box-sizing: border-box;
    height: 250px;
    margin: -1px 0 20px;
    overflow: auto;
    padding: 0 20px; }
    nav.aside_articles .notices h2 {
      padding: 20px 0 0; }

.user_block .profile_pic {
  float: left;
  width: 34px;
  height: 34px;
  margin: 0 10px 0 0;
  position: relative; }
  .user_block .profile_pic .icon-close {
    background: #333333 none repeat scroll 0 0;
    border: 2px solid #fff;
    border-radius: 12px;
    box-sizing: border-box;
    color: #fff;
    font-size: 4px;
    height: 14px;
    line-height: 11px;
    position: absolute;
    right: -4px;
    text-align: center;
    top: -2px;
    width: 14px; }
  .user_block .profile_pic img {
    display: block;
    width: 100%;
    border-radius: 90px; }
  .user_block .profile_pic .round {
    background: #333333;
    border-radius: 90px;
    color: #fff;
    font-weight: bold;
    height: 100%;
    font-size: 14px;
    line-height: 100%;
    text-align: center;
    width: 100%;
    line-height: 34px;
    text-transform: uppercase;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3); }

.user_block .profile_info {
  float: left;
  margin: -5px 0 0; }
  .user_block .profile_info h2 {
    font-size: 14px;
    font-weight: bold;
    margin: 0 0 2px; }
  .user_block .profile_info p {
    color: #000;
    line-height: 20px; }

nav.sub_header_menu {
  background: #fff;
  width: 100%;
  margin: 55px 0 0 0;
  height: 55px;
  line-height: 55px;
  text-align: left;
  font-size: 14px;
  border-top: 1px solid #f4f4f4;
  border-bottom: 1px solid #f4f4f4;
  text-transform: uppercase; }
  nav.sub_header_menu.volume {
    box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.19);
    position: relative;
    z-index: 10;
    text-transform: none; }
    nav.sub_header_menu.volume > .wrapper > ul > li {
      font-weight: bold; }
    nav.sub_header_menu.volume ul ul.subfolders {
      width: 262px;
      box-shadow: 1px 4px 2px rgba(0, 0, 0, 0.19);
      position: absolute;
      text-transform: none;
      top: 55px; }
      nav.sub_header_menu.volume ul ul.subfolders.fixed_width {
        width: 262px; }
  nav.sub_header_menu .search {
    position: relative; }
    nav.sub_header_menu .search .search_text {
      border: 1px solid #888; }
  nav.sub_header_menu .tags_list {
    right: 0;
    margin: 0;
    bottom: 0; }
    nav.sub_header_menu .tags_list li {
      margin: 0 7px 0 0; }
      nav.sub_header_menu .tags_list li a {
        height: auto;
        display: inline;
        text-transform: none; }
  nav.sub_header_menu.advert {
    margin: 352px 0 0 0; }
    nav.sub_header_menu.advert.shrink {
      margin: 55px 0 0 0; }
  nav.sub_header_menu.after_top {
    margin: 0; }
  nav.sub_header_menu.center {
    text-align: center; }
  nav.sub_header_menu .control {
    font-size: 14px;
    padding-left: 18px; }
  nav.sub_header_menu .control__indicator {
    top: 20px; }
  nav.sub_header_menu .wrapper {
    position: relative; }
  nav.sub_header_menu .search_form {
    position: absolute;
    right: -15px;
    top: 10px; }
    nav.sub_header_menu .search_form:hover input, nav.sub_header_menu .search_form:focus input {
      border: 1px solid #f4f4f4; }
    nav.sub_header_menu .search_form:hover .icon-search, nav.sub_header_menu .search_form:focus .icon-search {
      color: #333333; }
    nav.sub_header_menu .search_form input {
      border: rgba(255, 255, 255, 0) 1px solid;
      transition: all ease-in 0.2s;
      position: absolute;
      right: 0;
      width: 220px;
      background: none;
      border-radius: 30px;
      height: 33px;
      line-height: 33px;
      padding: 0 20px;
      box-sizing: border-box;
      font-size: 14px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
    nav.sub_header_menu .search_form .icon-search {
      transition: all ease-in 0.2s;
      position: absolute;
      right: 25px;
      top: 10px;
      color: #f4f4f4; }
  nav.sub_header_menu ul {
    display: block; }
    nav.sub_header_menu ul .icon-arrow-down-thin {
      position: relative;
      top: 2px; }
    nav.sub_header_menu ul.modes {
      position: absolute;
      top: 0;
      right: 0;
      font-size: 12px; }
      nav.sub_header_menu ul.modes li {
        margin: 0 10px 0 0px; }
        nav.sub_header_menu ul.modes li a.selected {
          font-weight: normal;
          color: #989898; }
        nav.sub_header_menu ul.modes li.last-child {
          margin: 0; }
    nav.sub_header_menu ul li {
      display: inline-block;
      margin: 0 25px 0 0;
      vertical-align: top; }
      nav.sub_header_menu ul li:last-child {
        margin: 0; }
      nav.sub_header_menu ul li.selected a {
        color: #fff; }
      nav.sub_header_menu ul li.dash {
        border-top: 1px solid #000;
        height: 10px;
        position: relative;
        top: 15px;
        vertical-align: top;
        width: 19px; }
      nav.sub_header_menu ul li a {
        display: block;
        height: 100%;
        color: #000; }
        nav.sub_header_menu ul li a.icon-bars, nav.sub_header_menu ul li a.icon-magazines {
          margin: 20px 0 0 0; }
        nav.sub_header_menu ul li a .icon-arrow-down {
          font-size: 11px;
          margin: 0 0 0 1px;
          position: relative;
          top: 0; }
        nav.sub_header_menu ul li a.selected {
          font-weight: bold; }
      nav.sub_header_menu ul li:hover > a {
        color: #989898; }
      nav.sub_header_menu ul li:hover ul.subfolders {
        display: block; }
    nav.sub_header_menu ul ul.subfolders {
      display: none;
      position: absolute;
      background: #fff;
      z-index: 100;
      text-transform: none;
      margin: 0 0 0 -20px;
      padding: 0 20px 10px 20px;
      box-sizing: border-box; }
      nav.sub_header_menu ul ul.subfolders.fixed_width {
        width: 180px; }
      nav.sub_header_menu ul ul.subfolders li {
        display: block;
        margin: 0;
        line-height: 34px;
        font-weight: normal;
        text-transform: uppercase; }

@media (max-width: 820px) {
  nav.sub_header_menu {
    height: auto; } }

textarea:focus, input:focus, button {
  outline: none; }

.next_page {
  background: #57996b none repeat scroll 0 0;
  border-radius: 30px;
  color: #fff;
  display: block;
  font-size: 14px;
  font-weight: bold;
  height: 33px;
  line-height: 33px;
  padding: 0 19px;
  text-align: center;
  z-index: 200; }

.hide_placeholder_on_hover:focus::-webkit-input-placeholder {
  transition: opacity 0.2s 0.2s ease;
  opacity: 0; }

.hide_placeholder_on_hover:focus:-moz-placeholder {
  transition: opacity 0.2s 0.2s ease;
  opacity: 0; }

.hide_placeholder_on_hover:focus::-moz-placeholder {
  transition: opacity 0.2s 0.2s ease;
  opacity: 0; }

.hide_placeholder_on_hover:focus:-ms-input-placeholder {
  transition: opacity 0.2s 0.2s ease;
  opacity: 0; }

.lower_header {
  font-size: 24px;
  text-transform: uppercase;
  margin: 46px 0 20px 0; }

.full_banner {
  background: #333333;
  height: 616px;
  clear: both;
  position: relative; }
  .full_banner .wrapper {
    position: relative;
    height: 100%;
    z-index: 10; }
  .full_banner > .tint {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }
  .full_banner h2.section_header {
    padding: 50px 0 130px 0;
    color: #fff; }
    .full_banner h2.section_header span a {
      color: #fff; }
  .full_banner .articles > article {
    width: 460px;
    vertical-align: top;
    margin: 0 0 30px 0;
    padding: 0 0 0 0 !important;
    position: absolute;
    bottom: 0; }
    .full_banner .articles > article h2 {
      font-size: 48px;
      height: auto;
      line-height: 60px;
      padding: 0; }
    .full_banner .articles > article .leed {
      position: relative;
      color: #fff;
      height: auto;
      line-height: 21px;
      bottom: auto;
      margin: 0 0 7px 0; }
    .full_banner .articles > article .author .user_pic {
      display: none; }
    .full_banner .articles > article .author .user_name strong {
      text-transform: uppercase; }
    .full_banner .articles > article .author .user_name .date {
      display: none; }
  .full_banner .articles ul.list {
    clear: both;
    position: absolute;
    right: 0px;
    bottom: 20px;
    width: 460px; }
    .full_banner .articles ul.list > li {
      list-style: none;
      display: block;
      float: left;
      margin: 17px 0 0 0;
      width: 220px; }
      .full_banner .articles ul.list > li:nth-child(2n) {
        float: right; }
      .full_banner .articles ul.list > li:nth-child(3n) {
        border-bottom: none; }
      .full_banner .articles ul.list > li:nth-child(4n) {
        float: right;
        border-bottom: none; }
      .full_banner .articles ul.list > li .social {
        margin: 0 0 20px 0; }
      .full_banner .articles ul.list > li .up, .full_banner .articles ul.list > li .down {
        bottom: 10px; }
    .full_banner .articles ul.list article {
      width: 220px;
      vertical-align: top;
      padding: 0;
      margin: 0; }
      .full_banner .articles ul.list article:hover h2 {
        text-decoration: underline; }
      .full_banner .articles ul.list article .edit {
        right: -4px;
        top: -25px;
        padding: 0 0 20px 20px; }
      .full_banner .articles ul.list article .date_ago {
        display: none; }
      .full_banner .articles ul.list article .text_group {
        margin: 0 0 0px 0; }
      .full_banner .articles ul.list article h2 {
        font-size: 18px;
        height: auto;
        line-height: 21px;
        margin: 0 0 4px; }
      .full_banner .articles ul.list article p {
        font-size: 14px;
        margin: 0 0 5px 0;
        line-height: 21px; }
  .full_banner .articles article {
    background: transparent none repeat scroll 0 0;
    border: medium none;
    padding: 0 20px 0 0; }
    .full_banner .articles article .author {
      display: none; }
    .full_banner .articles article a {
      color: #fff; }
    .full_banner .articles article h2 {
      color: #fff; }
    .full_banner .articles article .social ul li a {
      color: #fff; }
    .full_banner .articles article .date_ago {
      position: relative;
      bottom: auto;
      right: auto; }
    .full_banner .articles article .author .user_date .date {
      background: #fff; }

section.create_and_stuff {
  margin: 70px 0 80px 0;
  padding: 40px 0 22px;
  background: #f4f4f4;
  clear: both; }

section.wrapper.margin_top_pad {
  margin: 41px auto 0 auto; }

section.wrapper.home_wrap.strict_top_pad {
  margin: 55px auto;
  padding: 0 40px; }

section.wrapper article {
  margin: 0 20px 20px 0; }
  section.wrapper article:nth-child(4n+4) {
    margin: 0 0 20px 0; }

.become_author .left {
  float: left;
  width: 140px; }

.become_author .right {
  float: right;
  width: 700px; }

.become_author .authors .black_tooltip .tooltip_holder {
  top: -22px; }

.become_author .authors .author {
  width: 56px;
  height: 56px;
  float: left;
  clear: none;
  margin: 0 24px 20px 0; }
  .become_author .authors .author:nth-child(9n+9) {
    margin: 0 0 20px 0; }
  .become_author .authors .author .user_pic {
    width: 100%;
    height: 100%; }
    .become_author .authors .author .user_pic img {
      width: 100%;
      height: 100%; }
    .become_author .authors .author .user_pic .round {
      width: 100%;
      height: 100%;
      line-height: 53px;
      font-size: 20px; }

.gallery .wrapper {
  padding: 0; }

.gallery .rslides_frame {
  position: relative;
  display: none; }
  .gallery .rslides_frame.selected {
    display: block; }
  .gallery .rslides_frame .inscription {
    float: left; }

.gallery .gallery_frame {
  width: 820px;
  margin: 0 auto;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  padding: 20px;
  position: relative;
  box-sizing: border-box;
  display: none; }
  .gallery .gallery_frame.selected {
    display: block;
    left: -20px; }

.gallery .cancel {
  color: #000; }

.gallery .save {
  background: #57996b none repeat scroll 0 0;
  border: 1px solid #57996b;
  border-radius: 30px;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  height: 32px;
  margin: 0 0 0 20px;
  text-align: center;
  width: 140px; }

.gallery .previews {
  position: relative;
  min-height: 55px;
  display: block;
  margin: 0 0 1px 0;
  list-style: none; }
  .gallery .previews li {
    display: inline-block;
    margin: 0 1px 0 0;
    vertical-align: top; }
    .gallery .previews li.selected {
      border: 3px solid #57996b;
      box-sizing: border-box; }
      .gallery .previews li.selected a {
        display: block;
        height: 51px;
        overflow: hidden; }
    .gallery .previews li a {
      display: block; }
    .gallery .previews li img {
      height: 55px;
      width: auto; }

.gallery .list {
  display: block;
  list-style: none; }
  .gallery .list li {
    display: none; }
    .gallery .list li img {
      background: #f4f4f4;
      display: block;
      margin: 0 0 10px 0; }
    .gallery .list li textarea {
      background: #f4f4f4;
      height: 55px;
      width: 100%;
      display: block;
      border: none;
      resize: none;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      box-sizing: border-box;
      padding: 10px;
      font-size: 10px;
      color: #989898;
      line-height: 20px;
      margin: 11px 0 0 0; }
    .gallery .list li.selected {
      display: block; }

.gallery .edit {
  position: absolute;
  right: 20px;
  top: 20px;
  line-height: 55px; }
  .gallery .edit a {
    display: inline-block;
    margin: 0 3px 0 0;
    background: #333333;
    border-radius: 20px;
    width: 23px;
    height: 23px;
    color: #fff;
    vertical-align: middle; }
    .gallery .edit a:last-child {
      margin: 0 10px 0 0; }
    .gallery .edit a span {
      line-height: 23px;
      display: block;
      text-align: center;
      font-size: 12px; }

.gallery .buttons {
  position: absolute;
  right: 20px;
  bottom: 23px;
  line-height: 30px; }

.gallery .rslides_nav {
  position: absolute;
  bottom: 50%;
  z-index: 10;
  font-size: 44px;
  color: #fff;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0 12px 12px 0;
  margin: 0 0 0 0px;
  padding: 2px 5px 2px 0; }
  .gallery .rslides_nav .icon-arrow-up-thin {
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg);
    position: relative;
    display: block;
    padding: 5px 0; }
  .gallery .rslides_nav.prev {
    left: 0;
    z-index: 20; }
  .gallery .rslides_nav.next {
    right: 0;
    z-index: 20;
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -o-transform: scaleX(-1);
    transform: scaleX(-1); }

.gallery.stripped {
  /*
	.tint{
		height:625px;
		width:940px;
	}
*/ }
  .gallery.stripped .edit {
    z-index: 20;
    line-height: unset; }
    .gallery.stripped .edit.hidden {
      display: none; }
    .gallery.stripped .edit a {
      margin: 0 10px 0 0; }
      .gallery.stripped .edit a:last-child {
        margin: 0; }
  .gallery.stripped .previews.hidden_always, .gallery.stripped .previews.hidden {
    display: none; }
  .gallery.stripped .load-gallery,
  .gallery.stripped .load-gallery-form,
  .gallery.stripped .replace-image-form {
    display: none; }
  .gallery.stripped .buttons {
    display: none; }
  .gallery.stripped .list li textarea {
    background: none;
    height: 20px;
    padding: 0; }
  .gallery.stripped .rslides_nav {
    bottom: auto;
    top: 50%; }
  .gallery.stripped .upload_images_banner .image_frame {
    background: #f4f4f4 url("/img/les/upload.svg") no-repeat !important;
    background-position: center center !important;
    background-size: contain !important; }
  .gallery.stripped .gallery_frame {
    box-shadow: none;
    padding: 0;
    width: 100%;
    left: 0; }
    .gallery.stripped .gallery_frame .picture_frame {
      width: 100%;
      /*
			img{
				margin: 0;
				max-height: 100%;
				max-width: 100%;
				width: auto !important;
				height: auto !important;
			}
*/ }
      .gallery.stripped .gallery_frame .picture_frame .image_frame {
        min-height: 80vh;
        max-height: 100vh;
        /*
				width:940px;
*/
        background: #f4f4f4;
        margin: 0 0 10px; }

.black_banner {
  height: 56px;
  background: #000;
  color: #fff;
  font-size: 14px;
  line-height: 56px; }
  .black_banner a {
    font-weight: bold;
    color: #fff; }
  .black_banner .wrapper {
    position: relative;
    text-align: center; }
    .black_banner .wrapper ul {
      display: inline-block;
      list-style: none; }
      .black_banner .wrapper ul li {
        display: inline-block; }
        .black_banner .wrapper ul li:first-child {
          margin: 0 20px 0 0; }

.subscribe_icon {
  display: block;
  width: 21px;
  height: 21px;
  line-height: 21px;
  text-align: center; }
  .subscribe_icon .sub {
    background: #000 none repeat scroll 0 0;
    border-radius: 30px;
    color: #fff;
    font-size: 11px;
    height: 100%;
    line-height: 22px;
    text-align: center;
    width: 100%; }
  .subscribe_icon .subed {
    width: 100%;
    height: 100%;
    background: #57996b;
    color: #fff;
    border-radius: 30px;
    display: none;
    position: relative; }
    .subscribe_icon .subed:after {
      width: 3px;
      height: 7px;
      left: 7px;
      top: 5px;
      transform: rotate(45deg);
      border: solid #fff;
      content: "";
      position: absolute;
      border-radius: 0 0 1px 1px;
      border-width: 0 3px 3px 0; }
  .subscribe_icon.subscribed .subed {
    display: block; }
  .subscribe_icon.subscribed .sub {
    display: none; }

.small_map_location {
  position: absolute;
  width: 460px;
  height: 245px;
  border-radius: 0 0 5px 5px;
  box-shadow: 1px 1px 4px 2px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  background: #d9d9d9; }
  .small_map_location .map_view {
    height: 210px;
    width: 100%;
    display: block; }
  .small_map_location .map_input {
    display: block;
    height: 36px;
    width: 100%;
    border: none;
    box-shadow: none;
    line-height: 36px;
    padding: 0 10px;
    margin: 0;
    box-sizing: border-box;
    border-radius: 0 0 5px 5px; }
  .small_map_location .icon-search {
    bottom: 11px;
    position: absolute;
    right: 10px;
    color: #57996b;
    cursor: pointer; }
  .small_map_location .close {
    position: absolute;
    right: -11px;
    top: -11px;
    background: #fff;
    color: #d9d9d9;
    width: 22px;
    height: 22px;
    cursor: pointer;
    line-height: 20px;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.18);
    border-radius: 30px;
    text-align: center; }
    .small_map_location .close .icon-close {
      font-size: 8px;
      display: inline-block; }

.subscription_button {
  display: block;
  width: 156px;
  text-align: center;
  height: 34px;
  line-height: 34px;
  font-size: 14px !important;
  font-weight: bold;
  position: relative;
  margin: 0px 0 0 9px; }
  .subscription_button .sub {
    display: block;
    border-radius: 30px;
    background: #333333;
    color: #fff; }
  .subscription_button .subed {
    display: none;
    background: #57996b;
    color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 30px; }
  .subscription_button.subscribed .subed {
    display: block; }
  .subscription_button.subscribed .sub {
    display: none; }

aside.side_float_nav {
  position: fixed;
  left: -240px;
  height: 100%;
  width: 230px;
  z-index: 200;
  top: 0;
  box-sizing: border-box;
  padding: 160px 35px 160px 35px;
  background: #fff;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
  transition: ease-in 0.3s all; }
  aside.side_float_nav.selected {
    left: 0; }
  aside.side_float_nav h2 {
    font-size: 24px;
    margin: 0 0 20px 0;
    font-weight: bold; }
  aside.side_float_nav ul li {
    text-transform: uppercase;
    margin: 0 0 20px 0; }
    aside.side_float_nav ul li a {
      color: #000; }

.article_page {
  display: none; }
  .article_page.selected {
    display: block; }

#veil_settings2_toggle .veil.settings {
  margin: 0; }

#settings_part1_form,
#settings_part1a_form {
  height: auto; }

#settings_part2_form {
  height: 400px; }

#no_previews_selected {
  display: none; }
  #no_previews_selected.selected {
    display: block; }

.previews {
  display: block; }
  .previews.not_visible {
    display: none; }

.subpages {
  display: none; }
  .subpages #block_types_id.no_cover .full_background,
  .subpages #block_types_id.no_cover .half_image,
  .subpages #block_types_id.no_cover .half_gallery,
  .subpages #block_types_id.no_cover .half_quote {
    display: none; }
  .subpages .fillable_field {
    height: 32px;
    width: 100%;
    border: 1px solid #f4f4f4;
    font-size: 14px;
    padding: 5px 10px;
    white-space: nowrap;
    box-sizing: border-box;
    background: #fff;
    overflow: hidden; }
    .subpages .fillable_field .placeholder {
      color: #989898; }
    .subpages .fillable_field br {
      display: none; }
    .subpages .fillable_field a {
      color: #333333; }
    .subpages .fillable_field.space_bottom {
      margin: 0 0 10px 0; }
  .subpages .float_holder {
    width: 100%;
    float: left; }
  .subpages h2 {
    font-size: 24px;
    font-weight: bold;
    clear: both; }
  .subpages .next_page {
    background: #57996b none repeat scroll 0 0;
    border-radius: 30px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 33px;
    left: 50%;
    line-height: 33px;
    margin: 0 0 0 470px;
    padding: 0 19px;
    position: fixed;
    text-align: center;
    top: 12px;
    z-index: 200; }
  .subpages.selected {
    display: block;
    height: 100%; }
  .subpages .pointer_down {
    background: #333333;
    position: absolute;
    height: 22px;
    line-height: 22px;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    width: 152px;
    border-radius: 20px;
    left: 50%;
    color: #fff;
    margin: -11px 0 0 -76px; }
  .subpages .subpage form#settings_part1_form {
    background: #f4f4f4;
    margin: 0 auto 22px auto; }
  .subpages .subpage.selected {
    display: block;
    height: 100%; }
  .subpages #veil_description_toggle ul.modify {
    display: block; }
  .subpages #veil_description_toggle .veil {
    height: 100%; }
  .subpages #veil_description_toggle .left.articles {
    padding: 40px 0 0 0;
    position: relative; }
    .subpages #veil_description_toggle .left.articles ul.modify {
      right: 0;
      display: block; }
    .subpages #veil_description_toggle .left.articles article {
      display: none; }
      .subpages #veil_description_toggle .left.articles article.selected {
        display: block; }
  .subpages #veil_description_toggle .right.articles {
    padding: 40px 0 0 0;
    position: relative; }
    .subpages #veil_description_toggle .right.articles ul.modify {
      right: 0;
      display: block; }
    .subpages #veil_description_toggle .right.articles article {
      display: none; }
      .subpages #veil_description_toggle .right.articles article.selected {
        display: block; }
  .subpages #veil_description_toggle form {
    width: 100%;
    height: 100%; }
    .subpages #veil_description_toggle form #description {
      padding: 40px 0 0 0;
      height: 100%;
      position: relative; }
  .subpages > nav {
    margin: 55px 0 0 0;
    width: 100%;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4;
    z-index: 101; }
    .subpages > nav ul {
      list-style: none; }
      .subpages > nav ul li {
        display: inline-block; }
        .subpages > nav ul li.dash {
          border-top: 1px solid #000;
          height: 10px;
          margin: 0 24px;
          position: relative;
          top: 15px;
          vertical-align: top;
          width: 14px; }
        .subpages > nav ul li a {
          color: #000;
          text-transform: uppercase; }
          .subpages > nav ul li a.selected {
            font-weight: bold; }
  .subpages .veil {
    display: block; }
    .subpages .veil.settings {
      margin: 50px 0 0 0; }
      .subpages .veil.settings select {
        background: #fff; }
        .subpages .veil.settings select.space_bottom {
          margin: 0 0 24px 0; }
      .subpages .veil.settings .important {
        background: #57996b;
        border-radius: 20px;
        display: inline-block;
        height: 11px;
        vertical-align: top;
        margin: 5px 10px 12px 0;
        width: 11px; }
      .subpages .veil.settings .control {
        padding-left: 22px; }
      .subpages .veil.settings .left {
        margin: 0; }
        .subpages .veil.settings .left.special_left {
          box-sizing: border-box;
          padding: 0 0 0 28px; }
          .subpages .veil.settings .left.special_left .control.label_move {
            padding-left: 30px; }
          .subpages .veil.settings .left.special_left .label_move {
            position: relative;
            left: -31px;
            font-weight: bold; }
            .subpages .veil.settings .left.special_left .label_move .important {
              margin: 5px 14px 12px 5px; }
            .subpages .veil.settings .left.special_left .label_move .control {
              font-weight: bold; }
            .subpages .veil.settings .left.special_left .label_move .control__indicator {
              left: 5px; }
      .subpages .veil.settings .right {
        margin: 0; }
    .subpages .veil.settings, .subpages .veil.cover {
      height: auto;
      position: relative; }
      .subpages .veil.settings .tab, .subpages .veil.cover .tab {
        display: block; }
      .subpages .veil.settings form, .subpages .veil.cover form {
        position: relative;
        top: auto;
        left: auto;
        margin: 0 auto;
        box-shadow: none; }
      .subpages .veil.settings .hidden, .subpages .veil.cover .hidden {
        display: block;
        width: 780px;
        position: relative;
        left: -80px;
        margin: 0 auto; }

ul.publications {
  list-style: none;
  margin: 42px 0 0 0; }
  ul.publications li {
    display: inline-block;
    margin: 0 20px 0 0; }
    ul.publications li:nth-child(3n+3) {
      margin: 0; }
    ul.publications li a {
      margin: 0 0 23px 0;
      display: block;
      width: 140px;
      height: 76px;
      text-align: center;
      background: #d9d9d9;
      line-height: 76px;
      font-size: 12px;
      font-weight: bold;
      color: #fff; }
      ul.publications li a.selected, ul.publications li a:hover {
        background: #57996b; }

#location_map {
  height: 210px;
  width: 100%;
  margin: 22px 0 0 0; }

#find_location {
  width: 100%;
  background: #f4f4f4;
  height: 34px;
  line-height: 34px;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

#press_find_location {
  bottom: 20px;
  position: absolute;
  right: 10px; }

.veil form label {
  font-weight: bold;
  font-size: 14px;
  margin: 0 0 4px 0;
  display: block; }
  .veil form label.control {
    font-weight: normal; }
  .veil form label.where {
    margin: 40px 0 0 0; }

.veil form button.load_other_preview {
  width: 180px;
  background: none;
  color: #57996b;
  border: 1px solid #57996b;
  margin: 0 0 22px 0; }
  .veil form button.load_other_preview:hover {
    color: #fff;
    background: #57996b; }

.veil form header.darker h2 {
  background: #333333;
  color: #fff; }

.veil form header h2 {
  color: #333333;
  width: 100%;
  font-size: 24px;
  line-height: 55px;
  height: 55px;
  box-sizing: border-box;
  padding: 0 20px;
  margin: 0; }

.veil form header p {
  border-bottom: 1px solid #f4f4f4;
  height: 32px;
  box-sizing: border-box;
  padding: 0 20px;
  line-height: 32px;
  font-size: 14px;
  display: block;
  font-weight: bold;
  color: #333333; }

.veil.date form {
  width: 340px;
  padding: 25px;
  box-sizing: border-box; }

.veil.cover {
  position: absolute;
  height: 3000px;
  z-index: 100; }
  .veil.cover form {
    width: 980px;
    padding: 20px;
    position: absolute;
    top: 100px;
    margin: 0px 0px 0px -470px;
    left: 50%; }
  .veil.cover nav {
    border-top: 1px solid #f4f4f4;
    border-bottom: 1px solid #f4f4f4; }
    .veil.cover nav ul {
      list-style: none;
      height: 30px;
      line-height: 30px;
      width: 100%;
      box-sizing: border-box;
      padding: 0 20px;
      text-transform: uppercase; }
      .veil.cover nav ul li {
        display: inline-block;
        margin: 0 30px 0 0; }
        .veil.cover nav ul li a {
          color: #000; }
          .veil.cover nav ul li a.selected {
            font-weight: bold; }
        .veil.cover nav ul li:last-child {
          margin: 0; }
  .veil.cover #description.full_height .samples {
    height: 100%;
    height: 670px; }
  .veil.cover #description.full_height .pointer_down {
    display: block; }
  .veil.cover #description .pointer_down {
    display: none; }
  .veil.cover .samples {
    margin: 0px 0 0 0;
    height: auto; }
    .veil.cover .samples article {
      display: none;
      height: auto; }
      .veil.cover .samples article.full_background {
        min-height: 100%;
        left: -20px;
        padding: 40px 20px 50px;
        box-sizing: content-box;
        overflow: hidden; }
      .veil.cover .samples article.selected {
        display: block; }
  .veil.cover .switch {
    display: block;
    clear: both;
    width: 100%;
    margin: 0 0 20px 0;
    font-size: 14px;
    color: #000; }
  .veil.cover .hidden {
    display: none; }
    .veil.cover .hidden.selected {
      display: block; }
  .veil.cover .left {
    float: left; }
  .veil.cover .right {
    float: right; }
    .veil.cover .right article {
      margin: 0 !important; }
  .veil.cover .center_text {
    width: 940px;
    margin: 5px auto 6px;
    font-size: 14px; }
  .veil.cover .gallery {
    height: 43px;
    background: #f4f4f4;
    display: block;
    float: left;
    margin: 0 0 1px 0; }
  .veil.cover .switch .on {
    display: none; }
  .veil.cover .switch .off {
    display: block; }
  .veil.cover .switch.selected .on {
    display: block; }
  .veil.cover .switch.selected .off {
    display: none; }
  .veil.cover .tab {
    display: none; }
    .veil.cover .tab#description {
      width: 100%;
      box-sizing: border-box;
      padding: 0 80px; }
    .veil.cover .tab.selected {
      display: block; }
    .veil.cover .tab > ul {
      list-style: none;
      margin: 44px auto 0 auto; }
      .veil.cover .tab > ul > li {
        display: inline-block;
        margin: 0 46px 61px 0;
        vertical-align: top; }
        .veil.cover .tab > ul > li:nth-child(3n+3) {
          margin: 0 0 61px 0; }
        .veil.cover .tab > ul > li a {
          position: relative;
          display: block;
          height: 177px; }
          .veil.cover .tab > ul > li a .border {
            border: 3px solid transparent;
            box-sizing: border-box;
            height: 177px;
            position: absolute;
            width: 100%; }
          .veil.cover .tab > ul > li a img {
            display: block;
            box-sizing: border-box; }
          .veil.cover .tab > ul > li a p {
            position: absolute;
            top: 183px;
            font-size: 14px;
            color: #333333;
            display: inline-block; }
          .veil.cover .tab > ul > li a:hover .border, .veil.cover .tab > ul > li a.selected .border {
            border: 3px solid #57996b; }
          .veil.cover .tab > ul > li a:hover p, .veil.cover .tab > ul > li a.selected p {
            border-bottom: 1px solid #000; }
          .veil.cover .tab > ul > li a .border {
            border: 1px solid #f4f4f4; }

.veil.about_me {
  z-index: 120; }
  .veil.about_me .bottom {
    bottom: 0;
    box-sizing: border-box;
    height: 45px;
    margin: 20px 0 20px;
    padding: 0 20px;
    position: relative;
    width: 100%; }
    .veil.about_me .bottom p {
      position: absolute;
      left: 20px;
      bottom: 0; }
    .veil.about_me .bottom button {
      position: absolute;
      right: 20px;
      bottom: 0; }
  .veil.about_me form {
    width: 660px;
    max-height: 640px;
    height: 100%;
    padding: 0; }
    .veil.about_me form .holder {
      width: 100%;
      padding: 20px 20px;
      box-sizing: border-box;
      clear: both;
      height: 65%;
      max-height: 430px;
      overflow: auto; }
      .veil.about_me form .holder .left {
        float: left;
        width: 270px; }
      .veil.about_me form .holder .right {
        float: right;
        width: 270px; }
  .veil.about_me input.social {
    margin: 0 0 0px 0; }

.veil.settings {
  z-index: 100; }
  .veil.settings input[type='text'] {
    border: #f4f4f4 1px solid;
    background: #fff; }
    .veil.settings input[type='text']::-webkit-input-placeholder {
      color: #989898; }
    .veil.settings input[type='text']:-moz-placeholder {
      color: #989898; }
    .veil.settings input[type='text']::-moz-placeholder {
      color: #989898; }
    .veil.settings input[type='text']:-ms-input-placeholder {
      color: #989898; }
  .veil.settings .export {
    display: none; }
    .veil.settings .export.selected {
      display: block;
      position: relative; }
      .veil.settings .export.selected input {
        background: #fff;
        border: none; }
    .veil.settings .export .icon-search {
      position: absolute;
      right: 20px;
      bottom: 20px;
      color: #989898; }
    .veil.settings .export .holder {
      position: relative; }
  .veil.settings form {
    width: 980px;
    height: 620px;
    margin: 0 auto;
    background: #fff;
    color: #000;
    padding: 20px;
    font-size: 14px; }
    .veil.settings form:after {
      display: table;
      position: relative;
      content: " ";
      background: none;
      cursor: auto;
      box-shadow: none; }
    .veil.settings form > section {
      display: none; }
      .veil.settings form > section.selected {
        display: block; }
  .veil.settings .left {
    float: left;
    margin: 20px 0 0 20px;
    position: relative; }
    .veil.settings .left .icon-search {
      bottom: 20px;
      position: absolute;
      right: 10px; }
  .veil.settings .right {
    float: right;
    margin: 20px 20px 0 0px; }
  .veil.settings ul {
    list-style: none; }
    .veil.settings ul.space_bottom {
      margin: 0 0 24px 0; }
    .veil.settings ul.where li {
      display: inline-block;
      margin: 0 20px 0 0; }
    .veil.settings ul.where.space_bottom {
      margin: 0 0 20px 0; }
  .veil.settings .left, .veil.settings .right {
    width: 460px; }
  .veil.settings textarea {
    background: #fff;
    border: 1px solid #f4f4f4;
    margin: 0 0 20px 0;
    resize: none;
    height: 32px;
    line-height: 32px;
    margin: 0 0 20px;
    padding: 0 10px; }
  .veil.settings p {
    font-size: 10px; }
    .veil.settings p.normal_font {
      font-size: 14px; }
  .veil.settings .articles.list {
    margin: 44px 0 0 0; }
    .veil.settings .articles.list li.selected article {
      border: 3px solid #57996b; }
    .veil.settings .articles.list li.selected h3 {
      color: #57996b; }
    .veil.settings .articles.list article {
      width: 140px;
      font-size: 4.67px;
      padding: 0; }
      .veil.settings .articles.list article img {
        display: block; }
      .veil.settings .articles.list article.none, .veil.settings .articles.list article.small, .veil.settings .articles.list article.full {
        height: 128px; }
      .veil.settings .articles.list article.full header img {
        margin: 0 0 0 -59%;
        width: 200%; }
      .veil.settings .articles.list article header {
        width: 140px;
        min-height: auto; }
      .veil.settings .articles.list article .author {
        top: 10px; }
        .veil.settings .articles.list article .author .user_pic .round {
          width: 17px;
          height: 17px; }
        .veil.settings .articles.list article .author .user_name strong {
          font-size: 4.67px; }
        .veil.settings .articles.list article .author .user_name .date {
          font-size: 4.67px; }
      .veil.settings .articles.list article h2 {
        font-size: 4.67px;
        line-height: inherit;
        height: 28px; }
      .veil.settings .articles.list article .social ul li {
        font-size: 4.67px;
        margin: 0 7px 0 0; }
  .veil.settings .buttons_position {
    position: absolute;
    bottom: 44px;
    right: 80px; }
    .veil.settings .buttons_position.hidden {
      display: none; }
    .veil.settings .buttons_position button {
      display: inline-block;
      width: 140px;
      margin: 0 20px 0 0;
      vertical-align: top; }
      .veil.settings .buttons_position button:last-child {
        margin: 0; }
  .veil.settings nav {
    margin: 0 0 0 0; }
    .veil.settings nav ul li {
      display: inline-block;
      font-weight: bold;
      margin: 0 20px 0 0; }
      .veil.settings nav ul li:last-child {
        margin: 0; }
      .veil.settings nav ul li a {
        color: #000;
        display: block;
        margin: 0 0px 0 0; }
      .veil.settings nav ul li.selected a {
        color: #57996b; }
  .veil.settings ul.articles {
    display: block;
    list-style: none; }
    .veil.settings ul.articles > li {
      display: inline-block;
      width: 160px;
      vertical-align: top; }
      .veil.settings ul.articles > li:last-child {
        width: 140px; }
      .veil.settings ul.articles > li h3 {
        text-transform: none; }

.date_ago {
  position: absolute;
  right: 21px;
  bottom: 9px;
  font-size: 10px;
  color: #fff; }

.load_more {
  color: #333333;
  text-align: center;
  height: 34px;
  display: block;
  line-height: 34px;
  font-weight: bold;
  font-size: 14px;
  clear: both;
  position: relative; }
  .load_more:before {
    width: 100%;
    position: absolute;
    border-top: 1px solid #d9d9d9;
    content: " ";
    top: 18px;
    left: 0; }
  .load_more span {
    position: relative;
    background: #fff none repeat scroll 0 0;
    display: inline-block;
    padding: 0 10px;
    position: relative; }
  .load_more i {
    display: inline-block;
    width: 17px;
    height: 17px;
    margin: 0 10px 0 0;
    position: relative;
    top: 4px; }

.on_moderation {
  right: 50px;
  top: 20px;
  font-size: 10px;
  color: #fff;
  padding: 2px 4px;
  background: #57996b; }

.social ul {
  font-size: 12px;
  list-style: none; }
  .social ul li {
    display: inline-block;
    margin: 0 10px 0 0;
    color: #989898; }
    .social ul li.selected a {
      color: #57996b; }
    .social ul li.ago {
      float: right;
      font-size: 10px;
      position: relative;
      top: 2px; }
    .social ul li a {
      color: #989898; }
      .social ul li a i {
        display: inline-block;
        height: 14px;
        position: relative;
        top: 2px;
        width: 16px; }
      .social ul li a:hover {
        color: #57996b; }

.author {
  width: 100%;
  clear: both;
  margin: 0 0 6px 0; }
  .author .user_pic {
    float: left;
    margin: 0 10px 0 0; }
    .author .user_pic img {
      border-radius: 35px;
      display: block;
      width: 35px; }
    .author .user_pic .round {
      background: #fff none repeat scroll 0 0;
      border-radius: 35px;
      color: #000;
      font-weight: bold;
      height: 35px;
      line-height: 35px;
      text-align: center;
      width: 35px;
      text-transform: uppercase;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3); }
  .author .user_name {
    float: left; }
    .author .user_name a {
      color: #333333; }
    .author .user_name .location {
      display: inline-block;
      font-size: 14px;
      text-transform: none;
      color: #989898;
      margin: 0 0 0 20px; }
    .author .user_name strong {
      font-size: 14px;
      margin: 0 0 10px 0; }
    .author .user_name .date {
      color: #989898;
      font-size: 10px; }
    .author .user_name .subscribe {
      font-size: 10px;
      color: #fff;
      font-size: 12px;
      left: 5px;
      position: relative;
      top: 2px; }
      .author .user_name .subscribe.subscribed .name, .author .user_name .subscribe.subscribed:hover .name {
        display: none; }
      .author .user_name .subscribe.subscribed .icon-plus, .author .user_name .subscribe.subscribed:hover .icon-plus {
        display: none; }
      .author .user_name .subscribe.subscribed .icon-check, .author .user_name .subscribe.subscribed:hover .icon-check {
        display: inline-block; }
      .author .user_name .subscribe:hover .name {
        display: inherit; }
      .author .user_name .subscribe:hover .icon-plus {
        background: red;
        border-radius: 10px; }
      .author .user_name .subscribe .icon-check {
        display: none; }
      .author .user_name .subscribe .icon-plus {
        display: inline-block; }
      .author .user_name .subscribe .name {
        display: none;
        background: #57996b none repeat scroll 0 0;
        bottom: 2px;
        color: #fff;
        font-size: 8px;
        left: 5px;
        padding: 0 5px;
        position: relative; }
        .author .user_name .subscribe .name::before {
          border-bottom: 5px solid transparent;
          border-right: 5px solid #ff4e50;
          border-top: 5px solid transparent;
          content: " ";
          height: 0;
          left: 0;
          margin: 3px 0 0 -3px;
          position: absolute;
          width: 0; }

.articles.blocks {
  left: 10px;
  position: relative;
  width: 980px; }
  .articles.blocks article {
    margin: 0 20px 20px 0; }

.articles.fit_all {
  padding: 0;
  position: relative;
  width: 960px; }
  .articles.fit_all > article {
    float: left; }
    .articles.fit_all > article:nth-child(4n+4) {
      margin: 0 20px 20px 0; }

.articles.landing_wrap > article {
  float: left; }

.articles article[data-format='box'] {
  width: 220px;
  height: 250px; }
  .articles article[data-format='box'] .text_group {
    bottom: 55px;
    max-height: 156px !important; }
  .articles article[data-format='box'].none {
    border: 1px solid #f4f4f4; }
    .articles article[data-format='box'].none .date_ago {
      bottom: 40px;
      color: #989898;
      left: 20px;
      right: auto; }

.articles article[data-format='wide'], .articles.top_articles {
  width: 460px;
  height: 250px; }
  .articles article[data-format='wide'] .leed, .articles.top_articles .leed {
    font-size: 14px;
    line-height: 22px; }
  .articles article[data-format='wide'] a, .articles.top_articles a {
    color: #333333; }
  .articles article[data-format='wide'] .text_group, .articles.top_articles .text_group {
    bottom: 41px !important;
    width: 92% !important; }
  .articles article[data-format='wide'] h2, .articles.top_articles h2 {
    top: auto !important; }
  .articles article[data-format='wide'] .leed, .articles.top_articles .leed {
    position: relative;
    bottom: auto; }
  .articles article[data-format='wide'].none h2, .articles article[data-format='wide'].small h2, .articles article[data-format='wide'].full h2, .articles.top_articles.none h2, .articles.top_articles.small h2, .articles.top_articles.full h2 {
    bottom: auto;
    font-size: 24px;
    max-height: none;
    line-height: 31px;
    top: 80px;
    position: relative; }
  .articles article[data-format='wide'].none .date_ago, .articles article[data-format='wide'].small .date_ago, .articles article[data-format='wide'].full .date_ago, .articles.top_articles.none .date_ago, .articles.top_articles.small .date_ago, .articles.top_articles.full .date_ago {
    bottom: 18px;
    right: 21px;
    left: auto;
    position: absolute; }
  .articles article[data-format='wide'].full h2, .articles.top_articles.full h2 {
    top: auto; }
  .articles article[data-format='wide'].none, .articles.top_articles.none {
    border: 1px solid #f4f4f4; }
  .articles article[data-format='wide']:nth-child(odd), .articles.top_articles:nth-child(odd) {
    margin: 0 20px 25px 0; }
  .articles article[data-format='wide']:nth-child(even), .articles.top_articles:nth-child(even) {
    margin: 0 20px 25px 0; }

.articles article[data-format='box'] a {
  color: #333333; }

.articles .info_block {
  width: 220px;
  display: block;
  float: left;
  margin: 0 20px 0 0;
  height: 220px;
  position: relative;
  font-size: 14px; }
  .articles .info_block .actions {
    position: absolute;
    bottom: 20px;
    height: 130px; }
    .articles .info_block .actions a {
      color: #000;
      display: block;
      margin: 13px 0 0 0; }
      .articles .info_block .actions a:first-child {
        margin: 40px 0 0 0; }

.articles.full_width {
  width: 100%;
  height: 100%;
  margin: 0 auto; }
  .articles.full_width.center_view article {
    margin: 0; }
    .articles.full_width.center_view article.full_background .description_holder {
      position: absolute;
      width: 100%;
      bottom: 60px;
      z-index: 10; }
      .articles.full_width.center_view article.full_background .description_holder h2 {
        color: #fff; }
      .articles.full_width.center_view article.full_background .description_holder .leed {
        color: #fff;
        margin-bottom: 30px !important; }
      .articles.full_width.center_view article.full_background .description_holder .text_group {
        margin-bottom: 20px; }
      .articles.full_width.center_view article.full_background .description_holder .social {
        position: relative;
        width: 940px;
        margin: 0 auto;
        float: none;
        left: 0px; }
        .articles.full_width.center_view article.full_background .description_holder .social ul li a {
          color: #fff; }
          .articles.full_width.center_view article.full_background .description_holder .social ul li a.rubric {
            background: rgba(0, 0, 0, 0.3); }
    .articles.full_width.center_view article.full_background .text_group, .articles.full_width.center_view article.half_gallery .text_group, .articles.full_width.center_view article.full_text .text_group, .articles.full_width.center_view article.half_video .text_group, .articles.full_width.center_view article.half_image .text_group, .articles.full_width.center_view article.half_quote .text_group {
      width: 940px;
      margin: 0 auto;
      float: none; }
    .articles.full_width.center_view article.full_background .author, .articles.full_width.center_view article.half_gallery .author, .articles.full_width.center_view article.full_text .author, .articles.full_width.center_view article.half_video .author, .articles.full_width.center_view article.half_image .author, .articles.full_width.center_view article.half_quote .author {
      display: none; }
    .articles.full_width.center_view article.full_background .author .user_name .date, .articles.full_width.center_view article.half_gallery .author .user_name .date, .articles.full_width.center_view article.full_text .author .user_name .date, .articles.full_width.center_view article.half_video .author .user_name .date, .articles.full_width.center_view article.half_image .author .user_name .date, .articles.full_width.center_view article.half_quote .author .user_name .date {
      margin: 0 0 0 -600px;
      left: 50%;
      display: none; }
    .articles.full_width.center_view article.full_background .date_ago, .articles.full_width.center_view article.half_gallery .date_ago, .articles.full_width.center_view article.full_text .date_ago, .articles.full_width.center_view article.half_video .date_ago, .articles.full_width.center_view article.half_image .date_ago, .articles.full_width.center_view article.half_quote .date_ago {
      text-align: right; }
    .articles.full_width.center_view article.full_background .gallery, .articles.full_width.center_view article.half_gallery .gallery, .articles.full_width.center_view article.full_text .gallery, .articles.full_width.center_view article.half_video .gallery, .articles.full_width.center_view article.half_image .gallery, .articles.full_width.center_view article.half_quote .gallery {
      width: 940px; }
      .articles.full_width.center_view article.full_background .gallery:hover .rslides_nav, .articles.full_width.center_view article.half_gallery .gallery:hover .rslides_nav, .articles.full_width.center_view article.full_text .gallery:hover .rslides_nav, .articles.full_width.center_view article.half_video .gallery:hover .rslides_nav, .articles.full_width.center_view article.half_image .gallery:hover .rslides_nav, .articles.full_width.center_view article.half_quote .gallery:hover .rslides_nav {
        display: block; }
      .articles.full_width.center_view article.full_background .gallery .rslides_nav, .articles.full_width.center_view article.half_gallery .gallery .rslides_nav, .articles.full_width.center_view article.full_text .gallery .rslides_nav, .articles.full_width.center_view article.half_video .gallery .rslides_nav, .articles.full_width.center_view article.half_image .gallery .rslides_nav, .articles.full_width.center_view article.half_quote .gallery .rslides_nav {
        display: none; }
    .articles.full_width.center_view article.full_background .image .filter, .articles.full_width.center_view article.half_gallery .image .filter, .articles.full_width.center_view article.full_text .image .filter, .articles.full_width.center_view article.half_video .image .filter, .articles.full_width.center_view article.half_image .image .filter, .articles.full_width.center_view article.half_quote .image .filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    .articles.full_width.center_view article.full_background .image p, .articles.full_width.center_view article.half_gallery .image p, .articles.full_width.center_view article.full_text .image p, .articles.full_width.center_view article.half_video .image p, .articles.full_width.center_view article.half_image .image p, .articles.full_width.center_view article.half_quote .image p {
      position: relative;
      z-index: 20;
      background: #fff; }
  .articles.full_width article {
    width: 100%;
    border: none; }
    .articles.full_width article.half_gallery .gallery {
      display: block;
      float: none;
      height: 100%;
      margin: 0 auto;
      overflow: hidden;
      position: relative;
      width: 54.1666%; }
      .articles.full_width article.half_gallery .gallery p {
        position: relative;
        top: auto;
        font-size: 14px; }
    .articles.full_width article.full_background, .articles.full_width article.half_gallery, .articles.full_width article.full_text, .articles.full_width article.half_video, .articles.full_width article.half_image, .articles.full_width article.half_quote {
      box-sizing: border-box;
      padding: 40px 90px 50px; }
      .articles.full_width article.full_background .edit, .articles.full_width article.half_gallery .edit, .articles.full_width article.full_text .edit, .articles.full_width article.half_video .edit, .articles.full_width article.half_image .edit, .articles.full_width article.half_quote .edit {
        margin: 0 -470px 0 0;
        position: absolute;
        right: 50%;
        top: 20px;
        z-index: 100; }
      .articles.full_width article.full_background .text_group, .articles.full_width article.half_gallery .text_group, .articles.full_width article.full_text .text_group, .articles.full_width article.half_video .text_group, .articles.full_width article.half_image .text_group, .articles.full_width article.half_quote .text_group {
        padding: 0; }
      .articles.full_width article.full_background .leed, .articles.full_width article.half_gallery .leed, .articles.full_width article.full_text .leed, .articles.full_width article.half_video .leed, .articles.full_width article.half_image .leed, .articles.full_width article.half_quote .leed {
        height: auto;
        position: relative; }
        .articles.full_width article.full_background .leed a, .articles.full_width article.half_gallery .leed a, .articles.full_width article.full_text .leed a, .articles.full_width article.half_video .leed a, .articles.full_width article.half_image .leed a, .articles.full_width article.half_quote .leed a {
          color: #000; }
      .articles.full_width article.full_background .date_ago, .articles.full_width article.half_gallery .date_ago, .articles.full_width article.full_text .date_ago, .articles.full_width article.half_video .date_ago, .articles.full_width article.half_image .date_ago, .articles.full_width article.half_quote .date_ago {
        position: absolute;
        left: 90px;
        color: #000;
        font-size: 14px;
        right: auto;
        bottom: auto;
        top: 43px; }
      .articles.full_width article.full_background .author, .articles.full_width article.half_gallery .author, .articles.full_width article.full_text .author, .articles.full_width article.half_video .author, .articles.full_width article.half_image .author, .articles.full_width article.half_quote .author {
        margin: 0 0 3px; }
        .articles.full_width article.full_background .author > a, .articles.full_width article.half_gallery .author > a, .articles.full_width article.full_text .author > a, .articles.full_width article.half_video .author > a, .articles.full_width article.half_image .author > a, .articles.full_width article.half_quote .author > a {
          display: none; }
        .articles.full_width article.full_background .author .user_name, .articles.full_width article.half_gallery .author .user_name, .articles.full_width article.full_text .author .user_name, .articles.full_width article.half_video .author .user_name, .articles.full_width article.half_image .author .user_name, .articles.full_width article.half_quote .author .user_name {
          margin: 0; }
          .articles.full_width article.full_background .author .user_name strong a, .articles.full_width article.half_gallery .author .user_name strong a, .articles.full_width article.full_text .author .user_name strong a, .articles.full_width article.half_video .author .user_name strong a, .articles.full_width article.half_image .author .user_name strong a, .articles.full_width article.half_quote .author .user_name strong a {
            font-size: 14px;
            font-weight: bold;
            color: #000; }
          .articles.full_width article.full_background .author .user_name .date, .articles.full_width article.half_gallery .author .user_name .date, .articles.full_width article.full_text .author .user_name .date, .articles.full_width article.half_video .author .user_name .date, .articles.full_width article.half_image .author .user_name .date, .articles.full_width article.half_quote .author .user_name .date {
            background: #000 none repeat scroll 0 0;
            border-radius: 30px;
            color: #fff;
            font-size: 14px;
            font-weight: bold;
            height: 35px;
            left: 90px;
            line-height: 35px;
            padding: 0 20px;
            position: absolute;
            top: 0; }
            .articles.full_width article.full_background .author .user_name .date a, .articles.full_width article.half_gallery .author .user_name .date a, .articles.full_width article.full_text .author .user_name .date a, .articles.full_width article.half_video .author .user_name .date a, .articles.full_width article.half_image .author .user_name .date a, .articles.full_width article.half_quote .author .user_name .date a {
              color: #fff; }
    .articles.full_width article.full_background {
      min-height: 100vh;
      margin: 36px 0 0; }
      .articles.full_width article.full_background .edit {
        top: auto;
        bottom: 212px; }
        .articles.full_width article.full_background .edit .icon-pencil {
          background: rgba(255, 255, 255, 0.4); }
        .articles.full_width article.full_background .edit:hover .icon-pencil {
          background: #57996b; }
      .articles.full_width article.full_background .text_group {
        color: #fff; }
      .articles.full_width article.full_background h2 {
        margin-bottom: 1rem; }
      .articles.full_width article.full_background .leed {
        margin: 0 0 1rem; }
      .articles.full_width article.full_background .date_ago {
        right: auto;
        top: auto; }
      .articles.full_width article.full_background .author .user_name {
        top: auto;
        bottom: 176px;
        position: absolute; }
        .articles.full_width article.full_background .author .user_name .date {
          background: #fff none repeat scroll 0 0;
          bottom: 259px;
          color: #000;
          left: -12%;
          position: absolute;
          top: auto; }
          .articles.full_width article.full_background .author .user_name .date a {
            color: #000; }

.articles.feed {
  width: 780px;
  margin: 0 auto; }
  .articles.feed article {
    width: 100%;
    height: 400px; }

.articles article {
  background: #fff;
  border: 1px solid transparent;
  box-sizing: border-box;
  padding: 20px;
  margin: 0 0 20px 0;
  width: 220px;
  position: relative; }
  .articles article.advert {
    background: #f4f4f4 !important;
    height: 406px;
    padding: 0;
    margin-bottom: 57px; }
    .articles article.advert img {
      width: 100%;
      display: block; }
  .articles article.extrude.none {
    border: none;
    background: #f4f4f4;
    min-height: 406px;
    margin-bottom: 57px; }
    .articles article.extrude.none .text_group {
      bottom: auto !important;
      left: auto;
      max-height: 335px !important;
      overflow: hidden;
      position: relative;
      width: auto !important; }
      .articles article.extrude.none .text_group h2 {
        color: #000;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 12px; }
      .articles article.extrude.none .text_group .author {
        margin: 1px 0 15px 0; }
        .articles article.extrude.none .text_group .author .user_name .date.landing_link a {
          max-width: 80px;
          text-overflow: ellipsis;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .articles article.extrude.none .text_group .author .user_name .date a {
          background: #989898;
          border: 1px solid #989898;
          color: #fff;
          height: 21px;
          line-height: 18px; }
    .articles article.extrude.none .social {
      bottom: 19px; }
      .articles article.extrude.none .social ul li a span:first-child {
        font-size: 11px; }
    .articles article.extrude.none .date_ago {
      bottom: auto;
      top: 25px;
      color: #989898;
      left: auto;
      right: 20px;
      text-transform: uppercase; }
  .articles article.extrude.full {
    border: none;
    padding: 0;
    height: 406px;
    background: none;
    margin-bottom: 57px; }
    .articles article.extrude.full header {
      left: 0;
      overflow: hidden;
      padding: 0;
      top: 0;
      height: 252px;
      margin: 0 0 21px 0; }
    .articles article.extrude.full .text_group {
      position: relative;
      left: auto;
      bottom: auto !important;
      width: auto !important;
      color: #000; }
      .articles article.extrude.full .text_group > a {
        max-height: 70px;
        overflow: hidden;
        display: block; }
        .articles article.extrude.full .text_group > a br {
          display: none; }
      .articles article.extrude.full .text_group h2 {
        color: #000;
        font-size: 18px;
        line-height: 22px;
        margin: 0 0 2px; }
      .articles article.extrude.full .text_group .leed {
        color: #000; }
      .articles article.extrude.full .text_group .author {
        margin: 1px 0 13px 0; }
        .articles article.extrude.full .text_group .author .user_name .date.landing_link a {
          max-width: 130px;
          text-overflow: ellipsis;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .articles article.extrude.full .text_group .author .user_name .date a {
          background: #989898;
          border: 1px solid #989898;
          color: #fff;
          height: 21px;
          line-height: 18px; }
    .articles article.extrude.full .social {
      bottom: auto;
      position: relative;
      margin: 14px 0 43px 0; }
      .articles article.extrude.full .social ul li {
        color: #989898; }
        .articles article.extrude.full .social ul li a {
          color: #989898; }
          .articles article.extrude.full .social ul li a span:first-child {
            font-size: 11px; }
        .articles article.extrude.full .social ul li span {
          color: #989898; }
        .articles article.extrude.full .social ul li.disabled a {
          cursor: auto; }
    .articles article.extrude.full .date_ago {
      bottom: auto;
      top: 278px;
      color: #989898;
      left: auto;
      right: 0;
      text-transform: uppercase; }
  .articles article .author {
    margin: 0 0 20px; }
  .articles article.landing {
    height: 220px; }
  .articles article .date_ago {
    color: #333333; }
  .articles article .leed {
    color: #333333;
    box-sizing: border-box;
    overflow: hidden;
    line-height: 22px;
    font-size: 14px;
    position: relative; }
  .articles article.half_gallery, .articles article.full_text, .articles article.half_video, .articles article.full_background, .articles article.half_image, .articles article.half_quote {
    width: 100%;
    padding: 0;
    margin: 0 0 45px 0; }
    .articles article.half_gallery h2, .articles article.full_text h2, .articles article.half_video h2, .articles article.full_background h2, .articles article.half_image h2, .articles article.half_quote h2 {
      font-size: 18px;
      height: auto;
      margin: 0 0 11px; }
    .articles article.half_gallery .social, .articles article.full_text .social, .articles article.half_video .social, .articles article.full_background .social, .articles article.half_image .social, .articles article.half_quote .social {
      position: absolute;
      bottom: 18px;
      left: 20px; }
    .articles article.half_gallery .leed, .articles article.full_text .leed, .articles article.half_video .leed, .articles article.full_background .leed, .articles article.half_image .leed, .articles article.half_quote .leed {
      font-size: 14px;
      line-height: 21px;
      bottom: auto;
      margin: 0 0 15px; }
    .articles article.half_gallery .date_ago, .articles article.full_text .date_ago, .articles article.half_video .date_ago, .articles article.full_background .date_ago, .articles article.half_image .date_ago, .articles article.half_quote .date_ago {
      left: 20px;
      bottom: 40px; }
  .articles article.half_video .video_frame {
    background: #f4f4f4;
    height: 70vh !important;
    min-height: 400px;
    padding: 0 !important;
    position: relative;
    width: 940px;
    margin: 0 auto; }
    .articles article.half_video .video_frame iframe {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
  .articles article.half_gallery .text_group {
    width: 30.769%;
    padding: 20px;
    height: 90%;
    box-sizing: border-box;
    float: left; }
  .articles article.half_gallery h2 {
    height: auto;
    margin: 0 0 8px; }
  .articles article.half_gallery .leed {
    position: relative;
    font-size: 14px;
    margin: 0 0 15px 0;
    padding: 0; }
  .articles article.half_gallery .gallery {
    width: 69.23%;
    display: block;
    height: 100%;
    position: relative;
    float: right;
    overflow: hidden; }
    .articles article.half_gallery .gallery .rslides {
      height: 100%; }
    .articles article.half_gallery .gallery p {
      top: 12px;
      position: relative;
      font-size: 10px; }
    .articles article.half_gallery .gallery .rslides_tabs {
      bottom: 43px;
      position: absolute;
      text-align: center;
      width: 100%;
      z-index: 20;
      list-style: none; }
      .articles article.half_gallery .gallery .rslides_tabs li {
        display: inline-block;
        margin: 0 5px 0 0; }
        .articles article.half_gallery .gallery .rslides_tabs li:last-child {
          margin: 0; }
        .articles article.half_gallery .gallery .rslides_tabs li.rslides_here a {
          background: #fff; }
        .articles article.half_gallery .gallery .rslides_tabs li a {
          display: block;
          border: 1px solid #fff;
          border-radius: 20px;
          width: 10px;
          height: 10px;
          color: transparent; }
    .articles article.half_gallery .gallery .rslides_nav {
      /*
          bottom:50%;
          font-size:24px;
          color:#fff;
          background:rgba(0,0,0,0.1);
          border-radius: 0 12px 12px 0;
          margin: 0 0 0 -3px;
          padding: 2px 5px 2px 0;*/
      z-index: 10;
      position: absolute; }
      .articles article.half_gallery .gallery .rslides_nav .icon-arrow-up-thin {
        display: block;
        padding: 5px 0;
        position: relative;
        transform: rotate(-90deg); }
      .articles article.half_gallery .gallery .rslides_nav.prev {
        left: 0; }
      .articles article.half_gallery .gallery .rslides_nav.next {
        right: 0;
        -moz-transform: scaleX(-1);
        -webkit-transform: scaleX(-1);
        -o-transform: scaleX(-1);
        transform: scaleX(-1); }
  .articles article.full_text .text_group {
    width: 100%;
    padding: 20px;
    box-sizing: border-box; }
  .articles article.full_text .leed {
    height: 165px; }
  .articles article.full_background {
    color: #fff;
    /*
      .date_ago{
        color:#fff;
      }
*/
    /*
      .social ul li{
        a{
//          color:#fff;
          &.rubric{
            background:rgba(255,255,255,0.4);
          }
        }
      }
*/
    /*
      a{
        color:#fff;
      }
*/ }
    .articles article.full_background header {
      background: transparent url("/img/les/full_background.jpg") no-repeat scroll center center/cover;
      min-height: 85vh;
      min-height: 661px;
      max-height: 100vh;
      position: relative;
      width: 100%;
      margin-bottom: 1rem; }
      .articles article.full_background header .filter {
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.1); }
    .articles article.full_background .text_group {
      width: 100%;
      padding: 20px;
      box-sizing: border-box;
      z-index: 20;
      position: relative; }
  .articles article.half_image .text_group {
    width: 30.769%;
    padding: 20px;
    height: 90%;
    box-sizing: border-box;
    float: left; }
  .articles article.half_image .image {
    position: relative; }
    .articles article.half_image .image img {
      display: block; }
  .articles article.half_image h2 {
    height: auto;
    margin: 0 0 8px; }
  .articles article.half_image .leed {
    position: relative;
    height: 163px;
    font-size: 14px; }
  .articles article.half_quote .text_group {
    width: 69.230%;
    padding: 20px;
    height: 90%;
    box-sizing: border-box; }
  .articles article.none, .articles article.small, .articles article.full {
    height: 250px; }
    .articles article.none .text_group, .articles article.small .text_group, .articles article.full .text_group {
      position: absolute;
      bottom: 70px;
      max-height: 174px;
      overflow: hidden;
      left: 20px;
      width: 83%; }
    .articles article.none .author .user_pic, .articles article.small .author .user_pic, .articles article.full .author .user_pic {
      display: none; }
      .articles article.none .author .user_pic img, .articles article.small .author .user_pic img, .articles article.full .author .user_pic img {
        width: 35px;
        border-radius: 35px;
        display: block; }
      .articles article.none .author .user_pic .round, .articles article.small .author .user_pic .round, .articles article.full .author .user_pic .round {
        width: 35px;
        height: 35px;
        background: #fff;
        color: #000;
        border-radius: 35px;
        text-align: center;
        line-height: 35px;
        font-weight: bold; }
    .articles article.none .author .user_name strong, .articles article.small .author .user_name strong, .articles article.full .author .user_name strong {
      font-size: 10px;
      font-weight: bold;
      color: #fff;
      position: relative;
      text-transform: uppercase; }
    .articles article.none .author .user_name .date, .articles article.small .author .user_name .date, .articles article.full .author .user_name .date {
      color: #fff;
      font-size: 10px;
      position: relative;
      left: 0; }
      .articles article.none .author .user_name .date a, .articles article.small .author .user_name .date a, .articles article.full .author .user_name .date a {
        border-radius: 23px;
        color: #fff;
        display: block;
        height: 23px;
        padding: 0 10px;
        line-height: 23px;
        border: 1px solid #fff;
        font-weight: bold; }
        .articles article.none .author .user_name .date a:hover, .articles article.small .author .user_name .date a:hover, .articles article.full .author .user_name .date a:hover {
          color: #57996b; }
    .articles article.none h2, .articles article.small h2, .articles article.full h2 {
      font-size: 18px;
      line-height: 22px;
      height: auto;
      margin: 0;
      max-height: 70px;
      overflow: hidden;
      padding: 0;
      position: relative; }
    .articles article.none .social, .articles article.small .social, .articles article.full .social {
      position: absolute;
      bottom: 16px; }
  .articles article.none a {
    color: #333333; }
  .articles article.none .left, .articles article.none .right {
    color: #fff; }
  .articles article.none header {
    display: none; }
  .articles article.none .author .user_pic .round {
    background: #000;
    color: #fff; }
  .articles article.none .author .user_name strong {
    color: #000; }
  .articles article.none .author .user_name .date {
    color: #000; }
    .articles article.none .author .user_name .date a {
      color: #000;
      border: 1px solid #000; }
  .articles article.full a {
    /*color:#fff;*/ }
  .articles article.full h2 {
    color: #fff; }
  .articles article.full .date_ago {
    bottom: 40px;
    color: #fff;
    left: 20px;
    right: auto; }
  .articles article.full .leed {
    color: #fff;
    max-height: 84px; }
  .articles article.full .social ul li {
    color: #fff; }
    .articles article.full .social ul li a {
      color: #fff; }
  .articles article.full header {
    overflow: hidden;
    height: 250px;
    left: -20px;
    overflow: hidden;
    padding: 0 40px 0 0;
    width: 100%; }
    .articles article.full header img {
      margin: 0 0 0 -18%;
      width: 138%; }
    .articles article.full header .filter {
      width: 100%;
      height: 100%;
      position: absolute; }
  .articles article.small header {
    overflow: hidden;
    height: 144px; }
  .articles article.small .date_ago {
    color: #989898; }
  .articles article header {
    position: relative;
    /*
      left:-21px;
      top:-21px;
*/
    width: 220px;
    background: #d2d2d2;
    min-height: 111px; }
    .articles article header img {
      width: 100%;
      display: block; }
  .articles article:hover .edit {
    display: block; }
  .articles article:hover .left, .articles article:hover .right {
    display: block; }
  .articles article .up, .articles article .down {
    border-radius: 30px;
    box-sizing: border-box;
    color: #fff;
    font-size: 10px;
    height: 24px;
    width: 24px;
    line-height: 22px;
    text-align: center;
    padding: 0;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 0; }
  .articles article .down {
    right: 0;
    -moz-transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    transform: rotate(180deg); }
  .articles article .up {
    right: 26px; }
  .articles article .left, .articles article .right {
    border-radius: 30px;
    box-sizing: border-box;
    color: #fff;
    font-size: 10px;
    height: 24px;
    width: 24px;
    line-height: 22px;
    text-align: center;
    padding: 0;
    background: rgba(0, 0, 0, 0.2);
    position: absolute;
    top: 50%;
    display: none; }
  .articles article .left {
    margin: -10px 0 0 -10px;
    left: 0;
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg); }
  .articles article .right {
    margin: -10px -10px 0 0px;
    right: 0;
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  .articles article .edit {
    display: none;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 100; }
    .articles article .edit .icon-pencil {
      background: #57996b none repeat scroll 0 0;
      border-radius: 20px;
      color: #fff;
      display: block;
      font-size: 12px;
      padding: 6px; }
    .articles article .edit:hover nav.sub_menu {
      display: block; }
    .articles article .edit nav.sub_menu {
      right: -52px;
      top: 12px;
      margin: 0;
      display: none;
      border-radius: 3px;
      font-size: 10px; }
      .articles article .edit nav.sub_menu .arrow_up {
        left: auto;
        margin: 13px 0 0;
        position: absolute;
        right: 56px; }
      .articles article .edit nav.sub_menu ul {
        width: 125px;
        padding: 10px 10px 2px 10px;
        margin: 20px 0 0 0;
        border-radius: 3px; }
        .articles article .edit nav.sub_menu ul li {
          height: auto;
          line-height: inherit;
          margin: 0 0 8px;
          color: #fff;
          font-weight: bold; }
          .articles article .edit nav.sub_menu ul li.category_name {
            font-size: 10px;
            color: #fff;
            text-transform: uppercase; }
          .articles article .edit nav.sub_menu ul li.dotted_spacer {
            border-bottom: 1px dotted #fff; }
          .articles article .edit nav.sub_menu ul li.spacer {
            border-bottom: 1px solid #fff; }
            .articles article .edit nav.sub_menu ul li.spacer:last-child {
              display: none; }
          .articles article .edit nav.sub_menu ul li a {
            display: block;
            color: #fff; }
  .articles article h2 {
    font-size: 15px;
    font-weight: bold;
    color: #343434;
    line-height: 24px;
    margin: 0 0 20px;
    height: 75px;
    overflow: hidden; }
  .articles article .social ul {
    font-size: 10px;
    list-style: none; }
    .articles article .social ul li {
      display: inline-block;
      margin: 0 15px 0 0;
      color: #989898; }
      .articles article .social ul li.selected a {
        color: #57996b; }
      .articles article .social ul li.ago {
        float: right;
        font-size: 10px;
        position: relative;
        top: 2px; }
      .articles article .social ul li a {
        color: #989898; }
        .articles article .social ul li a.rubric {
          background: #c1c1c1 none repeat scroll 0 0;
          border-radius: 20px;
          color: #fff;
          font-weight: bold;
          padding: 4px 10px; }
        .articles article .social ul li a span:first-child {
          font-size: 13px;
          position: relative;
          top: 1px;
          margin: 0 3px 0 0; }
        .articles article .social ul li a i {
          display: inline-block;
          height: 14px;
          position: relative;
          top: 2px;
          width: 16px; }
        .articles article .social ul li a:hover {
          color: #57996b; }

@media (max-width: 530px) {
  .articles .info_block {
    margin: 0 auto;
    width: 220px;
    float: none; }
  .articles article.landing[data-block_type="box"].extrude {
    margin: 0 auto;
    width: 220px;
    float: none; } }

@keyframes shadow_play {
  0% {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.18); }
  50% {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4); }
  100% {
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.18); } }

.new_posts_notice {
  background: #fff none repeat scroll 0 0;
  border-radius: 30px;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  color: #000;
  display: none;
  font-size: 10px;
  height: 24px;
  left: 50%;
  line-height: 24px;
  margin: 10px 0 0 -55px;
  padding: 0 10px;
  position: fixed;
  text-align: center;
  top: 0;
  width: 110px;
  z-index: 300;
  animation-name: shadow_play;
  animation-duration: 1s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite; }
  .new_posts_notice.selected {
    display: block; }

.hidden_posts {
  display: none; }

.broadcast_stats {
  min-height: 100px;
  width: 100%;
  background: #333333;
  color: #989898; }
  .broadcast_stats ul {
    position: relative;
    bottom: -5px;
    list-style: none;
    font-weight: bold;
    font-size: 14px; }
    .broadcast_stats ul li {
      display: inline-block;
      width: 220px;
      margin: 0 20px 0 0; }
      .broadcast_stats ul li:last-child {
        margin: 0; }
      .broadcast_stats ul li strong {
        font-size: 36px;
        display: block;
        margin: 0 0 -4px 0; }

.selected_broadcasts {
  background: #f4f4f4;
  width: 100%;
  height: 350px; }
  .selected_broadcasts .left-arrow {
    display: block;
    left: 50%;
    margin: 0 0 0 -531px;
    padding: 5px 0;
    position: absolute;
    transform: rotate(-90deg);
    font-size: 47px;
    top: 115px;
    color: #d9d9d9; }
  .selected_broadcasts .right-arrow {
    display: block;
    margin: 0 -531px 0 0;
    padding: 5px 0;
    position: absolute;
    right: 50%;
    transform: rotate(-90deg);
    font-size: 47px;
    top: 115px;
    color: #d9d9d9; }
  .selected_broadcasts .edit {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 100;
    display: none; }
    .selected_broadcasts .edit:hover nav.sub_menu {
      display: block; }
    .selected_broadcasts .edit .icon-pencil {
      border-radius: 20px;
      color: #fff;
      display: block;
      font-size: 12px;
      padding: 6px;
      background: #57996b; }
    .selected_broadcasts .edit nav.sub_menu {
      border-radius: 3px;
      display: none;
      font-size: 10px;
      margin: 0;
      right: -52px;
      top: 12px; }
      .selected_broadcasts .edit nav.sub_menu .arrow_up {
        left: auto;
        margin: 13px 0 0;
        position: absolute;
        right: 56px; }
      .selected_broadcasts .edit nav.sub_menu ul {
        border-radius: 3px;
        margin: 20px 0 0;
        padding: 10px 10px 2px;
        width: 125px; }
        .selected_broadcasts .edit nav.sub_menu ul li {
          color: #fff;
          font-weight: bold;
          height: auto;
          line-height: inherit;
          margin: 0 0 8px; }
          .selected_broadcasts .edit nav.sub_menu ul li a {
            color: #fff;
            display: block; }
  .selected_broadcasts .wrapper {
    position: relative; }
    .selected_broadcasts .wrapper > h2 {
      font-size: 24px;
      margin: 0 0 16px;
      padding: 37px 0 0 0;
      overflow: visible; }
  .selected_broadcasts ul {
    white-space: nowrap;
    overflow: hidden; }
    .selected_broadcasts ul li {
      white-space: normal;
      position: relative;
      display: inline-block;
      width: 220px;
      vertical-align: top;
      margin-right: 20px; }
      .selected_broadcasts ul li:hover .edit {
        display: block; }
      .selected_broadcasts ul li:last-child {
        margin-right: 0; }
      .selected_broadcasts ul li a img {
        display: block;
        margin: 0 0 19px 0;
        width: 220px; }
      .selected_broadcasts ul li a .event_date {
        font-size: 10px;
        color: #989898;
        margin: 0 0 1px 0; }
        .selected_broadcasts ul li a .event_date strong {
          font-size: 14px; }
      .selected_broadcasts ul li a h2 {
        font-size: 14px;
        font-weight: bold;
        line-height: 22px;
        color: #000; }

.broadcast_editor_page .autocomplete-suggestions {
  font-size: 12px; }
  .broadcast_editor_page .autocomplete-suggestions .autocomplete-suggestion strong {
    color: #fff; }

.broadcast_editor_filter {
  height: 100%; }
  .broadcast_editor_filter .line {
    display: block;
    margin: 0 0 25px 0; }
    .broadcast_editor_filter .line .search_holder {
      display: inline-block;
      position: relative;
      right: auto;
      top: auto; }
    .broadcast_editor_filter .line .search {
      width: 350px; }
    .broadcast_editor_filter .line > strong {
      display: inline-block;
      width: 120px; }
    .broadcast_editor_filter .line ul {
      list-style: none;
      display: inline-block; }
      .broadcast_editor_filter .line ul li {
        display: inline-block;
        margin: 0 20px 0 0; }
        .broadcast_editor_filter .line ul li:last-child {
          margin: 0; }
    .broadcast_editor_filter .line .control__indicator {
      top: 3px; }
    .broadcast_editor_filter .line .control {
      margin: 0;
      font-size: 14px;
      padding-left: 18px; }
    .broadcast_editor_filter .line .rating label:first-child {
      margin: 0; }
    .broadcast_editor_filter .line label {
      text-transform: uppercase; }
  .broadcast_editor_filter .search_holder {
    position: absolute;
    top: 9px;
    right: 0; }
  .broadcast_editor_filter .search {
    line-height: 34px;
    height: 34px;
    box-sizing: border-box;
    font-size: 14px;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    border: 1px solid #d9d9d9;
    color: #000;
    border-radius: 30px;
    padding: 0 10px;
    background: none;
    width: 220px; }
  .broadcast_editor_filter .icon-search {
    color: #d9d9d9;
    position: absolute;
    bottom: 20px;
    right: 10px;
    top: 9px; }
  .broadcast_editor_filter .rating {
    text-transform: uppercase;
    text-align: right; }
    .broadcast_editor_filter .rating .lower {
      position: relative;
      top: 10px; }
    .broadcast_editor_filter .rating label {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      margin: 0 0 0 7px;
      padding-left: 17px;
      padding-top: 1px;
      /*
      &:first-child{
        margin:0 0 0 14px;
      }
      &:last-child{
        margin: 0 0 0 7px;
      }
*/ }
  .broadcast_editor_filter h2 {
    font-size: 24px;
    text-transform: none;
    margin: 17px 0 9px 0; }
  .broadcast_editor_filter nav.sub_header_menu {
    margin: 0;
    height: 253px;
    line-height: normal; }
    .broadcast_editor_filter nav.sub_header_menu .control__indicator {
      top: 3px; }
    .broadcast_editor_filter nav.sub_header_menu ul li {
      margin: 0 21px 0 0; }
      .broadcast_editor_filter nav.sub_header_menu ul li:last-child {
        margin: 0; }
    .broadcast_editor_filter nav.sub_header_menu .wrapper {
      position: relative; }
      .broadcast_editor_filter nav.sub_header_menu .wrapper ul li {
        margin: 0 25px 0 0; }
        .broadcast_editor_filter nav.sub_header_menu .wrapper ul li:last-child {
          margin: 0; }
      .broadcast_editor_filter nav.sub_header_menu .wrapper.second ul li {
        margin: 0 27px 0 0; }
        .broadcast_editor_filter nav.sub_header_menu .wrapper.second ul li:last-child {
          margin: 0; }
      .broadcast_editor_filter nav.sub_header_menu .wrapper ul {
        height: 33px; }
    .broadcast_editor_filter nav.sub_header_menu .more_options {
      position: relative;
      margin: 15px auto 0 auto;
      width: 940px;
      text-transform: none; }
      .broadcast_editor_filter nav.sub_header_menu .more_options .control {
        top: 6px;
        text-transform: uppercase; }
      .broadcast_editor_filter nav.sub_header_menu .more_options ul {
        list-style: none; }
        .broadcast_editor_filter nav.sub_header_menu .more_options ul li {
          display: inline-block;
          margin: 0 41px 0 0; }
          .broadcast_editor_filter nav.sub_header_menu .more_options ul li:last-child {
            margin: 0; }
      .broadcast_editor_filter nav.sub_header_menu .more_options .search_holder {
        position: relative;
        top: auto;
        right: auto;
        display: inline-block; }
    .broadcast_editor_filter nav.sub_header_menu .control {
      margin: 0;
      top: 10px; }

.broadcast_page {
  /*
  .broadcast_card.opened{
    display: inline;
  }
*/ }
  .broadcast_page .wrapper.load_more {
    width: 940px; }

.broadcast_card {
  position: relative;
  clear: both;
  margin: 0 0 1px 0;
  /*
  .holder h2{
    max-width: 795px;
  }
*/ }
  .broadcast_card .gallery.stripped .rslides_nav {
    margin: -44px 0 0 0; }
  .broadcast_card .gallery .gallery_frame .edit {
    margin: 0 -150px 0 0 !important;
    width: 300px;
    text-align: center; }
    .broadcast_card .gallery .gallery_frame .edit a {
      margin: 0 3px 0 0; }
      .broadcast_card .gallery .gallery_frame .edit a:last-child {
        margin: 0; }
  .broadcast_card .selectboxit-container .selectboxit-btn {
    background: none; }
    .broadcast_card .selectboxit-container .selectboxit-btn:hover, .broadcast_card .selectboxit-container .selectboxit-btn:focus {
      background: none; }
  .broadcast_card .selectboxit-container .selectboxit {
    width: 180px;
    margin: -3px 0 0 5px; }
  .broadcast_card .selectboxit-container .selectboxit-options {
    background: #fff;
    border-radius: 7px;
    padding: 21px 0 13px;
    box-shadow: 1px 1px 2px 1px rgba(0, 0, 0, 0.2); }
    .broadcast_card .selectboxit-container .selectboxit-options .selectboxit-option-anchor {
      font-size: 12px !important;
      margin: 0 0 0px;
      height: 34px;
      line-height: 34px; }
      .broadcast_card .selectboxit-container .selectboxit-options .selectboxit-option-anchor:hover {
        background: #333333;
        color: #fff; }
  .broadcast_card .delete_button.selected {
    background: #57996b; }
  .broadcast_card ul.modify {
    margin: 0 0 0 0;
    right: 0;
    left: 0;
    top: 0.5rem;
    text-align: center; }
    .broadcast_card ul.modify li {
      margin: 0 3px 0 0; }
  .broadcast_card .control input:checked ~ .control__indicator.photo, .broadcast_card .control input:checked ~ .control__indicator.video, .broadcast_card .control input:checked ~ .control__indicator.text, .broadcast_card .control input:checked ~ .control__indicator.corrector {
    border: 1px solid #000; }
  .broadcast_card .control__indicator.photo {
    background: #488ccb;
    border: 1px solid #488ccb; }
  .broadcast_card .control__indicator.video {
    background: #ff4e50;
    border: 1px solid #ff4e50; }
  .broadcast_card .control__indicator.text {
    background: #9185be;
    border: 1px solid #9185be; }
  .broadcast_card .control__indicator.corrector {
    background: #00bac0;
    border: 1px solid #00bac0; }
  .broadcast_card .icon-location {
    margin: 0 5px 0 1px;
    cursor: pointer; }
  .broadcast_card .load-gallery,
  .broadcast_card .load-image-form,
  .broadcast_card .load-gallery-form {
    display: none; }
  .broadcast_card.in_edit.someone_else ul.modify > li > a.edit_button {
    background: #ff4e50; }
  .broadcast_card.in_edit ul.modify > li > a.edit_button {
    background: #ff4e50; }
    .broadcast_card.in_edit ul.modify > li > a.edit_button .icon-pencil:before {
      content: "\e966" !important; }
  .broadcast_card.in_edit.opened .external_wrapper {
    display: block; }
  .broadcast_card ul.modify > li > a.open_button .icon-views {
    display: none; }
  .broadcast_card ul.modify > li > a.open_button .icon-hidden {
    display: block; }
  .broadcast_card.opened {
    margin: 0;
    height: 100%; }
    .broadcast_card.opened ul.modify > li > a.open_button {
      background: #57996b; }
      .broadcast_card.opened ul.modify > li > a.open_button .icon-views {
        display: block; }
      .broadcast_card.opened ul.modify > li > a.open_button .icon-hidden {
        display: none; }
    .broadcast_card.opened article {
      display: block; }
  .broadcast_card article {
    margin: 0 !important;
    padding: 0 !important;
    display: none; }
    .broadcast_card article.full_background:not(.jgnt_move_down) .social ul li a.rubric {
      background: rgba(0, 0, 0, 0.4); }
    .broadcast_card article.full_background:not(.jgnt_move_down) ul.tags_list li a {
      background: rgba(0, 0, 0, 0.4);
      color: #fff !important; }
    .broadcast_card article.full_background .text_group {
      height: auto !important; }
      .broadcast_card article.full_background .text_group h2 {
        position: relative;
        bottom: auto;
        height: auto;
        margin: 0; }
      .broadcast_card article.full_background .text_group .author .user_name {
        position: relative;
        bottom: auto; }
      .broadcast_card article.full_background .text_group .leed {
        position: relative;
        height: auto; }
    .broadcast_card article ul.tags_list {
      bottom: 13px;
      height: 24px;
      overflow: hidden;
      text-align: right;
      width: 690px; }
      .broadcast_card article ul.tags_list li {
        vertical-align: top;
        margin: 0 0 20px 7px; }
    .broadcast_card article .date_ago {
      font-size: 10px; }
      .broadcast_card article .date_ago strong {
        font-size: 14px; }
      .broadcast_card article .date_ago .local {
        margin: -3px 0 0;
        font-size: 10px; }
    .broadcast_card article .gallery li p {
      color: #989898;
      font-size: 10px !important; }
    .broadcast_card article .image p {
      margin: 0.5rem 0 0 0;
      color: #989898;
      font-size: 10px; }
    .broadcast_card article .text_group h2 {
      font-size: 24px;
      margin: 0 0 27px;
      overflow: visible; }
    .broadcast_card article .text_group .leed a {
      color: #57996b !important; }
    .broadcast_card article .text_group .author {
      display: block !important;
      margin: 0 0 1rem !important; }
      .broadcast_card article .text_group .author .user_name .location {
        color: #000;
        margin: 0 24px 0 20px; }
        .broadcast_card article .text_group .author .user_name .location .icon-location {
          margin: 0 0 0 0; }
      .broadcast_card article .text_group .author .user_name a:hover {
        color: #57996b !important; }
      .broadcast_card article .text_group .author .event_date {
        display: inline-block; }
        .broadcast_card article .text_group .author .event_date .local {
          font-size: 10px;
          display: inline-block;
          margin: 0 0 0 4px; }
  .broadcast_card .full_background header .modify {
    top: 20px; }
  .broadcast_card .full_background header .filter {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
  .broadcast_card .image .modify {
    top: 20px;
    right: 20px; }
  .broadcast_card .author_comments,
  .broadcast_card .editor_comments {
    margin: 0 0 13px 0;
    vertical-align: top; }
  .broadcast_card .holder {
    position: relative;
    box-sizing: border-box;
    padding: 20px 0;
    background: #f4f4f4; }
    .broadcast_card .holder .left {
      display: inline-block;
      width: 540px;
      margin: 0 0 13px 0; }
      .broadcast_card .holder .left .status strong {
        text-transform: uppercase; }
    .broadcast_card .holder .right {
      display: inline-block;
      width: 240px;
      margin: 0 0 13px 0; }
  .broadcast_card .status_float {
    position: absolute;
    width: 165px;
    height: 35px;
    top: 0;
    font-weight: bold;
    right: 0;
    line-height: 35px;
    color: #fff;
    background-color: #989898;
    text-align: center; }
    .broadcast_card .status_float:hover {
      color: #fff;
      background-color: #7f7f7f; }
    .broadcast_card .status_float.red {
      background-color: #ff4e50; }
      .broadcast_card .status_float.red:hover {
        background-color: #ff1b1e; }
    .broadcast_card .status_float.yellow {
      background-color: #ffd75e; }
      .broadcast_card .status_float.yellow:hover {
        background-color: #ffca2b; }
    .broadcast_card .status_float.accent_color, .broadcast_card .status_float.save_button {
      background-color: #57996b;
      color: #fff !important;
      border-radius: 30px; }
      .broadcast_card .status_float.accent_color:hover, .broadcast_card .status_float.save_button:hover {
        background-color: #457854; }
  .broadcast_card .number {
    text-align: center;
    background: #333333 none repeat scroll 0 0;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-weight: bold;
    left: -98px;
    padding: 0 10px;
    position: absolute;
    width: 79px;
    height: 34px;
    line-height: 34px; }
  .broadcast_card .modify {
    display: block; }
  .broadcast_card .modify.main {
    display: block;
    left: -112px;
    right: auto;
    top: 48px;
    width: 93px;
    text-align: right; }
    .broadcast_card .modify.main li {
      margin: 0 0 5px 12px; }
      .broadcast_card .modify.main li a {
        width: 34px;
        height: 34px; }
        .broadcast_card .modify.main li a div {
          font-size: 14px;
          line-height: 34px; }
      .broadcast_card .modify.main li.delete_button {
        position: absolute;
        right: -958px;
        top: 41px; }
        .broadcast_card .modify.main li.delete_button a {
          background: #d9d9d9; }
          .broadcast_card .modify.main li.delete_button a.selected {
            background: #57996b; }
  .broadcast_card h2 {
    margin: 0px 0 13px 0; }
  .broadcast_card a {
    color: #333333; }
  .broadcast_card .rating label,
  .broadcast_card .checks label {
    display: inline-block;
    font-size: 14px;
    padding-left: 18px;
    margin: 0 9px 0 0;
    font-weight: bold; }
    .broadcast_card .rating label:last-child,
    .broadcast_card .checks label:last-child {
      margin: 0; }
  .broadcast_card .rating {
    text-transform: uppercase;
    text-align: right; }
    .broadcast_card .rating label {
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      margin: 0 0 0 7px;
      padding-left: 17px;
      padding-top: 1px; }
      .broadcast_card .rating label:first-child {
        margin: 0 0 0 14px; }
      .broadcast_card .rating label:last-child {
        margin: 0 0 0 7px; }
  .broadcast_card .row {
    width: 240px;
    margin: 0 20px 0 0;
    display: inline-block;
    vertical-align: top; }
    .broadcast_card .row > div {
      white-space: nowrap;
      margin: 0 0 13px 0; }
    .broadcast_card .row.long {
      width: 300px;
      margin: 0 0 0 0; }
      .broadcast_card .row.long.to_right {
        text-align: right; }
  .broadcast_card .external_wrapper {
    display: none; }
  .broadcast_card .input_field {
    display: inline-block;
    min-width: 200px;
    min-height: 10px;
    vertical-align: top; }
  .broadcast_card .move_left {
    margin: 30px 20px 0 0;
    float: left;
    width: 460px; }
  .broadcast_card .move_right {
    margin: 30px 0 0 0;
    float: left;
    width: 220px; }
    .broadcast_card .move_right.space {
      margin: 30px 20px 0 0; }
  .broadcast_card .previews li {
    float: left; }
  .broadcast_card textarea.tags {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #f4f4f4;
    height: 114px;
    margin: 0 0 20px;
    resize: none; }
  .broadcast_card .wrapper {
    position: relative; }

article.landing {
  position: relative; }
  article.landing[data-block_type='wide'] {
    display: block;
    width: 700px;
    height: 220px;
    background: #57996b; }
    article.landing[data-block_type='wide'] header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
    article.landing[data-block_type='wide'] .filter {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  article.landing[data-block_type='box'] {
    display: block;
    width: 220px;
    height: 220px;
    padding: 0;
    align-items: center;
    text-align: center;
    position: relative;
    background: #57996b; }
    article.landing[data-block_type='box'] .social {
      display: none; }
    article.landing[data-block_type='box'].extrude {
      height: 387px;
      background: none; }
      article.landing[data-block_type='box'].extrude .social {
        display: block;
        list-style: none;
        color: #989898;
        font-size: 10px;
        margin: 0 0 12px 0; }
        article.landing[data-block_type='box'].extrude .social .icon-list {
          font-size: 12px;
          top: 2px;
          position: relative; }
        article.landing[data-block_type='box'].extrude .social .icon-views {
          font-size: 13px;
          position: relative;
          top: 2px; }
        article.landing[data-block_type='box'].extrude .social li {
          display: inline-block;
          margin: 0 14px 0 0; }
      article.landing[data-block_type='box'].extrude header {
        position: relative;
        width: 220px;
        height: 220px;
        background: none;
        left: 0;
        padding: 0;
        margin: 0 0 17px 0;
        background: #57996b; }
      article.landing[data-block_type='box'].extrude a.frame {
        color: #000;
        display: block;
        padding: 0;
        position: relative;
        text-align: left;
        height: auto;
        overflow: hidden;
        max-height: 120px; }
        article.landing[data-block_type='box'].extrude a.frame h2 {
          color: #000;
          margin: 0 0 3px 0; }
        article.landing[data-block_type='box'].extrude a.frame p {
          color: #000;
          font-size: 14px;
          font-weight: normal;
          line-height: 23px; }
    article.landing[data-block_type='box']:hover .screen {
      opacity: 1; }
    article.landing[data-block_type='box'] .filter {
      position: absolute;
      width: 100%;
      height: 100%; }
    article.landing[data-block_type='box'] header {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      left: -1px;
      padding: 0 2px 2px 0;
      position: absolute;
      top: -1px; }
    article.landing[data-block_type='box'] a.frame {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      margin: 0;
      position: absolute;
      text-align: center;
      width: 100%;
      z-index: 10;
      box-sizing: border-box;
      padding: 20px; }
      article.landing[data-block_type='box'] a.frame h2 {
        height: auto;
        color: #fff;
        margin: 0;
        font-size: 18px;
        font-weight: bold; }
      article.landing[data-block_type='box'] a.frame p {
        color: #fff;
        font-size: 10px;
        font-weight: bold; }

nav.landing_navigation {
  margin: 55px 0 0 0; }

ul.articles_setup {
  list-style: none;
  width: 940px;
  margin: 23px auto 0 auto; }
  ul.articles_setup > li {
    width: 460px;
    float: left;
    min-height: 250px;
    background: #f4f4f4;
    margin: 0 0 23px 0;
    position: relative; }
    ul.articles_setup > li .number {
      position: absolute;
      height: 32px;
      line-height: 32px;
      padding: 0 10px;
      background: #333333;
      color: #fff;
      font-weight: bold;
      top: 0;
      left: 0; }
    ul.articles_setup > li p {
      margin: 0 0 13px 0; }
    ul.articles_setup > li .status_list,
    ul.articles_setup > li .category_list {
      width: 130px;
      display: inline-block; }
    ul.articles_setup > li .info_block {
      float: left;
      width: 240px;
      box-sizing: border-box;
      padding: 50px 20px;
      position: relative; }
    ul.articles_setup > li .publish {
      bottom: 0;
      position: absolute;
      right: 20px; }
      ul.articles_setup > li .publish:hover {
        color: #fff; }
    ul.articles_setup > li .article_holder {
      float: right;
      width: 220px; }
    ul.articles_setup > li:nth-child(even) {
      float: right; }
    ul.articles_setup > li .selectboxit-container {
      margin: 0 0 0px 0;
      height: 22px; }
      ul.articles_setup > li .selectboxit-container .selectboxit {
        border-radius: 0; }
      ul.articles_setup > li .selectboxit-container .selectboxit-option-icon-container {
        display: none; }
      ul.articles_setup > li .selectboxit-container .selectboxit-btn {
        border: none;
        background: #fff; }
      ul.articles_setup > li .selectboxit-container .selectboxit-options {
        border: none;
        background: #fff;
        border: 0 none;
        border-radius: 0;
        width: 100%;
        margin: -4px 0 0 0; }
        ul.articles_setup > li .selectboxit-container .selectboxit-options span, ul.articles_setup > li .selectboxit-container .selectboxit-options a {
          height: 20px;
          line-height: 20px;
          color: #333333;
          clear: both; }
      ul.articles_setup > li .selectboxit-container .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
        background: none;
        color: #000; }
      ul.articles_setup > li .selectboxit-container span.selectboxit-btn {
        height: 22px;
        line-height: 22px; }
      ul.articles_setup > li .selectboxit-container span.selectboxit-text {
        max-width: 110px !important;
        height: 22px;
        line-height: 22px; }
    ul.articles_setup > li .selectboxit .selectboxit-arrow-container .selectboxit-arrow {
      bottom: auto;
      top: 5px;
      color: #333333;
      font-size: 12px;
      left: 10px;
      margin: 0 auto;
      position: absolute;
      right: 0; }

header.short_landing_header {
  margin: 55px 0 0 0;
  height: 164px;
  width: 100%;
  padding: 0;
  background: #989898;
  position: relative;
  z-index: 10; }
  header.short_landing_header .filter {
    position: absolute;
    width: 100%;
    height: 100%; }
  header.short_landing_header .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%; }
  header.short_landing_header h1 {
    font-size: 48px;
    text-align: center;
    width: 100%;
    color: #fff; }

section.landing_about_page {
  display: none; }
  section.landing_about_page.selected {
    display: block; }
  section.landing_about_page .manifest {
    margin: 90px 0 0 0;
    font-size: 24px;
    font-weight: bold;
    text-align: left; }
    section.landing_about_page .manifest.media_manifest {
      margin: 0; }
  section.landing_about_page .info {
    margin: 75px 0 0 0;
    font-size: 14px; }
  section.landing_about_page h2 {
    font-size: 18px;
    font-weight: bold; }
  section.landing_about_page .contact_us {
    margin: 100px 0 0 0; }
    section.landing_about_page .contact_us #small_map {
      width: 100%;
      height: 220px;
      margin: 20px 0 65px 0;
      float: left;
      background: #f4f4f4; }
    section.landing_about_page .contact_us h2 {
      margin: 0 0 10px 0; }
    section.landing_about_page .contact_us .left {
      float: left; }
    section.landing_about_page .contact_us .right {
      float: right; }
    section.landing_about_page .contact_us label {
      font-weight: bold;
      display: block;
      margin: 0 0 3px 0; }
    section.landing_about_page .contact_us p {
      margin: 0 0 13px 0; }
    section.landing_about_page .contact_us .links {
      list-style: none; }
      section.landing_about_page .contact_us .links li {
        display: inline-block;
        margin: 0 10px 0 0; }
        section.landing_about_page .contact_us .links li:last-child {
          margin: 0; }
        section.landing_about_page .contact_us .links li a {
          display: block;
          color: #333333;
          font-size: 18px; }

section.landing_page {
  display: none; }
  section.landing_page.selected {
    display: block;
    height: 100%; }
  section.landing_page nav.sub_header_menu {
    margin: 0; }
  section.landing_page .top_part {
    left: 10px;
    margin: 40px auto 0;
    padding: 0;
    width: 960px;
    position: relative; }
    section.landing_page .top_part article {
      margin: 0 20px 20px 0; }
      section.landing_page .top_part article.extrude {
        margin: 0 20px 57px 0; }
      section.landing_page .top_part article[data-format='wide'] {
        margin: 0 20px 20px 0; }
        section.landing_page .top_part article[data-format='wide'].extrude {
          margin: 0 20px 57px 0; }
  section.landing_page > header {
    margin: 55px 0 0 0;
    height: 507px;
    width: 100%;
    background: #333333;
    position: relative; }
    section.landing_page > header.media_sub_header {
      margin: 0;
      height: auto;
      background: none;
      position: absolute;
      top: 66px;
      z-index: 10; }
      section.landing_page > header.media_sub_header .edit {
        top: 0; }
      section.landing_page > header.media_sub_header ul.links {
        top: 0; }
        section.landing_page > header.media_sub_header ul.links a {
          color: #333333; }
        section.landing_page > header.media_sub_header ul.links .landing_subscription .sub {
          border: 1px solid #333333; }
    section.landing_page > header .filter {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      position: absolute; }
    section.landing_page > header .wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%; }
    section.landing_page > header .edit {
      position: absolute;
      right: 0;
      bottom: 20px; }
      section.landing_page > header .edit .button {
        height: 34px;
        line-height: 34px;
        border-radius: 30px;
        margin: 0 10px 0 0;
        display: inline-block;
        padding: 0 17px;
        background: #333333;
        color: #fff;
        font-weight: bold; }
        section.landing_page > header .edit .button:last-child {
          margin: 0; }
    section.landing_page > header ul.links {
      position: absolute;
      top: 12px;
      right: 0;
      list-style: none; }
      section.landing_page > header ul.links li {
        display: inline-block;
        margin: 0 10px 0 0; }
        section.landing_page > header ul.links li a {
          display: block;
          color: #fff;
          font-size: 18px; }
          section.landing_page > header ul.links li a.subscription_button {
            font-size: 14px; }
    section.landing_page > header h1 {
      font-size: 48px;
      text-align: center;
      width: 100%;
      color: #fff; }
    section.landing_page > header p {
      width: 100%;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      color: #fff; }

.landing_setup .video_frame {
  height: 0;
  padding-bottom: 56.25%;
  padding-top: 25px;
  position: relative;
  background: #f4f4f4 url("/img/les/upload_video.png") no-repeat scroll center center; }
  .landing_setup .video_frame iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

.landing_setup .load-image {
  display: none; }

.landing_setup textarea.manifest {
  height: 100px;
  resize: none; }

.landing_setup textarea.info {
  height: 445px;
  resize: none; }

.landing_setup ul.modify {
  display: block;
  right: 0; }

.landing_setup.broadcast_post .sub_header_menu {
  margin: 55px 0 43px 0; }

.landing_setup.broadcast_post h1 {
  margin: 0; }

.landing_setup.broadcast_post .date_block {
  margin: 0 0 32px 0; }

.landing_setup.broadcast_post .fields {
  position: relative; }
  .landing_setup.broadcast_post .fields .error {
    margin: 0; }
  .landing_setup.broadcast_post .fields textarea.short {
    height: 55px;
    resize: none; }
  .landing_setup.broadcast_post .fields textarea.tall {
    height: 168px;
    resize: none; }
  .landing_setup.broadcast_post .fields textarea.white {
    background: transparent none repeat scroll 0 0;
    border: 1px solid #f4f4f4;
    height: 56px;
    resize: none; }

.landing_setup.broadcast_post .choose_rubric {
  width: 220px; }

.landing_setup.broadcast_post div.block {
  width: 100%; }

.landing_setup.broadcast_post .wrapper > .left {
  width: 460px; }

.landing_setup.broadcast_post .wrapper > .right {
  width: 460px;
  margin: 0; }

.landing_setup #landing_profile .wrapper.hipster_block,
.landing_setup #landing_profile .wrapper.media_block {
  display: none; }

.landing_setup #landing_profile[data-template='hipster'] .wrapper.hipster_block {
  display: block; }

.landing_setup #landing_profile[data-template='hipster'] .wrapper.media_block {
  display: none; }

.landing_setup #landing_profile[data-template='media'] .wrapper.hipster_block {
  display: none; }

.landing_setup #landing_profile[data-template='media'] .wrapper.media_block {
  display: block;
  margin: -13px auto 0 auto; }
  .landing_setup #landing_profile[data-template='media'] .wrapper.media_block h1 {
    margin: 30px 0 13px 0; }

.landing_setup #landing_about .wrapper > .left {
  margin: -23px 0 0 0; }

.landing_setup #landing_about .editors_list {
  width: 100%; }
  .landing_setup #landing_about .editors_list .user_block {
    width: 220px;
    margin: 0 20px 20px 0;
    float: left; }
    .landing_setup #landing_about .editors_list .user_block:nth-child(3n+3) {
      margin: 0 0 20px 0; }

.landing_setup #landing_about .editors h1 {
  margin: 0 0 20px 0; }

.landing_setup #landing_about .editors .left {
  width: 700px;
  clear: none; }

.landing_setup #landing_about .editors .right {
  width: 220px;
  clear: none; }

.landing_setup #landing_about .editors .search_group {
  position: relative; }
  .landing_setup #landing_about .editors .search_group .icon-search {
    bottom: 20px;
    position: absolute;
    right: 10px; }

.landing_setup .wrapper > .right {
  margin: -35px 0 0 0; }

.landing_setup .inline {
  width: 480px; }
  .landing_setup .inline input {
    display: inline-block;
    margin: 0 20px 10px 0; }
    .landing_setup .inline input.half {
      width: 220px; }
    .landing_setup .inline input.third {
      width: 140px; }
    .landing_setup .inline input.full {
      width: 460px; }

.landing_setup .left_right .additional_rubrics {
  display: none; }
  .landing_setup .left_right .additional_rubrics.selected {
    display: block; }

.landing_setup .left_right input {
  width: 220px;
  float: left; }
  .landing_setup .left_right input:nth-child(even) {
    margin: 0 0 10px 20px; }

.landing_setup .left_right .fixed_field {
  box-sizing: border-box;
  float: left;
  padding: 0 10px;
  width: 220px;
  line-height: 35px; }
  .landing_setup .left_right .fixed_field:nth-child(even) {
    margin: 0 0 10px 20px; }

.landing_setup .left_right textarea {
  width: 220px;
  float: left;
  resize: none;
  height: 100px; }

.landing_setup .left_right select {
  float: right;
  width: 220px; }

.landing_setup .add, .landing_setup .hide {
  font-size: 10px;
  color: #333333; }

.landing_setup .add {
  float: left;
  display: block;
  margin: 5px 0 0 0; }

.landing_setup .hide {
  display: block;
  font-weight: bold;
  float: right;
  clear: both; }
  .landing_setup .hide .off {
    display: block; }
  .landing_setup .hide .on {
    display: none; }
  .landing_setup .hide.selected .off {
    display: none; }
  .landing_setup .hide.selected .on {
    display: block; }

.landing_setup .left {
  float: left;
  position: relative; }

.landing_setup .right {
  float: right;
  position: relative; }

.landing_setup h1 {
  font-size: 24px;
  margin: 30px 0 40px 0; }

.landing_setup div.block {
  margin: 0 0 30px 0;
  width: 460px;
  display: block;
  clear: both; }
  .landing_setup div.block.main_info {
    margin: 0 0 30px 0; }
  .landing_setup div.block.color_info {
    margin: 0 0 28px 0; }
  .landing_setup div.block.visible_block {
    margin: 0 0 54px 0; }
  .landing_setup div.block > .small {
    margin: 0 0 20px 0; }
  .landing_setup div.block.folder_block {
    margin: 0 0 0px 0; }
    .landing_setup div.block.folder_block.news {
      margin: 0 0 11px 0; }
  .landing_setup div.block.logo_place .left {
    width: 220px; }
    .landing_setup div.block.logo_place .left .logo_holder {
      margin: 0 auto;
      width: 113px;
      position: relative; }
      .landing_setup div.block.logo_place .left .logo_holder .logo_image {
        display: block;
        position: relative;
        width: 113px;
        height: 113px;
        background: #f4f4f4;
        margin: 15px 0 0 0;
        overflow: hidden;
        border-radius: 5px; }
        .landing_setup div.block.logo_place .left .logo_holder .logo_image img {
          display: block;
          width: 100%; }
  .landing_setup div.block.logo_place .right {
    width: 220px;
    margin: 2px 0 0px 0; }
    .landing_setup div.block.logo_place .right .holder {
      margin: 14px 0 0 0; }
  .landing_setup div.block.full_width {
    width: 100%;
    margin: 30px 0 20px 0;
    float: left; }
    .landing_setup div.block.full_width input[type='text'] {
      width: 220px;
      float: left;
      margin: 0 20px 10px 0; }
      .landing_setup div.block.full_width input[type='text']:nth-child(4n+5) {
        margin: 0 0 10px 0; }
    .landing_setup div.block.full_width .left {
      width: 700px; }
    .landing_setup div.block.full_width .right {
      width: 220px; }

.landing_setup .holder {
  margin: 10px 0 0 0;
  clear: both;
  background: #e5e5e5; }
  .landing_setup .holder .wrapper div:last-child {
    margin: 0 0 0; }

.landing_setup .rename_block > span {
  float: left;
  width: 220px; }

.landing_setup .rename_block input {
  float: right;
  width: 220px;
  clear: right; }

.landing_setup .sub_header_menu {
  border-bottom: 1px solid #f4f4f4; }
  .landing_setup .sub_header_menu .dash {
    border-top: 1px solid #000;
    height: 10px;
    margin: 0 8px 0 -18px;
    position: relative;
    top: 27px;
    vertical-align: top;
    width: 14px; }
  .landing_setup .sub_header_menu .selected {
    font-weight: bold; }
  .landing_setup .sub_header_menu a {
    color: #333333; }

.landing_setup section.tab {
  display: none; }
  .landing_setup section.tab.selected {
    display: block; }

.landing_setup ul.templates {
  list-style: none;
  margin: 0 0 55px 0; }
  .landing_setup ul.templates li {
    display: inline-block;
    width: 460px;
    vertical-align: top; }
    .landing_setup ul.templates li a.selected .frame {
      opacity: 1; }
    .landing_setup ul.templates li .image {
      float: left;
      position: relative; }
      .landing_setup ul.templates li .image .frame {
        width: 100%;
        height: 100%;
        border: 3px solid #57996b;
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        opacity: 0;
        transition: 0.2s ease-in opacity; }
      .landing_setup ul.templates li .image img {
        display: block; }
    .landing_setup ul.templates li .text {
      float: right;
      width: 220px;
      font-size: 14px;
      color: #333333; }
      .landing_setup ul.templates li .text strong {
        margin: 0 0 25px 0;
        display: block; }
      .landing_setup ul.templates li .text p {
        margin: 0 0 20px 0; }

.landing_setup .next_page {
  clear: both;
  float: right;
  display: block;
  margin: 0 0 30px 0; }
  .landing_setup .next_page:hover {
    color: #fff; }

aside.chat {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 300px;
  z-index: 120; }
  aside.chat.closed .messages {
    background: #fff none repeat scroll 0 0;
    box-sizing: border-box;
    height: 340px;
    margin: 0 0 -408px;
    padding: 0 10px;
    position: relative; }
  aside.chat.closed .message_field {
    opacity: 0; }
  aside.chat.closed header {
    opacity: 0;
    position: relative;
    z-index: 10; }
  aside.chat.closed footer {
    border-top: 1px solid #989898;
    border-left: 1px solid #989898;
    border-right: 1px solid #989898;
    border-radius: 10px 10px 0px 0px;
    right: 1px;
    position: relative; }
  aside.chat header {
    width: 100%;
    color: #fff;
    font-weight: bold;
    text-align: left;
    box-sizing: border-box;
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    background: #333333;
    transition: 0.4s ease-out all;
    cursor: pointer; }
    aside.chat header .icon-arrow-up {
      font-size: 12px; }
  aside.chat .toggle {
    border-left: 1px solid #989898;
    border-right: 1px solid #989898; }
  aside.chat .editors_list {
    height: 240px;
    background: #f4f4f4; }
  aside.chat .messages {
    height: 340px;
    background: #fff;
    box-sizing: border-box;
    padding: 0 10px;
    overflow: auto;
    transition: 0.4s ease-out all; }
  aside.chat .author {
    display: table;
    margin: 0 0 10px 0; }
    aside.chat .author.me {
      text-align: right; }
      aside.chat .author.me .user_pic {
        display: none; }
      aside.chat .author.me .user_name {
        float: right; }
        aside.chat .author.me .user_name strong {
          display: none; }
        aside.chat .author.me .user_name .date {
          float: right; }
        aside.chat .author.me .user_name .message {
          float: right;
          clear: right; }
    aside.chat .author .user_pic {
      display: table-cell;
      float: none;
      width: 45px;
      vertical-align: top; }
    aside.chat .author .user_name {
      display: table-cell; }
      aside.chat .author .user_name strong {
        font-size: 10px;
        vertical-align: top;
        margin: 0 7px 0 0;
        float: left; }
      aside.chat .author .user_name .date {
        vertical-align: top;
        display: inline-block;
        font-size: 10px;
        clear: right;
        float: left; }
    aside.chat .author .message {
      background: #f4f4f4;
      border-radius: 10px;
      font-size: 12px;
      padding: 2px 6px;
      position: relative;
      right: 4px;
      clear: left;
      float: left; }
  aside.chat .message_field {
    margin: 0;
    border: none;
    border-top: 1px solid #f4f4f4;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    box-sizing: border-box;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    resize: none;
    width: 100%;
    transition: 0.4s ease-out all; }
  aside.chat footer {
    line-height: 34px;
    height: 34px;
    width: 100%;
    transition: 0.4s ease-out all;
    background: #f4f4f4;
    text-align: center;
    font-weight: bold; }

#about_me_tab .selectboxit-container span, #about_me_tab .selectboxit-container .selectboxit-options a {
  display: inline-block;
  white-space: nowrap; }

#about_me_tab #birthdate_daySelectBoxIt {
  width: 68px;
  text-overflow: ellipsis;
  margin-right: 5px; }

#about_me_tab #birthdate_monthSelectBoxIt {
  width: 80px;
  text-overflow: ellipsis;
  margin-right: 5px; }

#about_me_tab #birthdate_daySelectBoxItText {
  max-width: 40px; }

#about_me_tab #birthdate_monthSelectBoxItText {
  max-width: 50px !important; }

#about_me_tab #birthdate_yearSelectBoxItText {
  display: inline;
  float: none;
  margin: 0 0 0 5px;
  max-width: 35px; }

#about_me_tab #birthdate_yearSelectBoxIt {
  width: 60px;
  text-overflow: ellipsis;
  overflow: hidden; }

#about_me_tab #rubric_idSelectBoxItContainer .selectboxit-options, #about_me_tab #rubric_idSelectBoxItContainer .selectboxit-btn {
  background: #f4f4f4; }

#about_me_tab .selectboxit .selectboxit-option-icon-container {
  margin-left: 0px; }

.veil.profile_subscribers form {
  width: 500px;
  height: 660px;
  padding: 0; }
  .veil.profile_subscribers form .left {
    width: 500px;
    height: 100%;
    float: left; }
    .veil.profile_subscribers form .left > nav {
      border-bottom: 1px solid #f4f4f4;
      padding: 0 20px;
      box-sizing: border-box; }
      .veil.profile_subscribers form .left > nav ul {
        height: 55px; }
        .veil.profile_subscribers form .left > nav ul li {
          display: inline-block;
          height: 55px;
          line-height: 55px;
          margin: 0 24px 0 0;
          position: relative; }
          .veil.profile_subscribers form .left > nav ul li a {
            font-weight: bold;
            font-size: 14px;
            color: #333333; }
            .veil.profile_subscribers form .left > nav ul li a.selected:after {
              content: " ";
              border-bottom: 1px solid #333333;
              position: absolute;
              bottom: 19px;
              width: 100%;
              left: 0; }
  .veil.profile_subscribers form .right {
    width: 160px;
    height: 100%;
    background: #f4f4f4;
    float: right; }
  .veil.profile_subscribers form .subscription_button {
    float: right; }
  .veil.profile_subscribers form h2 {
    color: #333333; }
  .veil.profile_subscribers form .users_holder {
    height: 560px;
    padding: 10px 20px;
    box-sizing: border-box;
    overflow: auto;
    display: none; }
    .veil.profile_subscribers form .users_holder.selected {
      display: block; }
    .veil.profile_subscribers form .users_holder > ul {
      list-style: none; }
      .veil.profile_subscribers form .users_holder > ul li.user_block {
        width: 100%;
        clear: both;
        height: 35px;
        margin: 0 0 48px 0; }

#load-usercover,
#load-userpic {
  display: none; }

body.profile_page .social_icons a {
  color: #000;
  font-size: 21px;
  display: inline-block;
  margin: 0 10px 0 0; }
  body.profile_page .social_icons a:last-child {
    margin: 0; }

body.profile_page .broadcast_card.opened {
  display: inline; }

body.profile_page .views_list {
  height: 100%;
  display: none; }
  body.profile_page .views_list.selected {
    display: block; }
  body.profile_page .views_list .view {
    height: 100%;
    display: none; }
    body.profile_page .views_list .view.selected {
      display: block; }
      body.profile_page .views_list .view.selected.articles.blocks {
        margin: 44px auto 0 auto; }

body.profile_page .broadcasts_list {
  display: none;
  height: 100%; }
  body.profile_page .broadcasts_list.selected {
    display: block; }

body.profile_page .authors,
body.profile_page .landings,
body.profile_page .about_me_tab,
body.profile_page .drafts {
  display: none;
  height: 100%; }
  body.profile_page .authors.selected,
  body.profile_page .landings.selected,
  body.profile_page .about_me_tab.selected,
  body.profile_page .drafts.selected {
    margin: 44px 0 0 0;
    display: block; }

.about_me_tab .moto_text {
  margin: -6px 0 44px 0;
  font-size: 24px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #000;
  border: none;
  width: 100%;
  text-align: center;
  background: none;
  font-weight: bold; }
  .about_me_tab .moto_text::-webkit-input-placeholder {
    color: #d9d9d9;
    opacity: 1; }
  .about_me_tab .moto_text:-moz-placeholder {
    color: #d9d9d9;
    opacity: 1; }
  .about_me_tab .moto_text::-moz-placeholder {
    color: #d9d9d9;
    opacity: 1; }
  .about_me_tab .moto_text:-ms-input-placeholder {
    color: #d9d9d9;
    opacity: 1; }

.about_me_tab ul.fields {
  display: block;
  width: 720px;
  float: left;
  list-style: none;
  margin: 10px 0 0 0; }
  .about_me_tab ul.fields > li {
    display: inline-block;
    vertical-align: top;
    width: 220px;
    margin: 0 20px 20px 0; }
    .about_me_tab ul.fields > li:nth-child(3n+3) {
      margin: 0 0 20px 0; }
    .about_me_tab ul.fields > li.double {
      width: 460px;
      margin: 0 0 20px 0; }
  .about_me_tab ul.fields label {
    font-weight: bold;
    display: block;
    width: 100%; }
  .about_me_tab ul.fields textarea,
  .about_me_tab ul.fields input[type='text'] {
    width: 100%;
    display: block;
    border: none;
    background: none;
    font-size: 14px;
    padding: 0;
    margin: 0 20px 0 0;
    resize: none; }
    .about_me_tab ul.fields textarea::-webkit-input-placeholder,
    .about_me_tab ul.fields input[type='text']::-webkit-input-placeholder {
      color: #989898;
      opacity: 1; }
    .about_me_tab ul.fields textarea:-moz-placeholder,
    .about_me_tab ul.fields input[type='text']:-moz-placeholder {
      color: #989898;
      opacity: 1; }
    .about_me_tab ul.fields textarea::-moz-placeholder,
    .about_me_tab ul.fields input[type='text']::-moz-placeholder {
      color: #989898;
      opacity: 1; }
    .about_me_tab ul.fields textarea:-ms-input-placeholder,
    .about_me_tab ul.fields input[type='text']:-ms-input-placeholder {
      color: #989898;
      opacity: 1; }
  .about_me_tab ul.fields textarea {
    height: 85px;
    min-height: 85px; }

.about_me_tab aside.rating {
  float: right;
  width: 220px;
  box-shadow: 1px 1px 3px 2px rgba(0, 0, 0, 0.07);
  box-sizing: border-box;
  padding: 20px;
  margin: -10px 0 0 0; }
  .about_me_tab aside.rating ul {
    list-style: none; }
    .about_me_tab aside.rating ul label {
      display: block;
      font-weight: bold; }
    .about_me_tab aside.rating ul li {
      margin: 0 0 26px 0; }

.drafts_list {
  margin: 0 auto;
  width: 940px;
  list-style: none;
  position: relative; }
  .drafts_list.rounded > li {
    box-sizing: border-box;
    padding: 0 0 0 80px;
    border-bottom: none; }
    .drafts_list.rounded > li .actions {
      position: absolute;
      right: auto;
      left: 0px;
      top: 2px; }
      .drafts_list.rounded > li .actions ul.modify {
        right: auto;
        display: block;
        position: relative; }
        .drafts_list.rounded > li .actions ul.modify li {
          display: inline-block;
          margin: 0 5px 0 0; }
          .drafts_list.rounded > li .actions ul.modify li a {
            background: #d9d9d9;
            box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.22); }
  .drafts_list.rounded .article_type {
    height: 23px;
    line-height: 23px;
    font-size: 10px;
    border-radius: 30px; }
  .drafts_list.rounded p {
    color: #989898; }
  .drafts_list.rounded h2 {
    font-size: 18px;
    margin: 19px 0 9px; }
  .drafts_list .article_type {
    background: #57996b;
    line-height: 34px;
    height: 34px;
    color: #fff;
    font-size: 14px;
    display: inline-block;
    padding: 0 10px;
    margin: 0 10px 0 0; }
    .drafts_list .article_type.current_draft {
      background: #ec5c57; }
    .drafts_list .article_type.personal {
      background: #57996b; }
    .drafts_list .article_type.draft {
      background: #485f99; }
    .drafts_list .article_type.public {
      background: #989898; }
    .drafts_list .article_type.archive {
      background: #333333; }
    .drafts_list .article_type.export {
      background: #9185be; }
  .drafts_list > li {
    border-bottom: 1px solid #f4f4f4;
    position: relative; }
  .drafts_list .actions {
    position: absolute;
    right: 0;
    top: 12px; }
    .drafts_list .actions ul {
      list-style: none; }
      .drafts_list .actions ul li {
        display: block;
        margin: 0 0 2px 0; }
  .drafts_list a {
    color: #333333; }
  .drafts_list h2 {
    font-size: 24px;
    margin: 19px 0  12px 0;
    color: #333333;
    width: 780px; }
    .drafts_list h2 .status {
      display: inline-block;
      background: #485f99;
      color: #fff;
      font-weight: bold;
      font-size: 10px;
      height: 21px;
      padding: 0 10px;
      line-height: 21px;
      position: relative;
      bottom: 3px;
      border-radius: 10px;
      text-transform: lowercase; }
  .drafts_list p {
    font-size: 10px;
    margin: 0 0 22px 0; }

.profile_cover {
  width: 100%;
  height: 395px;
  display: block;
  background: url("/img/les/forest.jpg") center center/cover;
  position: relative;
  margin: 55px 0 0 0; }
  .profile_cover .tan {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: rgba(0, 0, 0, 0.15); }
  .profile_cover .upload_cover_image {
    background: rgba(0, 0, 0, 0.66);
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    transition: all 0.2s ease-in; }
    .profile_cover .upload_cover_image .text {
      cursor: pointer;
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      position: absolute;
      width: 190px;
      height: 140px;
      text-align: center;
      left: 50%;
      top: 50%;
      margin: -70px 0 0 -95px; }
      .profile_cover .upload_cover_image .text .icon-upload {
        font-weight: normal;
        font-size: 65px;
        margin: 0 0 15px 0;
        display: block; }
    .profile_cover .upload_cover_image:hover {
      opacity: 1; }
  .profile_cover .pattern {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url("/img/les/patterns/black-criss-cross.png") repeat; }

header.user_profile {
  padding: 44px 0;
  font-size: 14px;
  position: relative; }
  header.user_profile.overflow {
    padding: 0; }
    header.user_profile.overflow .center {
      position: relative; }
    header.user_profile.overflow .profile_pic {
      position: absolute;
      left: 50%;
      width: 128px;
      height: 128px;
      top: -122px;
      margin: 0px 0 0 -64px;
      float: none;
      border: 4px #fff solid;
      overflow: hidden;
      border-radius: 90px; }
      header.user_profile.overflow .profile_pic .round {
        border-radius: 0px;
        background: #333333;
        color: #fff;
        width: 100%;
        height: 100%;
        box-shadow: 0;
        font-size: 50px;
        line-height: 126px; }
      header.user_profile.overflow .profile_pic img {
        width: 100%;
        display: block;
        border-radius: 0px; }
      header.user_profile.overflow .profile_pic.got_no_picture .replace_picture {
        background: #57996b; }
      header.user_profile.overflow .profile_pic .replace_picture {
        position: absolute;
        cursor: pointer;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        color: #fff;
        background: rgba(0, 0, 0, 0.65);
        color: #fff;
        font-size: 50px;
        line-height: 125px;
        text-align: center;
        top: 0;
        width: 100%;
        opacity: 0;
        transition: all 0.2s ease-in; }
        header.user_profile.overflow .profile_pic .replace_picture:hover {
          opacity: 1; }
    header.user_profile.overflow .profile_info {
      float: none;
      width: 460px;
      padding: 34px 0 0 0;
      margin: 0px auto 0 auto;
      text-align: center; }
      header.user_profile.overflow .profile_info .owned_landings .expert_style {
        color: #57996b;
        white-space: nowrap; }
    header.user_profile.overflow .buttons {
      text-align: center;
      margin: 20px 0 0 0; }
      header.user_profile.overflow .buttons a {
        color: #000; }
  header.user_profile .profile_pic {
    float: left;
    margin: 0px 20px 0 0; }
    header.user_profile .profile_pic img {
      width: 90px;
      border-radius: 90px;
      display: block; }
    header.user_profile .profile_pic .round {
      background: #fff none repeat scroll 0 0;
      border-radius: 90px;
      color: #000;
      font-weight: bold;
      height: 90px;
      font-size: 25px;
      line-height: 90px;
      text-align: center;
      width: 90px;
      text-transform: uppercase;
      box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.3); }
  header.user_profile .buttons {
    width: 100%;
    text-align: left;
    clear: both;
    margin: 20px 0 0 14px;
    float: left; }
    header.user_profile .buttons a {
      color: #989898;
      display: inline-block;
      font-size: 14px;
      font-weight: normal;
      height: 17px;
      line-height: 17px;
      margin: 0 37px 0 0; }
      header.user_profile .buttons a:last-child {
        margin: 0; }
      header.user_profile .buttons a.selected {
        font-weight: bold;
        border-bottom: 1px solid #333333; }
  header.user_profile .profile_info {
    float: left;
    max-width: 650px; }
    header.user_profile .profile_info h1 {
      font-size: 24px;
      font-weight: bold;
      height: 24px;
      line-height: 27px;
      margin: 0 0 18px;
      text-transform: capitalize; }
      header.user_profile .profile_info h1 .expert_style {
        color: #ffd75e;
        font-weight: normal;
        font-size: 14px;
        text-transform: none;
        display: inline-block;
        margin: 0 0 0 8px; }
    header.user_profile .profile_info h2 {
      font-size: 12px;
      font-weight: bold;
      margin: 0 0 6px; }
    header.user_profile .profile_info p {
      color: #000;
      line-height: 20px; }
      header.user_profile .profile_info p.owned_landings {
        margin: 16px 0 -10px; }
        header.user_profile .profile_info p.owned_landings a {
          color: #333333;
          font-weight: bold; }
  header.user_profile .user_type {
    margin: 24px 0 0 0;
    float: left;
    width: 100%;
    font-weight: bold;
    font-size: 12px; }
    header.user_profile .user_type .position {
      text-transform: uppercase; }

.veil.about_me_panel {
  display: none;
  height: auto;
  left: auto;
  position: absolute;
  top: 145px;
  width: 365px;
  z-index: 100; }
  .veil.about_me_panel.selected {
    display: block; }
  .veil.about_me_panel .close_panel {
    color: #fff;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: 9px; }
  .veil.about_me_panel h2 {
    background: #333333;
    font-weight: bold;
    font-size: 14px;
    color: #fff;
    height: 34px;
    line-height: 34px;
    width: 100%;
    display: block;
    box-sizing: border-box;
    padding: 0 20px; }
  .veil.about_me_panel form {
    width: auto;
    padding: 10px 50px 10px 20px;
    max-height: 500px;
    overflow: auto; }
  .veil.about_me_panel p {
    margin: 0 0 17px 0; }
  .veil.about_me_panel .social a {
    display: inline-block;
    font-size: 18px;
    margin: 0 10px 0 0; }
    .veil.about_me_panel .social a:last-child {
      margin: 0; }

nav.sub_header_menu.profile_nav {
  border-bottom: 1px solid #f4f4f4;
  border-top: 1px solid #f4f4f4;
  margin: 0 0 0px; }
  nav.sub_header_menu.profile_nav.sp {
    border-bottom: 1px solid #f4f4f4;
    border-top: medium none;
    box-shadow: 0 8px 11px 0 rgba(0, 0, 0, 0.04);
    position: relative;
    z-index: 26; }
    nav.sub_header_menu.profile_nav.sp ul#main_nav {
      position: absolute;
      text-align: center;
      width: 100%; }
  nav.sub_header_menu.profile_nav .wrapper {
    width: 940px;
    padding: 0;
    position: relative; }
  nav.sub_header_menu.profile_nav li a {
    color: #333333; }
    nav.sub_header_menu.profile_nav li a.selected {
      font-weight: bold; }

.profile_page section.profile_block {
  display: none; }
  .profile_page section.profile_block.selected {
    display: block; }

.profile_page .profile_wrap {
  position: relative; }
  .profile_page .profile_wrap ul.links {
    list-style: outside none none;
    position: absolute;
    right: 0;
    top: 12px;
    z-index: 20; }
    .profile_page .profile_wrap ul.links li {
      display: inline-block;
      margin: 0 10px 0 0; }
      .profile_page .profile_wrap ul.links li:last-child {
        margin: 0; }

.articles.my_posts {
  float: right !important; }

.live-feed.profile_block {
  float: right;
  width: 620px;
  max-height: inherit;
  overflow: auto; }
  .live-feed.profile_block .add_comment textarea {
    width: 100%; }

.users_list {
  float: left;
  margin: 0 0 0 20px;
  width: 460px; }
  .users_list article {
    width: 220px;
    box-sizing: border-box;
    padding: 20px;
    float: left;
    border: 1px solid #d9d9d9;
    display: block;
    margin: 0 0 12px 0; }
    .users_list article .user_pic .round {
      background: #333333;
      color: #fff;
      border-radius: 55px;
      height: 55px;
      line-height: 55px;
      width: 55px;
      font-size: 22px; }
    .users_list article .user_name {
      width: 193px; }
      .users_list article .user_name strong a {
        color: #000;
        font-size: 15px; }
    .users_list article .button_holder {
      text-align: right;
      width: 100%;
      margin: 20px 0 0; }
      .users_list article .button_holder button {
        background: #fff;
        border: 1px solid #019198;
        position: relative;
        color: #019198; }
        .users_list article .button_holder button.subscribe {
          font-size: 13px;
          left: auto;
          top: auto;
          color: #019198; }
        .users_list article .button_holder button:hover {
          background: #019198;
          color: #fff; }
          .users_list article .button_holder button:hover.subscribed::after {
            background: #fff;
            color: #019198; }
        .users_list article .button_holder button.subscribed::after {
          background: #019198 none repeat scroll 0 0;
          border: 1px solid #019198;
          color: #fff;
          content: "Слежу";
          display: block;
          height: 100%;
          left: -1px;
          line-height: 27px;
          position: absolute;
          top: -1px;
          width: 100%; }
    .users_list article:nth-child(even) {
      float: right; }

section.profile_settings form {
  border: 1px solid #989898;
  display: block;
  width: 460px;
  float: right;
  margin: 0 0 25px 0;
  padding: 21px 20px;
  box-sizing: border-box; }
  section.profile_settings form h2 {
    font-size: 12px;
    font-weight: bold;
    color: #000; }
  section.profile_settings form input[type='text'],
  section.profile_settings form input[type='password'] {
    border: 1px solid #989898;
    box-sizing: border-box;
    line-height: 35px;
    padding: 0 10px;
    width: 280px;
    margin: 0 0 10px 0; }

section.profile_settings .left {
  float: left;
  width: 280px; }

section.profile_settings .right {
  float: right;
  width: 280px;
  text-align: right; }

.resume {
  margin: 0 0 0 20px; }
  .resume .left {
    float: left;
    width: 220px; }
  .resume .right {
    float: right;
    width: 220px; }
  .resume article {
    display: block;
    float: left;
    width: 220px;
    margin: 0 0px 1px 0;
    background: #f4f4f4;
    box-sizing: border-box;
    padding: 22px 20px; }
    .resume article h3 {
      color: #000;
      text-transform: none; }
    .resume article p {
      width: 100%; }
      .resume article p[contenteditable="true"]:focus {
        background: rgba(0, 0, 0, 0.1); }

.gm-style-iw {
  box-shadow: none;
  border: none;
  border-radius: 3px; }

.map {
  width: 100%;
  height: 570px; }

.mylabel {
  font-weight: bold !important;
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }

.got_subfolders .all_states {
  max-height: 300px;
  overflow: auto; }

.rating_area .rating {
  margin: 40px 0 0 0;
  height: 825px;
  list-style: none; }
  .rating_area .rating article {
    position: relative;
    margin: 0 0 15px 0; }
    .rating_area .rating article .number {
      color: #d9d9d9;
      font-size: 18px;
      font-weight: bold;
      left: -67px;
      position: absolute;
      text-align: right;
      width: 50px;
      top: 17px; }
    .rating_area .rating article h2 {
      font-size: 18px;
      font-weight: bold;
      margin: 0 0 2px 0;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
      .rating_area .rating article h2 br {
        display: none; }
      .rating_area .rating article h2 div {
        display: inline; }
    .rating_area .rating article p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #000;
      min-height: 20px; }
      .rating_area .rating article p br {
        display: none; }
      .rating_area .rating article p div {
        display: inline; }
    .rating_area .rating article .criteria {
      color: #989898;
      font-size: 10px;
      margin: 0 0 1px 0; }
      .rating_area .rating article .criteria > span {
        font-size: 13px;
        margin: 0 2px 0 0;
        position: relative;
        top: 2px; }
  .rating_area .rating li .left, .rating_area .rating li .right {
    width: 400px; }
  .rating_area .rating li .left {
    float: left; }
  .rating_area .rating li .right {
    float: right; }

.map_location {
  text-transform: none;
  color: #989898;
  float: left; }
  .map_location > a {
    display: inline-block;
    margin: 0 20px 0 0px; }
    .map_location > a:first-child {
      margin: 0 20px 0 20px; }
    .map_location > a.selected {
      background: #57996b;
      border-radius: 5px;
      color: #fff;
      padding: 0 5px;
      line-height: 22px; }
  .map_location > ul {
    display: inline-block !important; }

ul.most_visited {
  float: right;
  width: 195px;
  text-align: right; }
  ul.most_visited .subfolders {
    text-align: left;
    width: 214px; }

.time_period {
  font-size: 14px;
  position: absolute;
  right: 40px;
  bottom: 3px; }
  .time_period .control {
    font-size: 14px;
    padding-left: 34px;
    display: inline-block; }
  .time_period .control__indicator {
    left: 13px;
    top: 3px; }

.map_filter {
  font-size: 18px;
  position: relative; }
  .map_filter ul li {
    display: inline-block;
    margin: 0 27px 0 0;
    text-transform: uppercase; }
    .map_filter ul li:last-child {
      margin: 0; }
    .map_filter ul li a {
      display: block;
      color: #000;
      margin: 0 0 16px 0; }
  .map_filter .pointer {
    transition: ease-in 0.2s all;
    height: 7px;
    position: absolute;
    bottom: 0;
    background: #57996b; }
    .map_filter .pointer[data-pos='pos1'] {
      width: 67px;
      left: 0; }
    .map_filter .pointer[data-pos='pos2'] {
      left: 100px;
      width: 72px; }
    .map_filter .pointer[data-pos='pos3'] {
      left: 204px;
      width: 89px; }

.map_holder {
  background: #f4f4f4; }
  .map_holder .low_background {
    background: #fff;
    border-bottom: 1px solid #f4f4f4;
    position: relative; }
    .map_holder .low_background .left-arrow,
    .map_holder .low_background .right-arrow {
      font-size: 62px;
      top: 43px;
      color: #d9d9d9; }
    .map_holder .low_background .left-arrow {
      display: block;
      left: 50%;
      margin: 0 0 0 -531px;
      padding: 5px 0;
      position: absolute;
      transform: rotate(-90deg); }
    .map_holder .low_background .right-arrow {
      transform: scaleX(-1);
      position: absolute;
      right: 50%;
      margin: 0 -531px 0 0;
      display: block;
      padding: 5px 0;
      transform: rotate(-90deg); }

.info_content h3, .info_content a {
  color: #333333; }

.info_content h3 {
  font-size: 14px;
  color: #333333;
  text-transform: none;
  margin: 0 0 15px; }

.info_content .number {
  color: #989898;
  font-size: 10px;
  margin: 2px 0 3px 0px; }
  .info_content .number span {
    font-size: 14px;
    position: relative;
    top: 2px; }

.wrapper.map_articles_holder.non_brakable {
  overflow: hidden;
  position: relative; }
  .wrapper.map_articles_holder.non_brakable ul {
    white-space: nowrap;
    height: 200px; }
    .wrapper.map_articles_holder.non_brakable ul li {
      display: inline-block;
      float: none;
      white-space: normal;
      vertical-align: top; }
      .wrapper.map_articles_holder.non_brakable ul li:nth-child(4n+4), .wrapper.map_articles_holder.non_brakable ul li:last-child {
        margin: 0 20px 20px 0; }

.wrapper.map_articles_holder ul {
  list-style: none; }
  .wrapper.map_articles_holder ul li {
    float: left;
    width: 220px;
    padding: 34px 0 25px 0;
    box-sizing: border-box;
    position: relative;
    margin: 0 20px 20px 0; }
    .wrapper.map_articles_holder ul li a {
      color: #333333; }
    .wrapper.map_articles_holder ul li .count {
      color: #d9d9d9; }
    .wrapper.map_articles_holder ul li:nth-child(4n+4), .wrapper.map_articles_holder ul li:last-child {
      margin: 0 0 20px 0; }
    .wrapper.map_articles_holder ul li h3 {
      font-size: 14px;
      color: #333333;
      text-transform: none; }
    .wrapper.map_articles_holder ul li .number {
      font-size: 10px;
      margin: 2px 0 3px 10px; }
      .wrapper.map_articles_holder ul li .number span {
        font-size: 14px;
        position: relative;
        top: 2px; }
    .wrapper.map_articles_holder ul li .count {
      font-size: 42.57px;
      font-weight: bold;
      position: absolute;
      top: 4px;
      left: -45px;
      text-align: right;
      width: 50px; }

section.about_page h1 {
  font-weight: normal;
  font-size: 36px;
  margin: 18px 0 40px 0; }

section.about_page h2 {
  font-size: 24px;
  font-weight: normal;
  margin: 0 0 65px 0; }

section.about_page a {
  color: #000;
  font-weight: bold; }

section.about_page ul.interview {
  list-style: none; }
  section.about_page ul.interview li {
    margin: 0 0 50px 0;
    float: left;
    width: 100%; }
    section.about_page ul.interview li.deformed .left {
      width: 100%; }
    section.about_page ul.interview li.deformed .right {
      width: 100%; }
  section.about_page ul.interview .left {
    width: 230px;
    font-weight: bold;
    float: left;
    font-size: 18px;
    line-height: 22px;
    margin: -3px 0 22px; }
  section.about_page ul.interview .right {
    float: right;
    width: 710px; }
    section.about_page ul.interview .right.large {
      font-size: 24px; }

.search_header_wrap {
  width: 940px;
  padding: 0; }

.h1_search {
  font-size: 48px;
  margin: 80px 0 0 0; }

nav.search_nav_tabs.sub_header_menu {
  margin: 6px 0 0;
  border-bottom: none; }
  nav.search_nav_tabs.sub_header_menu li {
    font-weight: normal; }
    nav.search_nav_tabs.sub_header_menu li.selected {
      font-weight: bold; }
      nav.search_nav_tabs.sub_header_menu li.selected a {
        color: #333333 !important; }

.search_tabs {
  height: 100%; }
  .search_tabs .tab {
    display: none; }
    .search_tabs .tab.selected {
      display: block; }
  .search_tabs .broadcast_card.opened {
    display: inline; }

.search_users {
  width: 100%;
  list-style: none; }
  .search_users .user_block {
    width: 100%; }
  .search_users .subscription_button {
    float: right; }
  .search_users li {
    width: 460px;
    float: left;
    height: 80px; }
    .search_users li:nth-child(even) {
      float: right; }

body.article_page, body.new_article_page {
  background: #fff; }

body.rubric_page section.wrapper {
  margin: 0 auto; }

.rubric_name {
  text-align: center;
  font-size: 24px;
  height: 110px;
  line-height: 110px;
  text-transform: uppercase; }

button {
  display: inline-block;
  border: 1px solid #57996b;
  background: #57996b none repeat scroll 0 0;
  color: #fff;
  width: 140px;
  height: 32px;
  border-radius: 30px;
  font-weight: bold;
  font-size: 14px;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
  cursor: pointer !important; }
  button:hover {
    background: #fff;
    color: #57996b; }

.editor_buttons {
  box-sizing: border-box;
  padding: 12px 25px 12px 0;
  text-align: right;
  float: right; }
  .editor_buttons a {
    display: inline-block;
    margin: 0 20px 0 0;
    width: auto;
    height: 32px;
    vertical-align: top;
    background: #57996b none repeat scroll 0 0;
    border-radius: 30px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 33px;
    line-height: 33px;
    padding: 0 19px;
    text-align: center;
    top: 12px; }
    .editor_buttons a:hover {
      color: #fff; }
  .editor_buttons button {
    display: inline-block;
    margin: 0 20px 0 0;
    color: #333333;
    width: auto;
    background: none;
    height: 32px;
    font-weight: normal;
    text-align: center;
    line-height: 13px;
    border: none;
    padding: 0;
    text-transform: uppercase;
    vertical-align: top; }
    .editor_buttons button.clean {
      font-weight: bold;
      cursor: pointer;
      font-size: 14px;
      text-transform: none; }
      .editor_buttons button.clean:hover {
        color: #333333; }
      .editor_buttons button.clean.selected {
        text-decoration: underline; }
    .editor_buttons button.icon_black {
      font-size: 16px;
      height: 34px;
      line-height: 32px;
      background: #333333;
      color: #fff;
      width: 34px;
      margin: 0 5px 0 0;
      font-size: normal !important; }
      .editor_buttons button.icon_black.selected {
        font-weight: normal;
        display: none; }
      .editor_buttons button.icon_black.black_tooltip .tooltip_holder {
        top: 40px; }
        .editor_buttons button.icon_black.black_tooltip .tooltip_holder .tooltip_text:after {
          margin-top: -10px;
          position: absolute;
          top: 0;
          transform: rotate(180deg); }
    .editor_buttons button.black {
      height: 34px;
      line-height: 34px;
      background: #333333;
      text-transform: none;
      font-weight: bold;
      color: #fff;
      padding: 0 20px;
      margin: 0 0 0 5px; }
      .editor_buttons button.black:last-child {
        margin: 0 0 0 20px; }
    .editor_buttons button.selected {
      font-weight: bold; }
    .editor_buttons button:hover {
      color: #fff; }
    .editor_buttons button:last-child {
      margin: 0; }

@media (max-width: 960px) {
  .editor_buttons {
    float: left;
    margin-left: 20px; } }

@media (max-width: 780px) {
  .editor_buttons {
    float: none;
    margin: 0;
    position: absolute;
    left: 20px;
    bottom: 0; }
    .editor_buttons a {
      height: 20px;
      font-size: 12px;
      line-height: 20px;
      padding: 0 10px; } }

nav.aside_nav .promo,
nav.aside_nav .news_list,
nav.aside_nav > header.user_profile {
  background: #f4f4f4;
  width: 100%;
  padding: 23px;
  box-sizing: border-box;
  margin: 0 0 1px 0; }

nav.aside_nav.profile ul li.selected a .icon, nav.aside_nav.profile ul li.selected a h3, nav.aside_nav.profile ul li.selected a .count {
  color: #57996b; }

nav.aside_nav.profile ul li a .icon, nav.aside_nav.profile ul li a h3, nav.aside_nav.profile ul li a .count {
  color: #000; }

nav.aside_nav .notices {
  background: #mcolor none repeat scroll 0 0;
  box-sizing: border-box;
  height: 275px;
  margin: -1px 0 20px;
  overflow: auto;
  padding: 0px 20px 0px 20px; }
  nav.aside_nav .notices h2 {
    padding: 20px 0 0 0; }

nav.aside_nav .news_list {
  height: 275px;
  background: #fff;
  overflow: auto;
  border: 1px solid #d9d9d9;
  padding: 0px 20px 0px 20px; }
  nav.aside_nav .news_list h2 {
    padding: 20px 0 0 0; }

nav.aside_nav .notices h2,
nav.aside_nav .news_list h2 {
  font-size: 15px;
  font-weight: bold;
  color: #333333; }

nav.aside_nav .notices ul li,
nav.aside_nav .news_list ul li {
  border-bottom: 1px solid #d9d9d9;
  display: block;
  margin: 15px 0 15px 0; }
  nav.aside_nav .notices ul li a .date,
  nav.aside_nav .news_list ul li a .date {
    font-size: 10px;
    color: #989898;
    margin: 0 0 5px 0; }
  nav.aside_nav .notices ul li a h3,
  nav.aside_nav .news_list ul li a h3 {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    margin: 0 0 15px 0;
    text-transform: none; }
  nav.aside_nav .notices ul li:last-child,
  nav.aside_nav .news_list ul li:last-child {
    border-bottom: 0; }

nav.aside_nav .menu {
  margin: 14px 0 0 0; }
  nav.aside_nav .menu ul {
    font-size: 8px; }
    nav.aside_nav .menu ul li {
      display: inline-block;
      margin: 0 8px 0 0; }
      nav.aside_nav .menu ul li:last-child {
        margin: 0 0 0 0; }
      nav.aside_nav .menu ul li a {
        text-decoration: underline;
        color: #989898;
        display: block; }
        nav.aside_nav .menu ul li a:hover {
          text-decoration: none; }

nav.aside_nav .promo {
  margin: 0 0 23px 0; }
  nav.aside_nav .promo .tag {
    font-size: 10px;
    font-weight: bold;
    color: #57996b;
    margin: 0 0 10px 0; }
  nav.aside_nav .promo .date {
    color: #989898;
    font-size: 10px;
    margin: 0 0 35px 0; }
  nav.aside_nav .promo h2 {
    font-size: 15px;
    font-weight: bold; }

nav.aside_nav > ul.sublist li h3 {
  text-transform: none; }

nav.aside_nav > ul li {
  background: #f4f4f4;
  margin: 0 0 1px 0;
  width: 100%;
  display: block;
  height: 54px;
  position: relative; }
  nav.aside_nav > ul li.large_space {
    margin: 0 0 12px 0; }
  nav.aside_nav > ul li.selected {
    /*
        &:after{
          background: $red none repeat scroll 0 0;
          content: " ";
          display: block;
          height: 100%;
          position: absolute;
          top: 0;
          width: 5px;
        }*/ }
    nav.aside_nav > ul li.selected .name, nav.aside_nav > ul li.selected .icon, nav.aside_nav > ul li.selected .count {
      color: #57996b; }
  nav.aside_nav > ul li a {
    display: block;
    padding: 20px;
    box-sizing: border-box;
    height: 100%; }
    nav.aside_nav > ul li a.highlight {
      color: #57996b !important;
      transition: ease-out 0.1s all; }
      nav.aside_nav > ul li a.highlight .name {
        color: #57996b !important;
        transition: ease-out 0.1s all; }
    nav.aside_nav > ul li a:hover .name, nav.aside_nav > ul li a:hover .icon, nav.aside_nav > ul li a:hover .count {
      color: #57996b; }
    nav.aside_nav > ul li a .icon {
      float: left;
      width: 15px;
      margin: 0 28px 0 0;
      display: block;
      height: 20px;
      font-size: 16.5px;
      color: #989898; }
    nav.aside_nav > ul li a .name {
      float: left; }
    nav.aside_nav > ul li a .count {
      font-weight: bold;
      float: right;
      color: #989898; }

nav.sub_menu {
  display: block;
  position: absolute;
  right: 0;
  margin: 0px -10px 0 0;
  font-size: 12px; }
  nav.sub_menu a {
    color: #989898; }
  nav.sub_menu .arrow_up {
    border-bottom: 8px solid #57996b;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    height: 0;
    left: 50%;
    margin: 3px 0 0 -8px;
    position: absolute;
    width: 0; }
  nav.sub_menu ul {
    background: #57996b;
    display: block;
    position: relative;
    margin: 10px 0 0 0;
    width: 140px;
    box-sizing: border-box;
    padding: 15px 20px;
    line-height: normal;
    clear: both; }
    nav.sub_menu ul li {
      height: 35px;
      clear: both;
      line-height: 35px;
      margin: 0 0 0px 0;
      width: 100%;
      float: none; }

.search_text {
  height: 34px;
  line-height: 34px;
  border: 1px solid #f4f4f4;
  border-radius: 30px;
  color: #333333;
  width: 220px;
  padding: 0 30px;
  box-sizing: border-box; }

.search_icon_position {
  position: absolute;
  left: 10px;
  top: 19px;
  font-size: 17px;
  color: #d9d9d9;
  z-index: 20; }

.load_layer {
  position: relative;
  top: 0;
  left: 0; }
  .load_layer .loading_icon {
    background: transparent url("/img/les/loading.png") no-repeat scroll 0 0/50px auto;
    height: 50px;
    margin: 0 auto;
    position: fixed;
    right: 50px;
    top: 50px;
    z-index: 10000;
    width: 50px;
    animation: infinite-spinning 0.5s linear infinite; }

.notice_layer {
  z-index: 10000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center; }
  .notice_layer .notice {
    width: 374px;
    position: relative;
    background: #fff;
    padding: 54px 40px;
    box-sizing: border-box;
    margin: 0 auto;
    color: #57996b;
    font-weight: bold;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4);
    text-align: center; }
    .notice_layer .notice.article_notice {
      width: 460px;
      padding: 0;
      text-align: left; }
      .notice_layer .notice.article_notice header {
        background: #333333;
        color: #fff;
        padding: 22px 50px;
        box-sizing: border-box; }
        .notice_layer .notice.article_notice header h2 {
          font-size: 24px;
          margin: 0 0 -1px 0; }
        .notice_layer .notice.article_notice header p {
          font-weight: normal;
          font-size: 14px; }
      .notice_layer .notice.article_notice .main {
        padding: 22px 55px;
        box-sizing: border-box; }
        .notice_layer .notice.article_notice .main label {
          font-weight: bold;
          color: #333333;
          left: -15px;
          position: relative; }
          .notice_layer .notice.article_notice .main label a {
            font-weight: normal;
            text-decoration: underline;
            color: #333333; }
        .notice_layer .notice.article_notice .main ul {
          list-style: none; }
          .notice_layer .notice.article_notice .main ul > li {
            margin: 0 0 20px 0;
            display: none; }
            .notice_layer .notice.article_notice .main ul > li.selected {
              display: block; }
        .notice_layer .notice.article_notice .main ol {
          list-style: disc; }
          .notice_layer .notice.article_notice .main ol li {
            font-weight: normal;
            margin: 0 0 5px 0;
            color: #000; }

.veil {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 2000;
  background: rgba(255, 255, 255, 0.5); }
  .veil aside.side_float_nav {
    position: fixed;
    left: -240px;
    height: 100%;
    width: 230px;
    z-index: 200;
    top: 0;
    box-sizing: border-box;
    padding: 160px 35px 160px 35px;
    background: #fff;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4);
    transition: ease-in 0.3s all; }
    .veil aside.side_float_nav.selected {
      left: 0; }
    .veil aside.side_float_nav h2 {
      font-size: 24px;
      margin: 0 0 20px 0;
      font-weight: bold; }
    .veil aside.side_float_nav ul li {
      text-transform: uppercase;
      margin: 0 0 20px 0; }
      .veil aside.side_float_nav ul li a {
        color: #000; }
  .veil .close {
    background: #ff4e50 none repeat scroll 0 0;
    box-sizing: border-box;
    color: #fff;
    display: block;
    font-size: 14px;
    height: 55px;
    line-height: 55px;
    position: absolute;
    text-align: center;
    top: 0;
    width: 55px;
    left: 50%;
    z-index: 20;
    margin: 0 0 0 415px; }
  .veil.white form {
    background: #fff;
    color: #343434;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2); }
    .veil.white form > span {
      color: #343434; }
  .veil form {
    position: relative;
    background: #fff;
    width: 413px;
    padding: 38px 55px 38px;
    box-sizing: border-box;
    margin: 0 auto;
    color: #333333;
    text-align: left;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
    /*
    .selectboxit-container{
      * {
        font-family: $font1;
      }
      .selectboxit-options{
        width:100% !important;
        border-radius: 0;
        border: 1px solid #000;
        box-shadow: none;
      }
      .selectboxit{
        width:100% !important;
        border-radius:0;
      }
      span,
      .selectboxit-options a{
        color:#000;
        font-size: 14px;
      }
    }
    .selectboxit-container span, .selectboxit-container .selectboxit-options a{

    }*/ }
    .veil form.center {
      text-align: center; }
      .veil form.center h2 {
        margin: 0 0 25px; }
      .veil form.center .left {
        float: left;
        width: 140px;
        box-sizing: border-box;
        padding: 0; }
      .veil form.center .right {
        float: right;
        width: 140px;
        box-sizing: border-box;
        padding: 0; }
    .veil form .close_button {
      background: #fff none repeat scroll 0 0;
      border-radius: 20px;
      box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
      display: block;
      font-size: 7px;
      height: 22px;
      line-height: 22px;
      position: absolute;
      right: -11px;
      text-align: center;
      top: -11px;
      width: 22px;
      color: #d9d9d9;
      cursor: pointer; }
    .veil form.broadcast_form {
      width: 308px; }
    .veil form .small {
      font-size: 10px; }
    .veil form#video_form {
      width: 340px;
      padding: 20px; }
    .veil form.tab {
      display: none; }
      .veil form.tab.selected {
        display: block; }
    .veil form h2 {
      font-size: 24px;
      font-weight: bold;
      clear: both;
      margin: 0 0 10px 0; }
      .veil form h2.or {
        margin: 0 0 14px; }
    .veil form p.under_h {
      position: relative;
      margin: -10px 0 0 0; }
    .veil form > span {
      font-size: 8px;
      color: #fff;
      margin: 0 0 14px;
      display: block; }
    .veil form ul.types {
      display: block;
      list-style: none; }
      .veil form ul.types li a {
        font-weight: bold;
        color: #333333; }
    .veil form ul.social {
      display: block;
      list-style: none;
      clear: both; }
      .veil form ul.social li {
        display: block;
        float: left;
        width: 140px;
        height: 34px;
        margin: 0 0 10px 0; }
        .veil form ul.social li a {
          width: 100%;
          height: 100%;
          color: #fff;
          font-size: 12px;
          font-weight: bold;
          display: block;
          line-height: 34px;
          text-align: center;
          position: relative; }
          .veil form ul.social li a i {
            height: 16px;
            position: relative;
            width: 16px;
            display: inline-block; }
          .veil form ul.social li a.facebook {
            background: #3b5998; }
            .veil form ul.social li a.facebook i {
              background: url("/img/les/icons/LES_Icons_2-01.svg") no-repeat;
              margin: 0 5px 0 0;
              top: 4px; }
          .veil form ul.social li a.vk {
            background: #45668e; }
            .veil form ul.social li a.vk i {
              background: url("/img/les/icons/LES_Icons_2-02.svg") no-repeat;
              margin: 0 10px 0 0;
              top: 6px; }
          .veil form ul.social li a.twitter {
            background: #00aced; }
            .veil form ul.social li a.twitter i {
              background: url("/img/les/icons/LES_Icons_2-03.svg") no-repeat;
              margin: 0 13px 0 0;
              top: 5px; }
        .veil form ul.social li:nth-child(even) {
          float: right; }
    .veil form .forgot_password {
      color: #333333;
      display: block;
      float: left;
      font-size: 14px;
      font-weight: bold;
      line-height: 34px;
      margin: 0;
      text-align: center; }
    .veil form .error {
      width: 100%;
      box-sizing: border-box;
      font-size: 10px;
      color: #333333;
      margin: 0px 0 10px 0; }
    .veil form select,
    .veil form input[type='text'],
    .veil form input[type='email'],
    .veil form input[type='password'],
    .veil form textarea {
      width: 100%;
      background: #f4f4f4;
      height: 34px;
      border: none;
      color: #000;
      margin: 0 0 10px 0;
      box-sizing: border-box;
      padding: 0 10px;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 14px; }
      .veil form select::-webkit-input-placeholder,
      .veil form input[type='text']::-webkit-input-placeholder,
      .veil form input[type='email']::-webkit-input-placeholder,
      .veil form input[type='password']::-webkit-input-placeholder,
      .veil form textarea::-webkit-input-placeholder {
        color: #343434; }
      .veil form select:-moz-placeholder,
      .veil form input[type='text']:-moz-placeholder,
      .veil form input[type='email']:-moz-placeholder,
      .veil form input[type='password']:-moz-placeholder,
      .veil form textarea:-moz-placeholder {
        color: #343434; }
      .veil form select::-moz-placeholder,
      .veil form input[type='text']::-moz-placeholder,
      .veil form input[type='email']::-moz-placeholder,
      .veil form input[type='password']::-moz-placeholder,
      .veil form textarea::-moz-placeholder {
        color: #343434; }
      .veil form select:-ms-input-placeholder,
      .veil form input[type='text']:-ms-input-placeholder,
      .veil form input[type='email']:-ms-input-placeholder,
      .veil form input[type='password']:-ms-input-placeholder,
      .veil form textarea:-ms-input-placeholder {
        color: #343434; }
      .veil form select:focus::-webkit-input-placeholder,
      .veil form input[type='text']:focus::-webkit-input-placeholder,
      .veil form input[type='email']:focus::-webkit-input-placeholder,
      .veil form input[type='password']:focus::-webkit-input-placeholder,
      .veil form textarea:focus::-webkit-input-placeholder {
        transition: opacity 0.2s 0.2s ease;
        opacity: 0; }
      .veil form select:focus:-moz-placeholder,
      .veil form input[type='text']:focus:-moz-placeholder,
      .veil form input[type='email']:focus:-moz-placeholder,
      .veil form input[type='password']:focus:-moz-placeholder,
      .veil form textarea:focus:-moz-placeholder {
        transition: opacity 0.2s 0.2s ease;
        opacity: 0; }
      .veil form select:focus::-moz-placeholder,
      .veil form input[type='text']:focus::-moz-placeholder,
      .veil form input[type='email']:focus::-moz-placeholder,
      .veil form input[type='password']:focus::-moz-placeholder,
      .veil form textarea:focus::-moz-placeholder {
        transition: opacity 0.2s 0.2s ease;
        opacity: 0; }
      .veil form select:focus:-ms-input-placeholder,
      .veil form input[type='text']:focus:-ms-input-placeholder,
      .veil form input[type='email']:focus:-ms-input-placeholder,
      .veil form input[type='password']:focus:-ms-input-placeholder,
      .veil form textarea:focus:-ms-input-placeholder {
        transition: opacity 0.2s 0.2s ease;
        opacity: 0; }
    .veil form textarea {
      height: 112px;
      padding: 13px 12px;
      resize: none; }
    .veil form > p {
      margin: 0 0 10px 0; }
      .veil form > p a {
        color: #333333; }
    .veil form > span a {
      color: #019198; }
    .veil form button {
      border: none;
      background: #57996b;
      width: 140px;
      border-radius: 15px;
      float: right;
      margin: 0 0 10px 0;
      font-weight: bold;
      text-align: center;
      color: #fff;
      height: 34px;
      font-size: 14px; }

.veil.subscribers_group .user_block {
  width: 100%;
  display: block;
  float: left;
  margin: 0 0 43px 0; }

.veil.subscribers_group form {
  width: 660px;
  height: 650px;
  padding: 0; }
  .veil.subscribers_group form h1 {
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 24px;
    font-weight: bold;
    padding: 0 20px;
    box-sizing: border-box; }
    .veil.subscribers_group form h1 .red_button {
      display: block;
      float: right;
      position: relative;
      top: 10px; }
  .veil.subscribers_group form .users .remove {
    display: none; }
  .veil.subscribers_group form .group_users .add {
    display: none; }
  .veil.subscribers_group form .sub_titles {
    width: 100%;
    height: 34px;
    font-weight: bold;
    line-height: 34px;
    box-sizing: border-box;
    padding: 0 20px; }
    .veil.subscribers_group form .sub_titles .left {
      float: left;
      width: 50%; }
    .veil.subscribers_group form .sub_titles .right {
      width: 50%;
      box-sizing: border-box;
      padding: 0 0 0 40px;
      float: right; }
  .veil.subscribers_group form .sliders .search {
    position: relative; }
  .veil.subscribers_group form .sliders .subscriber_name {
    background: #f4f4f4 none repeat scroll 0 0;
    box-sizing: border-box;
    font-family: "Open Sans",sans-serif;
    font-size: 14px;
    height: 34px;
    line-height: 34px;
    padding: 0 10px;
    width: 100%; }
  .veil.subscribers_group form .sliders .icon-search {
    bottom: 20px;
    position: absolute;
    right: 10px; }
  .veil.subscribers_group form .sliders .left {
    width: 50%;
    float: left;
    box-sizing: border-box;
    padding: 10px 20px 20px 20px;
    max-height: 566px;
    overflow: auto; }
  .veil.subscribers_group form .sliders .right {
    float: right;
    width: 50%;
    box-sizing: border-box;
    padding: 10px 20px 20px 20px;
    max-height: 566px;
    overflow: auto; }
  .veil.subscribers_group form .sliders .remove.icon-close,
  .veil.subscribers_group form .sliders .add.icon-plus {
    float: right;
    color: #f4f4f4;
    font-size: 30px; }

body, .jgnt_page {
  background: #fff;
  font-size: 14px; }
  body .wrapper, .jgnt_page .wrapper {
    width: 960px;
    padding: 0 10px;
    width: 940px;
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box; }
  body a, .jgnt_page a {
    color: #57996b; }
    body a:hover, .jgnt_page a:hover {
      color: #57996b;
      text-decoration: none; }
      body a:hover h3, .jgnt_page a:hover h3 {
        color: #57996b; }
  body h3, .jgnt_page h3 {
    font-size: 12px;
    text-transform: uppercase;
    color: #989898;
    font-weight: bold; }
  body .header_article_type, .jgnt_page .header_article_type {
    height: 34px;
    text-align: center;
    position: fixed;
    top: 25px;
    width: 100%;
    background: #485f99;
    color: #fff;
    line-height: 34px;
    left: 0;
    transition: ease-out 0.2s all;
    z-index: 109; }
    body .header_article_type.display_as_view, .jgnt_page .header_article_type.display_as_view {
      display: none; }
    body .header_article_type.selected, .jgnt_page .header_article_type.selected {
      top: 55px; }
    body .header_article_type.current_draft, .jgnt_page .header_article_type.current_draft {
      background: #ec5c57; }
    body .header_article_type.personal, .jgnt_page .header_article_type.personal {
      background: #57996b; }
    body .header_article_type.draft, .jgnt_page .header_article_type.draft {
      background: #485f99; }
    body .header_article_type.public, .jgnt_page .header_article_type.public {
      background: #989898; }
    body .header_article_type.archive, .jgnt_page .header_article_type.archive {
      background: #333333; }
    body .header_article_type.export, .jgnt_page .header_article_type.export {
      background: #9185be; }
  body > header, .jgnt_page > header {
    width: 100%;
    position: fixed;
    top: 0;
    background: #fff;
    height: 55px;
    z-index: 100;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 0 0px;
    z-index: 110; }
    body > header.advert, .jgnt_page > header.advert {
      height: 352px; }
      body > header.advert.shrink, .jgnt_page > header.advert.shrink {
        top: -297px; }
    body > header .special_stuff, .jgnt_page > header .special_stuff {
      height: 297px; }
      body > header .special_stuff .wrapper, .jgnt_page > header .special_stuff .wrapper {
        padding: 18px 0 0 0;
        height: 297px;
        position: relative; }
      body > header .special_stuff .broadcast, .jgnt_page > header .special_stuff .broadcast {
        margin: 11px 0 0 0; }
      body > header .special_stuff .fond, .jgnt_page > header .special_stuff .fond {
        bottom: 33px; }
    body > header.broadcast_header, body > header.broadcast_short_header, body > header.media_header, .jgnt_page > header.broadcast_header, .jgnt_page > header.broadcast_short_header, .jgnt_page > header.media_header {
      position: relative;
      height: auto;
      box-shadow: none;
      z-index: 1; }
      body > header.broadcast_header .wrapper, body > header.broadcast_short_header .wrapper, body > header.media_header .wrapper, .jgnt_page > header.broadcast_header .wrapper, .jgnt_page > header.broadcast_short_header .wrapper, .jgnt_page > header.media_header .wrapper {
        position: relative; }
      body > header.broadcast_header .edit, body > header.broadcast_short_header .edit, body > header.media_header .edit, .jgnt_page > header.broadcast_header .edit, .jgnt_page > header.broadcast_short_header .edit, .jgnt_page > header.media_header .edit {
        position: absolute;
        right: 10px;
        top: 64px; }
        body > header.broadcast_header .edit .button, body > header.broadcast_short_header .edit .button, body > header.media_header .edit .button, .jgnt_page > header.broadcast_header .edit .button, .jgnt_page > header.broadcast_short_header .edit .button, .jgnt_page > header.media_header .edit .button {
          height: 34px;
          line-height: 34px;
          border-radius: 30px;
          margin: 0 10px 0 0;
          display: inline-block;
          padding: 0 17px;
          background: #333333;
          color: #fff;
          font-weight: bold; }
          body > header.broadcast_header .edit .button:last-child, body > header.broadcast_short_header .edit .button:last-child, body > header.media_header .edit .button:last-child, .jgnt_page > header.broadcast_header .edit .button:last-child, .jgnt_page > header.broadcast_short_header .edit .button:last-child, .jgnt_page > header.media_header .edit .button:last-child {
            margin: 0; }
      body > header.broadcast_header ul.links, body > header.broadcast_short_header ul.links, body > header.media_header ul.links, .jgnt_page > header.broadcast_header ul.links, .jgnt_page > header.broadcast_short_header ul.links, .jgnt_page > header.media_header ul.links {
        position: absolute;
        top: 90px;
        right: 0;
        list-style: none; }
        body > header.broadcast_header ul.links li, body > header.broadcast_short_header ul.links li, body > header.media_header ul.links li, .jgnt_page > header.broadcast_header ul.links li, .jgnt_page > header.broadcast_short_header ul.links li, .jgnt_page > header.media_header ul.links li {
          display: inline-block;
          margin: 0 10px 0 0; }
          body > header.broadcast_header ul.links li:last-child, body > header.broadcast_short_header ul.links li:last-child, body > header.media_header ul.links li:last-child, .jgnt_page > header.broadcast_header ul.links li:last-child, .jgnt_page > header.broadcast_short_header ul.links li:last-child, .jgnt_page > header.media_header ul.links li:last-child {
            margin: 0; }
          body > header.broadcast_header ul.links li a, body > header.broadcast_short_header ul.links li a, body > header.media_header ul.links li a, .jgnt_page > header.broadcast_header ul.links li a, .jgnt_page > header.broadcast_short_header ul.links li a, .jgnt_page > header.media_header ul.links li a {
            display: block;
            color: #333333;
            font-size: 18px; }
    body > header.broadcast_short_header, .jgnt_page > header.broadcast_short_header {
      background: #333333;
      min-height: 176px; }
      body > header.broadcast_short_header > .wrapper, .jgnt_page > header.broadcast_short_header > .wrapper {
        min-height: 176px; }
      body > header.broadcast_short_header h1, .jgnt_page > header.broadcast_short_header h1 {
        font-size: 48px;
        font-weight: normal;
        color: #fff;
        position: absolute;
        bottom: 29px;
        left: 0;
        white-space: nowrap;
        overflow: hidden;
        width: 100%; }
        body > header.broadcast_short_header h1 strong, .jgnt_page > header.broadcast_short_header h1 strong {
          text-transform: none; }
      body > header.broadcast_short_header ul.links, .jgnt_page > header.broadcast_short_header ul.links {
        bottom: 20px;
        list-style: outside none none;
        position: absolute;
        right: auto;
        top: auto; }
        body > header.broadcast_short_header ul.links li a, .jgnt_page > header.broadcast_short_header ul.links li a {
          color: #fff;
          margin: 0 20px 0 0; }
        body > header.broadcast_short_header ul.links li a.button, .jgnt_page > header.broadcast_short_header ul.links li a.button {
          color: #fff;
          font-size: 14px;
          border: 1px solid #fff; }
        body > header.broadcast_short_header ul.links .subscription_button .sub, .jgnt_page > header.broadcast_short_header ul.links .subscription_button .sub {
          border: 1px solid #fff; }
      body > header.broadcast_short_header .tint, .jgnt_page > header.broadcast_short_header .tint {
        position: absolute;
        width: 100%;
        height: 100vh;
        left: 0;
        top: 0; }
      body > header.broadcast_short_header nav.main_menu, .jgnt_page > header.broadcast_short_header nav.main_menu {
        margin: 20px 0 0 0; }
        body > header.broadcast_short_header nav.main_menu ul li.hidden, .jgnt_page > header.broadcast_short_header nav.main_menu ul li.hidden {
          display: none; }
        body > header.broadcast_short_header nav.main_menu ul li a, .jgnt_page > header.broadcast_short_header nav.main_menu ul li a {
          color: #fff; }
        body > header.broadcast_short_header nav.main_menu ul li .button, .jgnt_page > header.broadcast_short_header nav.main_menu ul li .button {
          border: 1px solid #fff;
          /*
              &#back_to_broadcast{
                border:1px solid №ааа;
                font-weight: normal;

              }
*/ }
        body > header.broadcast_short_header nav.main_menu ul li a.media_logo, .jgnt_page > header.broadcast_short_header nav.main_menu ul li a.media_logo {
          margin: 23px 0 55px 0; }
      body > header.broadcast_short_header .wrapper, .jgnt_page > header.broadcast_short_header .wrapper {
        position: relative;
        height: 100%; }
      body > header.broadcast_short_header .edit, .jgnt_page > header.broadcast_short_header .edit {
        top: auto;
        bottom: 20px; }
    body > header.broadcast_header, .jgnt_page > header.broadcast_header {
      height: 450px; }
      body > header.broadcast_header .edit, .jgnt_page > header.broadcast_header .edit {
        top: 120px; }
      body > header.broadcast_header a, .jgnt_page > header.broadcast_header a {
        color: #fff; }
      body > header.broadcast_header .wrapper, .jgnt_page > header.broadcast_header .wrapper {
        position: relative;
        height: 100%; }
      body > header.broadcast_header .tint, .jgnt_page > header.broadcast_header .tint {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0; }
      body > header.broadcast_header ul.links li a, .jgnt_page > header.broadcast_header ul.links li a {
        color: #fff; }
      body > header.broadcast_header ul.links .subscription_button .sub, .jgnt_page > header.broadcast_header ul.links .subscription_button .sub {
        border: 1px solid #fff; }
      body > header.broadcast_header .broadcast_info, .jgnt_page > header.broadcast_header .broadcast_info {
        position: absolute;
        bottom: 0px;
        left: 0px;
        color: #fff; }
        body > header.broadcast_header .broadcast_info h1, .jgnt_page > header.broadcast_header .broadcast_info h1 {
          font-size: 48px;
          font-weight: bold;
          margin: 0 0 4px 0; }
        body > header.broadcast_header .broadcast_info p, .jgnt_page > header.broadcast_header .broadcast_info p {
          font-size: 14px;
          font-weight: bold;
          margin: 0 0 16px 0; }
        body > header.broadcast_header .broadcast_info .sponsored_tags, .jgnt_page > header.broadcast_header .broadcast_info .sponsored_tags {
          height: 23px;
          margin: 0 0 20px 0; }
      body > header.broadcast_header nav.main_menu ul li .button, .jgnt_page > header.broadcast_header nav.main_menu ul li .button {
        color: #fff;
        border: 1px solid #fff; }
    body > header.broadcast_header.mediapolygon, .jgnt_page > header.broadcast_header.mediapolygon {
      height: 560px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover; }
      body > header.broadcast_header.mediapolygon .logo, .jgnt_page > header.broadcast_header.mediapolygon .logo {
        margin: 35px 0 0 0; }
      body > header.broadcast_header.mediapolygon .logo_spacer.white, .jgnt_page > header.broadcast_header.mediapolygon .logo_spacer.white {
        top: 30px; }
      body > header.broadcast_header.mediapolygon .edit, .jgnt_page > header.broadcast_header.mediapolygon .edit {
        top: 120px;
        right: 0; }
        body > header.broadcast_header.mediapolygon .edit a.button, .jgnt_page > header.broadcast_header.mediapolygon .edit a.button {
          display: block;
          background: none;
          border: 1px solid #fff;
          color: #fff;
          margin: 0 0 10px;
          width: 115px;
          padding: 0;
          height: 34px;
          line-height: 34px;
          text-align: center; }
      body > header.broadcast_header.mediapolygon nav.main_menu, .jgnt_page > header.broadcast_header.mediapolygon nav.main_menu {
        text-transform: uppercase; }
        body > header.broadcast_header.mediapolygon nav.main_menu.to_left, .jgnt_page > header.broadcast_header.mediapolygon nav.main_menu.to_left {
          margin: -15px 0 0 0; }
        body > header.broadcast_header.mediapolygon nav.main_menu.common, .jgnt_page > header.broadcast_header.mediapolygon nav.main_menu.common {
          top: 30px;
          position: relative; }
        body > header.broadcast_header.mediapolygon nav.main_menu ul li, .jgnt_page > header.broadcast_header.mediapolygon nav.main_menu ul li {
          color: #fff; }
          body > header.broadcast_header.mediapolygon nav.main_menu ul li.profile:hover > a, .jgnt_page > header.broadcast_header.mediapolygon nav.main_menu ul li.profile:hover > a {
            color: #fff; }
          body > header.broadcast_header.mediapolygon nav.main_menu ul li.profile > a:hover, .jgnt_page > header.broadcast_header.mediapolygon nav.main_menu ul li.profile > a:hover {
            color: #57996b; }
        body > header.broadcast_header.mediapolygon nav.main_menu ul li.search.selected .search_icon_position, .jgnt_page > header.broadcast_header.mediapolygon nav.main_menu ul li.search.selected .search_icon_position {
          color: #d9d9d9; }
        body > header.broadcast_header.mediapolygon nav.main_menu ul li.search .search_icon_position, .jgnt_page > header.broadcast_header.mediapolygon nav.main_menu ul li.search .search_icon_position {
          color: #fff; }
      body > header.broadcast_header.mediapolygon .broadcast_info, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info {
        text-align: center;
        top: 245px;
        bottom: auto;
        width: 780px;
        left: 80px; }
        body > header.broadcast_header.mediapolygon .broadcast_info .ribakov, body > header.broadcast_header.mediapolygon .broadcast_info .other_partners, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .ribakov, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .other_partners {
          position: absolute; }
          body > header.broadcast_header.mediapolygon .broadcast_info .ribakov label, body > header.broadcast_header.mediapolygon .broadcast_info .other_partners label, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .ribakov label, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .other_partners label {
            font-size: 14px;
            font-weight: normal;
            color: #f4f4f4; }
        body > header.broadcast_header.mediapolygon .broadcast_info .ribakov, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .ribakov {
          left: 304px;
          top: -82px; }
          body > header.broadcast_header.mediapolygon .broadcast_info .ribakov label, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .ribakov label {
            width: 100%;
            display: block; }
          body > header.broadcast_header.mediapolygon .broadcast_info .ribakov .image, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .ribakov .image {
            background: transparent url("/img/les/fond-riba2.png") no-repeat scroll 0 0/157px auto;
            height: 23px;
            margin: 3px 0 0;
            display: block; }
        body > header.broadcast_header.mediapolygon .broadcast_info .other_partners, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .other_partners {
          top: -73px;
          right: 165px; }
        body > header.broadcast_header.mediapolygon .broadcast_info .subscribe_icon, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info .subscribe_icon {
          position: absolute;
          right: 130px;
          top: 10px; }
        body > header.broadcast_header.mediapolygon .broadcast_info h1, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info h1 {
          margin: 0 0 38px 0; }
        body > header.broadcast_header.mediapolygon .broadcast_info p, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info p {
          font-weight: normal;
          line-height: 22px; }
        body > header.broadcast_header.mediapolygon .broadcast_info #broadcast_type_form, .jgnt_page > header.broadcast_header.mediapolygon .broadcast_info #broadcast_type_form {
          font-weight: bold;
          display: inline-block;
          padding: 0 20px;
          height: 34px;
          margin: 21px 0 0 0;
          line-height: 34px;
          border: 1px solid #fff;
          border-radius: 30px; }
    body > header .article_status, .jgnt_page > header .article_status {
      float: left;
      line-height: 55px;
      margin: 0 0 0 20px; }
    body > header .logo_spacer, .jgnt_page > header .logo_spacer {
      height: 37px;
      top: 10px;
      position: relative;
      float: left;
      width: 1px;
      background: #000;
      margin: 0 13px 0 13px; }
      body > header .logo_spacer.white, .jgnt_page > header .logo_spacer.white {
        background: #fff; }
    body > header .logo, .jgnt_page > header .logo {
      margin: 11px 0 0 0;
      float: left; }
      body > header .logo.logo_h, .jgnt_page > header .logo.logo_h {
        margin: 0; }
        body > header .logo.logo_h img, .jgnt_page > header .logo.logo_h img {
          height: 55px;
          top: 0; }
      body > header .logo .landing_name, .jgnt_page > header .logo .landing_name {
        font-size: 24px;
        font-weight: bold;
        color: #000;
        text-transform: uppercase;
        position: absolute;
        max-width: 245px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      body > header .logo.landing_logo img, .jgnt_page > header .logo.landing_logo img {
        height: 32px;
        width: auto; }
      body > header .logo .icon-logo, .jgnt_page > header .logo .icon-logo {
        font-size: 83px;
        margin: -27px 0 0;
        height: 70px;
        overflow: hidden; }
      body > header .logo img, .jgnt_page > header .logo img {
        display: block;
        height: 27px;
        position: relative;
        top: 4px; }
    body > header .live-feed-banner, .jgnt_page > header .live-feed-banner {
      background: #57996b none repeat scroll 0 0;
      box-sizing: border-box;
      color: #fff;
      float: left;
      height: 30px;
      left: 10px;
      line-height: 30px;
      overflow: hidden;
      padding: 0;
      position: relative;
      text-align: center;
      top: 10px;
      white-space: nowrap;
      width: 0px;
      transition: 0.2s ease-in width; }
      body > header .live-feed-banner.selected, .jgnt_page > header .live-feed-banner.selected {
        width: 100px; }
    body > header a, .jgnt_page > header a {
      color: #333333; }
    body > header nav.main_menu, .jgnt_page > header nav.main_menu {
      float: right;
      font-weight: bold; }
      body > header nav.main_menu.main_folders, .jgnt_page > header nav.main_menu.main_folders {
        margin: 0 0 0 24px;
        position: absolute;
        margin: 0 0 0 108px; }
        body > header nav.main_menu.main_folders a, .jgnt_page > header nav.main_menu.main_folders a {
          font-weight: bold; }
      body > header nav.main_menu.to_left, .jgnt_page > header nav.main_menu.to_left {
        float: left;
        font-weight: bold; }
        body > header nav.main_menu.to_left ul li a.selected, .jgnt_page > header nav.main_menu.to_left ul li a.selected {
          color: #333333;
          font-weight: bold; }
          body > header nav.main_menu.to_left ul li a.selected.media_logo:after, .jgnt_page > header nav.main_menu.to_left ul li a.selected.media_logo:after {
            display: none; }
          body > header nav.main_menu.to_left ul li a.selected:after, .jgnt_page > header nav.main_menu.to_left ul li a.selected:after {
            border-top: 1px solid #333333;
            content: " ";
            display: block;
            position: relative;
            top: -20px;
            width: 100%; }
      body > header nav.main_menu ul, .jgnt_page > header nav.main_menu ul {
        height: 100%;
        line-height: 55px; }
        body > header nav.main_menu ul li, .jgnt_page > header nav.main_menu ul li {
          display: block;
          float: left;
          margin: 0 20px 0 0;
          position: relative;
          color: #989898; }
          body > header nav.main_menu ul li .media_logo, .jgnt_page > header nav.main_menu ul li .media_logo {
            margin: 23px 0 20px 0;
            min-height: 75px; }
            body > header nav.main_menu ul li .media_logo.mediapoly, .jgnt_page > header nav.main_menu ul li .media_logo.mediapoly {
              margin: 0; }
              body > header nav.main_menu ul li .media_logo.mediapoly img, .jgnt_page > header nav.main_menu ul li .media_logo.mediapoly img {
                width: 188px; }
            body > header nav.main_menu ul li .media_logo:hover .resize, .jgnt_page > header nav.main_menu ul li .media_logo:hover .resize {
              display: block; }
            body > header nav.main_menu ul li .media_logo img, .jgnt_page > header nav.main_menu ul li .media_logo img {
              width: 100px;
              display: block;
              position: relative;
              z-index: 10; }
            body > header nav.main_menu ul li .media_logo .resize, .jgnt_page > header nav.main_menu ul li .media_logo .resize {
              background: transparent url("/img/les/resize.svg") repeat scroll 0 0;
              bottom: 13px;
              display: none;
              height: 16px;
              position: absolute;
              right: -8px;
              width: 16px;
              z-index: 5; }
          body > header nav.main_menu ul li .button, .jgnt_page > header nav.main_menu ul li .button {
            background: transparent none repeat scroll 0 0;
            border: 1px solid #57996b;
            color: #57996b;
            border-radius: 30px;
            font-weight: bold;
            height: 32px;
            line-height: 32px;
            margin: 10px 0 0;
            padding: 0 20px;
            text-align: center;
            vertical-align: top; }
            body > header nav.main_menu ul li .button.black, .jgnt_page > header nav.main_menu ul li .button.black {
              background: #333333;
              border: 1px solid #333333;
              color: #fff; }
          body > header nav.main_menu ul li.search .name, .jgnt_page > header nav.main_menu ul li.search .name {
            margin: -10px 0 0 -11px; }
          body > header nav.main_menu ul li.search.selected .search_text, .jgnt_page > header nav.main_menu ul li.search.selected .search_text {
            width: 220px;
            border: 1px solid #f4f4f4;
            color: #333333;
            padding: 0 30px;
            background: #fff; }
          body > header nav.main_menu ul li.search.selected .search_icon_position, .jgnt_page > header nav.main_menu ul li.search.selected .search_icon_position {
            left: 10px;
            color: #d9d9d9; }
          body > header nav.main_menu ul li.search .search_text, .jgnt_page > header nav.main_menu ul li.search .search_text {
            width: 17px;
            padding: 0;
            transition: 0.2s ease-in all;
            border: 1px transparent solid;
            background: none; }
          body > header nav.main_menu ul li.search .search_icon_position, .jgnt_page > header nav.main_menu ul li.search .search_icon_position {
            color: #333333;
            left: 0;
            transition: 0.2s ease-in all; }
          body > header nav.main_menu ul li.nav .name, .jgnt_page > header nav.main_menu ul li.nav .name {
            margin: -10px 0 0 -12px; }
          body > header nav.main_menu ul li.home .name, .jgnt_page > header nav.main_menu ul li.home .name {
            margin: -10px 0 0 -21px; }
          body > header nav.main_menu ul li.notice .name, .jgnt_page > header nav.main_menu ul li.notice .name {
            margin: -10px 0 0 -26px; }
          body > header nav.main_menu ul li.post .name, .jgnt_page > header nav.main_menu ul li.post .name {
            margin: -10px 0 0 -29px; }
          body > header nav.main_menu ul li.profile, .jgnt_page > header nav.main_menu ul li.profile {
            /*

						&:hover{
							>a{
								color:$black;
								text-decoration: underline;
							}
						}
*/ }
            body > header nav.main_menu ul li.profile > a, .jgnt_page > header nav.main_menu ul li.profile > a {
              font-weight: bold; }
              body > header nav.main_menu ul li.profile > a:hover, .jgnt_page > header nav.main_menu ul li.profile > a:hover {
                color: #333333;
                text-decoration: underline; }
            body > header nav.main_menu ul li.profile.unsigned a, .jgnt_page > header nav.main_menu ul li.profile.unsigned a {
              display: inline-block; }
          body > header nav.main_menu ul li:last-child, .jgnt_page > header nav.main_menu ul li:last-child {
            margin: 0; }
          body > header nav.main_menu ul li:hover a .name, .jgnt_page > header nav.main_menu ul li:hover a .name {
            display: block; }
          body > header nav.main_menu ul li a, .jgnt_page > header nav.main_menu ul li a {
            display: block;
            height: 100%; }
            body > header nav.main_menu ul li a .icon, .jgnt_page > header nav.main_menu ul li a .icon {
              height: 14px;
              top: 3px;
              width: 18px;
              position: relative;
              display: inline-block;
              font-size: 19px; }
            body > header nav.main_menu ul li a .icon-close, .jgnt_page > header nav.main_menu ul li a .icon-close {
              color: #57996b;
              font-size: 14px; }
            body > header nav.main_menu ul li a .name, .jgnt_page > header nav.main_menu ul li a .name {
              display: none;
              position: absolute;
              line-height: normal;
              white-space: nowrap;
              font-size: 8px;
              background: #57996b;
              padding: 3px 8px;
              margin: -10px 0 0 0;
              color: #fff; }
              body > header nav.main_menu ul li a .name::before, .jgnt_page > header nav.main_menu ul li a .name::before {
                content: " ";
                border-bottom: 5px solid #57996b;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                height: 0;
                left: 50%;
                margin: -7px 0 0 -3px;
                position: absolute;
                width: 0; }
            body > header nav.main_menu ul li a.selected, body > header nav.main_menu ul li a:hover, .jgnt_page > header nav.main_menu ul li a.selected, .jgnt_page > header nav.main_menu ul li a:hover {
              color: #fff;
              color: #57996b; }
          body > header nav.main_menu ul li:hover nav.sub_menu, .jgnt_page > header nav.main_menu ul li:hover nav.sub_menu {
            display: block; }
          body > header nav.main_menu ul li nav.sub_menu, .jgnt_page > header nav.main_menu ul li nav.sub_menu {
            display: none;
            left: 50%;
            margin: -14px -10px 0 -70px;
            position: absolute;
            width: 141px; }
            body > header nav.main_menu ul li nav.sub_menu .arrow_up, .jgnt_page > header nav.main_menu ul li nav.sub_menu .arrow_up {
              background: #fff none repeat scroll 0 0;
              border: medium none;
              box-shadow: -2px -2px 3px -2px rgba(0, 0, 0, 0.32);
              content: "";
              display: block;
              left: 50%;
              height: 8px;
              margin: -2px 0 0 -5px;
              width: 8px;
              position: absolute;
              transform: rotate(45deg) translate(6px, 6px);
              z-index: 4; }
            body > header nav.main_menu ul li nav.sub_menu ul, .jgnt_page > header nav.main_menu ul li nav.sub_menu ul {
              background: #fff;
              display: block;
              position: relative;
              margin: 10px 0 0 0;
              width: 140px;
              box-sizing: border-box;
              border-radius: 10px;
              padding: 15px 18px;
              line-height: normal;
              clear: both;
              border: 1px solid #f7f7f7;
              box-shadow: 0 0 4px rgba(0, 0, 0, 0.32); }
              body > header nav.main_menu ul li nav.sub_menu ul li, .jgnt_page > header nav.main_menu ul li nav.sub_menu ul li {
                height: 35px;
                font-size: 14px;
                font-weight: normal;
                clear: both;
                line-height: 35px;
                margin: 0 0 0px 0;
                width: 100%;
                float: none; }
                body > header nav.main_menu ul li nav.sub_menu ul li:hover a span.folder, .jgnt_page > header nav.main_menu ul li nav.sub_menu ul li:hover a span.folder {
                  text-decoration: underline; }
                body > header nav.main_menu ul li nav.sub_menu ul li a, .jgnt_page > header nav.main_menu ul li nav.sub_menu ul li a {
                  color: #333; }
                  body > header nav.main_menu ul li nav.sub_menu ul li a .ico, .jgnt_page > header nav.main_menu ul li nav.sub_menu ul li a .ico {
                    font-size: 14px;
                    margin: 0 9px 0 0; }
  @media (max-width: 760px) {
    body header nav.main_menu.to_left, .jgnt_page header nav.main_menu.to_left {
      position: relative;
      float: left;
      margin-left: 1rem;
      margin-right: 1rem; }
    body header nav.main_menu .profile, body header nav.main_menu .search, .jgnt_page header nav.main_menu .profile, .jgnt_page header nav.main_menu .search {
      display: none; }
    body.post_page header nav.main_menu .profile, body.post_page header nav.main_menu .search, .jgnt_page.post_page header nav.main_menu .profile, .jgnt_page.post_page header nav.main_menu .search {
      display: block; }
    body.post_page header nav.main_menu ul, .jgnt_page.post_page header nav.main_menu ul {
      line-height: 30px;
      margin: 12px 0; }
    body.post_page header nav.main_menu .search_icon_position, .jgnt_page.post_page header nav.main_menu .search_icon_position {
      top: 9px; } }
  @media (max-width: 480px) {
    body.post_page header nav.main_menu ul li, .jgnt_page.post_page header nav.main_menu ul li {
      margin-right: 5px; } }
  body section.wrapper, .jgnt_page section.wrapper {
    margin: 55px auto 0 auto;
    box-sizing: border-box;
    padding: 0 40px 44px 40px;
    width: 100%;
    max-width: 1020px;
    background: #fff; }
    body section.wrapper.map_wrap, .jgnt_page section.wrapper.map_wrap {
      margin: 49px auto 0 auto;
      padding: 55px 40px 0;
      position: relative; }
    body section.wrapper.flat_wrap, .jgnt_page section.wrapper.flat_wrap {
      margin-top: 0;
      padding: 0px 40px 44px 40px; }
    body section.wrapper.home_wrap, .jgnt_page section.wrapper.home_wrap {
      padding: 0px 40px 44px 40px; }
    body section.wrapper.profile_wrap, .jgnt_page section.wrapper.profile_wrap {
      padding: 0;
      width: 940px; }
    body section.wrapper.landing_wrap, .jgnt_page section.wrapper.landing_wrap {
      margin: 20px auto 67px auto;
      padding: 0 40px 0; }
    body section.wrapper .rubrics, .jgnt_page section.wrapper .rubrics {
      display: block;
      width: 100%;
      font-size: 12px;
      font-weight: bold;
      display: block;
      margin: -17px 0 40px; }
      body section.wrapper .rubrics ul, .jgnt_page section.wrapper .rubrics ul {
        list-style: none;
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between; }
        body section.wrapper .rubrics ul li, .jgnt_page section.wrapper .rubrics ul li {
          display: block; }
          body section.wrapper .rubrics ul li:hover a, body section.wrapper .rubrics ul li.selected a, .jgnt_page section.wrapper .rubrics ul li:hover a, .jgnt_page section.wrapper .rubrics ul li.selected a {
            color: #57996b; }
          body section.wrapper .rubrics ul li a, .jgnt_page section.wrapper .rubrics ul li a {
            display: block;
            height: 100%;
            color: #333; }
    body section.wrapper .cell3, body section.wrapper .cell6, body section.wrapper .cell9, .jgnt_page section.wrapper .cell3, .jgnt_page section.wrapper .cell6, .jgnt_page section.wrapper .cell9 {
      float: left; }
    body section.wrapper .articles.cell6, .jgnt_page section.wrapper .articles.cell6 {
      margin: 0px 0px 0 20px; }
      body section.wrapper .articles.cell6 article:nth-child(even), .jgnt_page section.wrapper .articles.cell6 article:nth-child(even) {
        float: right; }
    body section.wrapper .articles.cell9, .jgnt_page section.wrapper .articles.cell9 {
      width: 720px; }
      body section.wrapper .articles.cell9 nav.aside_nav.cell3, .jgnt_page section.wrapper .articles.cell9 nav.aside_nav.cell3 {
        float: right;
        margin: 0 20px 0 0;
        height: 588px; }
      body section.wrapper .articles.cell9 article, .jgnt_page section.wrapper .articles.cell9 article {
        vertical-align: top;
        margin: 0 20px 20px 0; }
      body section.wrapper .articles.cell9 .load_more, .jgnt_page section.wrapper .articles.cell9 .load_more {
        margin: 0 20px 45px 0; }
    body section.wrapper > aside .fixed_holder, .jgnt_page section.wrapper > aside .fixed_holder {
      width: 220px; }
    body section.wrapper > aside section, .jgnt_page section.wrapper > aside section {
      width: 220px;
      background: #fff;
      margin: 0 0 1px 0;
      box-sizing: border-box;
      padding: 24px 20px 16px 20px;
      font-size: 12px; }
      body section.wrapper > aside section h3, .jgnt_page section.wrapper > aside section h3 {
        margin: 0 0 10px; }
      body section.wrapper > aside section p, .jgnt_page section.wrapper > aside section p {
        line-height: 20px; }
  body > footer, .jgnt_page > footer {
    width: 100%;
    background: #f4f4f4; }
    body > footer .wrapper, .jgnt_page > footer .wrapper {
      padding: 40px 0; }
    body > footer nav.lowest, .jgnt_page > footer nav.lowest {
      width: 100%;
      background: #333333; }
      body > footer nav.lowest .wrapper, .jgnt_page > footer nav.lowest .wrapper {
        position: relative; }
      body > footer nav.lowest .icon-logo, .jgnt_page > footer nav.lowest .icon-logo {
        color: #989898;
        font-size: 83px;
        position: absolute;
        top: 8px; }
      body > footer nav.lowest ul, .jgnt_page > footer nav.lowest ul {
        text-align: left;
        float: right; }
        body > footer nav.lowest ul li, .jgnt_page > footer nav.lowest ul li {
          display: inline-block;
          margin: 0 20px 0 0; }
          body > footer nav.lowest ul li:last-child, .jgnt_page > footer nav.lowest ul li:last-child {
            margin: 0; }
          body > footer nav.lowest ul li a, .jgnt_page > footer nav.lowest ul li a {
            color: #989898; }
            body > footer nav.lowest ul li a:hover, .jgnt_page > footer nav.lowest ul li a:hover {
              color: #fff; }

.city_search {
  line-height: 60px;
  height: 60px; }
  .city_search span {
    text-transform: uppercase;
    margin: 0 20px 0 0; }
  .city_search button {
    border-radius: 30px;
    height: 35px;
    line-height: 35px;
    position: relative;
    top: 3px; }
  .city_search input[type='text'] {
    border: none;
    background: #fff;
    box-shadow: none;
    height: 35px;
    line-height: 35px;
    width: 220px;
    box-sizing: border-box;
    padding: 0 20px;
    margin: 0 20px 0 0; }

.ratings {
  background: #f4f4f4;
  width: 100%;
  padding: 40px 0; }
  .ratings h2 {
    font-size: 15px;
    font-weight: bold;
    margin: 0 0 30px 0;
    text-transform: uppercase;
    padding: 0 20px;
    box-sizing: border-box; }
    .ratings h2 nav.time {
      text-transform: none;
      display: inline-block;
      margin: 0 0 0 10px;
      font-size: 10px; }
      .ratings h2 nav.time a {
        color: #333333; }
        .ratings h2 nav.time a.selected, .ratings h2 nav.time a:hover {
          color: #57996b; }
  .ratings nav.categories {
    font-size: 12px;
    font-weight: bold;
    margin: 0 0 30px 0;
    padding: 0 20px;
    box-sizing: border-box; }
    .ratings nav.categories ul li {
      display: inline-block;
      margin: 0 15px 0 0; }
      .ratings nav.categories ul li:hover a, .ratings nav.categories ul li.selected a {
        border-bottom: 3px solid #57996b;
        transition: 0.2s ease-out border;
        color: #57996b; }
      .ratings nav.categories ul li a {
        display: block;
        padding: 0 0 8px 0;
        border-bottom: 3px solid rgba(255, 78, 80, 0);
        transition: 0.2s ease-out border;
        color: #333333; }
  .ratings .articles_list ul {
    list-style: none;
    display: none; }
    .ratings .articles_list ul.selected {
      display: block; }
      .ratings .articles_list ul.selected li {
        width: 220px;
        margin: 0 20px 16px 0;
        padding: 0 20px;
        box-sizing: border-box;
        display: inline-block;
        vertical-align: top; }
        .ratings .articles_list ul.selected li:nth-child(3n+3) {
          margin: 0 0 10px 0; }
        .ratings .articles_list ul.selected li a {
          color: #333333;
          padding: 0 0 13px 0;
          border-bottom: 1px solid #d9d9d9;
          display: block; }
          .ratings .articles_list ul.selected li a h3 {
            font-size: 12px;
            color: #333333;
            text-transform: none;
            height: 20px;
            overflow: hidden; }

.pagination {
  display: flex;
  float: left;
  width: 100%;
  text-align: center;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 40px; }
  .pagination li {
    margin: 0 5px; }
    .pagination li a:hover {
      color: #000; }

#add_options.add_options {
  display: none; }

#autosave_template {
  display: none; }

#template.template {
  display: none; }

#veil_date_toggle,
#veil_about_me_toggle,
#veil_cover_toggle {
  display: none; }
  #veil_date_toggle.selected,
  #veil_about_me_toggle.selected,
  #veil_cover_toggle.selected {
    display: block; }

#load-other-preview {
  display: none; }

.range-slider {
  height: 125px;
  left: 0px;
  z-index: 20; }

ul.modify {
  display: none;
  position: absolute;
  top: 0;
  right: 35px;
  list-style: none;
  z-index: 105; }
  ul.modify .icon-english,
  ul.modify .icon-russian {
    font-size: 13px; }
  ul.modify .circle {
    border: 2px solid #fff;
    border-radius: 10px;
    height: 13px;
    width: 13px; }
    ul.modify .circle .inner {
      background: #fff none repeat scroll 0 0;
      border-radius: 20px;
      height: 9px;
      margin: 2px 0 0 2px;
      width: 9px; }
  ul.modify:hover > li > a {
    background: #d9d9d9; }
  ul.modify > li {
    vertical-align: top;
    display: inline-block;
    margin: 0 5px 0px 0; }
    ul.modify > li:last-child {
      margin: 0; }
    ul.modify > li:hover a.switch_icon {
      background: #333333; }
      ul.modify > li:hover a.switch_icon div.default {
        display: none; }
      ul.modify > li:hover a.switch_icon div.toggle {
        display: block; }
    ul.modify > li:hover div.holder, ul.modify > li:hover div.style_holder, ul.modify > li:hover div.position_holder, ul.modify > li:hover div.bg_position_holder, ul.modify > li:hover .range-slider {
      display: block; }
    ul.modify > li .range-slider {
      display: none; }
    ul.modify > li div.holder {
      display: none;
      z-index: 21;
      position: absolute;
      white-space: nowrap; }
      ul.modify > li div.holder ul.sub_menu {
        display: block;
        color: #fff;
        list-style: none;
        font-size: 8px; }
        ul.modify > li div.holder ul.sub_menu li {
          margin: 3px 0 0 0; }
          ul.modify > li div.holder ul.sub_menu li:first-child {
            margin: 0; }
          ul.modify > li div.holder ul.sub_menu li a {
            position: relative;
            display: block;
            background: #333333;
            border-radius: 40px;
            width: 23px;
            height: 23px;
            z-index: 20; }
            ul.modify > li div.holder ul.sub_menu li a:hover {
              background: #57996b; }
            ul.modify > li div.holder ul.sub_menu li a div {
              text-align: center;
              color: #fff;
              line-height: 23px;
              font-size: 12px; }
            ul.modify > li div.holder ul.sub_menu li a .circle {
              border-radius: 10px;
              height: 13px;
              left: 3px;
              position: relative;
              top: 3px;
              width: 13px; }
              ul.modify > li div.holder ul.sub_menu li a .circle .inner {
                height: 12px;
                margin: 2px 0 0 2px;
                width: 12px; }
    ul.modify > li div.style_holder {
      position: absolute;
      background: #333333;
      border-radius: 5px;
      display: none;
      margin: 0 0 0 -60px;
      z-index: 10;
      text-align: left; }
      ul.modify > li div.style_holder ul.sub_menu {
        position: relative;
        width: 100%;
        padding: 1px 12px 5px 7px;
        list-style: none; }
        ul.modify > li div.style_holder ul.sub_menu li {
          width: 100%; }
          ul.modify > li div.style_holder ul.sub_menu li a {
            color: #fff; }
            ul.modify > li div.style_holder ul.sub_menu li a.deck {
              font-size: 24px;
              line-height: 33px;
              font-weight: bold; }
            ul.modify > li div.style_holder ul.sub_menu li a.leed {
              font-size: 24px;
              line-height: 33px; }
            ul.modify > li div.style_holder ul.sub_menu li a.text {
              font-size: 14px; }
            ul.modify > li div.style_holder ul.sub_menu li a.footnote {
              font-size: 10px; }
    ul.modify > li div.position_holder, ul.modify > li div.bg_position_holder {
      width: 66px;
      height: 73px;
      position: absolute;
      background: #333333;
      border-radius: 5px;
      margin: 0 0 0 -20px;
      box-sizing: border-box;
      padding: 11px;
      z-index: 10;
      display: none; }
      ul.modify > li div.position_holder ul.sub_menu, ul.modify > li div.bg_position_holder ul.sub_menu {
        display: block;
        list-style: none;
        position: relative;
        width: 100%;
        height: 100%; }
        ul.modify > li div.position_holder ul.sub_menu .circle .inner, ul.modify > li div.bg_position_holder ul.sub_menu .circle .inner {
          background: none; }
        ul.modify > li div.position_holder ul.sub_menu .circle:hover .inner, ul.modify > li div.position_holder ul.sub_menu .circle.selected .inner, ul.modify > li div.bg_position_holder ul.sub_menu .circle:hover .inner, ul.modify > li div.bg_position_holder ul.sub_menu .circle.selected .inner {
          background: #fff none repeat scroll 0 0; }
        ul.modify > li div.position_holder ul.sub_menu li, ul.modify > li div.bg_position_holder ul.sub_menu li {
          position: absolute; }
          ul.modify > li div.position_holder ul.sub_menu li.align-top-left, ul.modify > li div.bg_position_holder ul.sub_menu li.align-top-left {
            top: 0;
            left: 0; }
          ul.modify > li div.position_holder ul.sub_menu li.align-top-right, ul.modify > li div.bg_position_holder ul.sub_menu li.align-top-right {
            top: 0;
            right: 0; }
          ul.modify > li div.position_holder ul.sub_menu li.align-top, ul.modify > li div.bg_position_holder ul.sub_menu li.align-top {
            top: 0; }
          ul.modify > li div.position_holder ul.sub_menu li.align-center, ul.modify > li div.bg_position_holder ul.sub_menu li.align-center {
            top: 50%;
            left: 50%;
            margin: -7px 0 0 -7px; }
          ul.modify > li div.position_holder ul.sub_menu li.align-bottom-left, ul.modify > li div.bg_position_holder ul.sub_menu li.align-bottom-left {
            bottom: 0;
            left: 0; }
          ul.modify > li div.position_holder ul.sub_menu li.align-bottom, ul.modify > li div.bg_position_holder ul.sub_menu li.align-bottom {
            bottom: 0; }
          ul.modify > li div.position_holder ul.sub_menu li.align-bottom-right, ul.modify > li div.bg_position_holder ul.sub_menu li.align-bottom-right {
            bottom: 0;
            right: 0; }
    ul.modify > li > a {
      position: relative;
      display: block;
      background: #333333;
      border-radius: 40px;
      width: 23px;
      height: 23px;
      z-index: 20;
      margin: 0 0 3px 0;
      transition: background ease-out 0.1s; }
      ul.modify > li > a:hover {
        background: #333333; }
        ul.modify > li > a:hover span.name {
          display: block; }
      ul.modify > li > a .circle {
        border-radius: 10px;
        height: 13px;
        left: 3px;
        position: relative;
        top: 3px;
        width: 13px;
        border-radius: 10px; }
        ul.modify > li > a .circle .inner {
          height: 12px;
          margin: 2px 0 0 2px;
          width: 12px; }
      ul.modify > li > a.switch_icon div.default {
        display: block; }
      ul.modify > li > a.switch_icon div.toggle {
        display: none; }
      ul.modify > li > a .tooltip_holder {
        margin: 0 0 0 -2px;
        top: -23px; }
      ul.modify > li > a span.name {
        display: none;
        background: #333333 none repeat scroll 0 0;
        color: #fff;
        font-size: 10px;
        margin: 0 0 0 -5px;
        padding: 1px 7px 3px;
        position: absolute;
        top: -23px;
        white-space: nowrap; }
        ul.modify > li > a span.name::before {
          border-top: 5px solid #333333;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          height: 0;
          content: " ";
          margin: 1px 0px 0 10px;
          position: absolute;
          top: 17px;
          width: 0; }
      ul.modify > li > a div {
        text-align: center;
        color: #fff;
        line-height: 23px;
        font-size: 12px; }
        ul.modify > li > a div.icon-upload {
          font-size: 15px; }

.picture_frame {
  display: block;
  position: relative; }
  .picture_frame .tint {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }
    .picture_frame .tint.no-picture {
      background: url("/img/les/upload.svg") no-repeat center center; }
    .picture_frame .tint .progress {
      display: block;
      width: 295px;
      margin: -10px auto 0;
      text-align: center;
      top: 20%;
      position: relative; }
      .picture_frame .tint .progress .bar {
        width: 100%;
        border-radius: 3px;
        background: #d9d9d9;
        height: 5px; }
        .picture_frame .tint .progress .bar .percents {
          width: 0;
          background: #57996b;
          height: 100%;
          border-radius: 20px; }
      .picture_frame .tint .progress p {
        margin: 10px 0 0 0;
        font-weight: bold; }

.article_footer {
  height: 300px;
  clear: both; }

#rubric_idSelectBoxItContainer .selectboxit-options,
#rubric_idSelectBoxItContainer .selectboxit-btn {
  width: 220px;
  background: #fff; }

article.article_page {
  background: #fff;
  margin: 55px 0 0 0;
  height: 100%; }
  article.article_page #block_types_id.no_cover .full_background,
  article.article_page #block_types_id.no_cover .half_image,
  article.article_page #block_types_id.no_cover .half_gallery,
  article.article_page #block_types_id.no_cover .half_quote {
    display: none; }
  article.article_page .social.top.right a .val {
    position: relative;
    bottom: 5px; }
  article.article_page .social.top ul {
    font-size: 21px; }
    article.article_page .social.top ul li {
      margin: 0 8px 0 0; }
      article.article_page .social.top ul li a {
        color: #000; }
        article.article_page .social.top ul li a .val {
          font-size: 10px; }
  article.article_page.display_as_view .modify {
    display: none !important; }
  article.article_page.display_as_view header.editor {
    display: none !important; }
  article.article_page .creators {
    position: relative;
    margin: -3px 0 0 0;
    float: left; }
    article.article_page .creators ul {
      list-style: none; }
      article.article_page .creators ul li a {
        font-weight: bold;
        color: #000; }
  article.article_page .next_page {
    background: #57996b none repeat scroll 0 0;
    border-radius: 30px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 33px;
    left: 50%;
    line-height: 33px;
    margin: 0 0 0 470px;
    padding: 0 19px;
    position: fixed;
    text-align: center;
    top: 12px;
    z-index: 200; }
  article.article_page #load-cover {
    display: none; }
  article.article_page .inscription {
    min-height: 25px;
    font-size: 10px;
    line-height: normal;
    font-size: 14px;
    line-height: 26px;
    margin: 10px auto 0 auto;
    clear: both;
    display: block;
    color: #333333; }
  article.article_page .rslides_tabs {
    bottom: 53px;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 20;
    list-style: none; }
    article.article_page .rslides_tabs li {
      display: inline-block;
      margin: 0 5px 0 0; }
      article.article_page .rslides_tabs li:last-child {
        margin: 0; }
      article.article_page .rslides_tabs li.rslides_here a {
        background: #fff; }
      article.article_page .rslides_tabs li a {
        display: block;
        border: 1px solid #fff;
        border-radius: 20px;
        width: 10px;
        height: 10px;
        color: transparent; }
  article.article_page .rslides_frame:hover .rslides_nav {
    display: block; }
  article.article_page .rslides_frame .rslides_nav {
    display: none; }
  article.article_page .rslides_nav {
    position: absolute;
    bottom: 50%;
    z-index: 10;
    font-size: 24px;
    color: #fff;
    background: rgba(0, 0, 0, 0.1);
    border-radius: 0 12px 12px 0;
    margin: 0 0px -13px 0;
    padding: 2px 5px 2px 0; }
    article.article_page .rslides_nav .icon-arrow-up-thin {
      -moz-transform: rotate(-90deg);
      -webkit-transform: rotate(-90deg);
      -o-transform: rotate(-90deg);
      transform: rotate(-90deg);
      position: relative;
      display: block;
      padding: 5px 0;
      margin: 0 0 0 -7px;
      font-size: 48px; }
    article.article_page .rslides_nav.prev {
      left: 0;
      z-index: 20; }
    article.article_page .rslides_nav.next {
      right: 0;
      z-index: 20;
      -moz-transform: scaleX(-1);
      -webkit-transform: scaleX(-1);
      -o-transform: scaleX(-1);
      transform: scaleX(-1); }
  article.article_page #bottom_marker {
    position: relative;
    clear: both; }
  article.article_page [contenteditable="true"]:focus {
    background: rgba(0, 0, 0, 0.1); }
  article.article_page .saved_article {
    display: inline; }
  article.article_page .user_info .date.right {
    font-size: 14px;
    margin: 0px 0 0 0;
    text-transform: lowercase; }
    article.article_page .user_info .date.right strong {
      margin: -6px 0 0 0;
      font-weight: bold;
      display: block;
      line-height: 33px;
      height: 33px; }
  article.article_page .user_info .button {
    height: 33px;
    line-height: 33px;
    color: #fff;
    display: block;
    font-size: 14px;
    padding: 0 15px;
    border-radius: 20px;
    background: #d9d9d9;
    text-transform: none; }
  article.article_page .user_info .authors {
    display: inline-block;
    margin: 0 0 0 10px;
    max-width: 580px;
    min-height: 20px;
    min-width: 100px;
    vertical-align: top; }
  article.article_page .actions {
    width: 100%;
    box-sizing: border-box;
    padding: 32px 35px 22px;
    text-align: right; }
    article.article_page .actions button {
      display: inline-block;
      margin: 0 20px 0 0;
      border: 1px solid #57996b;
      color: #57996b;
      background: none;
      height: 32px;
      font-weight: bold; }
      article.article_page .actions button:hover {
        background: #57996b;
        color: #fff; }
      article.article_page .actions button:last-child {
        margin: 0; }
  article.article_page.guest section.block header.editor {
    display: none; }
  article.article_page.guest section.block:hover .modify {
    display: none; }
  article.article_page .aside_nav {
    position: fixed;
    left: 0;
    z-index: 2000;
    top: 75px; }
    article.article_page .aside_nav ul li {
      transition: 0.2s ease-out all;
      white-space: nowrap; }
      article.article_page .aside_nav ul li a .icon {
        font-size: 15px;
        position: relative;
        right: 2px; }
      article.article_page .aside_nav ul li .upload-percents {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 4px;
        background: #57996b;
        transition: 0.2s ease-out width; }
      article.article_page .aside_nav ul li #load-image-button,
      article.article_page .aside_nav ul li #load-cover-button {
        display: none; }
      article.article_page .aside_nav ul li.main {
        background: #57996b;
        z-index: 100; }
        article.article_page .aside_nav ul li.main .icon {
          color: #fff; }
        article.article_page .aside_nav ul li.main .name {
          color: #fff; }
        article.article_page .aside_nav ul li.main .count {
          color: #fff; }
      article.article_page .aside_nav ul li.child {
        background: #d9d9d9;
        z-index: 50; }
      article.article_page .aside_nav ul li.option {
        color: #989898;
        display: block; }
        article.article_page .aside_nav ul li.option a.button {
          display: inline-block;
          color: #989898;
          opacity: 1;
          transition: 2s ease-out all;
          flex-grow: 1;
          text-align: center; }
          article.article_page .aside_nav ul li.option a.button.half {
            width: 50%; }
          article.article_page .aside_nav ul li.option a.button.third {
            width: 33.333%; }
          article.article_page .aside_nav ul li.option a.button.quarter {
            width: 25%; }
          article.article_page .aside_nav ul li.option a.button.fifth {
            width: 20%; }
          article.article_page .aside_nav ul li.option a.button.sixth {
            width: 16.6666%; }
        article.article_page .aside_nav ul li.option.closed.lv1 {
          opacity: 0;
          margin: -55px 0 1px 0;
          display: block; }
          article.article_page .aside_nav ul li.option.closed.lv1 a.button {
            display: none;
            transition: 0.5s ease-in all; }
        article.article_page .aside_nav ul li.option .name {
          color: #989898; }
        article.article_page .aside_nav ul li.option .icon {
          color: #989898; }
      article.article_page .aside_nav ul li .name {
        opacity: 1;
        transition: 0.5s ease-in opacity;
        position: absolute;
        left: 63px; }
      article.article_page .aside_nav ul li .count {
        transition: 0.5s ease-in opacity; }
      article.article_page .aside_nav ul li.closed {
        width: 18%;
        overflow: hidden; }
        article.article_page .aside_nav ul li.closed .name {
          opacity: 0;
          transition: 0.05s ease-out opacity; }
        article.article_page .aside_nav ul li.closed .count {
          opacity: 0;
          transition: 0.05s ease-in opacity; }
      article.article_page .aside_nav ul li .icon, article.article_page .aside_nav ul li .name, article.article_page .aside_nav ul li .count {
        color: #000; }
  article.article_page header {
    width: 100%;
    height: 80vh;
    display: block;
    position: relative; }
    article.article_page header.editor {
      height: auto; }
    article.article_page header.fixed {
      background-attachment: fixed !important; }
    article.article_page header.no_cover {
      color: #000;
      height: auto;
      background-image: none !important; }
      article.article_page header.no_cover .tan {
        display: none; }
      article.article_page header.no_cover .pattern {
        display: none; }
      article.article_page header.no_cover ul.modify > li div.position_holder, article.article_page header.no_cover ul.modify > li div.bg_position_holder {
        border-radius: 17px;
        height: 32px;
        margin: 0 0 0 -22px;
        padding: 9px 7px 9px 8px;
        width: 65px; }
        article.article_page header.no_cover ul.modify > li div.position_holder .align-center, article.article_page header.no_cover ul.modify > li div.bg_position_holder .align-center {
          top: 8px;
          margin: -8px 0 0 -7px; }
        article.article_page header.no_cover ul.modify > li div.position_holder .align-bottom-left,
        article.article_page header.no_cover ul.modify > li div.position_holder .align-bottom-right, article.article_page header.no_cover ul.modify > li div.bg_position_holder .align-bottom-left,
        article.article_page header.no_cover ul.modify > li div.bg_position_holder .align-bottom-right {
          display: none; }
      article.article_page header.no_cover .frame {
        width: 780px;
        height: auto;
        left: 50%;
        margin: -80px 0 0 -390px; }
        article.article_page header.no_cover .frame.modified {
          top: auto;
          width: 780px;
          margin: 80px auto -21px auto;
          left: auto;
          position: relative; }
          article.article_page header.no_cover .frame.modified h1 {
            font-size: 48px;
            margin: 0 0 10px 0;
            line-height: 55px;
            float: none !important; }
          article.article_page header.no_cover .frame.modified h2.rubric {
            font-size: 14px !important;
            margin: 0 0 32px 0 !important;
            float: none;
            display: inline-block !important;
            padding: 0 !important;
            top: none !important; }
            article.article_page header.no_cover .frame.modified h2.rubric.bordered {
              border: 1px solid #000 !important;
              line-height: 32px;
              height: 32px;
              padding: 0 15px !important;
              margin: 23px 0 3px !important; }
            article.article_page header.no_cover .frame.modified h2.rubric input {
              font-size: 14px;
              float: none; }
          article.article_page header.no_cover .frame.modified .published_at {
            font-size: 14px;
            margin: 30px 0 94px; }
          article.article_page header.no_cover .frame.modified > p {
            font-size: 24px; }
        article.article_page header.no_cover .frame h1 {
          font-size: 48px;
          margin: 0 0 37px 0; }
        article.article_page header.no_cover .frame h2.rubric {
          font-size: 14px;
          margin: 0 0 32px 0;
          /*
          input{
            font-size: 14px;
            color:#000;
          }
*/ }
        article.article_page header.no_cover .frame > p {
          font-size: 24px; }
        article.article_page header.no_cover .frame .jgnt_btn_bordered, article.article_page header.no_cover .frame h2.rubric, article.article_page header.no_cover .frame h2.rubric input, article.article_page header.no_cover .frame h1, article.article_page header.no_cover .frame h2, article.article_page header.no_cover .frame p, article.article_page header.no_cover .frame .h1, article.article_page header.no_cover .frame .h2, article.article_page header.no_cover .frame .leed, article.article_page header.no_cover .frame .published_at {
          color: #fff !important; }
        article.article_page header.no_cover .frame[data-color="white"] .jgnt_btn_bordered, article.article_page header.no_cover .frame[data-color="white"] h2.rubric, article.article_page header.no_cover .frame[data-color="white"] h2.rubric input, article.article_page header.no_cover .frame[data-color="white"] h1, article.article_page header.no_cover .frame[data-color="white"] h2, article.article_page header.no_cover .frame[data-color="white"] p, article.article_page header.no_cover .frame[data-color="white"] .h1, article.article_page header.no_cover .frame[data-color="white"] .h2, article.article_page header.no_cover .frame[data-color="white"] .leed, article.article_page header.no_cover .frame[data-color="white"] .published_at {
          color: #fff !important; }
        article.article_page header.no_cover .frame[data-color="dark"] .jgnt_btn_bordered, article.article_page header.no_cover .frame[data-color="dark"] h2.rubric, article.article_page header.no_cover .frame[data-color="dark"] h2.rubric input, article.article_page header.no_cover .frame[data-color="dark"] h1, article.article_page header.no_cover .frame[data-color="dark"] h2, article.article_page header.no_cover .frame[data-color="dark"] p, article.article_page header.no_cover .frame[data-color="dark"] .h1, article.article_page header.no_cover .frame[data-color="dark"] .h2, article.article_page header.no_cover .frame[data-color="dark"] .leed, article.article_page header.no_cover .frame[data-color="dark"] .published_at {
          color: #000 !important; }
        article.article_page header.no_cover .frame[data-color="gray2"] .jgnt_btn_bordered, article.article_page header.no_cover .frame[data-color="gray2"] h2.rubric, article.article_page header.no_cover .frame[data-color="gray2"] h2.rubric input, article.article_page header.no_cover .frame[data-color="gray2"] h1, article.article_page header.no_cover .frame[data-color="gray2"] h2, article.article_page header.no_cover .frame[data-color="gray2"] p, article.article_page header.no_cover .frame[data-color="gray2"] .h1, article.article_page header.no_cover .frame[data-color="gray2"] .h2, article.article_page header.no_cover .frame[data-color="gray2"] .leed, article.article_page header.no_cover .frame[data-color="gray2"] .published_at {
          color: #989898 !important; }
        article.article_page header.no_cover .frame[data-color="gray"] .jgnt_btn_bordered, article.article_page header.no_cover .frame[data-color="gray"] h2.rubric, article.article_page header.no_cover .frame[data-color="gray"] h2.rubric input, article.article_page header.no_cover .frame[data-color="gray"] h1, article.article_page header.no_cover .frame[data-color="gray"] h2, article.article_page header.no_cover .frame[data-color="gray"] p, article.article_page header.no_cover .frame[data-color="gray"] .h1, article.article_page header.no_cover .frame[data-color="gray"] .h2, article.article_page header.no_cover .frame[data-color="gray"] .leed, article.article_page header.no_cover .frame[data-color="gray"] .published_at {
          color: #d9d9d9 !important; }
        article.article_page header.no_cover .frame[data-color="theme"] .jgnt_btn_bordered, article.article_page header.no_cover .frame[data-color="theme"] h2.rubric, article.article_page header.no_cover .frame[data-color="theme"] h2.rubric input, article.article_page header.no_cover .frame[data-color="theme"] h1, article.article_page header.no_cover .frame[data-color="theme"] h2, article.article_page header.no_cover .frame[data-color="theme"] p, article.article_page header.no_cover .frame[data-color="theme"] .h1, article.article_page header.no_cover .frame[data-color="theme"] .h2, article.article_page header.no_cover .frame[data-color="theme"] .leed, article.article_page header.no_cover .frame[data-color="theme"] .published_at {
          color: #57996b !important; }
    article.article_page header img {
      width: 100%;
      display: block; }
    article.article_page header:hover .modify {
      display: block; }
    article.article_page header .modify {
      top: 0;
      margin: 0;
      padding: 25px 20px 0 70px;
      right: 0px;
      text-align: center; }
      article.article_page header .modify .icon-download {
        font-size: 14px; }
      article.article_page header .modify .position_holder, article.article_page header .modify .bg_position_holder {
        width: 65px;
        height: 65px;
        border-radius: 15px;
        padding: 9px; }
        article.article_page header .modify .position_holder .circle, article.article_page header .modify .bg_position_holder .circle {
          width: 14px;
          height: 14px;
          box-sizing: border-box;
          border: 1px solid #fff; }
          article.article_page header .modify .position_holder .circle .inner, article.article_page header .modify .bg_position_holder .circle .inner {
            box-sizing: border-box;
            height: 8px;
            margin: 2px 0 0 2px;
            width: 8px; }
    article.article_page header .tan {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.3); }
    article.article_page header .pattern {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("/img/les/patterns/black-criss-cross.png") repeat;
      z-index: 10; }
    article.article_page header .frame {
      width: 79.362%;
      left: 10.319%;
      margin: -6.405vw 0 0 0px;
      position: absolute;
      top: 50%;
      height: 12.81vw;
      z-index: 100; }
      article.article_page header .frame .jgnt_btn_bordered, article.article_page header .frame h2.rubric, article.article_page header .frame h2.rubric input, article.article_page header .frame h1, article.article_page header .frame h2, article.article_page header .frame p, article.article_page header .frame .h1, article.article_page header .frame .h2, article.article_page header .frame .leed, article.article_page header .frame.frame.modified .published_at, article.article_page header .frame a.jgnt_btn_bordered {
        color: #fff;
        border-color: #fff !important; }
      article.article_page header .frame a.jgnt_btn_bordered:hover {
        opacity: 0.8; }
      article.article_page header .frame[data-color="white"] .jgnt_btn_bordered, article.article_page header .frame[data-color="white"] h2.rubric, article.article_page header .frame[data-color="white"] h2.rubric input, article.article_page header .frame[data-color="white"] h1, article.article_page header .frame[data-color="white"] h2, article.article_page header .frame[data-color="white"] p, article.article_page header .frame[data-color="white"] .h1, article.article_page header .frame[data-color="white"] .h2, article.article_page header .frame[data-color="white"] .leed, article.article_page header .frame[data-color="white"].frame.modified .published_at {
        color: #fff;
        border-color: #fff !important; }
      article.article_page header .frame[data-color="dark"] .jgnt_btn_bordered, article.article_page header .frame[data-color="dark"] h2.rubric, article.article_page header .frame[data-color="dark"] h2.rubric input, article.article_page header .frame[data-color="dark"] h1, article.article_page header .frame[data-color="dark"] h2, article.article_page header .frame[data-color="dark"] p, article.article_page header .frame[data-color="dark"] .h1, article.article_page header .frame[data-color="dark"] .h2, article.article_page header .frame[data-color="dark"] .leed, article.article_page header .frame[data-color="dark"].frame.modified .published_at {
        color: #000;
        border-color: #000 !important; }
      article.article_page header .frame[data-color="gray2"] .jgnt_btn_bordered, article.article_page header .frame[data-color="gray2"] h2.rubric, article.article_page header .frame[data-color="gray2"] h2.rubric input, article.article_page header .frame[data-color="gray2"] h1, article.article_page header .frame[data-color="gray2"] h2, article.article_page header .frame[data-color="gray2"] p, article.article_page header .frame[data-color="gray2"] .h1, article.article_page header .frame[data-color="gray2"] .h2, article.article_page header .frame[data-color="gray2"] .leed, article.article_page header .frame[data-color="gray2"].frame.modified .published_at {
        color: #989898;
        border-color: #989898 !important; }
      article.article_page header .frame[data-color="gray"] .jgnt_btn_bordered, article.article_page header .frame[data-color="gray"] h2.rubric, article.article_page header .frame[data-color="gray"] h2.rubric input, article.article_page header .frame[data-color="gray"] h1, article.article_page header .frame[data-color="gray"] h2, article.article_page header .frame[data-color="gray"] p, article.article_page header .frame[data-color="gray"] .h1, article.article_page header .frame[data-color="gray"] .h2, article.article_page header .frame[data-color="gray"] .leed, article.article_page header .frame[data-color="gray"].frame.modified .published_at {
        color: #989898;
        border-color: #989898 !important; }
      article.article_page header .frame[data-color="theme"] .jgnt_btn_bordered, article.article_page header .frame[data-color="theme"] h2.rubric, article.article_page header .frame[data-color="theme"] h2.rubric input, article.article_page header .frame[data-color="theme"] h1, article.article_page header .frame[data-color="theme"] h2, article.article_page header .frame[data-color="theme"] p, article.article_page header .frame[data-color="theme"] .h1, article.article_page header .frame[data-color="theme"] .h2, article.article_page header .frame[data-color="theme"] .leed, article.article_page header .frame[data-color="theme"].frame.modified .published_at {
        color: #57996b;
        border-color: #57996b !important; }
      article.article_page header .frame[data-position='align-top-left'] {
        text-align: left;
        top: 10.48%;
        margin: 0;
        bottom: auto;
        height: auto; }
        article.article_page header .frame[data-position='align-top-left'] h2.rubric, article.article_page header .frame[data-position='align-top-left'] h1, article.article_page header .frame[data-position='align-top-left'] p {
          text-align: left; }
        article.article_page header .frame[data-position='align-top-left'] h2.rubric input {
          text-align: left;
          margin: 0; }
      article.article_page header .frame[data-position='align-top-right'] {
        text-align: right;
        top: 10.48%;
        margin: 0;
        bottom: auto;
        height: auto; }
        article.article_page header .frame[data-position='align-top-right'] h2.rubric, article.article_page header .frame[data-position='align-top-right'] h1, article.article_page header .frame[data-position='align-top-right'] p {
          text-align: right; }
        article.article_page header .frame[data-position='align-top-right'] h2.rubric input {
          text-align: right;
          margin: 0;
          float: right; }
      article.article_page header .frame[data-position='align-bottom-right'] {
        text-align: right;
        top: auto;
        bottom: 10.48%;
        height: auto;
        margin: 0; }
        article.article_page header .frame[data-position='align-bottom-right'] h2.rubric, article.article_page header .frame[data-position='align-bottom-right'] h1, article.article_page header .frame[data-position='align-bottom-right'] p {
          text-align: right; }
        article.article_page header .frame[data-position='align-bottom-right'] h2.rubric input {
          text-align: right;
          margin: 0;
          float: right; }
      article.article_page header .frame[data-position='align-bottom'] {
        top: auto;
        bottom: 10.48%;
        height: auto; }
      article.article_page header .frame[data-position='align-bottom-left'] {
        text-align: left;
        top: auto;
        bottom: 10.48%;
        height: auto;
        margin: 0; }
        article.article_page header .frame[data-position='align-bottom-left'] h2.rubric, article.article_page header .frame[data-position='align-bottom-left'] h1, article.article_page header .frame[data-position='align-bottom-left'] p {
          text-align: left; }
        article.article_page header .frame[data-position='align-bottom-left'] h2.rubric input {
          text-align: left;
          margin: 0; }
      article.article_page header .frame.modified {
        text-align: center; }
        article.article_page header .frame.modified h2.rubric.bordered {
          border: 0.11vw solid #fff;
          text-transform: none;
          display: inline-block;
          border-radius: 30px;
          padding: 0.35vw 1vw;
          margin: 0 0 0.96vw;
          position: relative;
          top: -1vw;
          font-size: 0.92vw; }
        article.article_page header .frame.modified h1 {
          line-height: 3.95vw;
          margin: 0 0 0.6vw;
          position: relative;
          z-index: 1; }
        article.article_page header .frame.modified p {
          font-weight: normal;
          font-size: 1.68vw; }
        article.article_page header .frame.modified .published_at {
          font-weight: bold;
          color: #fff;
          font-size: 0.98vw;
          margin: 2.15vw 0 0; }
        article.article_page header .frame.modified[data-position='align-top-left'] {
          text-align: left; }
        article.article_page header .frame.modified[data-position='align-top-right'] {
          text-align: right; }
        article.article_page header .frame.modified[data-position='align-bottom-left'] {
          text-align: left; }
        article.article_page header .frame.modified[data-position='align-bottom-right'] {
          text-align: right; }
      article.article_page header .frame h2.rubric {
        font-size: 0.84vw;
        text-transform: uppercase;
        font-weight: bold;
        color: #fff;
        text-align: center;
        margin: 0 0 2.5vw 0; }
        article.article_page header .frame h2.rubric input {
          background: transparent none repeat scroll 0 0;
          border: medium none;
          color: #fff;
          display: block;
          font-size: 0.82vw;
          font-weight: bold;
          margin: 0 auto;
          padding: 0;
          text-align: center;
          text-transform: none;
          width: 15vw; }
          article.article_page header .frame h2.rubric input:focus {
            background: rgba(0, 0, 0, 0.1); }
      article.article_page header .frame h1 {
        font-size: 3.35vw;
        color: #fff;
        font-weight: bold;
        position: relative;
        width: 100%;
        text-align: center;
        margin: 0 0 3.1vw 0; }
      article.article_page header .frame > p {
        text-align: center;
        font-size: 1.25vw;
        font-weight: bold;
        color: #fff; }
  article.article_page section.block {
    padding: 30px 0;
    float: left;
    width: 100%;
    position: relative;
    font-size: 14px; }
    article.article_page section.block .wrapper {
      width: 940px;
      margin: 0 auto;
      line-height: 35px;
      font-size: 21px; }
      article.article_page section.block .wrapper.cell3 {
        width: 220px; }
    article.article_page section.block.only_section header.editor, article.article_page section.block:last-child header.editor {
      opacity: 1;
      width: 58px; }
      article.article_page section.block.only_section header.editor.selected, article.article_page section.block:last-child header.editor.selected {
        width: 518px; }
      article.article_page section.block.only_section header.editor .plus-button,
      article.article_page section.block.only_section header.editor ul li a, article.article_page section.block:last-child header.editor .plus-button,
      article.article_page section.block:last-child header.editor ul li a {
        background: #57996b;
        width: 56px;
        height: 56px;
        font-size: 18px; }
        article.article_page section.block.only_section header.editor .plus-button div,
        article.article_page section.block.only_section header.editor ul li a div, article.article_page section.block:last-child header.editor .plus-button div,
        article.article_page section.block:last-child header.editor ul li a div {
          line-height: 56px; }
      article.article_page section.block.only_section header.editor .tooltip_holder, article.article_page section.block:last-child header.editor .tooltip_holder {
        margin: 0 0 0 -2px;
        top: -23px; }
      article.article_page section.block.only_section header.editor ul, article.article_page section.block:last-child header.editor ul {
        width: 518px; }
        article.article_page section.block.only_section header.editor ul li a:hover div, article.article_page section.block:last-child header.editor ul li a:hover div {
          color: #fff; }
    article.article_page section.block ul.modify {
      /*left: 50%;
      margin: 0 0 0 -125px;
      right: auto;
      text-align: center;
      top: -5px;
      width: 250px;*/
      margin: 0;
      padding: 23px 20px 0 70px;
      right: 0;
      top: -28px; }
    article.article_page section.block.closed {
      background: red none repeat scroll 0 0;
      color: transparent !important;
      float: none;
      height: 50px !important;
      margin: 0 auto 20px;
      overflow: hidden;
      padding: 0;
      position: relative;
      width: 50px;
      transition: 1s ease all; }
    article.article_page section.block.focus {
      background: #d0d0d0; }
      article.article_page section.block.focus ul.modify {
        display: block; }
        article.article_page section.block.focus ul.modify li.edit_button a {
          background: #57996b; }
          article.article_page section.block.focus ul.modify li.edit_button a span.name {
            display: block; }
      article.article_page section.block.focus header.editor ul li a div {
        color: #000; }
    article.article_page section.block .wrapper [contenteditable="true"] {
      -moz-hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      hyphens: auto; }
    article.article_page section.block.deck .wrapper {
      font-size: 24px;
      font-weight: bold;
      line-height: 33px;
      font-size: 36px;
      line-height: 45px; }
    article.article_page section.block:hover .modify {
      display: block; }
    article.article_page section.block:hover header.editor {
      opacity: 1; }
    article.article_page section.block .load-image, article.article_page section.block .load-gallery {
      display: none; }
    article.article_page section.block header.editor {
      bottom: 0;
      clear: both;
      display: block;
      opacity: 0;
      margin: 30px auto 0 auto;
      position: relative;
      text-align: center;
      width: 35px;
      top: 16px;
      background: #fff;
      z-index: 10;
      transition: 0.3s ease-out all; }
      article.article_page section.block header.editor.selected {
        width: 350px; }
        article.article_page section.block header.editor.selected .plus-button {
          opacity: 0;
          z-index: 5; }
        article.article_page section.block header.editor.selected ul {
          opacity: 1;
          z-index: 10; }
          article.article_page section.block header.editor.selected ul li {
            visibility: visible; }
      article.article_page section.block header.editor .plus-button {
        position: absolute;
        z-index: 10; }
      article.article_page section.block header.editor .plus-button,
      article.article_page section.block header.editor ul li a {
        transition: 0.3s ease-out all;
        background: #d9d9d9;
        color: #fff;
        display: block;
        font-size: 14px;
        padding: 0;
        width: 35px;
        height: 35px;
        border-radius: 35px; }
        article.article_page section.block header.editor .plus-button .tooltip_holder,
        article.article_page section.block header.editor ul li a .tooltip_holder {
          margin: 0 0 0 -2px;
          top: -23px; }
        article.article_page section.block header.editor .plus-button div,
        article.article_page section.block header.editor ul li a div {
          color: #fff;
          line-height: 35px; }
      article.article_page section.block header.editor ul {
        z-index: 5;
        position: relative;
        transition: 0.3s ease-out all;
        width: 350px;
        list-style: none;
        opacity: 0; }
        article.article_page section.block header.editor ul li {
          display: inline-block;
          position: relative;
          margin: 0 10px 0 0;
          vertical-align: top;
          visibility: hidden; }
          article.article_page section.block header.editor ul li:last-child {
            margin: 0; }
          article.article_page section.block header.editor ul li a[data-section='picture'] .name {
            margin: 8px 0 0 -48px; }
          article.article_page section.block header.editor ul li a[data-section='plain'] .name {
            margin: 8px 0 0 -11px; }
          article.article_page section.block header.editor ul li a[data-section='quote'] .name {
            margin: 8px 0 0 -15px; }
          article.article_page section.block header.editor ul li a[data-section='interview'] .name {
            margin: 8px 0 0 -21px; }
          article.article_page section.block header.editor ul li a .name {
            display: none;
            position: absolute;
            line-height: normal;
            white-space: nowrap;
            font-size: 8px;
            background: #57996b;
            padding: 3px 8px;
            margin: 8px 0 0 -50px;
            color: #fff; }
            article.article_page section.block header.editor ul li a .name::before {
              content: " ";
              border-bottom: 5px solid #57996b;
              border-left: 5px solid transparent;
              border-right: 5px solid transparent;
              height: 0;
              left: 50%;
              margin: -7px 0 0 -5px;
              position: absolute;
              width: 0; }
          article.article_page section.block header.editor ul li a:hover div {
            color: #57996b; }
          article.article_page section.block header.editor ul li a:hover .name {
            display: block; }
    article.article_page section.block.quote[data-language='russian'] .english_wrapper, article.article_page section.block.quote_and_text[data-language='russian'] .english_wrapper {
      display: none; }
    article.article_page section.block.quote[data-language='english'] .russian_wrapper, article.article_page section.block.quote_and_text[data-language='english'] .russian_wrapper {
      display: none; }
    article.article_page section.block.quote_and_text[data-language='english'] .quote_text {
      float: left; }
    article.article_page section.block.quote .right {
      display: none; }
    article.article_page section.block.quote .author {
      display: none; }
    article.article_page section.block.quote[data-language='english'] .wrapper {
      width: 780px; }
    article.article_page section.block.quote[data-language='english'] .quote {
      width: 780px; }
    article.article_page section.block.quote[data-language='english'] .quote_text {
      text-align: left;
      width: 720px;
      float: left; }
    article.article_page section.block.quote .wrapper, article.article_page section.block .wrapper .quote {
      font-size: 24px;
      text-align: center;
      color: #57996b;
      font-weight: bold;
      width: 780px;
      line-height: 33px;
      font-size: 36px;
      line-height: 45px; }
      article.article_page section.block.quote .wrapper .icon-qoutes, article.article_page section.block .wrapper .quote .icon-qoutes {
        font-size: 70px;
        height: 55px;
        margin: -23px 0 10px;
        text-align: center; }
      article.article_page section.block.quote .wrapper .icon-qoute-fancy, article.article_page section.block .wrapper .quote .icon-qoute-fancy {
        bottom: 5px;
        float: left;
        font-size: 37px;
        width: 60px;
        text-align: left;
        position: relative; }
      article.article_page section.block.quote .wrapper .right, article.article_page section.block .wrapper .quote .right {
        float: right;
        width: 688px;
        text-align: left; }
      article.article_page section.block.quote .wrapper .author, article.article_page section.block .wrapper .quote .author {
        font-size: 12px;
        font-weight: normal; }
    article.article_page section.block.quote_and_text .left.quote {
      text-align: left;
      width: 380px; }
      article.article_page section.block.quote_and_text .left.quote .icon-qoutes {
        text-align: left; }
      article.article_page section.block.quote_and_text .left.quote .quote_text {
        width: 300px; }
    article.article_page section.block.quote_and_text .right {
      width: 380px; }
    article.article_page section.block.leed .wrapper {
      width: 780px;
      width: 940px;
      font-size: 24px;
      line-height: 33px;
      font-size: 36px;
      line-height: 45px; }
    article.article_page section.block.footnote .wrapper {
      width: 780px;
      width: 940px;
      font-size: 10px;
      line-height: 14px; }
    article.article_page section.block.link .wrapper {
      font-size: 14px; }
    article.article_page section.block.solid_footnote {
      color: #000; }
      article.article_page section.block.solid_footnote .wrapper {
        background: #f4f4f4;
        box-sizing: border-box;
        width: 780px;
        padding: 20px; }
      article.article_page section.block.solid_footnote .main {
        font-weight: bold;
        margin: 0 0 10px 0; }
      article.article_page section.block.solid_footnote .left {
        font-size: 10px;
        line-height: 16px;
        font-size: 14px;
        line-height: 26px;
        width: 360px;
        float: left; }
      article.article_page section.block.solid_footnote .right {
        font-size: 10px;
        line-height: 16px;
        font-size: 14px;
        line-height: 26px;
        width: 360px;
        float: right; }
    article.article_page section.block.solid_footnote_mono .wrapper {
      background: #f4f4f4;
      box-sizing: border-box;
      width: 780px;
      padding: 20px; }
    article.article_page section.block.solid_footnote_mono .main {
      font-weight: bold;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 10px 0; }
    article.article_page section.block.solid_footnote_mono .left {
      font-size: 10px;
      line-height: 16px; }
    article.article_page section.block.solid_footnote_mono .right {
      display: none; }
    article.article_page section.block.picture .overlap_text {
      display: none; }
    article.article_page section.block.picture .fixed_background {
      display: none; }
    article.article_page section.block.fullscreen_picture_fixed .inscription, article.article_page section.block.fullscreen_picture .inscription {
      margin: 10px auto 0 auto !important; }
    article.article_page section.block.fullscreen_picture_fixed .wrapper {
      margin: 0 auto;
      padding: 0;
      width: 100%; }
      article.article_page section.block.fullscreen_picture_fixed .wrapper div.editable {
        width: 780px;
        width: 940px;
        margin: 0 auto; }
    article.article_page section.block.fullscreen_picture_fixed .fixed_background {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%; }
    article.article_page section.block.fullscreen_picture_fixed .picture_frame img {
      max-width: none;
      width: 100%;
      max-height: none; }
    article.article_page section.block.fullscreen_picture_fixed .overlap_text {
      display: none; }
    article.article_page section.block.fullscreen_picture .fixed_background {
      display: none; }
    article.article_page section.block.fullscreen_picture .wrapper {
      margin: 0 auto;
      padding: 0;
      width: 100%; }
      article.article_page section.block.fullscreen_picture .wrapper div.editable {
        width: 780px;
        width: 940px;
        margin: 0 auto; }
    article.article_page section.block.fullscreen_picture .picture_frame img {
      max-width: none;
      width: 100%;
      max-height: none; }
    article.article_page section.block.fullscreen_picture .overlap_text {
      display: none; }
    article.article_page section.block.picture_with_text2 .picture_frame {
      float: right;
      width: auto;
      max-width: 380px;
      margin: 7px 0px 0 20px; }
      article.article_page section.block.picture_with_text2 .picture_frame .tint.no-picture {
        background: transparent url("/img/les/upload.svg") no-repeat scroll center center/310px auto; }
    article.article_page section.block.picture_with_text2 .inscription {
      float: right;
      width: 380px;
      margin: 10px 0px 0 20px; }
    article.article_page section.block.picture_with_text .picture_frame {
      float: left;
      width: auto;
      max-width: 380px;
      margin: 7px 20px 0 0; }
      article.article_page section.block.picture_with_text .picture_frame .tint.no-picture {
        background: transparent url("/img/les/upload.svg") no-repeat scroll center center/360px auto; }
    article.article_page section.block.picture_with_text .inscription {
      float: left;
      width: 380px;
      margin: 10px 20px 0 0; }
    article.article_page section.block.fullscreen_video {
      position: relative;
      height: 100%; }
      article.article_page section.block.fullscreen_video .wrapper {
        display: inline; }
      article.article_page section.block.fullscreen_video .video_frame {
        display: block;
        width: 100%;
        height: 100%; }
        article.article_page section.block.fullscreen_video .video_frame iframe {
          position: relative;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }
    article.article_page section.block .video_frame {
      background: #f4f4f4; }
      article.article_page section.block .video_frame.blank {
        background: #f4f4f4 url("/img/les/upload_video.png") no-repeat scroll center center; }
    article.article_page section.block.video .video_frame {
      position: relative;
      padding-bottom: 56.25%;
      /* 16:9 */
      padding-top: 25px; }
      article.article_page section.block.video .video_frame iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
    article.article_page section.block[data-section='keyword'] {
      font-size: 42px;
      font-weight: 300;
      font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif; }
      article.article_page section.block[data-section='keyword'] .wrapper {
        text-align: center;
        font-size: 42px;
        line-height: 43px;
        font-size: 36px;
        line-height: 45px;
        position: relative;
        padding: 53px 0 63px 0; }
    article.article_page section.block.keyword_center_left .wrapper {
      text-align: left; }
      article.article_page section.block.keyword_center_left .wrapper .aside_text {
        display: none; }
      article.article_page section.block.keyword_center_left .wrapper:before {
        content: " ";
        width: 180px;
        height: 3px;
        position: absolute;
        top: 0;
        background: #000;
        margin: 0 0 0 0;
        left: 0; }
      article.article_page section.block.keyword_center_left .wrapper:after {
        content: " ";
        width: 180px;
        height: 3px;
        position: absolute;
        bottom: 0;
        background: #000;
        margin: 0 0 0 0;
        left: 0; }
    article.article_page section.block.keyword_center_right .wrapper {
      text-align: right; }
      article.article_page section.block.keyword_center_right .wrapper .aside_text {
        display: none; }
      article.article_page section.block.keyword_center_right .wrapper:before {
        content: " ";
        width: 180px;
        height: 3px;
        position: absolute;
        top: 0;
        background: #000;
        margin: 0 0 0 0;
        right: 0; }
      article.article_page section.block.keyword_center_right .wrapper:after {
        content: " ";
        width: 180px;
        height: 3px;
        position: absolute;
        bottom: 0;
        background: #000;
        margin: 0 0 0 0;
        right: 0; }
    article.article_page section.block.keyword_left .wrapper {
      text-align: left; }
      article.article_page section.block.keyword_left .wrapper .aside_text {
        display: none; }
      article.article_page section.block.keyword_left .wrapper:before {
        content: " ";
        width: 140px;
        height: 5px;
        position: absolute;
        top: 0;
        background: #57996b;
        margin: 0 0 0 0;
        left: 0; }
    article.article_page section.block.keyword_top .wrapper .aside_text {
      display: none; }
    article.article_page section.block.keyword_top .wrapper:before {
      content: " ";
      width: 140px;
      height: 5px;
      position: absolute;
      top: 0;
      background: #57996b;
      margin: 0 0 0 -70px;
      left: 50%; }
    article.article_page section.block.keyword_right .wrapper {
      text-align: right; }
      article.article_page section.block.keyword_right .wrapper .aside_text {
        display: none; }
      article.article_page section.block.keyword_right .wrapper:before {
        content: " ";
        width: 140px;
        height: 5px;
        position: absolute;
        top: 0;
        background: #57996b;
        margin: 0 0 0 0;
        right: 0; }
    article.article_page section.block.keyword_text .wrapper {
      text-align: left;
      padding: 0; }
      article.article_page section.block.keyword_text .wrapper .aside_text {
        float: left;
        width: 380px;
        line-height: 35px;
        font-size: 21px;
        font-weight: normal; }
      article.article_page section.block.keyword_text .wrapper .main_text {
        float: right;
        width: 380px; }
    article.article_page section.block.keyword_text_right .wrapper {
      text-align: left;
      padding: 0; }
      article.article_page section.block.keyword_text_right .wrapper .aside_text {
        float: right;
        width: 380px;
        line-height: 35px;
        font-size: 21px;
        font-weight: normal; }
      article.article_page section.block.keyword_text_right .wrapper .main_text {
        float: left;
        width: 380px; }
    article.article_page section.block.keyword .wrapper .aside_text {
      display: none; }
    article.article_page section.block.keyword .wrapper:before {
      content: " ";
      width: 180px;
      height: 3px;
      position: absolute;
      top: 0;
      background: #000;
      margin: 0 0 0 -90px;
      left: 50%; }
    article.article_page section.block.keyword .wrapper:after {
      content: " ";
      width: 180px;
      height: 3px;
      position: absolute;
      bottom: 0;
      background: #000;
      margin: 0 0 0 -90px;
      left: 50%; }
    article.article_page section.block.keyword_full .wrapper .aside_text {
      display: none; }
    article.article_page section.block.keyword_full .wrapper:before {
      content: " ";
      width: 100%;
      height: 3px;
      position: absolute;
      top: 0;
      background: #000;
      left: 0%; }
    article.article_page section.block.keyword_full .wrapper:after {
      content: " ";
      width: 100%;
      height: 3px;
      position: absolute;
      bottom: 0;
      background: #000;
      left: 0%; }
    article.article_page section.block.interview_with_faces .add_string {
      font-size: 10px;
      font-size: 14px;
      color: #989898;
      display: block;
      float: right;
      width: 640px;
      text-align: left;
      position: relative;
      top: -31px; }
    article.article_page section.block.interview_with_faces .wrapper ul {
      list-style: none;
      display: block; }
      article.article_page section.block.interview_with_faces .wrapper ul li {
        display: block;
        float: left;
        padding: 0 0px 32px 0;
        width: 100%; }
        article.article_page section.block.interview_with_faces .wrapper ul li .portrait {
          display: block;
          width: 140px;
          float: left; }
          article.article_page section.block.interview_with_faces .wrapper ul li .portrait img {
            width: 90px;
            height: 90px;
            border-radius: 50px;
            display: block;
            float: left; }
        article.article_page section.block.interview_with_faces .wrapper ul li .person {
          display: block;
          float: left;
          font-weight: bold;
          font-size: 14px; }
        article.article_page section.block.interview_with_faces .wrapper ul li .question,
        article.article_page section.block.interview_with_faces .wrapper ul li .answer {
          width: 640px;
          float: left;
          display: table-cell; }
          article.article_page section.block.interview_with_faces .wrapper ul li .question .name,
          article.article_page section.block.interview_with_faces .wrapper ul li .answer .name {
            display: block; }
    article.article_page section.block.interview_with_borders .add_string, article.article_page section.block.interview .add_string {
      font-size: 10px;
      color: #989898;
      display: block;
      float: right;
      width: 89.6%;
      text-align: left;
      position: relative;
      top: -6px; }
    article.article_page section.block.interview_with_borders .wrapper ul, article.article_page section.block.interview .wrapper ul {
      list-style: none;
      display: table; }
      article.article_page section.block.interview_with_borders .wrapper ul li, article.article_page section.block.interview .wrapper ul li {
        display: table-row;
        float: none;
        margin: 0 0 20px 0;
        width: 100%; }
        article.article_page section.block.interview_with_borders .wrapper ul li .portrait, article.article_page section.block.interview .wrapper ul li .portrait {
          display: none; }
        article.article_page section.block.interview_with_borders .wrapper ul li .person, article.article_page section.block.interview .wrapper ul li .person {
          padding: 0 10px 20px 0;
          min-width: 7.692%;
          display: table-cell;
          hyphens: none; }
        article.article_page section.block.interview_with_borders .wrapper ul li .question, article.article_page section.block.interview .wrapper ul li .question {
          font-weight: bold; }
        article.article_page section.block.interview_with_borders .wrapper ul li .question,
        article.article_page section.block.interview_with_borders .wrapper ul li .answer, article.article_page section.block.interview .wrapper ul li .question,
        article.article_page section.block.interview .wrapper ul li .answer {
          width: 89.743%;
          float: none;
          display: table-cell;
          padding: 0 0px 20px 0; }
          article.article_page section.block.interview_with_borders .wrapper ul li .question .name,
          article.article_page section.block.interview_with_borders .wrapper ul li .answer .name, article.article_page section.block.interview .wrapper ul li .question .name,
          article.article_page section.block.interview .wrapper ul li .answer .name {
            display: none; }
    article.article_page section.block.interview_with_borders .wrapper {
      border: 1px dashed #000;
      box-sizing: border-box;
      padding: 21px 23px 31px 23px;
      border-radius: 3px; }
    article.article_page section.block.large_link_with_preview .wrapper .left, article.article_page section.block.link_with_preview .wrapper .left {
      width: 380px; }
      article.article_page section.block.large_link_with_preview .wrapper .left .card img, article.article_page section.block.link_with_preview .wrapper .left .card img {
        display: block;
        float: left;
        width: 140px;
        margin: 0 20px 0 0; }
    article.article_page section.block.large_link_with_preview .wrapper .right, article.article_page section.block.link_with_preview .wrapper .right {
      width: 220px; }
    article.article_page section.block.large_link_with_preview .wrapper .card, article.article_page section.block.link_with_preview .wrapper .card {
      display: block;
      width: 100%;
      height: 100%;
      background: #f4f4f4;
      position: relative; }
    article.article_page section.block.large_link_with_preview .wrapper .info, article.article_page section.block.link_with_preview .wrapper .info {
      padding: 20px;
      box-sizing: border-box;
      height: 100%; }
      article.article_page section.block.large_link_with_preview .wrapper .info span, article.article_page section.block.link_with_preview .wrapper .info span {
        position: absolute;
        bottom: 20px;
        color: #989898;
        text-transform: uppercase; }
      article.article_page section.block.large_link_with_preview .wrapper .info h2, article.article_page section.block.link_with_preview .wrapper .info h2 {
        font-weight: bold;
        font-size: 18px;
        color: #000; }
      article.article_page section.block.large_link_with_preview .wrapper .info p, article.article_page section.block.link_with_preview .wrapper .info p {
        color: #000; }
    article.article_page section.block.large_link_with_preview .wrapper .card {
      width: 100%; }
      article.article_page section.block.large_link_with_preview .wrapper .card img {
        width: 50%;
        float: left;
        margin: 0 20px 0 0; }
    article.article_page section.block .gallery_frame .previews li img {
      width: auto; }
    article.article_page section.block .picture_frame img {
      max-height: 900px;
      max-width: 100%;
      width: auto; }
    article.article_page section.block img {
      width: 100%;
      display: block;
      margin: 0 auto; }
  article.article_page .left {
    float: left; }
  article.article_page .right {
    float: right; }

.add_comment {
  background: #fff;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  text-align: left;
  margin: 0 0 10px 0; }
  .add_comment .author {
    display: block;
    float: left;
    margin: 0;
    vertical-align: top;
    width: auto; }
  .add_comment textarea {
    float: right;
    width: 690px;
    padding: 0;
    margin: 0;
    border: none;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 14px;
    display: inline-block;
    resize: unset;
    height: 35px; }

ul.comments_list {
  list-style: none; }
  ul.comments_list li {
    background: #fff;
    display: block;
    margin: 0 0 12px 0;
    width: 100%;
    box-sizing: border-box;
    padding: 20px; }
    ul.comments_list li iframe {
      width: 100%;
      display: block; }

.article_specs {
  box-sizing: border-box;
  clear: both;
  padding: 70px 0; }
  .article_specs .cnt {
    width: 940px;
    margin: auto; }
  .article_specs ul.tags_list {
    position: relative;
    bottom: 0;
    right: auto;
    margin: 0; }
    .article_specs ul.tags_list li a {
      margin: 0 0 10px 0; }
    .article_specs ul.tags_list.rubric li a {
      background: #000;
      color: #fff !important; }
  .article_specs .location_and_tags {
    float: left;
    width: 50%; }
    .article_specs .location_and_tags .location {
      margin: 0 0 10px 0; }
  .article_specs .rating {
    float: right;
    text-align: right; }
  .article_specs .spacer {
    width: 100%;
    float: left;
    height: 11px; }
  .article_specs .social.left {
    float: left; }
    .article_specs .social.left ul li {
      margin: 0 20px 0 0; }
      .article_specs .social.left ul li a span:last-child {
        bottom: 2px;
        font-size: 10px;
        position: relative; }
      .article_specs .social.left ul li.disabled a {
        cursor: auto; }
  .article_specs .social.right {
    float: right;
    position: relative;
    top: -4px; }
    .article_specs .social.right a span:first-child {
      font-size: 21px; }
    .article_specs .social.right a span:last-child {
      position: relative;
      bottom: 5px;
      font-size: 10px; }
  .article_specs .social ul li a {
    color: #000; }
  .article_specs .social ul li:last-child {
    margin: 0; }
  .article_specs .social ul li span {
    color: #000; }
  .article_specs a {
    color: #333333; }

.made_on.wrapper {
  border: 1px solid #57996b;
  box-sizing: border-box;
  padding: 40px 80px;
  margin-bottom: 87px; }
  .made_on.wrapper h2 {
    font-size: 36px;
    color: #000;
    margin-bottom: 32px; }
    .made_on.wrapper h2 .smaller {
      font-size: 21px;
      margin-left: 12px; }
  .made_on.wrapper p {
    margin-bottom: 19px; }
  .made_on.wrapper h3 {
    color: #000;
    font-size: 21px;
    text-transform: none;
    margin-bottom: 13px; }
  .made_on.wrapper .column {
    width: 356px;
    display: inline-block; }
    .made_on.wrapper .column.space {
      margin-right: 46px; }

div.comments {
  width: 100%;
  background: #f4f4f4;
  clear: both;
  position: relative;
  z-index: 1; }
  div.comments .wrapper {
    padding: 0px 0 40px 0;
    width: 780px; }
  div.comments h2 {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    padding: 21px 0 26px 0; }
  div.comments form {
    text-align: right;
    margin: 0 0 20px 0; }
  div.comments .button {
    float: right; }

a[href^="http://maps.google.com/maps"], a[href^="https://maps.google.com/maps"] {
  display: none !important; }

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none; }

@media (max-width: 960px) {
  .article_page {
    overflow-x: hidden !important; }
  .add_comment textarea {
    width: 100% !important; }
  .post_page > header,
  .post_page .wrapper,
  .post_page #true_header .wrapper,
  .post_page article.article_page.selected,
  .post_page article.article_page section.block .wrapper,
  .post_page article.article_page .saved_article,
  .post_page article.article_page section.block.quote .wrapper, article.article_page section.block .wrapper .quote,
  .post_page article.article_page section.block.leed .wrapper,
  .post_page footer.comments .wrapper,
  .post_page .article_specs,
  .post_page .article_specs .location_and_tags,
  .post_page article.article_page section.block.quote .wrapper,
  .post_page article.article_page section.block.quote .quote,
  .post_page article.article_page section.block.quote .quote_text {
    width: 100% !important; }
  .post_page #true_header .wrapper,
  .post_page article.article_page section.block .wrapper,
  .post_page .article_specs,
  .post_page footer.comments .wrapper {
    padding-left: 20px !important;
    padding-right: 20px !important; }
  .post_page article.article_page section.block .inscription {
    max-width: none !important;
    width: 100% !important; }
  .post_page > header {
    height: 80px !important; }
  .post_page > header nav.main_menu {
    float: right !important;
    right: 20px !important;
    top: 27px !important;
    position: absolute; }
  .post_page > header nav.main_menu.to_left {
    top: 0 !important; }
  .post_page article.article_page section.block.leed .wrapper {
    font-size: 25px !important;
    line-height: 125% !important; }
  .post_page article.article_page section.block[data-section="keyword"] .wrapper {
    font-size: 29px !important;
    line-height: 125% !important;
    padding-top: 40px !important;
    padding-bottom: 30px !important; }
  .post_page article.article_page section.block.quote .wrapper, article.article_page section.block .wrapper .quote {
    font-size: 28px !important;
    line-height: 125% !important; }
  .post_page article.article_page section.block .wrapper {
    font-size: 20px !important;
    line-height: 144% !important; }
  .post_page article.article_page section.block {
    padding: 18px 0 !important; }
  .mfp-arrow-right {
    transform-origin: 50% 50% 0 !important; }
  .post_page article.article_page section.block.footnote .wrapper div {
    font-size: 14px !important;
    line-height: 23px !important; }
  .post_page article.article_page section.block.picture_with_text2 .picture_frame {
    float: none !important;
    max-width: none !important;
    width: 100% !important;
    margin-left: 0 !important; }
  .post_page article.article_page section.block.picture_with_text2 .inscription {
    margin-left: 0 !important;
    margin-bottom: 10px !important; }
  .post_page article.article_page section.block.quote .wrapper .author, .post_page article.article_page section.block .wrapper .quote .author {
    line-height: 150% !important; }
  .post_page article.article_page section.block .wrapper .quote .author {
    margin-top: 6px !important;
    margin-bottom: 16px !important; }
  .post_page article.article_page .right {
    float: none !important; }
  .post_page article.article_page section.block.quote_and_text .right {
    width: 100% !important; }
  .post_page article.article_page section.block.picture_with_text .picture_frame {
    float: none !important;
    margin-right: 0 !important; }
  .post_page article.article_page section.block.picture_with_text .inscription {
    float: none !important;
    margin-right: 0 !important;
    margin-bottom: 16px !important; }
  .post_page article.article_page section.block.quote .quote_text {
    float: none !important; } }

@media (max-width: 480px) {
  .post_page > header nav.main_menu.to_left {
    right: 0 !important;
    margin-right: 0;
    margin-left: 0; } }

p.small {
  font-size: 0.8;
  margin-bottom: 0.4rem !important; }

.jgnt_linkbox {
  cursor: pointer; }
  .jgnt_linkbox > a.jgnt_linkbox {
    display: none; }
  .jgnt_linkbox:hover .jgnt_link_hover {
    text-decoration: underline; }

.journal_select {
  margin-top: 0.6rem;
  margin-bottom: 0.6rem; }

.jgnt_loader {
  min-height: 50px;
  min-width: 50px; }
  .jgnt_loader .icon {
    display: block;
    height: 50px;
    width: 50px;
    background: transparent url("/img/les/loading.png") no-repeat scroll 0 0/50px auto;
    margin: 0 auto;
    animation: infinite-spinning 0.5s linear infinite; }

.jgnt_processing {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.8); }
  .jgnt_processing.off {
    display: none; }
  .jgnt_processing .icon {
    background: transparent url("/img/les/loading.png") no-repeat scroll 0 0/50px auto;
    height: 50px;
    width: 50px;
    margin: 0 auto;
    position: fixed;
    right: 50px;
    top: 50px;
    z-index: 10000;
    animation: infinite-spinning 0.5s linear infinite; }

.jgnt_icon {
  height: .8em;
  width: auto;
  margin-right: 0.4em; }
  .jgnt_icon.big {
    height: 1.8em;
    margin-right: 0; }

.jgnt_badge {
  display: inline-block;
  background-color: #989898;
  border: 1px solid #989898;
  border-radius: 10em;
  text-decoration: none;
  font-size: 10px;
  font-weight: bold;
  line-height: 18px;
  color: #fff !important;
  padding: 0.2em 1em; }
  .jgnt_badge:hover {
    color: #989898 !important;
    background-color: #fff;
    text-decoration: none; }

.jgnt_page_block .filter {
  margin-bottom: 1rem; }
  .jgnt_page_block .filter .head {
    font-size: 24px;
    color: #000;
    font-weight: bold;
    margin-bottom: 0rem;
    text-transform: uppercase;
    margin-right: 0.5rem; }
  .jgnt_page_block .filter .list a {
    display: inline-block;
    text-transform: uppercase;
    color: #333333;
    margin: 0 0.2rem; }
    .jgnt_page_block .filter .list a.active {
      border-bottom: 1px solid #333333; }
    .jgnt_page_block .filter .list a:hover {
      text-decoration: none; }

.jgnt_items {
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-flow: row nowrap;
  flex-flow: row wrap;
  margin: 0 -0.5rem; }
  .jgnt_items .item {
    flex-basis: 25%;
    margin-bottom: 1rem; }
    .jgnt_items .item .item_wrap {
      margin: 0 0.5rem; }
    .jgnt_items .item .pic {
      background-position: center center;
      background-size: cover;
      height: 252px;
      margin-bottom: 1rem; }
    .jgnt_items .item .info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-flow: row nowrap;
      margin-bottom: 1rem; }
      .jgnt_items .item .info .date {
        font-size: 10px;
        color: #989898; }
    .jgnt_items .item .name {
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      margin-bottom: 2px; }
    .jgnt_items .item .text {
      font-size: 14px;
      line-height: 22px;
      margin-bottom: 0.6rem; }
    .jgnt_items .item .social {
      font-size: 10px; }
      .jgnt_items .item .social span {
        color: #989898;
        margin-right: 1em; }
        .jgnt_items .item .social span i {
          margin-right: 0.4em; }
  @media (max-width: 980px) {
    .jgnt_items .item {
      flex-basis: 50%; } }
  @media (max-width: 540px) {
    .jgnt_items .item {
      flex-basis: 100%; } }

/*
.jgnt_tab_switch {
	list-style: none;
	padding: 0;
	border: none !important;
	.nav-link {
		display: none;
		padding: 0;
		&.active {
			display: block;
		}
	}
}
*/
.jgnt_tabs .tab-pane {
  display: none; }

.jgnt_tabs .tab-pane.active {
  display: flex; }

.jgnt_tabs.tab-pane-block .tab-pane.active {
  display: block; }

.jgnt_sortable ul li {
  display: inline-block !important;
  float: none !important; }

.sub_menu {
  z-index: 9999; }

@media (max-width: 460px) {
  footer ul {
    /*
		position: relative;
		top: -1rem;
*/ }
    footer ul li {
      display: block !important;
      margin: 0 0 0.5rem 0 !important;
      text-align: right; } }

@media (max-width: 500px) {
  .small_map_location {
    width: auto !important;
    right: 0.7rem !important;
    left: 0.5rem !important; } }

.jgnt_image_container {
  position: relative;
  margin-bottom: 1rem;
  max-height: 85vh !important;
  display: flex;
  align-items: center;
  justify-content: center; }
  .jgnt_image_container img {
    margin: auto !important;
    object-fit: contain;
    width: 100%;
    max-height: 85vh; }

.feed_page h1 {
  font-weight: normal;
  font-size: 36px;
  margin: 1rem 0 2rem 0; }

.feed_page p {
  margin-bottom: 2rem; }

.feed_page a:hover {
  text-decoration: underline; }

.profile_page .load_more {
  margin-bottom: 2rem; }

.jgnt_block_article_bottom {
  background-color: #fff;
  position: relative;
  z-index: 1;
  padding: 2rem 0; }
  @media (max-width: 980px) {
    .jgnt_block_article_bottom .jgnt_content {
      margin: 0 1rem; } }

article.article_page header .frame {
  width: auto;
  left: auto;
  top: auto;
  margin: 0;
  position: relative;
  height: auto;
  z-index: 100; }
  article.article_page header .frame h1 {
    font-size: 3rem;
    line-height: 1 !important;
    margin-bottom: 0.8rem !important; }
  article.article_page header .frame .jgnt_btn_bordered {
    font-size: 14px;
    margin-bottom: 1.5rem;
    position: relative;
    z-index: 1; }
  article.article_page header .frame .leed {
    font-size: 1.4rem !important;
    position: relative;
    z-index: 1; }
  article.article_page header .frame .published_at {
    font-size: 14px !important;
    margin-top: 1rem !important; }
  @media (max-width: 991px) {
    article.article_page header .frame h1 {
      font-size: 2.6rem !important; }
    article.article_page header .frame .jgnt_btn_bordered {
      font-size: 13px !important; }
    article.article_page header .frame .leed {
      font-size: 1.2rem !important; }
    article.article_page header .frame .published_at {
      font-size: 13px !important; } }
  @media (max-width: 500px) {
    article.article_page header .frame h1 {
      font-size: 1.8rem !important; }
    article.article_page header .frame .jgnt_btn_bordered {
      font-size: 11px !important; }
    article.article_page header .frame .leed {
      font-size: 1rem !important; }
    article.article_page header .frame .published_at {
      font-size: 11px !important; } }

article.article_page .jgnt_cover {
  display: flex;
  align-items: center;
  justify-content: center; }
  article.article_page .jgnt_cover[data-text-position='align-center'] .frame {
    margin-left: 8rem;
    margin-right: 8rem; }
  article.article_page .jgnt_cover[data-text-position='align-top-left'] {
    align-items: flex-start;
    justify-content: flex-start; }
    article.article_page .jgnt_cover[data-text-position='align-top-left'] .frame {
      margin-top: 1rem;
      margin-left: 8rem;
      margin-right: 8rem; }
  article.article_page .jgnt_cover[data-text-position='align-top-right'] {
    align-items: flex-start;
    justify-content: flex-end; }
    article.article_page .jgnt_cover[data-text-position='align-top-right'] .frame {
      margin-top: 1rem;
      margin-left: 8rem;
      margin-right: 8rem; }
  article.article_page .jgnt_cover[data-text-position='align-bottom-left'] {
    align-items: flex-end;
    justify-content: flex-start; }
    article.article_page .jgnt_cover[data-text-position='align-bottom-left'] .frame {
      margin-left: 8rem;
      margin-right: 8rem; }
  article.article_page .jgnt_cover[data-text-position='align-bottom-right'] {
    align-items: flex-end;
    justify-content: flex-end; }
    article.article_page .jgnt_cover[data-text-position='align-bottom-right'] .frame {
      margin-left: 8rem;
      margin-right: 8rem; }
  @media (max-width: 1199px) {
    article.article_page .jgnt_cover .frame {
      margin-left: 1rem !important;
      margin-right: 1rem !important; } }

.landing_media_page article {
  float: left; }

.profile_page .articles.blocks {
  left: 10px !important;
  width: 960px !important; }

.profile_page .views_list article {
  float: left; }

@media (max-width: 1020px) {
  .profile_page .modes {
    right: 1rem !important; } }

.profile_page .articles.full_width {
  max-width: 940px !important;
  padding-top: 2rem; }
  @media (max-width: 1020px) {
    .profile_page .articles.full_width {
      width: auto !important;
      padding: 2rem 1rem;
      margin: 0 !important; }
      .profile_page .articles.full_width .modes {
        right: 1rem; } }
  .profile_page .articles.full_width article {
    float: none !important;
    padding: 0 !important;
    margin-bottom: 4rem; }
    .profile_page .articles.full_width article .text_group {
      height: auto !important;
      width: 100% !important; }
    .profile_page .articles.full_width article .date_ago {
      position: absolute !important;
      text-align: right !important;
      right: auto !important;
      top: 3px !important;
      left: -170px !important;
      width: 150px !important;
      color: #333333 !important; }
      @media (max-width: 1200px) {
        .profile_page .articles.full_width article .date_ago {
          position: static !important;
          margin-bottom: 1rem !important;
          left: 0 !important;
          width: auto !important;
          text-align: left !important;
          border-left: 3px solid #333333;
          padding-left: 1rem !important; } }
    .profile_page .articles.full_width article .social {
      position: static !important; }

@media (max-width: 1000px) {
  .broadcast_editor_filter .search_holder {
    display: inline-block;
    position: relative;
    top: 0;
    margin-bottom: 1rem; } }

.jgnt_broadcast_newpost:hover {
  background-color: #57996b !important;
  color: #fff !important; }

.broadcast_page .jgnt_broadcast_promo_topic {
  margin-top: 3rem;
  margin-bottom: 1rem;
  font-size: 0.8rem; }
  @media (max-width: 400px) {
    .broadcast_page .jgnt_broadcast_promo_topic {
      font-size: 0.75rem; } }

.broadcast_page .bottom_block {
  clear: both !important; }

.broadcast_page .main_menu .post {
  display: none; }

.broadcast_page .main_menu a:hover {
  text-decoration: none !important; }

.broadcast_page .button, .broadcast_page .send-post {
  cursor: pointer; }
  .broadcast_page .button:hover, .broadcast_page .send-post:hover {
    color: #fff;
    background-color: #57996b !important;
    border-color: #57996b !important; }

.broadcast_page article.full_background {
  margin-top: 68px !important; }

.broadcast_page .main_folders li {
  float: right !important; }

.broadcast_info {
  left: 0 !important;
  right: 0 !important;
  top: auto !important;
  bottom: 2rem !important;
  width: 100% !important; }

.jgnt_broadcast_logo {
  width: 100px;
  height: 77px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center; }
  .jgnt_broadcast_logo img {
    width: auto !important;
    height: auto !important;
    max-width: 100px;
    max-height: 80px; }

.broadcast_header .main_menu.common {
  clear: right;
  float: right !important;
  position: static !important;
  margin-top: 30px; }

.broadcast_header .edit {
  clear: right;
  float: right !important;
  position: static !important; }

.broadcast_header .links {
  clear: right;
  float: right !important;
  position: static !important;
  margin-bottom: 2rem; }

@media (max-width: 767px) {
  .broadcast_header .main_menu.common {
    clear: left;
    float: left !important;
    margin-top: 0; }
  .broadcast_header .edit {
    clear: left;
    float: left !important; }
  .broadcast_header .links {
    clear: left;
    float: left !important; } }

.broadcast_setup_page .logo_holder {
  width: 120px;
  margin: 0 auto 0 0 !important; }

@media (max-width: 960px) {
  .broadcast_setup_page .left_right input, .broadcast_setup_page .left_right select {
    float: none !important;
    width: 100% !important; }
  .broadcast_setup_page .left_right .fixed_field:nth-child(even) {
    margin-left: 0 !important; }
  .broadcast_setup_page .holder {
    margin-bottom: 1rem !important; }
    .broadcast_setup_page .holder article.landing[data-block_type='wide'] {
      width: 100% !important; }
  .broadcast_setup_page .block {
    width: 100% !important; }
  .broadcast_setup_page .left {
    float: none;
    width: 100% !important;
    max-width: 680px; }
    .broadcast_setup_page .left::after {
      content: "" !important;
      display: table !important;
      clear: both !important; }
    .broadcast_setup_page .left textarea.info {
      height: auto !important;
      max-height: 300px; }
  .broadcast_setup_page .right {
    float: none;
    width: 100% !important;
    max-width: 680px;
    margin: 0 !important; }
  .broadcast_setup_page .next_page {
    float: none;
    display: inline-block !important; } }

.broadcast_page, .broadcast_post_page, .broadcast_editor_page, .broadcast_setup_page {
  /*
	.image {
		max-height: 90vh !important;
		img {
			width: 100% !important;
			height: auto !important;
			max-height: 80vh !important;
		}
	}
*/ }
  .broadcast_page .links a:hover, .broadcast_post_page .links a:hover, .broadcast_editor_page .links a:hover, .broadcast_setup_page .links a:hover {
    color: #57996b !important; }
  .broadcast_page .bottom_block, .broadcast_post_page .bottom_block, .broadcast_editor_page .bottom_block, .broadcast_setup_page .bottom_block {
    clear: both !important; }
  .broadcast_page .profile:hover, .broadcast_page .profile *:hover, .broadcast_post_page .profile:hover, .broadcast_post_page .profile *:hover, .broadcast_editor_page .profile:hover, .broadcast_editor_page .profile *:hover, .broadcast_setup_page .profile:hover, .broadcast_setup_page .profile *:hover {
    text-decoration: none !important; }
  .broadcast_page .profile .button, .broadcast_post_page .profile .button, .broadcast_editor_page .profile .button, .broadcast_setup_page .profile .button {
    cursor: pointer;
    /*
			&:hover {
				color: $jgntColorSuccess !important;
				border-color: $jgntColorSuccess !important;
			}
*/ }
  .broadcast_page .profile .icon, .broadcast_post_page .profile .icon, .broadcast_editor_page .profile .icon, .broadcast_setup_page .profile .icon {
    display: none; }
  @media (max-width: 420px) {
    .broadcast_page .profile .icon, .broadcast_post_page .profile .icon, .broadcast_editor_page .profile .icon, .broadcast_setup_page .profile .icon {
      display: inline; }
    .broadcast_page .profile .username, .broadcast_post_page .profile .username, .broadcast_editor_page .profile .username, .broadcast_setup_page .profile .username {
      display: none; } }
  .broadcast_page .go_back a:hover, .broadcast_post_page .go_back a:hover, .broadcast_editor_page .go_back a:hover, .broadcast_setup_page .go_back a:hover {
    background-color: #57996b !important;
    color: #fff !important;
    border-color: #57996b !important; }
  .broadcast_page .back_to_main a:hover, .broadcast_post_page .back_to_main a:hover, .broadcast_editor_page .back_to_main a:hover, .broadcast_setup_page .back_to_main a:hover {
    background-color: #57996b !important;
    color: #fff !important; }
  .broadcast_page .sub_header_menu, .broadcast_post_page .sub_header_menu, .broadcast_editor_page .sub_header_menu, .broadcast_setup_page .sub_header_menu {
    height: auto !important;
    padding: 0.5rem 0 !important; }
    .broadcast_page .sub_header_menu h1, .broadcast_post_page .sub_header_menu h1, .broadcast_editor_page .sub_header_menu h1, .broadcast_setup_page .sub_header_menu h1 {
      line-height: 1.2 !important; }
  .broadcast_page .image_frame, .broadcast_post_page .image_frame, .broadcast_editor_page .image_frame, .broadcast_setup_page .image_frame {
    width: 100% !important;
    max-height: 80vh !important;
    min-height: 300px !important;
    background-size: contain !important; }
  @media (max-width: 600px) {
    .broadcast_page .back_to_main, .broadcast_post_page .back_to_main, .broadcast_editor_page .back_to_main, .broadcast_setup_page .back_to_main {
      display: none; } }
  .broadcast_page .broadcast_post .map_block, .broadcast_post_page .broadcast_post .map_block, .broadcast_editor_page .broadcast_post .map_block, .broadcast_setup_page .broadcast_post .map_block {
    margin-bottom: 2rem !important; }
  @media (max-width: 960px) {
    .broadcast_page .broadcast_post .left, .broadcast_post_page .broadcast_post .left, .broadcast_editor_page .broadcast_post .left, .broadcast_setup_page .broadcast_post .left {
      float: none;
      width: 100% !important;
      max-width: 680px; }
    .broadcast_page .broadcast_post .right, .broadcast_post_page .broadcast_post .right, .broadcast_editor_page .broadcast_post .right, .broadcast_setup_page .broadcast_post .right {
      float: none;
      width: 100% !important;
      max-width: 680px; }
    .broadcast_page .broadcast_post .next_page, .broadcast_post_page .broadcast_post .next_page, .broadcast_editor_page .broadcast_post .next_page, .broadcast_setup_page .broadcast_post .next_page {
      float: none;
      display: inline-block !important; } }

.broadcast_editor_page {
  /*
	.cmd_buttons {
@media ( max-width: 420px ) {
		max-width: 220px;
		margin-bottom: 1rem !important;
		
}
	}
*/ }
  .broadcast_editor_page .wrapper {
    max-width: 960px !important; }
  .broadcast_editor_page .back_to_main {
    display: none; }
  .broadcast_editor_page .broadcast_card {
    /*
		.row {
			min-width: 260px;
		}
*/ }
    .broadcast_editor_page .broadcast_card .rating {
      text-align: left; }
    .broadcast_editor_page .broadcast_card .checks {
      text-align: left; }
    .broadcast_editor_page .broadcast_card .location {
      text-align: left; }
      .broadcast_editor_page .broadcast_card .location > div {
        white-space: normal !important; }
    .broadcast_editor_page .broadcast_card .left {
      width: 560px; }
    .broadcast_editor_page .broadcast_card.in_edit .input_field {
      background-color: #e8e8e8; }
    @media (max-width: 1180px) {
      .broadcast_editor_page .broadcast_card .number {
        position: static;
        display: inline-block; }
      .broadcast_editor_page .broadcast_card .main_switch {
        width: auto !important;
        position: relative;
        top: -6px;
        left: 0;
        display: inline-block;
        margin: auto; }
        .broadcast_editor_page .broadcast_card .main_switch .delete_button {
          position: static !important; }
      .broadcast_editor_page .broadcast_card .status_float {
        right: 1rem !important; } }
    @media (max-width: 920px) {
      .broadcast_editor_page .broadcast_card .right {
        display: block;
        width: auto;
        float: none;
        clear: both; }
      .broadcast_editor_page .broadcast_card .left {
        display: block;
        width: auto;
        float: none;
        clear: both; } }
    @media (max-width: 590px) {
      .broadcast_editor_page .broadcast_card .row {
        width: 100% !important; }
        .broadcast_editor_page .broadcast_card .row > div {
          white-space: normal !important; } }
    @media (max-width: 560px) {
      .broadcast_editor_page .broadcast_card .status_float {
        display: block;
        clear: both;
        position: static;
        margin-bottom: 0.5rem; } }
    @media (max-width: 420px) {
      .broadcast_editor_page .broadcast_card .input_field {
        width: 100%;
        margin-top: 3px; } }
  .broadcast_editor_page .profile {
    margin-right: 0 !important; }
    .broadcast_editor_page .profile a:hover {
      color: #fff !important; }
  .broadcast_editor_page .broadcast_short_header {
    height: auto !important;
    min-height: 1rem !important; }
    .broadcast_editor_page .broadcast_short_header .wrapper {
      height: auto !important;
      min-height: 1rem !important; }
      .broadcast_editor_page .broadcast_short_header .wrapper .main_menu {
        margin-top: 0 !important; }
    .broadcast_editor_page .broadcast_short_header .button:hover {
      background-color: #57996b !important;
      color: #fff !important;
      border-color: #57996b !important; }
  .broadcast_editor_page .top_info {
    background: #333333; }
  .broadcast_editor_page .broadcast_editor_h1 {
    background: #333333;
    position: static !important;
    color: #fff;
    font-size: 2rem;
    font-weight: normal; }
    @media (max-width: 660px) {
      .broadcast_editor_page .broadcast_editor_h1 {
        font-size: 1.6rem; } }
  .broadcast_editor_page .broadcast_stats {
    min-height: 0 !important;
    padding-bottom: 1rem !important; }
    .broadcast_editor_page .broadcast_stats ul {
      font-size: 1rem; }
      .broadcast_editor_page .broadcast_stats ul li {
        font-weight: normal; }
        .broadcast_editor_page .broadcast_stats ul li strong {
          color: #fff;
          font-size: 2rem; }
    @media (max-width: 960px) {
      .broadcast_editor_page .broadcast_stats ul {
        font-size: 0.7rem; }
        .broadcast_editor_page .broadcast_stats ul li {
          width: auto !important;
          margin-right: 1rem; }
          .broadcast_editor_page .broadcast_stats ul li strong {
            font-size: 1.2rem; } }

.broadcast_page .image, .broadcast_page .image_frame {
  margin: 1rem 0 !important; }

.broadcast_page nav.sub_header_menu {
  height: auto !important;
  min-height: 55px !important; }
  .broadcast_page nav.sub_header_menu .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    flex-flow: row wrap; }
  .broadcast_page nav.sub_header_menu .filters {
    position: static !important;
    margin-right: 1rem !important; }
  .broadcast_page nav.sub_header_menu .tags_list {
    position: static !important; }

.broadcast_card {
  margin-bottom: 2rem !important; }
  .broadcast_card article {
    float: none !important; }
  .broadcast_card .jgnt_content {
    position: relative;
    padding-top: 2rem; }
    .broadcast_card .jgnt_content::after {
      content: "" !important;
      display: table !important;
      clear: both !important; }
  .broadcast_card .text_group {
    width: auto !important;
    position: static !important; }
    .broadcast_card .text_group h2 {
      margin-bottom: 0.2rem !important;
      color: inherit !important; }
    .broadcast_card .text_group .leed {
      margin-bottom: 1rem !important;
      line-height: 1.4 !important; }
    .broadcast_card .text_group .event_date span {
      font-weight: bold; }
    .broadcast_card .text_group .author {
      margin-bottom: 1rem !important; }
      .broadcast_card .text_group .author .textuser {
        display: inline-block !important;
        margin: 0 1rem 0 0 !important; }
      .broadcast_card .text_group .author .location {
        display: inline-block !important;
        margin: 0 1rem 0 0 !important; }
      .broadcast_card .text_group .author .event_date {
        display: inline-block !important;
        margin: 0 1rem 0 0 !important; }
  .broadcast_card .image, .broadcast_card .image_frame {
    margin: 1rem 0 !important; }
    .broadcast_card .image p, .broadcast_card .image_frame p {
      color: #333333 !important; }
  .broadcast_card .gallery {
    margin-bottom: 1rem !important;
    width: auto !important; }
  .broadcast_card .video_frame {
    margin: 1rem 0 !important;
    width: 100% !important; }
  .broadcast_card .bottom_block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-flow: row nowrap;
    align-items: flex-start; }
  .broadcast_card .social {
    min-height: 24px;
    line-height: 24px;
    left: 0 !important;
    margin: 0 !important;
    bottom: 0 !important;
    position: static !important;
    display: block !important;
    min-width: 400px !important; }
    .broadcast_card .social .rubric:hover {
      background-color: #57996b !important;
      color: #fff !important; }
  .broadcast_card ul.tags_list {
    width: auto !important;
    position: static !important;
    display: block !important;
    height: auto !important; }
    .broadcast_card ul.tags_list li {
      margin: 0 0 0.5rem 0.5rem !important; }
  .broadcast_card .date_ago {
    position: absolute !important;
    text-align: right !important;
    right: auto !important;
    top: 2rem !important;
    left: -170px !important;
    width: 150px !important; }
  .broadcast_card .holder .wrapper {
    overflow: visible !important; }
  .broadcast_card .holder .selectboxit-container {
    margin-bottom: 0 !important; }
  .broadcast_card .holder .selectboxit-text {
    float: right !important;
    margin-right: 2rem !important; }
  .broadcast_card .holder .setup_type {
    margin-bottom: 5px !important; }
  .broadcast_card .holder .text_move_down {
    font-size: 14px;
    padding-left: 1.2rem !important; }
  .broadcast_card .full_background .jgnt_content {
    position: absolute;
    bottom: 1rem;
    left: 0;
    right: 0; }
  .broadcast_card .full_background:not(.jgnt_move_down) .location {
    color: #fff !important; }
  .broadcast_card .full_background:not(.jgnt_move_down) .leed {
    color: #fff !important; }
  .broadcast_card .full_background:not(.jgnt_move_down) .author a {
    color: #fff !important; }
  .broadcast_card .full_background:not(.jgnt_move_down) .date_ago {
    color: #fff !important; }
  .broadcast_card .full_background:not(.jgnt_move_down) .social ul li {
    color: #fff !important; }
    .broadcast_card .full_background:not(.jgnt_move_down) .social ul li a {
      color: #fff !important; }
  .broadcast_card .full_background.jgnt_move_down {
    height: auto !important;
    background-color: #fff !important; }
    .broadcast_card .full_background.jgnt_move_down .bg_picture {
      height: 100vh;
      position: relative !important;
      background-color: #989898 !important; }
    .broadcast_card .full_background.jgnt_move_down .jgnt_content {
      position: relative; }
      .broadcast_card .full_background.jgnt_move_down .jgnt_content .text_group {
        color: #333333 !important; }
  @media (max-width: 1200px) {
    .broadcast_card .text_group h2 {
      margin-top: 3rem !important; }
    .broadcast_card .date_ago {
      left: 0 !important;
      width: auto !important;
      text-align: left !important;
      border-left: 3px solid #333333;
      padding-left: 1rem !important; }
    .broadcast_card .full_background:not(.jgnt_move_down) .date_ago {
      border-left: 3px solid #fff; } }
  @media (max-width: 990px) {
    .broadcast_card .jgnt_content {
      margin-left: 1rem;
      margin-right: 1rem; }
    .broadcast_card .gallery .rslides_tabs {
      bottom: 1rem !important; } }
  @media (max-width: 800px) {
    .broadcast_card .bottom_block {
      display: block; }
    .broadcast_card .social {
      margin-bottom: 1rem !important;
      min-width: 280px !important; }
    .broadcast_card ul.tags_list {
      text-align: left !important; }
      .broadcast_card ul.tags_list li {
        margin: 0 0.5rem 0.5rem 0 !important; } }
  @media (max-width: 375px) {
    .broadcast_card {
      margin-bottom: 1rem !important; }
      .broadcast_card .social .permalink {
        padding-top: 0.5rem !important; } }

@media (max-width: 1080px) {
  .selected_broadcasts {
    position: relative; }
    .selected_broadcasts .left-arrow {
      margin: 0 !important;
      left: 1rem !important; }
    .selected_broadcasts .right-arrow {
      margin: 0 !important;
      right: 1rem !important; }
    .selected_broadcasts .items {
      width: 700px !important;
      margin: auto !important; } }

@media (max-width: 840px) {
  .selected_broadcasts .items {
    width: 460px !important; } }

@media (max-width: 620px) {
  .selected_broadcasts .items {
    width: 200px !important; } }

.landing_hipster_page .profile .icon {
  display: none; }

.landing_hipster_page .articles article {
  float: left; }

.in_editor.selected .editable {
  min-height: 1em; }
  .in_editor.selected .editable.person {
    min-width: 45px; }
  .in_editor.selected .editable:hover {
    background: rgba(0, 0, 0, 0.02); }
  .in_editor.selected .editable:focus {
    min-height: 1em;
    background: rgba(0, 0, 0, 0.03); }

.interview_with_faces .person {
  min-width: 45px; }

.solid_footnote_mono .left {
  float: none !important; }

.picture_with_text .picture_frame, .picture_with_text2 .picture_frame {
  margin-top: 0 !important; }

.jgnt_btn_bordered {
  display: inline-block;
  background: transparent;
  border: 2px solid #fff;
  border-radius: 30px;
  padding: 5px 15px;
  font-weight: bold;
  text-align: center;
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */ }
  .jgnt_btn_bordered::-webkit-input-placeholder {
    color: inherit; }
  .jgnt_btn_bordered:-moz-placeholder {
    color: inherit; }
  .jgnt_btn_bordered::-moz-placeholder {
    color: inherit; }
  .jgnt_btn_bordered:-ms-input-placeholder {
    color: inherit; }
  .jgnt_btn_bordered:focus::-webkit-input-placeholder {
    color: inherit; }
  .jgnt_btn_bordered:focus:-moz-placeholder {
    color: inherit; }
  .jgnt_btn_bordered:focus::-moz-placeholder {
    color: inherit; }
  .jgnt_btn_bordered:focus:-ms-input-placeholder {
    color: inherit; }
  .jgnt_btn_bordered:placeholder-shown {
    opacity: 0.7;
    min-width: 280px; }

[contentEditable=true]:empty:not(:focus):before {
  content: attr(data-placeholder);
  color: darkgray;
  color: inherit;
  opacity: 0.7; }

.jgnt_form_validation fieldset {
  position: relative; }

.jgnt_form_validation .nod-error-message {
  font-size: 10px;
  position: relative;
  top: -10px; }

.jgnt_form_validation .has-danger .form-control {
  border: 1px solid #ff4e50 !important; }

.jgnt_form_validation .has-success .form-control {
  border: 1px solid #57996b !important; }

.jgnt_form_validation #press_find_location {
  top: 10px !important;
  bottom: auto; }

#press_auto_location {
  position: absolute;
  left: 0;
  top: 40px; }

#auto_location_small {
  position: absolute;
  right: 10px;
  bottom: 150px;
  font-size: 26px; }

.jgnt_upload_progress {
  background-color: #ddd;
  height: 10px; }
  .jgnt_upload_progress .progress-bar {
    height: inherit;
    background-color: #ff4e50; }
    .jgnt_upload_progress .progress-bar.done {
      background-color: #57996b; }

.upload_images_banner {
  cursor: pointer; }

.jgnt_infoblock {
  padding: 6rem 0 !important;
  font-size: 2rem; }
  .jgnt_infoblock::after {
    content: "" !important;
    display: table !important;
    clear: both !important; }

.user_block .profile_pic .delete {
  cursor: pointer !important; }
  .user_block .profile_pic .delete:hover {
    background-color: #ff4e50 !important; }

.tags_list a:hover {
  background-color: #57996b !important;
  color: #fff !important; }

.landing_page::after {
  content: "" !important;
  display: table !important;
  clear: both !important; }

.jgnt_inputclear {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 20px;
  color: #888;
  font-size: 17px; }
  .jgnt_inputclear:hover {
    color: #57996b; }

.jgnt_search_tag a {
  display: inline-block !important; }

.jgnt_search_tag .btn {
  position: relative;
  line-height: 10px;
  display: inline-block !important;
  background-color: #57996b;
  color: #fff;
  border-radius: 5px;
  font-weight: normal;
  font-size: 10px;
  padding: 6px 22px 6px 10px; }

.jgnt_fieldclear {
  cursor: pointer;
  position: absolute;
  right: 6px;
  top: 5px;
  color: #fff;
  font-size: 12px;
  background-color: transparent;
  border-radius: 50%;
  /*
	&:hover {
		background-color: #fff;
		color: $jgntColorSuccess;
	}
*/ }

.full_background .jgnt_upload_progress {
  position: absolute;
  left: 0;
  right: 0;
  top: -10px; }

.jgnt_order_by {
  font-weight: normal !important; }
  .jgnt_order_by span {
    color: #000 !important;
    font-weight: bold;
    white-space: nowrap !important; }
  .jgnt_order_by a {
    display: inline-block !important;
    color: #989898 !important;
    text-decoration: none !important; }
    .jgnt_order_by a.selected {
      color: #000 !important; }
    .jgnt_order_by a:hover {
      color: #57996b !important;
      text-decoration: underline !important; }

.dummy_holder {
  margin: 0 auto;
  position: relative; }
  .dummy_holder .dummy_image {
    display: block;
    position: relative;
    width: 220px;
    height: 120px;
    background: #f4f4f4;
    margin: 5px 0 0 0;
    border-radius: 5px; }
    .dummy_holder .dummy_image img {
      max-width: 100%;
      max-height: 100%;
      display: block;
      width: 100%; }

.accent_color.ready {
  background-color: #ffb900 !important;
  color: #fff !important;
  border-radius: 30px; }
  .accent_color.ready:hover {
    background-color: #e6a700 !important; }

h2 span {
  font-size: 0.6em;
  font-weight: normal; }
  h2 span.float_right {
    float: right; }

.rslides li {
  max-height: none !important; }
  .rslides li img {
    max-width: 100% !important;
    max-height: 80vh !important;
    display: block !important;
    width: auto !important;
    height: auto !important;
    margin: auto !important; }
  .rslides li .inscription {
    background-color: #fff !important;
    float: none !important;
    clear: both !important;
    margin: 0 !important;
    padding-top: 10px !important; }
  .rslides li .inscription:empty {
    display: none; }

.gallery .rslides_nav {
  margin-bottom: -31px !important; }

.gallery .rslides li img {
  width: auto !important; }

.gallery .save, .gallery .cancel {
  background-color: #57996b !important;
  color: #fff;
  display: inline-block !important;
  font-size: 14px !important;
  font-weight: bold !important;
  line-height: 34px !important;
  border: none !important;
  border-radius: 30px !important;
  height: 34px !important;
  padding: 0 20px !important;
  width: 140px !important;
  text-align: center !important; }

.gallery .cancel {
  background-color: #989898 !important; }

.gallery .gallery_frame .picture_frame .tint:not(.no-picture) {
  cursor: pointer; }
  .gallery .gallery_frame .picture_frame .tint:not(.no-picture):hover {
    background-color: rgba(255, 255, 255, 0.8);
    background-image: url(/img/les/upload.svg);
    background-position: center center;
    background-size: auto;
    background-repeat: no-repeat; }

.gallery .gallery_frame .picture_frame .list li {
  /*
					.image {
						max-width:100% !important;
						max-height: 100% !important;
						display: block !important;
						width: auto !important;
						height: auto !important;
					}
*/ }
  .gallery .gallery_frame .picture_frame .list li .image_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 520px !important; }

.jgnt_home_promo {
  display: flex !important;
  align-items: flex-end;
  flex-wrap: wrap;
  margin-bottom: -20px !important; }
  .jgnt_home_promo > li {
    margin: 0 0 30px 0 !important;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 50%; }
    .jgnt_home_promo > li .social {
      margin-bottom: 0 !important; }
    .jgnt_home_promo > li .edit {
      top: 5px; }
    .jgnt_home_promo > li .up, .jgnt_home_promo > li .down {
      bottom: 0 !important;
      display: none; }
    .jgnt_home_promo > li:hover .up, .jgnt_home_promo > li:hover .down {
      display: block; }

.block {
  background-color: #fff;
  /*
	&.date_block {
		z-index: 2;
	}
	&.rubric_block {
		z-index: 2;
	}
*/ }

.article_specs {
  position: relative;
  z-index: 1;
  background-color: #fff; }

.article_page {
  position: relative;
  z-index: 1; }

.map_holder {
  position: relative;
  z-index: 1; }

footer {
  position: relative;
  z-index: 1; }

.saved_article {
  background-color: #fff;
  position: relative;
  z-index: 1; }

.article_footer {
  background-color: #fff;
  position: relative;
  z-index: 1; }

.jgnt_cover {
  position: relative;
  overflow: hidden;
  z-index: 1; }
  .jgnt_cover .jgnt_bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    z-index: 0; }
  .jgnt_cover.no_cover .jgnt_bg {
    display: none; }
  .jgnt_cover.fixed .jgnt_bg {
    position: fixed;
    top: 55px;
    height: 80%; }
  .jgnt_cover[data-bg-position="align-top-left"] .jgnt_bg {
    background-position: left top; }
  .jgnt_cover[data-bg-position="align-top-right"] .jgnt_bg {
    background-position: right top; }
  .jgnt_cover[data-bg-position="align-bottom-left"] .jgnt_bg {
    background-position: left bottom; }
  .jgnt_cover[data-bg-position="align-bottom-right"] .jgnt_bg {
    background-position: right bottom; }

/*


.jgnt_cover_fixed {
	position: relative;
	overflow: hidden;
	z-index: 1;
	.jgnt_bg {
		display: block;
//	    background-color: red;
	    background: url('/img/les/cityscape.jpg') no-repeat center center;
	    background-size: contain;
	    background-position: center center;
	    will-change: transform; // creates a new paint layer
	    z-index: -1;
	    &[data-bg-position="align-top-left"] {
		    background-position: left top;
	    }
	    &[data-bg-position="align-top-right"] {
		    background-position: right top;
	    }
	    &[data-bg-position="align-bottom-left"] {
		    background-position: left bottom;
	    }
	    &[data-bg-position="align-bottom-right"] {
		    background-position: right bottom;
	    }
	}
}
*/
.add_comment {
  position: relative; }
  .add_comment .nod-error-message {
    position: absolute;
    left: 5px;
    top: auto;
    bottom: -15px; }
  .add_comment button {
    cursor: pointer !important; }
  .add_comment.has-danger .form-control, .add_comment.has-success .form-control {
    border: none !important; }

.jgnt_social_top {
  position: absolute;
  right: -2px;
  top: 14px;
  height: 25px;
  line-height: 25px !important; }
  .jgnt_social_top a {
    display: inline-block;
    height: 25px;
    margin-left: 14px;
    font-size: 24px;
    color: #333333; }
    .jgnt_social_top a:hover {
      color: #57996b; }
      .jgnt_social_top a:hover #telegram {
        fill: #57996b; }
    .jgnt_social_top a.telegram {
      position: relative;
      top: 2px; }

.jgnt_article_small .extrude.full {
  height: auto !important; }

.jgnt_article_small .article_holder {
  height: 430px !important; }

/*
.selectboxit-container {
	position: relative;
	z-index: 1000;
}

.selectboxit-list {
	z-index: 1000;
}

.selectboxit-options {
	z-index: 1000;
}

.selectboxit {
	z-index: 1000;
}
*/
.selectboxit-container .selectboxit-options {
  z-index: 1000; }

#first_section_ever {
  z-index: 2; }

.jgnt_partner {
  margin: 0 auto 2rem auto;
  text-align: center;
  max-width: 220px;
  color: #fff; }
  .jgnt_partner img {
    width: 100%;
    height: auto; }
  .jgnt_partner a {
    color: #fff; }

.jgnt_select_post_types a {
  display: block;
  padding: 0 20px;
  height: 34px;
  line-height: 34px;
  border: 1px solid #000;
  border-radius: 30px;
  text-align: center;
  margin-bottom: 10px; }
  .jgnt_select_post_types a:hover {
    background-color: #57996b;
    border: 1px solid #57996b;
    color: #fff !important; }

article.article_page section.block ul.modify {
  top: -23px !important; }

article.article_page.in_editor .inscription {
  background: rgba(0, 0, 0, 0.08); }

.jgnt_textarea {
  border: 1px solid #f4f4f4;
  height: 56px;
  padding: 7px 10px; }
  .jgnt_textarea a {
    color: #57996b; }
  .jgnt_textarea.tags {
    height: 100px; }

/*
.jgnt_placeholder {
	position: relative;
	.jgnt_placeholder_text {
		&.off {
			display: none;
		}
	}
}
*/
.special_stuff {
  display: flex;
  align-items: center;
  justify-content: center; }
  .special_stuff img {
    display: block;
    width: 100% !important;
    max-width: 940px !important;
    margin: auto !important; }

.team.padded {
  width: 100% !important;
  max-width: 960px; }

/*
.landing_wrap {
	padding: 0 !important;
}
*/
.jgnt_page {
  width: 100% !important;
  min-height: 88vh; }

section.wrapper {
  padding: 55px 0 44px 0 !important;
  max-width: 940px !important; }
  section.wrapper.become_author {
    max-width: 960px !important; }

.next_page {
  color: #fff !important; }

.broadcast_editor_page #true_header .tint {
  display: none !important; }

.home_page #authors_list h2 {
  color: #333333 !important; }

.jgnt_content {
  max-width: 940px;
  margin: auto; }

.jgnt_big_banner {
  background-position: center center;
  background-size: cover;
  height: auto !important;
  padding: 3rem 0 2rem 0; }
  .jgnt_big_banner .logo {
    float: left;
    max-width: 320px;
    margin-top: 0.2rem;
    margin-bottom: 4rem; }
    .jgnt_big_banner .logo img {
      width: 100%;
      height: auto; }
  .jgnt_big_banner .button {
    float: left;
    clear: left;
    display: inline-block; }
    .jgnt_big_banner .button a {
      display: block;
      background-color: #0EB1F3;
      color: #fff;
      text-align: center;
      padding: 0.5rem 2rem;
      border-radius: 18px;
      text-transform: uppercase; }
      .jgnt_big_banner .button a:hover {
        background-color: #fff;
        color: #000; }
  .jgnt_big_banner .text {
    float: right;
    max-width: 540px;
    color: #fff; }
    .jgnt_big_banner .text h2 {
      font-size: 1.4rem;
      margin-bottom: 2rem; }
    .jgnt_big_banner .text p {
      font-size: 1rem;
      margin-bottom: 1rem; }
  @media (max-width: 1000px) {
    .jgnt_big_banner {
      padding: 3rem 2rem 2rem 2rem; }
      .jgnt_big_banner .logo {
        float: none;
        margin-top: 0;
        margin-bottom: 2rem; }
      .jgnt_big_banner .text {
        float: none;
        max-width: none;
        margin-bottom: 2rem; }
      .jgnt_big_banner .button {
        float: none; } }

@media (max-width: 1020px) {
  section.wrapper {
    padding: 0 1rem 44px 1rem !important; }
  .wrapper {
    width: 100% !important;
    padding-right: 1rem !important;
    padding-left: 1rem !important; }
  .home_wrap {
    overflow-y: hidden; }
  .jgnt_social_top {
    right: 1rem; }
  .articles.fit_all {
    width: 100% !important; }
    .articles.fit_all article {
      width: 48% !important; }
      .articles.fit_all article:nth-child(2n) {
        margin-right: 0 !important; }
  nav.sub_header_menu .search_form {
    right: 0; }
  section.wrapper article:nth-child(4n+4) {
    margin: 0 20px 20px 0; }
  .full_banner .articles {
    max-width: 940px; } }

@media (max-width: 600px) {
  nav.sub_header_menu.after_top {
    height: auto; }
  nav.sub_header_menu .search_form {
    position: static; }
  nav.sub_header_menu .search_form input {
    position: static; }
  nav.sub_header_menu .search_form .icon-search {
    position: static;
    margin-left: -25px; } }

@media (max-width: 540px) {
  .articles.fit_all article {
    width: 100% !important;
    margin-right: 0 !important; }
  section.wrapper article {
    width: 100%; } }

.map_articles_holder {
  width: 960px !important; }
  .map_articles_holder li h3 {
    padding-right: 20px !important; }
  .map_articles_holder li p {
    padding-right: 20px !important; }

@media (max-width: 1070px) {
  .map_holder {
    background-color: #fff;
    width: 100%;
    overflow: hidden !important; }
    .map_holder .left-arrow {
      left: 0 !important;
      margin: 0 !important; }
    .map_holder .right-arrow {
      right: 0 !important;
      margin: 0 !important; }
  .map_articles_holder {
    width: 720px !important;
    padding: 0 !important; } }

@media (max-width: 820px) {
  .map_articles_holder {
    width: 480px !important;
    padding: 0 !important; } }

@media (max-width: 600px) {
  .map_articles_holder {
    width: 240px !important;
    padding: 0 !important; } }

@media (max-width: 820px) {
  .home_page #top_main_menu_nav {
    height: auto !important; }
    .home_page #top_main_menu_nav ul {
      margin-top: 12px;
      margin-right: 140px;
      line-height: 30px;
      min-height: 55px; }
      .home_page #top_main_menu_nav ul .icon-bars {
        margin-top: 7px; } }

@media (max-width: 760px) {
  .home_page #true_header .main_folders {
    position: relative !important;
    float: left !important;
    margin-left: 1rem !important;
    margin-right: 1rem !important; } }

@media (max-width: 600px) {
  body > header nav.main_menu {
    float: none; } }

.jgnt_burger {
  position: absolute;
  left: 0;
  top: 0; }
  .jgnt_burger a {
    color: #333333; }

@media (max-width: 1020px) {
  .jgnt_burger {
    left: 1rem; } }

.jgnt_top_menu {
  margin-left: 2rem; }

@media (max-width: 820px) {
  .jgnt_top_menu {
    margin-top: 12px;
    margin-right: 140px;
    line-height: 30px;
    min-height: 55px; } }

@media (min-width: 721px) and (max-width: 960px) {
  .first_article {
    bottom: auto !important;
    left: 2rem !important;
    top: 2rem !important;
    width: 60% !important; }
  .jgnt_home_promo {
    right: 2rem !important; } }

@media (max-width: 720px) {
  .full_banner .articles {
    padding-top: 2rem;
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-flow: row nowrap; }
    .full_banner .articles article {
      position: relative !important;
      margin: 0  0 4rem 0 !important; }
    .full_banner .articles .jgnt_home_promo {
      position: relative !important; } }

@media (max-width: 520px) {
  .full_banner .articles {
    width: 100% !important;
    display: block !important; }
    .full_banner .articles .first_article {
      position: absolute !important;
      top: auto !important;
      bottom: 0 !important;
      margin-bottom: 2rem !important;
      width: auto !important; }
    .full_banner .articles .jgnt_home_promo {
      display: none !important; } }

@media (max-width: 420px) {
  .jgnt_hide_small {
    display: none; }
  .jgnt_hide_big {
    display: inline; } }

@media (min-width: 421px) {
  .jgnt_hide_small {
    display: inline; }
  .jgnt_hide_big {
    display: none; } }

body > header nav.main_menu ul li.profile .icon-user {
  margin-right: 0.5rem; }
