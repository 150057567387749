.editor_bubble{
  position: absolute;
  background:$black;
  color:#d9d9d9;
  left:0;
  top:0;
  z-index: 200;
  font-size:10px;
  font-family: $font1;
  margin: -35px 0 0 -125.5px;
  text-align: center;
  border-radius: 20px;
  display: none;
  visibility: hidden;
  padding:15px;
  height:43px;
  box-sizing: border-box;
  width:251px;
  &--active{
    display: inline-block;
    visibility: visible;
  }
  &::after {
    content: " ";
    line-height: normal;
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -3px;
    margin-top:-1px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
    line-height: normal !important;
  }
  ul{
    list-style: none;
    li{
      display: inline-block;
      vertical-align: top;
      &.spacer{
        width: 20px;
        &:after{
          content: " ";
          position: absolute;
          height: 18px;
          width:1px;
          top:12px;
          background:#d9d9d9;
        }
      }
      &:first-child{
        button{
          padding-left:0;
        }
      }
      &:last-child{
        button{
          padding-right:0;
        }
      }
    }
  }
  button{
    width:auto;
    border:none;
    height: auto;
    background:none;
    cursor: pointer;
    font-weight: normal;
    font-size: 11px;
    line-height: normal;
    position: relative;
    top: -3px;
    color:#d9d9d9;
    &:hover{
      background:none;
      color:#fff;
    }
    &.color{
      width:19px;
      height:19px;
      border:2px solid #fff;
      top:-5px;
      position: relative;
      margin:0 3px;
    }
  }
}

@keyframes pop-upwards {
  0% {
      opacity: 0;
      transform: matrix(0.97, 0, 0, 1, 0, 12);
  }
  20% {
      opacity: 0.7;
      transform: matrix(0.99, 0, 0, 1, 0, 2);
  }
  40% {
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, -1);
  }
  70% {
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
  }
  100% {
      opacity: 1;
      transform: matrix(1, 0, 0, 1, 0, 0);
  }
}
