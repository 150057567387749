section.block.plain {
	.editable {
	}
}

.in_editor.selected {
	.editable {
		min-height: 1em;
		&.person {
			min-width: 45px;
		}
		&:hover {
			background:rgba(0,0,0,0.02);
		}
		&:focus {
			min-height: 1em;
			background:rgba(0,0,0,0.03);
		}
	}
}

.interview_with_faces {
	.person {
		min-width: 45px;
	}
}

.solid_footnote_mono {
	.left {
		float: none !important;
	}
}

.picture_with_text, .picture_with_text2 {
	.picture_frame {
		margin-top: 0 !important;
	}
}

.jgnt_btn_bordered {
	display: inline-block;
	background: transparent;
	border: 2px solid #fff;
	border-radius: 30px;
//	color: #fff;
	padding: 5px 15px;
	font-weight: bold;
	text-align: center;
	@include jgnt_placeholder_color( inherit, inherit );
	&:placeholder-shown {
		opacity: 0.7;
		min-width: 280px;
	}
}

[contentEditable=true]:empty:not(:focus):before{
    content:attr(data-placeholder);
    color: rgb(169, 169, 169);
    color: inherit;
    opacity: 0.7;
}
