@font-face {
  font-family: 'les-icons4';
  src:  url('/css/fonts/les-icons4.eot?y3q0zo');
  src:  url('/css/fonts/les-icons4.eot?y3q0zo#iefix') format('embedded-opentype'),
    url('/css/fonts/les-icons4.ttf?y3q0zo') format('truetype'),
    url('/css/fonts/les-icons4.woff?y3q0zo') format('woff'),
    url('/css/fonts/les-icons4.svg?y3q0zo#les-icons4') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'les-icons4' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-logo:before {
  content: "\e900";
}
.icon-views:before {
  content: "\e901";
}
.icon-settings:before {
  content: "\e902";
}
.icon-draft:before {
  content: "\e903";
}
.icon-rotate:before {
  content: "\e904";
}
.icon-trash:before {
  content: "\e905";
}
.icon-switch:before {
  content: "\e906";
}
.icon-copy:before {
  content: "\e907";
}
.icon-arrow-down-thin:before {
  content: "\e908";
}
.icon-plus:before {
  content: "\e909";
}
.icon-text-case:before {
  content: "\e90a";
}
.icon-star:before {
  content: "\e90b";
}
.icon-bold-italic:before {
  content: "\e90c";
}
.icon-bold:before {
  content: "\e90d";
}
.icon-italic:before {
  content: "\e90e";
}
.icon-underline:before {
  content: "\e90f";
}
.icon-linethrough:before {
  content: "\e910";
}
.icon-align-left:before {
  content: "\e911";
}
.icon-align-center:before {
  content: "\e912";
}
.icon-align-right:before {
  content: "\e913";
}
.icon-quote:before {
  content: "\e914";
}
.icon-russian:before {
  content: "\e915";
}
.icon-english:before {
  content: "\e916";
}
.icon-quote-cover-right:before {
  content: "\e917";
}
.icon-quote-cover:before {
  content: "\e918";
}
.icon-flag:before {
  content: "\e919";
}
.icon-keyword-center:before {
  content: "\e91a";
}
.icon-keyword-center-left:before {
  content: "\e91b";
}
.icon-keyword-center-right:before {
  content: "\e91c";
}
.icon-keyword-left:before {
  content: "\e91d";
}
.icon-keyword-top:before {
  content: "\e91e";
}
.icon-keyword-right:before {
  content: "\e91f";
}
.icon-keyword-text:before {
  content: "\e920";
}
.icon-keyword-text-right:before {
  content: "\e921";
}
.icon-keyword-full:before {
  content: "\e922";
}
.icon-interview:before {
  content: "\e923";
}
.icon-interview-border:before {
  content: "\e924";
}
.icon-interview-portraits:before {
  content: "\e925";
}
.icon-chat:before {
  content: "\e926";
}
.icon-footnote-half:before {
  content: "\e927";
}
.icon-footnote-full:before {
  content: "\e928";
}
.icon-cover:before {
  content: "\e929";
}
.icon-content-center:before {
  content: "\e92a";
}
.icon-content-top:before {
  content: "\e92b";
}
.icon-spacer:before {
  content: "\e92c";
}
.icon-content-cover:before {
  content: "\e92d";
}
.icon-content-cover-right:before {
  content: "\e92e";
}
.icon-brightness:before {
  content: "\e92f";
}
.icon-plus-minus:before {
  content: "\e930";
}
.icon-move:before {
  content: "\e931";
}
.icon-gallery:before {
  content: "\e932";
}
.icon-pencil:before {
  content: "\e933";
}
.icon-upload:before {
  content: "\e934";
}
.icon-video:before {
  content: "\e935";
  left: 0.06vw;
  position: relative;
}
.icon-link:before {
  content: "\e936";
}
.icon-content-left-full:before {
  content: "\e937";
}
.icon-content-right-full:before {
  content: "\e938";
}
.icon-square:before {
  content: "\e939";
}
.icon-location:before {
  content: "\e93a";
}
.icon-position:before {
  content: "\e93b";
}
.icon-download:before {
  content: "\e93c";
}
.icon-arrow-up-thin:before {
  content: "\e93d";
}
.icon-qoutes:before {
  content: "\e93e";
}
.icon-qoute-fancy:before {
  content: "\e93f";
}
.icon-facebook-round:before {
  content: "\e940";
}
.icon-vk-round:before {
  content: "\e941";
}
.icon-twitter-round:before {
  content: "\e942";
}
.icon-google-plus-round:before {
  content: "\e943";
}
.icon-pinterest:before {
  content: "\e944";
}
.icon-facebook:before {
  content: "\e945";
}
.icon-vk:before {
  content: "\e946";
}
.icon-twitter:before {
  content: "\e947";
}
.icon-likes:before {
  content: "\e948";
}
.icon-expert_likes:before,
.icon-expert-likes:before {
  content: "\e949";
}
.icon-shares:before {
  content: "\e94a";
}
.icon-comments:before {
  content: "\e94b";
}
.icon-search:before {
  content: "\e94c";
}
.icon-notice:before {
  content: "\e94d";
}
.icon-write:before {
  content: "\e94e";
}
.icon-user:before {
  content: "\e94f";
}
.icon-logout:before {
  content: "\e951";
}
.icon-bars:before {
  content: "\e952";
}
.icon-bars2:before {
  content: "\e953";
}
.icon-smile:before {
  content: "\e954";
}
.icon-conversation:before {
  content: "\e955";
}
.icon-broadcast:before {
  content: "\e956";
}
.icon-print:before {
  content: "\e957";
}
.icon-magazines:before {
  content: "\e958";
}
.icon-hidden:before {
  content: "\e959";
}
.icon-round-check:before {
  content: "\e95a";
}
.icon-round-plus:before {
  content: "\e95b";
}
.icon-round-close:before {
  content: "\e95c";
}
.icon-list:before {
  content: "\e95d";
}
.icon-important:before {
  content: "\e95e";
}
.icon-LES_Icons_part_4-96 .path1:before {
  content: "\e95f";
  color: #57996b;
  opacity: 0.5;
}
.icon-LES_Icons_part_4-96 .path2:before {
  content: "\e960";
  margin-left: -1em;
  color: #57996b;
}
.icon-arrow-left .path1:before {
  content: "\e961";
  color: rgb(0, 0, 0);
  opacity: 0.25;
}
.icon-arrow-left .path2:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-arrow-both .path1:before {
  content: "\e963";
  color: rgb(255, 255, 255);
}
.icon-arrow-both .path2:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.25;
}
.icon-arrow-both .path3:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-close:before {
  content: "\e966";
}
.icon-fat-plus:before {
  content: "\e967";
}
