.fields{
  select,
  input[type='text'],
  input[type='email'],
  input[type='password'],
  textarea{
    width:100%;
    background:$mcolor;
    height:34px;
    border: none;
    color:#000;
    margin:0 0 10px 0;
    box-sizing: border-box;
    padding: 0 10px;
    font-family: $font1;
    font-size: 14px;
    &:focus{
      @include placeholder {
        transition: opacity 0.2s 0.2s ease;
        opacity:0;
      }
    }
  }
  textarea{
    padding: 7px 10px;
  }
  label{
    font-weight: bold;
    font-size: 14px;
    margin:0 0 4px 0;
    display: block;
    &.control{
      font-weight: normal;
    }
    &.where{
      margin:40px 0 0 0;
    }
  }
  .error{
    width:100%;
    box-sizing: border-box;
    font-size: 10px;
    color:$black;
    margin:0px 0 10px 0;
  }
  .small{
    font-size:10px;
    font-weight: normal;
  }
}
