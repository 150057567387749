.veil.subscribers_group{
  .user_block{
    width:100%;
    display: block;
    float:left;
    margin:0 0 43px 0;
  }
  form{
    width:660px;
    height: 650px;
    padding: 0;
    h1{
      width:100%;
      height:50px;
      line-height: 50px;
      font-size:24px;
      font-weight: bold;
      padding:0 20px;
      box-sizing: border-box;
      .red_button{
        display:block;
        float:right;
        position: relative;
        top:10px;
      }
    }
    .users{
      .remove{
        display: none;
      }
    }
    .group_users{
      .add{
        display: none;
      }
    }
    .sub_titles{
      width:100%;
      height:34px;
      font-weight: bold;
      line-height: 34px;
      box-sizing: border-box;
      padding:0 20px;
      .left{
        float:left;
        width:50%;
      }
      .right{
        width:50%;
        box-sizing: border-box;
        padding: 0 0 0 40px;
        float:right;
      }
    }
    .sliders{
      .search{
        position: relative;
      }
      .subscriber_name{
        background: #f4f4f4 none repeat scroll 0 0;
        box-sizing: border-box;
        font-family: "Open Sans",sans-serif;
        font-size: 14px;
        height: 34px;
        line-height: 34px;
        padding: 0 10px;
        width: 100%;
      }
      .icon-search{
        bottom: 20px;
        position: absolute;
        right: 10px;
      }
      .left{
        width:50%;
        float:left;
        box-sizing: border-box;
        padding: 10px 20px 20px 20px;
        max-height: 566px;
        overflow: auto;
      }
      .right{
        float:right;
        width:50%;
        box-sizing: border-box;
        padding: 10px 20px 20px 20px;
        max-height: 566px;
        overflow: auto;
      }
      .remove.icon-close,
      .add.icon-plus{
        float:right;
        color:$mcolor;
        font-size:30px;
      }

    }
  }
}
