.team{
  margin:90px 0 0 0;
  clear:both;
  width:100%;
  &.padded{
    width:960px;
    left:0px;
    margin:0;
    position: relative;
    .info_block{
      display: block;
      float: left;
      font-size: 14px;
      height: 220px;
      margin: 0 20px 0 0;
      position: relative;
      width: 220px;
    }
    ul{
      li{
        width:$cell3;
        margin:0 20px 0 0;
      }
    }
  }
  ul{
    list-style: none;
    li{
      width:25%;
      float:left;
      height:220px;
    }
  }
  .profile_pic{
    text-align: center;
    height:97px;
    img{
      width:90px;
      border-radius: 90px;
      display: inline-block;
    }
    .round{
      background: #fff none repeat scroll 0 0;
      border-radius: 90px;
      color: #000;
      font-weight: bold;
      height: 90px;
      font-size: 25px;
      line-height: 90px;
      text-align: center;
      width: 90px;
      text-transform: uppercase;
      box-shadow: 0px 0px 1px rgba(0,0,0,0.3);
      display: inline-block;
    }
  }
  a{
    color:$black;
    &:hover{
      color:$black;
    }
  }
  .views{
    font-size:10px;
    color:$gray;
    vertical-align: top;
    .icon-views{
      font-size: 13px;
      margin: 0 2px 0 0;
      position: relative;
      top: 2px;
    }
  }
  h2{
    font-size:18px;
    font-weight: bold;
  }
  .full_name{
    font-size:14px;
    font-weight: bold;
  }
  .profile_info{
    text-align: center;
  }
  .position{
    text-align: center;
    color:$gray2;
    margin:10px 0 0 0;
  }
  .small_position{
    font-size:10px;
    color:$gray;
    text-align: center;
    margin:6px 0 0 0;
    a{
      color:$gray;
    }
  }
}

@media (max-width: 530px) {
  .team {
    &.padded {
      .info_block {
        margin: 0 auto 50px;
        float: none;
        height: auto;
      }
      ul {
        li {
          margin: 0 auto;
          float: none;
        }
      }
    }
  }
}
