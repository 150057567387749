&.broadcast_header.mediapolygon{
  height:560px;
  
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  
  .logo{
    margin:35px 0 0 0;
  }
  .logo_spacer.white{
    top:30px;
  }
  .edit{
    top:120px;
    right:0;
    a.button{
      display: block;
      background:none;
      border:1px solid #fff;
      color:#fff;
      margin:0 0 10px;
      width:115px;
      padding: 0;
      height:34px;
      line-height: 34px;
      text-align: center;
    }
  }
   nav.main_menu{
     text-transform: uppercase;
     &.to_left{
       margin:-15px 0 0 0;
     }
     &.common{
       top:30px;
       position: relative;
     }
     #landing_about_switch{
     }
     ul{
       li{
         color:#fff;
         &.profile{
           &:hover{
            >a{
              color:#fff;
            }
           }
           > a{
             &:hover{
               color:$red;
             }
           }
         }
       }
     }
     ul li.search{
       &.selected{
         .search_icon_position{
           color:$gray2;
         }
       }
       .search_icon_position{
        color:#fff;
        }
     }
   }
  .broadcast_info{
    text-align: center;
    top:245px;
    bottom:auto;
    width:$cell10;
    left:$cell1+20px;
    .ribakov,.other_partners{
      position: absolute;
      label{
        font-size:14px;
        font-weight: normal;
        color:$mcolor;
      }
    }
    .ribakov{
      left: 304px;
      top: -82px;
      label{
        width:100%;
        display: block;
      }
      .image{
        background: rgba(0, 0, 0, 0) url("/img/les/fond-riba2.png") no-repeat scroll 0 0 / 157px auto;
        height: 23px;
        margin: 3px 0 0;
        display: block;
      }
    }
    .other_partners{
      top:-73px;
      right:165px;
    }
    .subscribe_icon{
      position: absolute;
      right: 130px;
      top: 10px;
    }
    h1{
      margin:0 0 38px 0;
    }
    p{
      font-weight: normal;
      line-height: 22px;
    }
    #broadcast_type_form{
      font-weight: bold;
      display: inline-block;
      padding: 0 20px;
      height:34px;
      margin:21px 0 0 0;
      line-height: 34px;
      border:1px solid #fff;
      border-radius: 30px;
    }
  }
}
