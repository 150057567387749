.article_page{
  display:none;
  &.selected{
    display: block;
  }
}
#veil_settings2_toggle{
  .veil.settings{
    margin:0;
  }
}
#settings_part1_form,
#settings_part1a_form{
  height:auto;
}
#settings_part2_form{
  height:400px;
}
#no_previews_selected{
  display: none;
  &.selected{
    display: block;
  }
}
.previews{
  display: block;
  &.not_visible{
    display: none;
  }
}
.subpages{
  display: none;
  #block_types_id{
    &.no_cover{
      .full_background,
      .half_image,
      .half_gallery,
      .half_quote{
        display: none;
      }
    }
  }
  .fillable_field{
    height:32px;
    width:100%;
    border:1px solid $mcolor;
    font-size:14px;
    padding: 5px 10px;
    white-space: nowrap;
    box-sizing: border-box;
    background:#fff;
    overflow: hidden;
    .placeholder{
      color:$gray;
    }
    br{
      display: none;
    }
    a{
      color:$black;
    }
    &.space_bottom{
      margin:0 0 10px 0;
    }
  }
  .float_holder{
    width:100%;
    float:left;
  }
  h2{
    font-size:24px;
    font-weight: bold;
    clear:both;
  }
  .next_page{
    background: $red none repeat scroll 0 0;
    border-radius: 30px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: bold;
    height: 33px;
    left: 50%;
    line-height: 33px;
    margin: 0 0 0 470px;
    padding: 0 19px;
    position: fixed;
    text-align: center;
    top: 12px;
    z-index: 200;
  }
  &.selected{
    display: block;
    height:100%;
  }
  .pointer_down{
    background:$black;
    position: absolute;
    height:22px;
    line-height: 22px;
    text-align: center;
    font-weight: bold;
    font-size:10px;
    width:152px;
    border-radius: 20px;
    left:50%;
    color:#fff;
    margin:-11px 0 0 -76px;
  }
  .subpage{
      form{
        &#settings_part1_form{
          background:$mcolor;
          margin: 0 auto 22px auto;
        }

    }
    &.selected{
      display: block;
      height:100%;
    }
  }
  #veil_description_toggle{
    ul.modify{
      display: block;
    }
    .veil{
      height:100%;
    }
    .left.articles{
      padding:40px 0 0 0;
      position: relative;
      ul.modify{
        right:0;
        display: block;
      }
      article{
        display: none;
        &.selected{
          display: block;
        }
      }
    }
    .right.articles{
      padding:40px 0 0 0;
      position: relative;
      ul.modify{
        right:0;
        display: block;
      }
      article{
        display: none;
        &.selected{
          display: block;
        }
      }
    }
    form{
      width:100%;
      height:100%;
      #description{
        padding: 40px 0 0 0;
        height:100%;
        position: relative;
      }
    }
  }
  >nav{
    margin:$header-height 0 0 0;
    width:100%;
    height:30px;
    line-height: 30px;
    text-align: center;
    border-top:1px solid $mcolor;
    border-bottom:1px solid $mcolor;
    z-index: 101;
    ul{
      list-style: none;
      li{
        display: inline-block;
        &.dash{
          border-top: 1px solid #000;
          height: 10px;
          margin: 0 24px;
          position: relative;
          top: 15px;
          vertical-align: top;
          width: 14px;
        }
        a{
          color:#000;
          text-transform: uppercase;
          &.selected{
            font-weight: bold;
          }
        }
      }
    }
  }
  .veil{
    display: block;
    &.settings{
      select{
        background:#fff;
        &.space_bottom{
          margin:0 0 24px 0;
        }
      }
      margin:50px 0 0 0;
      .important{
        background:$red;
        border-radius: 20px;
        display: inline-block;
        height: 11px;
        vertical-align: top;
        margin:5px 10px 12px 0;
        width: 11px;
      }

      .control{
        padding-left:22px;
      }
      .left{
        margin:0;
        &.special_left{
          box-sizing: border-box;
          padding: 0 0 0 28px;
          .control.label_move{
            padding-left: 30px;
          }
          .label_move{
            position: relative;
            left:-31px;
            font-weight: bold;
            .important{
              margin: 5px 14px 12px 5px;
            }
            .control{
              font-weight: bold;
            }
            .control__indicator{
              left:5px;
            }
          }
        }
      }
      .right{
        margin:0;
      }
    }
    &.settings,
    &.cover{
      height: auto;
      position: relative;
      .tab{
        display: block;
      }
      form{
        position: relative;
        top:auto;
        left:auto;
        margin: 0 auto;
        box-shadow: none;
        h2{
          //display: none;
        }
      }
      .hidden{
        display: block;
        width:780px;
        position: relative;
        left:-80px;
        margin:0 auto;
      }
    }
  }
}
ul.publications{
  list-style: none;
  margin:42px 0 0 0;
  li{
    display: inline-block;
    margin:0 20px 0 0;
    &:nth-child(3n+3){
      margin:0;
    }
    a{
      margin:0 0 23px 0;
      display: block;
      width:140px;
      height:76px;
      text-align: center;
      background:$gray2;
      line-height: 76px;
      font-size:12px;
      font-weight: bold;
      color:#fff;
      &.selected,&:hover{
        background:$red;
      }
    }
  }
}
#location_map{
  height:210px;
  width:100%;
  margin:22px 0 0 0;
}
#find_location{
  width:100%;
  background:$mcolor;
  height:34px;
  line-height: 34px;
  box-sizing: border-box;
  padding: 0 10px;
  font-size: 14px;
  font-family: $font1;
}
#press_find_location{
  bottom: 20px;
  position: absolute;
  right: 10px;
}
.veil{
  form{
    label{
      font-weight: bold;
      font-size: 14px;
      margin:0 0 4px 0;
      display: block;
      &.control{
        font-weight: normal;
      }
      &.where{
        margin:40px 0 0 0;
      }
    }
    button.load_other_preview{
      width:180px;
      background:none;
      color:$red;
      border:1px solid $red;
      margin:0 0 22px 0;
      &:hover{
        color:#fff;
        background:$red;
      }
    }
    header{
      &.darker{
        h2{
          background:$black;
          color:#fff;
        }
      }
      h2{
        color:$black;
        width:100%;
        font-size:24px;
        line-height: 55px;
        height:55px;
        box-sizing: border-box;
        padding: 0 20px;
        margin: 0;
      }
      p{
        border-bottom: 1px solid $mcolor;
        height:32px;
        box-sizing: border-box;
        padding: 0 20px;
        line-height: 32px;
        font-size:14px;
        display:block;
        font-weight: bold;
        color:$black;
      }
    }
  }
  &.date{
    form{
      width:340px;
      padding: 25px;
      box-sizing: border-box;
    }
  }
  &.cover{
    position: absolute;
    height: 3000px;
    z-index:100;
    form{
      width:980px;
      //min-height:495px;
      padding: 20px;
      position: absolute;
      top:100px;
      margin:0px 0px 0px (-940px/2);
      left:50%;
    }
    nav{
      border-top:1px solid $mcolor;
      border-bottom:1px solid $mcolor;
      ul{
        list-style: none;
        height:30px;
        line-height: 30px;
        width:100%;
        box-sizing: border-box;
        padding: 0 20px;
        text-transform:uppercase;
        li{
          display: inline-block;
          margin:0 30px 0 0;
          a{
            color:#000;
            &.selected{
              font-weight: bold;
            }
          }
          &:last-child{
            margin:0;
          }
        }
      }
    }
    #description{
      &.full_height{
        .samples{
          height:100%;
          height:670px;
        }
        .pointer_down{
          display: block;
        }
      }
      .pointer_down{
        display: none;
      }
    }
    .samples{
      margin:0px 0 0 0;
      height:auto;
      article{
        display: none;
        height:auto;
        &.full_background{
          min-height:100%;
          left: -20px;
          padding: 40px 20px 50px;
          box-sizing: content-box;
          overflow: hidden;
        }
        &.selected{
          display: block;
        }
      }
    }
    .switch{
      display: block;
      clear: both;
      width:100%;
      margin:0 0 20px 0;
      font-size: 14px;
      color:#000;
    }
    .tint{

    }
    .hidden{
      display:none;
      &.selected{
        display:block;
      }
    }
    .left{
      float:left;
    }
    .right{
      float:right;
      article{
        margin:0 !important;
      }
    }
    .center_text{
      width:$cell12;
      margin:5px auto 6px;
      font-size:14px;
    }
    .gallery{
      height:43px;
      background:$mcolor;
      display: block;
      float:left;
      margin:0 0 1px 0;
    }
    .switch{
        .on{
          display: none;
        }
        .off{
          display: block;
        }
      &.selected{
        .on{
          display: block;
        }
        .off{
          display: none;
        }
      }
    }
    .tab{
      display: none;
      &#description{
        width:100%;
        box-sizing: border-box;
        padding: 0 80px;
      }
      &.selected{
        display: block;
      }
      >ul{
        list-style: none;
        margin:44px auto 0 auto;
        >li{
          display: inline-block;
          margin:0 46px 61px 0;
          vertical-align: top;
          &:nth-child(3n+3){
      			margin:0 0 61px 0;
      		}
          a{
            position: relative;
            display: block;
            height:177px;
            .border{
              border: 3px solid rgba(0,0,0,0);
              box-sizing: border-box;
              height: 177px;
              position: absolute;
              width: 100%;
            }
            img{
              display: block;
              box-sizing: border-box;
            }
            p{
              position: absolute;
              top:183px;
              font-size: 14px;
              color:$black;
              display: inline-block;
            }
            &:hover,&.selected{
              .border{
                border: 3px solid $red;
              }
              p{
                border-bottom:1px solid #000;
              }
            }
            .border{
              border:1px solid $mcolor;
            }
          }
        }
      }
    }
  }
  &.about_me{
    z-index:120;
    .bottom{
      bottom: 0;
      box-sizing: border-box;
      height: 45px;
      margin: 20px 0 20px;
      padding: 0 20px;
      position: relative;
      width: 100%;
      p{
        position: absolute;
        left:20px;
        bottom:0;
      }
      button{
        position: absolute;
        right:20px;
        bottom:0;
      }
    }
    form{
      width:660px;
      //height:640px;
      max-height: 640px;
      height:100%;
      padding: 0;

      .holder{
        width:100%;
        padding: 20px 20px;
        box-sizing: border-box;
        clear:both;
        height: 65%;
        max-height: 430px;
        overflow: auto;
        .left{
          float:left;
          width:270px;
        }
        .right{
          float:right;
          width:270px;
        }
      }
    }
    input.social{
      margin:0 0 0px 0;
    }

  }
  &.settings{
    z-index:100;
    input[type='text']{
      border:$mcolor 1px solid;
      background:#fff;
      @include placeholder {
        color:$gray;
      }
    }
    .export{
      display: none;
      &.selected{
        display: block;
        position: relative;
        input{
          background:#fff;
          border:none;
        }
      }
      .icon-search{
        position: absolute;
        right:20px;
        bottom:20px;
        color:$gray;
      }
      .holder{
        position: relative;
      }
    }
    form{
      width:980px;
      height:620px;
      margin:0 auto;
      background:#fff;
      color:#000;
      padding:20px;
      font-size: 14px;
        &:after{
          display: table;
          position: relative;
          content:" ";
          background:none;
          cursor: auto;
          box-shadow: none;
        }
      h2{
        //border-bottom:1px solid $mcolor;
      }
      >section{
        display: none;
        &.selected{
          display: block;
        }
      }
    }
    .left{
      float:left;
      margin:20px 0 0 20px;
      position: relative;
      .icon-search{
        bottom: 20px;
        position: absolute;
        right: 10px;
      }
    }
    .right{
      float:right;
      margin:20px 20px 0 0px;
    }
    ul{
      list-style:none;
      li{

      }
      &.space_bottom{
        margin:0 0 24px 0;
      }
      &.where{
        li{
          display: inline-block;
          margin:0 20px 0 0;
        }
        &.space_bottom{
          margin:0 0 20px 0;
        }
      }
    }
    .left,.right{
      width:$cell6;
    }
    textarea{
      background:#fff;
      border:1px solid $mcolor;
      margin:0 0 20px 0;
      resize:none;
      height: 32px;
      line-height: 32px;
      margin: 0 0 20px;
      padding: 0 10px;
    }
    p{
      font-size:10px;
      &.normal_font{
        font-size:14px;
      }
    }

    .articles.list{
      margin:44px 0 0 0;
      li{
        &.selected{
          article{
            border:3px solid $red;
          }
          h3{
            color:$red;
          }
        }
      }
      article{
        width:140px;
        font-size:4.67px;
        padding: 0;
        img{
          display: block;
        }
        &.none,&.small,&.full{
          height:128px;
        }
        &.full{
          header{
            img{
              margin: 0 0 0 -59%;
              width: 200%;
            }
          }
        }
        header{
          width:140px;
          min-height: auto;
        }
        .author{
          top:10px;
          .user_pic{
            .round{
              width:17px;
              height:17px;
            }
          }
          .user_name{
            strong{
              font-size:4.67px;
            }
            .date{
              font-size:4.67px;
            }
          }
        }
        h2{
          font-size: 4.67px;
          line-height: inherit;
          height:28px;
        }
        .social ul li{
          font-size: 4.67px;
          margin:0 7px 0 0;
        }
      }
    }
    .buttons_position{
      position: absolute;
      bottom:44px;
      right:80px;
      &.hidden{
        display: none;
      }
      button{
        display: inline-block;
        width:140px;
        margin:0 20px 0 0;
        vertical-align: top;
        &:last-child{
          margin:0;
        }
      }
    }
    nav{
      margin:0 0 0 0;
      ul{
        li{
          display: inline-block;
          font-weight: bold;
          margin:0 20px 0 0;
          &:last-child{
            margin:0;
          }
          a{
            color:#000;
            display: block;
            margin:0 0px 0 0;
          }

          &.selected{
            a{
              color:$red;
            }
          }
        }
      }
    }
    ul.articles{
      display: block;
      list-style: none;
      >li{
        display: inline-block;
        width:140px+20px;
        vertical-align: top;
        &:last-child{
          width:140px;
        }
        h3{
          text-transform: none;
        }
      }
    }

  }
}
