.black_tooltip{
  position:relative;
  display: inline-block;
  &:hover{
    .tooltip_holder{
      display: block;
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }
  }
  &.special{
    .tooltip_holder{
      right:70px !important;
      .tooltip_text{
        &:after{
          right:15% !important;
        }
      }
    }
  }
  .tooltip_holder{
    position: absolute;
    font-family: $font1;
    right:50%;
    top:-15px;
    display: block;
    opacity: 0;
    transition: opacity 0.1s ease-out;
    line-height: normal !important;
    .tooltip_text{
      position: relative;
      right:-50%;
      background:$black;
      border-radius: 3px;
      padding:2px 10px;
      color:#fff !important;
      font-size:10px;
      font-weight: normal;
      text-transform: none;
      white-space: nowrap;
      line-height: normal !important;
      &::after {
        content: " ";
        line-height: normal;
        position: absolute;
        top: 100%; /* At the bottom of the tooltip */
        right: 50%;
        margin-right: -5px;
        margin-top:-1px;
        border-width: 5px;
        border-style: solid;
        border-color: $black transparent transparent transparent;
        line-height: normal !important;
      }
    }
  }
}
